import { useContext } from 'react'
import CustomerTabs from '../components/Customer/CustomerTabs';
import Footer from '../components/Footer/Footer';
import Header from '../components/Header/Header'
import Sidebar from '../components/Sidebar/Sidebar'
import { HeaderText} from '../StyledComponents/pages/pages';
import { useTranslation } from "react-i18next";
import { UserContext } from '../context/UserContext';
import ChangeAlert from '../components/ChangeAlert/ChangeAlert';

export default function Clients() {
  const { t } = useTranslation('translation');
  const { changed } = useContext(UserContext);

  return (
    <>
    <div style={{ display: 'flex', width: '100%', height: '100%', backgroundColor: '#2F4050' }}>
      <div style={{ height: '100svh', minHeight: '100svh', }}>
        <Sidebar />
      </div>
      <div style={{ width: '100%', backgroundColor: '#F3F3F4' }}>
        {changed && <ChangeAlert />}
        <Header />
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px', width: '100%', marginBottom: '50px' }}>
        <HeaderText>{t('customerPage.customerManagement')}</HeaderText>
          <CustomerTabs />
        </div>
        <Footer />
      </div>
    </div>
  </>
    // <MainPageContainer>
    //   <div className="side-bar">
    //     <Sidebar />
    //   </div>
    //   <PageContentContainer>
    //     {changed && <ChangeAlert />}
    //     <Header />
    //     <Container>
    //       <HeaderText>{t('customerPage.customerManagement')}</HeaderText>
    //       <CustomerTabs />
    //     </Container>
    //     <Footer />
    //   </PageContentContainer>
    // </MainPageContainer>
  );
}