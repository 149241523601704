import { useCallback, useEffect, useMemo, useState } from 'react'
import MUIDataTable, { MUIDataTableOptions } from "mui-datatables";
import { useTranslation } from 'react-i18next';
import { ThemeProvider } from '@mui/material/styles';
import Table from '../Skeletons/Table';
import AttToken from '../../helpers/attToken';
import Checklists from '../../service/Checklists';
import FormFillingModal from './FormFillingModal';
import { getMuiTheme } from "../TableHelpers/options";
import { tableOptions } from '../TableHelpers/TableOptions';
import { StyledDiv } from '../TableHelpers/TableStyle';
import { useQuery } from '@tanstack/react-query';

type formListType = {
  dataCriacao: number,
  id: string,
  nome: string,
}

export default function FillingComp() {
  const [formId, setFormId] = useState('')
  const { t } = useTranslation('translation');

  const checkListService = useMemo(() => new Checklists(), []);

  const { data: formList, isLoading: loading } = useQuery({
    queryKey: ['getPreenchimentoWeb'],
    queryFn: async () => {
      const token = await AttToken();
      if (token) {
        const response: formListType[] = await checkListService.getPreenchimentoWeb(token)
        return response;
      }
    },
    retry: 5,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    refetchOnMount: 'always',
  })

  const columns = [
    {
      name: "nome",
      label: t('name'),
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true
      }
    },
    {
      name: "dataCriacao",
      label: 'Data de Cadastro',
      options: {
        customBodyRender: (value: number) => {
          const date = new Date(value).toLocaleString('pt-br')
          return <span style={{ marginRight: '1.5em' }}>{date}</span>
        },
        filter: true,
        sort: true,
        sortThirdClickReset: true
      }
    },
    {
      name: "id",
      label: 'Preencher',
      options: {
        display: false,
        filter: true,
        sort: true,
        sortThirdClickReset: true
      }
    },
  ];

  const translatedTextLabels: MUIDataTableOptions = {
    onRowClick: (rowData) => {
      setFormId(rowData[2]);
      //setSelectedId(value);
    },
    textLabels: {
      body: {
        noMatch: `${t('table.noMatch')}`,
        toolTip: `${t('table.toolTip')}`,
        columnHeaderTooltip: column =>
          `${t('table.columnHeaderTooltip')} ${column.label}`
      },
      pagination: {
        next: `${t('table.next')}`,
        previous: `${t('table.previous')}`,
        rowsPerPage: `${t('table.rowsPerPage')}`,
        displayRows: `${t('table.displayRows')}`
      },
      toolbar: {
        search: `${t('table.search')}`,
        filterTable: `${t('table.filterTable')}`
      },
      filter: {
        title: `${t('table.title')}`,
        reset: `${t('table.reset')}`
      },
      viewColumns: {
        title: `${t('table.viewColumnsTitle')}`
      }
    }
  }

  return (
    (loading) ? (<Table />) : (
      <StyledDiv>
        <ThemeProvider theme={getMuiTheme()}>
          <MUIDataTable
            title={'Execução de formulários'}
            data={formList as formListType[]}
            columns={columns}
            options={{ ...tableOptions, ...translatedTextLabels }}
          />
        </ThemeProvider>
        {formId &&
          <FormFillingModal formId={formId} setFormId={setFormId}
          />
        }
      </StyledDiv>
    )
  )
}