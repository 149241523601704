import React, { useEffect, useMemo, useState } from 'react'
import { Label } from '../../Notifications/style/SingleNotificationStyle'
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@tanstack/react-query';
import AttToken from '../../../helpers/attToken';
import Users from '../../../service/Users';
import { AxiosError } from 'axios';
import { TagContainer, TagSpan } from '../../../StyledComponents/Modal/generic';
import { userBackObj } from '../../Users/UserSearch';
import { AiOutlineClose } from 'react-icons/ai';
import { ConjuntoType } from '../../../service/Places';
import Checklists, { simpleFormType } from '../../../service/Checklists';
import Aprovations from '../../../service/Aprovations';
import { toast } from 'react-toastify';
import { Skeleton } from '@mui/material';

export type listaAprovadoresType = {
  [name: string]: string[]
}

type props = {
  openModalAprove: boolean,
  setOpenModalAprove: Function,
  conjunto: ConjuntoType,
  getPlaces: Function,
}

export default function EditAproveModal({
  openModalAprove,
  setOpenModalAprove,
  conjunto,
  getPlaces
}: props
) {

  const { t } = useTranslation('translation');
  const usersService = useMemo(() => new Users(), []);
  const formService = useMemo(() => new Checklists(), []);
  const aprovationService = useMemo(() => new Aprovations(), []);
  const [disable, setDisable] = useState(false);

  const [selectedUsers, setSelectedUsers] = useState<listaAprovadoresType>(conjunto.listaAprovadores || {});

  const {
    data: usersData,
    isLoading: loadingUsers,
    isFetching: fetchingUsers,
  } = useQuery({
    queryKey: ['Users'],
    queryFn: async () => {
      const token = await AttToken();
      if (token) {
        const body = { ...token }
        const result = await usersService.getAllUsers(body);
        return result.users
      }
    },
    keepPreviousData: true,
    retry: 5,
    refetchOnWindowFocus: false,
    refetchOnMount: 'always',
    onError: () => {
      console.log(AxiosError)
    }
  });

  const {
    data: forms,
    isLoading: loadingForms,
    isFetching: fetchingForms
  } = useQuery({
    queryKey: ['GET_FORMS'],
    queryFn: async () => {
      const token = await AttToken();
      if (token) {
        const body = { ...token }
        const result = await formService.getSimpleForm(body);
        return result
      }
    },
    keepPreviousData: true,
    retry: 5,
    refetchOnWindowFocus: false,
    refetchOnMount: 'always',
    onError: () => {
      console.log(AxiosError)
    }
  });

  const { mutate } = useMutation({
    mutationKey: ['UPDATE_LISTA_APROVADORES'],
    mutationFn: async () => {
      setDisable(true)
      toast.warn('Salvando alterações');
      const token = await AttToken();
      if (token) {
        const response = await aprovationService.updateListAprovers(token, conjunto.id, selectedUsers)
        return response
      }
    },
    onSuccess: () => {
      getPlaces(conjunto.cliente)
      toast.success('Alterações salvas!')
      setOpenModalAprove(false)
      setDisable(false)
    }
  })

  useEffect(() => {
    conjunto.listaAprovadores && setSelectedUsers(conjunto.listaAprovadores)
  }, [conjunto.listaAprovadores])

  const findUserName = (value: string) => {
    const userName = usersData.find((el: userBackObj) => el.id === value);
    if (userName) return userName.nome
    else return;
  }

  const findFormName = (value: string) => {
    const formName = forms && forms?.find((el: simpleFormType) => el.id === value);
    if (formName) return formName.nome
    else return false;
  }

  return (
    <>
      <div className={`modal ${openModalAprove ? "modal is-active" : "modal"}`}>
        <div className="modal-background"></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">Gerenciamento de Aprovadores</p>
          </header>
          <section className="modal-card-body">
            {loadingUsers || fetchingUsers || loadingForms || fetchingForms ? (
              <>
                <Skeleton variant='text' width={100} />
                <Skeleton variant='rounded' className='input is-large' />
              </>) :
              <>
                <Label>
                  Aprovadores associados
                </Label>
                <TagContainer>
                  {Object?.keys(selectedUsers)?.map((e: string, i: number) => (
                    selectedUsers[e] && <TagSpan key={`element${e}|index${i}`} onClick={() => {
                      delete selectedUsers[e]
                      setSelectedUsers({ ...selectedUsers })
                    }}>
                      {
                        findUserName(e)
                      }
                      <AiOutlineClose />
                    </TagSpan>
                  ))}
                </TagContainer>
                <select
                  disabled={usersData.length < 1}
                  defaultValue=''
                  className="select is-small is-fullwidth"
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                    setSelectedUsers({ ...selectedUsers, [e.target.value]: [] })
                  }}
                >
                  <option value=''>{t('select')}</option>
                  {
                    usersData
                      .sort((a: userBackObj, b: userBackObj) => { return a.nome.toLowerCase() > b.nome.toLowerCase() ? 1 : - 1 })
                      .filter((e: userBackObj) => e.status === 'ativo')
                      .filter((el: userBackObj) => el.aprovador === true)
                      .map((e: userBackObj, i: number) => (
                        <option
                          key={i}
                          disabled={selectedUsers && Object.keys(selectedUsers).includes(e.aliasname)}
                          value={e.aliasname}>
                          {e.nome}
                        </option>
                      ))
                  }
                </select>
                {Object.keys(selectedUsers).length > 0 && <Label>
                  Formulários associados
                </Label>}
                {selectedUsers && Object.keys(selectedUsers).map((user: string) => (
                  <>
                    <Label>
                      {user}
                    </Label>
                    <TagContainer>
                      {selectedUsers[user]?.map((formId: string, i: number) => (
                       // forms.includes(formId) &&
                        <TagSpan key={i}
                          onClick={() => {
                            setSelectedUsers((prev) => ({ ...prev, [user]: prev[user as keyof typeof prev].filter(ele => ele !== formId) }))
                          }}>
                          {
                            findFormName(formId)
                          }
                          <AiOutlineClose />
                        </TagSpan>
                      ))}
                    </TagContainer>
                    <select
                      defaultValue=''
                      className="select is-small is-fullwidth"
                      onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                        setSelectedUsers((prev) => ({ ...prev, [user]: [...prev[user as keyof typeof prev], e.target.value] }))
                      }}
                    >
                      <option value='' disabled>{t('select')}</option>
                      {
                        forms
                          ?.sort((a: simpleFormType, b: simpleFormType) => { return a.nome.toLowerCase() > b.nome.toLowerCase() ? 1 : - 1 })
                          ?.filter((el: simpleFormType) => {
                            const thisUser: userBackObj | null = usersData.find((u: userBackObj) => u.aliasname === user)
                            return thisUser ? thisUser?.listaEquipes.some(equipeId => el.equipes.includes(equipeId)) : ''
                          })
                          ?.map((e: simpleFormType, i: number) => (
                            <option
                              key={i}
                              disabled={selectedUsers[user] && selectedUsers[user].includes(e.id)}
                              value={e.id}>
                              {e.nome}
                            </option>
                          ))
                      }
                    </select>
                  </>
                ))}
              </>}
          </section>
          <div className='modal-card-foot' style={{ display: "flex", justifyContent: "space-between" }}>
            <button disabled={disable} className='button is-success is-small' onClick={() => mutate()}>{t('save')}</button>
            <button className='button is-warning is-small' onClick={() => setOpenModalAprove(false)}>{t('cancel')}</button>
          </div>
        </div>
      </div>
    </>
  )
}
