import React, { useState } from 'react';
import { SubItemFormFilling } from './Types';
import TicketEditModal from './Modals/TicketEditModal';
import { BsMegaphone } from 'react-icons/bs';
import { verifySubitem } from './CheckIcon';
import CheckMandatory from './CheckMandatorys';

type props = {
  subItem: SubItemFormFilling,
  itemIndex: number
}

export default function OpenTicket({ subItem, itemIndex }: props) {
  const [open, setOpen] = useState(false)

  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingBottom: '10px', backgroundColor: '#F2F2F2' }}>
      {open && (
        <TicketEditModal open={open} setOpen={setOpen} subItemIndex={subItem.id} itemIndex={itemIndex} />
      )}
      <button
        disabled={!verifySubitem(subItem)}
        className={`button ${subItem.chamadosConfig
          && subItem.chamadosConfig.chamadoObrigatorio === 'sempre'
          && (!subItem.chamados || subItem.chamados.length === 0) ? 'is-danger' : 'is-success'}`}
        onClick={() => setOpen(true)}
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        Abrir Chamado&nbsp;
        <BsMegaphone />&nbsp;
        {subItem.chamados ? `(${subItem.chamados.length})` : ''}
      </button>
    </div>
  )
}
