import { useEffect, useMemo, useRef, useState } from 'react';
import { ButtonsContainer } from './FormModalStyle';
import { Tooltip } from '@material-ui/core';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import copy from "copy-to-clipboard";
import { useTranslation } from 'react-i18next';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import Checklists, { ChecklistType } from '../../../../service/Checklists';
import { useMutation } from '@tanstack/react-query';
import AttToken from '../../../../helpers/attToken';
import { toast } from 'react-toastify';

export type linkType = {
  index: number,
  name: string,
  value: string
}

export default function FillingConfig({
  formId,
  formWeb,
  setEditModal,
  form
}: {
  formId: string,
  formWeb: boolean,
  setEditModal: Function,
  form: ChecklistType
}) {
  const { t } = useTranslation('translation');

  const [disable, setDisable] = useState(false);

  const [link, setLink] = useState<linkType[]>(form.link || [{
    index: 0,
    name: '',
    value: ''
  }])

  const checklistService = useMemo(() => new Checklists(), []);

  const copyToClipboard = () => {
    let copyText = ''
    if (linkRef.current) copyText = linkRef.current.value
    copy(copyText);
    alert(`link copiado para área de transferência`);
  }

  const linkRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (link.length > 0) {
      form.link = link?.map((e, i) => (
        e
      ))
    }
  }, [link])

  const { mutate } = useMutation({
    mutationKey: ['changeForm'],
    mutationFn: async (form: ChecklistType) => {
      toast.warn('Salvando alteração!')
      setDisable(true)
      const token = await AttToken();
      if (token) {
        const response = await checklistService.updateChecklist(token, form)
        return response
      }
    },
    onSuccess: () => {
      toast.success('Alteraçao salva!')
      setDisable(false)
      //setEditModal(-1)
    },
    onError: () => {
      toast.error('Erro ao salvar alterações')
    }
  })

  return (
    <div>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginBottom: '60px' }}>
        {/* <label className="checkbox">
          <input
            checked={objectToSend.enabledMessageBefore}
            onChange={() => setObjectToSend({ ...objectToSend, enabledMessageBefore: !objectToSend.enabledMessageBefore })}
            type="checkbox"
          />&nbsp;
          Incluir mensagem e foto (opcional) antes do formulário
        </label>
        {objectToSend.enabledMessageBefore &&
          <>
            <textarea
              value={objectToSend.messageBefore}
              onChange={(e) => setObjectToSend({ ...objectToSend, messageBefore: e.target.value })}
              className="textarea"
              maxLength={255}
            />
            <div style={{ display: 'flex' }}>
              {objectToSend.pictureBefore === '' ?
                <div
                  style={{ display: 'flex', alignItems: 'center', marginTop: '.5em' }}
                  className="file button is-small is-success">
                  <label className="file-label">
                    <input
                      onChange={(e) => renderImages(e.target.files, (el) => { setObjectToSend({ ...objectToSend, pictureBefore: el }) })}
                      className="file-input"
                      type="file"
                      name="resume"
                    />
                    <span className="file-cta">
                      <UploadFileIcon />
                      <span className="file-label">
                        Escolher foto...
                      </span>
                    </span>
                  </label>
                </div> :
                <div>
                  <img
                    style={{ marginTop: '.5em' }}
                    width={'200dvw'}
                    src={objectToSend.pictureBefore}
                    alt=""
                  />
                  <button
                    style={{ borderRadius: '50px', padding: '1px', marginTop: '.5em', marginLeft: '.5em' }}
                    className="button is-small is-danger"
                    onClick={() => setObjectToSend({ ...objectToSend, pictureBefore: '' })}
                  >
                    <DeleteIcon />
                  </button>
                </div>}
            </div>
          </>}
        <hr />
        <label className="checkbox">
          <input
            checked={objectToSend.enabledMessageAfter}
            onChange={() => setObjectToSend({ ...objectToSend, enabledMessageAfter: !objectToSend.enabledMessageAfter })}
            type="checkbox"
          />&nbsp;
          Incluir mensagem e foto (opcional) depois do formulário
        </label>
        {objectToSend.enabledMessageAfter &&
          <>
            <textarea
              value={objectToSend.messageAfter}
              onChange={(e) => setObjectToSend({ ...objectToSend, messageAfter: e.target.value })}
              className="textarea"
              maxLength={255}
            />
            <div style={{ display: 'flex' }}>
              {objectToSend.pictureAfter === '' ?
                <div
                  style={{ display: 'flex', alignItems: 'center', marginTop: '.5em' }}
                  className="file button is-small is-success">
                  <label className="file-label">
                    <input
                      onChange={(e) => renderImages(e.target.files, (el) => { setObjectToSend({ ...objectToSend, pictureAfter: el }) })}
                      className="file-input"
                      type="file"
                      name="resume"
                    />
                    <span className="file-cta">
                      <UploadFileIcon />
                      <span className="file-label">
                        Escolher foto...
                      </span>
                    </span>
                  </label>
                </div> :
                <div>
                  <img
                    style={{ marginTop: '.5em' }}
                    width={'200dvw'}
                    src={objectToSend.pictureAfter}
                    alt=""
                  />
                  <button
                    style={{ borderRadius: '50px', padding: '1px', marginTop: '.5em', marginLeft: '.5em' }}
                    className="button is-small is-danger"
                    onClick={() => setObjectToSend({ ...objectToSend, pictureAfter: '' })}
                  >
                    <DeleteIcon />
                  </button>
                </div>}
            </div>
          </>}
        <hr />
        <label className="checkbox">
          <input
            checked={objectToSend.loop}
            onChange={() => setObjectToSend({ ...objectToSend, loop: !objectToSend.loop, urlChecked: false })}
            type="checkbox"
          />&nbsp;
          Habilitar loop no formulário
        </label>
        <label className="checkbox">
          <input
            checked={objectToSend.urlChecked}
            onChange={() => setObjectToSend({ ...objectToSend, urlChecked: !objectToSend.urlChecked, loop: false })}
            type="checkbox"
          />&nbsp;
          Habilitar site de redirecionamento
        </label>

        {objectToSend.urlChecked &&
          <>
            <label>. URL</label>
            <input
              value={objectToSend.url}
              onChange={(e) => setObjectToSend({ ...objectToSend, url: e.target.value })}
              type='url'
              className='input is-small is-fullwidth'
              placeholder='www.example.com'
            />
          </>
        }<hr /> */}
        {formWeb && <>
          <p>
           {t('checklists.subitemModal.preenchimento.Link')}
          </p>
          <div style={{ display: 'flex', width: '100%' }}>
            <input
              ref={linkRef}
              disabled
              value={link.map((e, i) => {
                if (e.value && e.name) {
                  if (i === 0) return `${process.env.REACT_APP_AXYMA_FRONT_URL}preenchedor/${formId}?${e.name}=${e.value}`
                  else return `&${e.name}=${e.value}`
                }
              }).join('') || `${process.env.REACT_APP_AXYMA_FRONT_URL}preenchedor/${formId}`}
              className='input is-small is-fullwidth'
            />
            <Tooltip title={` ${t('checklists.subitemModal.preenchimento.copiar')}`}>
              <button
                onClick={() => copyToClipboard()}
                className='button is-small is-success'>
                <ContentCopyIcon />
              </button>
            </Tooltip>
          </div>
          <div style={{ marginTop: '.5em' }} className='columns'>
            <div className='column'>
              {t('name')}
            </div>
            <div className='column'>
              {t('checklists.subitemModal.preenchimento.valor')}
            </div>
          </div>
          {link.map((e, i) => (
            <>
              <div style={{ display: 'flex', marginBottom: '.5em' }}>
                <input
                  value={link[i].name}
                  onChange={(e) => {
                    const newState = link.map((item, index) => {
                      if (index === i) {
                        item.name = e.target.value;
                      }
                      return item
                    })
                    setLink(newState)
                  }}
                  className='input is-small column is-2'
                />
                <input
                  disabled={link[i].name === ''}
                  style={{ marginLeft: '.5em' }}
                  value={link[i].value}
                  onChange={(e) => {
                    const newState = link.map((item, index) => {
                      if (index === i) {
                        item.value = e.target.value;
                      }
                      return item
                    })
                    setLink(newState)
                  }}
                  className='input is-small column'
                />
                {i === 0 ?
                  <button
                    style={{ borderRadius: '50px', padding: '1px', marginLeft: '.5em' }}
                    className="button is-small is-success"
                    onClick={() => setLink([...link, { name: '', value: '', index: link.length }])}
                  >
                    <AddCircleIcon />
                  </button>
                  :
                  <button
                    style={{ borderRadius: '50px', padding: '1px', marginLeft: '.5em' }}
                    className="button is-small is-danger"
                    onClick={() => setLink(link.filter((el) => el.index !== e.index))}
                  >
                    <DeleteIcon />
                  </button>}
              </div>
            </>
          ))}
        </>}
      </div>
      <ButtonsContainer>
        <button
          disabled={disable}
          onClick={() => {
            mutate(form)
          }}
          className='button is-success is-small'>
          {t('save')}
        </button>
        <button
          onClick={() => setEditModal(-1)}
          className='button is-light is-small'>
          {t('close')}
        </button>
      </ButtonsContainer>
    </div>
  )
}
