import { useCallback, useEffect, useMemo, useState } from 'react'
import AttToken from '../../helpers/attToken'
import { useMutation, useQuery } from '@tanstack/react-query'
import register, { RequestType, userReportsType } from '../../service/PowerBi';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import queryClient from '../../service/query';
import Loading from '../Loading/Loading';
import Users from '../../service/Users';
import UserComponent, { userBackObj } from './UserComponent';
import DeleteIcon from '@mui/icons-material/Delete';
import { GrUpdate } from 'react-icons/gr';

export type UserAccessType = {
  [userName: string]: string[]
}

export type empresaReportsType = {
  [id: string]: singleEmpresaType
}

export type singleEmpresaType = {
  datasetId: string,
  datasetWorkspaceId: string
  id: string,
  name: string,
  users: {
    name: [key: string],
  }
}
const usersService = new Users();
const servicePowerBi = new register();

export default function DashboardLicense({ userReportsData, isLoadingUserReports }: { userReportsData: any, isLoadingUserReports: boolean }) {
  const { t } = useTranslation('translation');
  const [url, setUrl] = useState<string>('');
  const [userReports, setUserReports] = useState<userReportsType[]>();
  const [block, setBlock] = useState(false)
  const [selectedReport, setSelectedReport] = useState<userReportsType>({
    datasetId: '',
    datasetWorkspaceId: '',
    id: '',
    name: '',
    users: [],
  });

  const [userAccess, setUsersAccess] = useState({} as UserAccessType);
  const [empresaReports, setEmpresaReports] = useState<singleEmpresaType[]>([])
  const [usersAdded, setUsersAdded] = useState<any>([]);
  const [users, setUsers] = useState<userBackObj[]>([])
  const [workspaceId, setWorkspaceId] = useState('')

  const { mutate } = useMutation({
    mutationKey: ['REGISTER_WORKSPACE'],
    mutationFn: async (value: string) => {
      toast.warn('Salvando alteração')
      setBlock(true)
      const token = await AttToken()
      if (token) {
        const args = {
          empresa: token.userEmpresa,
          userAdm: token.username,
          url: value
        }
        const response = await servicePowerBi.registerWorkSpace(token.userToken, args)
        setUserReports(response.reports);
        return response
      }
    },
    onSuccess: () => {
      queryClient.resetQueries(['GET_USER_REPORTS'])
      queryClient.resetQueries(['GET_EMPRESA_REPORTS'])
      toast.success('Alteração salva com sucesso');
    }
  })

  const { data: empresaReportsData, isLoading: isLoadingEmpresas } = useQuery({
    queryKey: ['GET_EMPRESA_REPORTS'],
    queryFn: async () => {
      const token = await AttToken()
      if (token) {
        const args = {
          empresa: token.userEmpresa, //'-Lmyih0t3MjeBjSGrNxE',
          userAdm: token.username,
        }
        const response = await servicePowerBi.getEmpresaReports(token.userToken, args)
        return response
      }
    },
    refetchOnMount: false,
    keepPreviousData: true,
    retry: 1,
    refetchOnWindowFocus: false,
  })

  useEffect(() => {
    if (empresaReportsData)
      setWorkspaceId(String(Object.keys(empresaReportsData)))
  }, [])

  const transform = useCallback((obj: empresaReportsType) => {
    if (!isLoadingEmpresas && obj) {
      const keys = Object?.keys(obj)
      const newObj = Object?.values(obj[keys[0]])
      setEmpresaReports(newObj as unknown as singleEmpresaType[])
    }
  }, [isLoadingEmpresas])

  useEffect(() => {
    transform(empresaReportsData)
  }, [empresaReportsData, transform])

  const { data: userData } = useQuery({
    queryKey: ['getUsers'],
    queryFn: async () => {
      const token = await AttToken();
      if (token) {
        const body = { ...token }
        const result = await usersService.getAllUsers(body);
        return result.users
      }
    },
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    refetchOnMount: 'always',
  })

  const { mutate: updateLicense } = useMutation({
    mutationKey: ['updateLicense'],
    mutationFn: async () => {
      toast.warn('Salvando')
      const token = await AttToken()
      if (token) {
        const args = {
          empresa: token.userEmpresa, //'-Lmyih0t3MjeBjSGrNxE',
          userAdm: token.username,
        }
        const update: RequestType = { [userReportsData[0].datasetWorkspaceId]: { [userReportsData[0].id + '/users']: userAccess } }
        const response = await servicePowerBi.manageLicence(token.userToken, args, update)
        return response
      }
    },
    onSuccess: () => {
      toast.success('Salvo!')
      queryClient.resetQueries(['GET_EMPRESA_REPORTS'])
      queryClient.resetQueries(['GET_USER_REPORTS'])
    }
  })

  const { mutate: updateWorkspace } = useMutation({
    mutationKey: ['UPDATE_WORKSPACE'],
    mutationFn: async () => {
      toast.warn('Atualizando workspace')
      const token = await AttToken()
      if (token && empresaReports) {
        const args = {
          empresa: token.userEmpresa,
          userAdm: token.username,
          workspaceId: workspaceId
        }
        const response = await servicePowerBi.powerbiUpdateWorkspace(token.userToken, args)
        return response
      }
    },
    onSuccess: () => {
      queryClient.resetQueries(['GET_USER_REPORTS'])
      queryClient.resetQueries(['GET_EMPRESA_REPORTS'])
      toast.success('Workspace atualizado!')
    }
  })

  useEffect(() => {
    if (!isLoadingUserReports) {
      if (userReportsData) {
        setUsersAccess(userReportsData[0]?.users)
        setUserReports(userReportsData)
        setUsersAdded(userData?.filter((e: userBackObj) => {
          if (userReportsData[0]?.users) {
            e.matricula = userReportsData[0]?.users[e.aliasname]
            return (
              Object?.keys(userReportsData[0]?.users).includes(e.aliasname)
            )
          } else return e
        }))
        setUsers(userData?.filter((e: userBackObj) => !usersAdded?.some((user: any) => user.nome === e.nome)))
      }
    }
  }, [userReportsData, isLoadingUserReports, userData])

  const renderSwitch = (value: string) => {
    switch (String(value)) {
      case 'rls_gerente':
        return (
          'Gerente'
        )
      case 'rls_cliente':
        return (
          'Cliente'
        )
      case 'rls_conjunto':
        return (
          'Conjunto'
        )

      case 'rls_empresa':
        return (
          'Empresa'
        )
      default:
        return (
          'Todos'
        )
    }
  }

  return (
    <>
      <button
        onClick={() => updateWorkspace()}
        style={{ marginBottom: '10px' }}
        className='button is-success is-small'>
        Atualizar workspace&nbsp;
        <GrUpdate />
      </button>
      {userReports && (userReports?.length === 0 || userReports === undefined) ? <label>
        <p style={{ textAlign: 'left', margin: '2px', fontWeight: '600' }}>Insira uma URL de relatório do PowerBI para cadastrar:</p>
        <div style={{ display: 'flex' }}>
          <input value={url} onChange={(e) => {
            setUrl(e.target.value)
          }} className='input is-small is-fullwidth' />
          <button onClick={(e) => {
            e.preventDefault()
            mutate(url)
          }}
            disabled={url === '' || block}
            type='submit'
            className='button is-success is-small'
          >
            Enviar url
          </button>
        </div>
      </label> :
        <>
          {!userReports ? <Loading /> :
            <>
              <select
                disabled={(!empresaReports?.length || empresaReports === undefined) || !userData}
                defaultValue=''
                className="select is-small is-fullwidth"
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                  if (e.target.value !== '') {
                    const userReportValue = JSON.parse(e.target.value);
                    userReportValue && setSelectedReport(userReportValue)
                  }
                }}
              >
                <option value='' >{t('select')}</option>
                {
                  empresaReports && empresaReports
                    ?.sort((a: singleEmpresaType, b: singleEmpresaType) => { return a.name?.toLowerCase() > b.name?.toLowerCase() ? 1 : - 1 })
                    ?.map((e: singleEmpresaType, i: number) => (
                      <option key={i} value={JSON.stringify(e)}>{e.name}</option>
                    ))
                }
              </select>
            </>
          }
          {userData && selectedReport.name !== '' &&
            <div style={{ marginTop: '20px' }}>
              <div style={{ display: 'flex', marginBottom: '.5em' }}>
                <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                  <div style={{ marginBottom: '.5em', display: 'flex', width: 'fit-content' }}>
                    <button onClick={() => updateLicense()} className='button is-small is-success'>Salvar alterações</button>
                  </div>
                  <table className="table" style={{ textAlign: 'left' }}>
                    <thead>
                      <tr>
                        <th>Nome</th>
                        <th>Email</th>
                        <th>Perfil</th>
                        <th>Função</th>
                        <th>Remover</th>
                      </tr>
                    </thead>
                    {usersAdded?.map((user: userBackObj) => (
                      <tbody>
                        <tr>
                          <th>{user.nome}</th>
                          <td>{user.email}</td>
                          <td>{user.perfil}</td>
                          <td>{renderSwitch(user.matricula[0])}</td>
                          <td>
                            <button
                              className='button is-small is-danger'
                              onClick={() => {
                                setUsersAdded(usersAdded.filter((e: userBackObj) => e.id !== user.id))
                                let userData = userAccess
                                delete userData[user.id]
                                setUsersAccess(userData)
                                setUsers([...users, user])
                              }}
                            >
                              <DeleteIcon />
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    ))}
                  </table>
                </div>
              </div>
              <UserComponent
                onChanged={(e) => {
                  setUsersAccess({
                    ...userAccess, [e.userName]: [e.value]
                  })
                  const currentUser = userData.find((curr: any) => curr.id === e.userName)
                  setUsersAdded([...usersAdded, { ...currentUser, matricula: [e.value] }])
                  setUsers(userData.filter((el: any) => el.email !== currentUser.email))
                }}
                userData={userData?.filter((e: userBackObj) => !usersAdded?.some((user: any) => user.nome === e.nome))} />
            </div>}
        </>
      }
    </>
  )
}