import { useContext, useMemo, useState } from 'react'
import { Team, TeamServiceTypes } from '../../service/Teams'
import { useQuery } from '@tanstack/react-query';
import AttToken from '../../helpers/attToken';
import Clients, { ClientsWithChildren } from '../../service/Clients';
import Loading from '../Loading/Loading';
import { Accordion, AccordionSummary } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Label } from '../Notifications/style/SingleNotificationStyle';
import { Skeleton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { UserContext } from '../../context/UserContext';


type props = {
  tipoServico: string;
  tipoLocalAssocia: TeamServiceTypes[] | null;
  setShowingAssociation: Function;
  nomeServico: string;
  equipe: Team
}

export default function TypePlacesTeamModal({ tipoServico, tipoLocalAssocia, setShowingAssociation, nomeServico, equipe }: props) {
  const thisTipoLocal = tipoLocalAssocia ? tipoLocalAssocia.find(e => e.tipo === tipoServico) || null : null;
  const [expanded, setExpanded] = useState<string | boolean>(false);

  const handleChange = (isExpanded: boolean, panel: string) => {
    setExpanded(isExpanded ? panel : false);
  };

  const { t } = useTranslation('translation');

  const CustomerService = useMemo(() => new Clients(), []);

  const { data: ClientesChildren, isLoading: isLoadingClientesChildren, isFetching } = useQuery({
    queryKey: ['ClientesChildren'],
    queryFn: async () => {
      const data = await AttToken();
      if (data) {
        const teams = await CustomerService.getAllWithSets(data, tipoServico);
        return teams
      } else throw new Error('aaaa')
    },
    retry: 10
  });

  if (isFetching || isLoadingClientesChildren) return (
    <>
      <Skeleton width={300} height={40} />
      <Skeleton width={180} height={50} style={{ marginTop: -10 }} />
      <Skeleton width={300} height={40} style={{ marginTop: -10 }} />
      <Skeleton width={120} height={40} style={{ marginTop: -10 }} />
      <Skeleton width={'100%'} height={80} style={{ marginTop: -10 }} />
    </>
  )
  return (
    <div>
      <h1>{`${t('teamManage.registered.edit')} - ${nomeServico}`}</h1>
      <button
        className="button is-success is-small"
        onClick={() => {
          setShowingAssociation(false);
        }}
      >
        <ArrowBackIcon />&nbsp;{t('teamManage.registered.voltar')}
      </button>
      <h1>{t('teamManage.registered.AssociacaoConjuntos')}</h1>
      <h2>{t('teamManage.registered.Clientes')}</h2>
      {isLoadingClientesChildren || isFetching || !ClientesChildren ? <Loading /> : (
        ClientesChildren.map((e: ClientsWithChildren, i: number) => (
          e &&
          <AccordeonPlaces
            expanded={expanded}
            handleChange={handleChange}
            client={e}
            key={e.id}
            thisTipoLocal={thisTipoLocal}
            equipe={equipe}
            tipoServico={tipoServico}
          />
        ))
      )}
      {(!isLoadingClientesChildren && !isFetching) && ClientesChildren.length === 0 &&
        <div style={{ backgroundColor: 'orange', padding: '0px 5px 0px 5px', borderRadius: '5px', height: 'fit-content' }}>
          <span style={{ fontSize: '13px' }}>
            * Você não possui nenhuma estrutura geográfica associada a
            esse tipo, por favor associe em "Gestão de Serviços" na aba de "Gestão de Local"
            ou em "Gestão de Cliente" na aba "Meus Clientes".
          </span>
        </div>
      }
    </div>
  )
}

type AccordeonPlacesParams = {
  client: ClientsWithChildren;
  thisTipoLocal: TeamServiceTypes | null;
  equipe: Team,
  tipoServico: string
  expanded: string | boolean,
  handleChange: Function
}

const AccordeonPlaces = ({ client, thisTipoLocal, equipe, tipoServico, expanded, handleChange }: AccordeonPlacesParams) => {
  const [subExpanded, setSubExpanded] = useState(-1);

  const [cnjsArr, setCnjsArr] = useState(thisTipoLocal ? thisTipoLocal.cnjs || [] : []);

  const { userData } = useContext(UserContext);

  const allSetsFromClient: string[] = [];
  client.locaisList.forEach((local) => {
    local.conjuntosList.forEach((cnj) => allSetsFromClient.push(cnj.id))
  })

  return (
    <div key={client.id}>
      <Accordion style={{ marginBottom: '10px' }} expanded={expanded === client.id}  >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon
            onClick={(isExpanded) => {
              if (expanded === client.id) {
                handleChange(!isExpanded, '')
              } else
                handleChange(isExpanded, client.id)
            }}
          />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Label className='checkbox'>
            <input
              type='checkbox'
              style={{ marginLeft: '10px', marginRight: '10px' }}
              checked={allSetsFromClient.every(set => cnjsArr.includes(set))}
              disabled={!userData.formMigrado && allSetsFromClient.every(set => cnjsArr.includes(set))}
              onChange={(event) => {
                const cnjs: string[] = [];
                if (event.target.checked) {
                  client.locaisList.forEach((local) => {
                    local.conjuntosList.forEach((cnj) => {
                      setCnjsArr((prev) => prev ? [...prev, cnj.id] : [cnj.id])
                      cnjs.push(cnj.id)
                    })
                  })
                  if (equipe.tiposLocaisAssocia) {
                    equipe.tiposLocaisAssocia.forEach(el => {
                      if (el.tipo === tipoServico) {
                        if (el.cnjs) el.cnjs = [...el.cnjs, ...cnjs];
                        else el.cnjs = cnjs;
                      }
                    })
                  }
                }
                else {
                  client.locaisList.forEach((local) => {
                    local.conjuntosList.forEach((cnj) => {
                      setCnjsArr((prev) => prev.filter(el => el !== cnj.id))
                      cnjs.push(cnj.id)
                    })
                  })
                  if (equipe.tiposLocaisAssocia) {
                    equipe.tiposLocaisAssocia.forEach(el => {
                      if (el.tipo === tipoServico) {
                        if (el.cnjs) el.cnjs = el.cnjs.filter(set => !cnjs.includes(set));
                        else el.cnjs = [];
                      }
                    })
                  }
                }
              }}
            />
            <span style={{ fontWeight: 'bold', color: 'black' }}>{client.nome}</span>
          </Label>
        </AccordionSummary>
        <h2 style={{ marginLeft: '10px', fontSize: '0.8xem' }}>Locais:</h2>
        {client.locaisList && client.locaisList.map((e, i) => {
          const allSetsFromPlace: string[] = e.conjuntosList.map((cnj) => cnj.id)
          return (
            <div key={i}>
              <Accordion style={{ backgroundColor: '#e5e5e5', borderRadius: '0', margin: '10px' }} expanded={subExpanded === i} >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  key={i}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  IconButtonProps={{
                    onClick: () => subExpanded === i ? setSubExpanded(-1) : setSubExpanded(i)
                  }}
                >
                  <Label className='checkbox'>
                    <input
                      type='checkbox'
                      style={{ marginLeft: '10px', marginRight: '10px' }}
                      checked={allSetsFromPlace.every(set => cnjsArr.includes(set))}
                      disabled={!userData.formMigrado && allSetsFromPlace.every(set => cnjsArr.includes(set))}
                      onChange={(event) => {
                        const cnjs: string[] = [];
                        if (event.target.checked) {
                          e.conjuntosList.forEach((cnj) => {
                            setCnjsArr((prev) => prev ? [...prev, cnj.id] : [cnj.id])
                            cnjs.push(cnj.id)
                          })
                          if (equipe.tiposLocaisAssocia) {
                            equipe.tiposLocaisAssocia.forEach(el => {
                              if (el.tipo === tipoServico) {
                                if (el.cnjs) el.cnjs = [...el.cnjs, ...cnjs];
                                else el.cnjs = cnjs;
                              }
                            })
                          }
                        } else {
                          e.conjuntosList.forEach((cnj) => {
                            setCnjsArr((prev) => prev.filter(el => el !== cnj.id))
                            cnjs.push(cnj.id)
                          })
                          if (equipe.tiposLocaisAssocia) {
                            equipe.tiposLocaisAssocia.forEach(el => {
                              if (el.tipo === tipoServico) {
                                if (el.cnjs) el.cnjs = el.cnjs.filter(set => !cnjs.includes(set));
                                else el.cnjs = [];
                              }
                            })
                          }
                        }
                      }}
                    />
                    <span style={{ fontWeight: 'bold', color: 'black' }}>{e.nome}</span>
                  </Label>
                </AccordionSummary>
                <h2 style={{ marginLeft: '10px', fontSize: '0.6em' }}>Conjuntos:</h2>
                {e.conjuntosList && e.conjuntosList.map((el, ind) => {
                  return (
                    <Label className='checkbox' key={ind}>
                      <input
                        type='checkbox'
                        style={{ marginLeft: '25px', marginRight: '10px' }}
                        checked={cnjsArr.includes(el.id)}
                        disabled={!userData.formMigrado && cnjsArr.includes(el.id)}
                        onChange={(event) => {
                          const cnjs: string[] = [];
                          if (event.target.checked) {
                            setCnjsArr((prev) => prev ? [...prev, el.id] : [el.id])
                            cnjs.push(el.id)
                            if (equipe.tiposLocaisAssocia) {
                              equipe.tiposLocaisAssocia.forEach(el => {
                                if (el.tipo === tipoServico) {
                                  if (el.cnjs) el.cnjs = [...el.cnjs, ...cnjs];
                                  else el.cnjs = cnjs;
                                }
                              })
                            }
                          } else {
                            setCnjsArr((prev) => prev.filter(ele => ele !== el.id))
                            cnjs.push(el.id)
                            if (equipe.tiposLocaisAssocia) {
                              equipe.tiposLocaisAssocia.forEach(el => {
                                if (el.tipo === tipoServico) {
                                  if (el.cnjs) el.cnjs = el.cnjs.filter(set => !cnjs.includes(set));
                                  else el.cnjs = [];
                                }
                              })
                            }
                          }
                        }}
                      />
                      {el.nome}
                    </Label>
                  )
                })}
              </Accordion>
            </div>
          )
        })}
      </Accordion>
    </div>
  )
}