import React, { useCallback, useContext, useMemo, useState } from 'react';
import { GenericHeader, TagContainer, TagSpan } from '../../../../StyledComponents/Modal/generic';
import { UserContext } from '../../../../context/UserContext';
import Clients, { ClientType } from '../../../../service/Clients';
import { useTranslation } from 'react-i18next';
import { Label } from '../../../Notifications/style/SingleNotificationStyle';
import { AiOutlineClose } from 'react-icons/ai';
import AttToken from '../../../../helpers/attToken';
import { toast } from 'react-toastify';
import queryClient from '../../../../service/query';

type props = {
  open: boolean,
  setOpen: Function,
  selectedCustomer: string,
  customers: ClientType[]
}

export default function CustomerTypeAssoci({ open, setOpen, customers, selectedCustomer }: props) {
  const thisCustomer = customers.filter(e => e.id === selectedCustomer)[0]
  const { serviceTypesList, setChanged } = useContext(UserContext);
  const { t } = useTranslation('translation');
  const [selectedServices, setSelectedServices] = useState<string[]>(thisCustomer.tiposServico || []);

  const customerService = useMemo(() => new Clients(), []);

  const updateCustomer = useCallback(async () => {
    try {
      const token = await AttToken();
      if (token) {
        await customerService.addServiceType({ ...token }, selectedServices, selectedCustomer);
        setOpen(false);
        queryClient.resetQueries(['GET_CUSTOMERS'])
        toast.success('Tipos Adicionados Com Sucesso!');
      }
    }
    catch (err) {
      return console.log(err);
    }
  }, [customerService, selectedServices, setOpen, selectedCustomer])

  const findServiceName = (value: string) => {
    const serviceName = serviceTypesList.find((el) => el.tipoId === value);
    if (serviceName) return serviceName.nome
    else return 'teste';
  }

  return (
    <div className={`modal ${open ? "modal is-active" : "modal"}`}>
      <div className="modal-background" onClick={() => setOpen(false)} />
      <div className="modal-card" onSubmit={(e: React.FormEvent) => e.preventDefault()}>
        <header className="modal-card-head" style={{ display: "flex", flexDirection: "column" }}>
          <GenericHeader>
            {t('places.Associar_Tipos_de_Serviço')}
          </GenericHeader>
        </header>
        <div className="modal-card-body" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column"
            }}
          >
            <Label>{t('Services.servicesManagement.kind')}</Label>
            <TagContainer>
              {selectedServices && selectedServices.length > 0 && selectedServices.map((e: string, i: number) => (
                <TagSpan key={i} onClick={() => {
                  setSelectedServices(selectedServices.filter(el => el !== e))
                }}>
                  {findServiceName(e)}
                  <AiOutlineClose />
                </TagSpan>
              ))}
            </TagContainer>
            <select
              disabled={serviceTypesList.length < 1}
              defaultValue=''
              className="select is-small"
             // placeholder="Selecione as Tags"
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                setSelectedServices([...selectedServices, e.target.value])
              }}
            >
              <option value='' >{t('select')}</option>
              {
                serviceTypesList && serviceTypesList.sort((a, b) => { return a.nome.toLowerCase() > b.nome.toLowerCase() ? 1 : - 1 }).map((e, i) => (
                  <option key={Math.random()} disabled={selectedServices && selectedServices.includes(e.tipoId)} value={e.tipoId}>{e.nome}</option>
                ))
              }
            </select>
            <h1 style={{ marginTop: '10px', backgroundColor: '#ffcc00', color: 'black', padding: '5px', borderRadius: '10px' }}>
              {t('places.click_to_save')}
            </h1>
          </div>
        </div>
        <div className='modal-card-foot' style={{ display: "flex", justifyContent: "space-between" }}>
          <button
            type='button'
            className="button is-success is-small"
            onClick={() => {
              localStorage.setItem('changedHirarquia', 'true');
              setChanged(true);
              updateCustomer()
            }}
          >
            {t('save')}
          </button>
          <button
            type="button"
            onClick={() => { setOpen(false) }}
            className="button is-small is-warning"
          >
            {t('cancel')}
          </button>
        </div>
      </div>
    </div>
  )
}
