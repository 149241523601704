import { useMutation, useQuery } from "@tanstack/react-query";
import AttToken from "../../../../helpers/attToken";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Clients, { ClientType } from "../../../../service/Clients";
import { useTranslation } from "react-i18next";
import { Label } from "../../../../StyledComponents/Input/generic";
import { ButtonsContainer, HorizontalLine, TimeSelectDiv } from "./FormModalStyle";
import { WeightInput } from "../../FormStyle";
import { toast } from "react-toastify";
import TicketsAndNotifications, { typeToSend } from "../../../../service/Tickets_Notifications";
import { TagContainer, TagSpan } from "../../../../StyledComponents/Modal/generic";
import { IoCloseCircleOutline } from "react-icons/io5";
import Checklists, { ChecklistType } from "../../../../service/Checklists";
import { Skeleton } from "@mui/material";
import { Typography } from "@material-ui/core";

export type generalType = {
  chamadoObrigatorio: boolean,
  cliente: string,
  delegacao: boolean,
  distanciaLimite: number | undefined,
  duracaoValidade: number,
  empresa: string,
  formulario: string,
  gravaPOS: boolean,
  id: string,
  limiteChamado: number,
  qrcode: boolean,
  tipoChamado: number[],
  tipoValidade: string | null,
}

const initialState: generalType = {
  chamadoObrigatorio: false,
  cliente: "",
  delegacao: false,
  distanciaLimite: undefined,
  duracaoValidade: 0,
  empresa: "",
  formulario: "",
  gravaPOS: false,
  id: "",
  limiteChamado: 0,
  qrcode: false,
  tipoChamado: [],
  tipoValidade: ""
}

type props = {
  setEditModal: Function,
  formId: string,
  form: ChecklistType
}

export default function GeneralTab({ setEditModal, formId, form }: props) {
  const { t } = useTranslation('translation');
  const customerService = useMemo(() => new Clients(), []);
  const NotificationsService = useMemo(() => new TicketsAndNotifications(), []);
  const checklistService = useMemo(() => new Checklists(), []);
  const [disabled, setDisabled] = useState(false)
  const formAnonimo = form.preenchimentoDeslogado && form.preenchimentoWeb;
  const [general, setGeneral] = useState<generalType>({ ...initialState, cliente: formAnonimo ? 'anonimo' : '' })
  const [parsed, setParsed] = useState<string[]>([])

  const userDataLocal = localStorage.getItem('userDataLocal')

  const {
    isFetching,
    data,
    isLoading,
  } = useQuery({
    queryKey: ['customers'],
    queryFn: async () => {
      const token = await AttToken();
      if (token) {
        const body = { ...token, empresa: token.userEmpresa };
        const result = await customerService.getAllClients(body, true);
        return result.clientList
      }
    },
    keepPreviousData: true,
    retry: 5,
    refetchOnWindowFocus: false,
    refetchOnMount: 'always',
  });

  const { data: ticketsData } = useQuery({
    queryKey: ['getTickets'],
    queryFn: async () => {
      const token = await AttToken()
      if (token) {
        const response = await NotificationsService.getTypes(token, 'chamado')
        return response;
      }
    },
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    refetchOnMount: 'always',
  });

  const { data: formData, isLoading: isLoadingForm, mutate } = useMutation({
    mutationKey: ['getFormsData'],
    mutationFn: async (value: string) => {
      const token = await AttToken()
      if (token) {
        const response = await checklistService.getFormConfigData(token, formId, value)
        return response
      }
    }
  });

  const setStateGeneral = useCallback(() => {
    setGeneral({
      chamadoObrigatorio: formData.chamadoObrigatorio || false,
      cliente: general.cliente,
      delegacao: formData.delegacao || false,
      distanciaLimite: formData.distanciaLimite || 0,
      duracaoValidade: formData.duracaoValidade || 0,
      empresa: formData.empresa,
      formulario: formId,
      gravaPOS: formData.gravaPOS || false,
      id: formData.id,
      limiteChamado: formData.limiteChamado || 0,
      qrcode: formData.qrcode || false,
      tipoChamado: formData.tipoChamado || [],
      tipoValidade: formData.tipoValidade || '',
    })
  }, [formData, formId, general.cliente])

  useEffect(() => {
    formData && setStateGeneral()
  }, [formData, setStateGeneral])

  useEffect(() => {
    if (formAnonimo) {
      mutate('anonimo')
    }
  }, [formAnonimo, mutate])

  const customerSelectRef = useRef<HTMLSelectElement>(null);

  const { mutate: editConfig } = useMutation({
    mutationKey: ['getFormsData'],
    mutationFn: async (value: string) => {
      setDisabled(true)
      toast.warn(`${t('saving')}`)
      const token = await AttToken()
      if (token) {
        const clienteFormulario = {
          chamadoObrigatorio: general.chamadoObrigatorio,
          cliente: general.cliente,
          delegacao: general.delegacao,
          distanciaLimite: Number(general.distanciaLimite),
          duracaoValidade: general.duracaoValidade,
          empresa: token.userEmpresa,
          formulario: formId,
          gravaPOS: general.gravaPOS,
          id: `${general.cliente}_${formId}`,
          limiteChamado: general.limiteChamado,
          qrcode: general.qrcode,
          tipoChamado: general.tipoChamado,
          tipoValidade: general.tipoValidade || null
        }
        const response = await checklistService.editConfigForm(token, formId, value, clienteFormulario)
        if (customerSelectRef.current) customerSelectRef.current.value = '';
        setGeneral(prevState => prevState && { ...prevState, cliente: formAnonimo ? 'anonimo' : '' })
        return response
      }
    },
    onSuccess: () => {
      setDisabled(false)
      toast.success(`${t('salvo')}`)
    }
  })

  const findticketsName = (value: number) => {
    const ticketName = ticketsData && ticketsData.find((el: typeToSend) => Number(el.id) === value);
    if (ticketName) return ticketName.nome
    else return 'teste';
  }

  useEffect(() => {
    if (userDataLocal) {
      const userData = JSON.parse(userDataLocal)
      setParsed(userData.listaFuncionalidades)
    }
  }, [userDataLocal])

  const InputPositionRef = useRef<HTMLInputElement>(null);

  const checkPOS = () => {
    if (general.gravaPOS && general.distanciaLimite !== undefined && general.distanciaLimite <= 0) {
      setDisabled(true)
    } else
      setDisabled(false)
  }

  useEffect(() => {
    checkPOS()
  }, [general.gravaPOS, general.distanciaLimite])

  return (
    <div style={{ marginBottom: '60px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      {isLoading || isFetching ?
        <>{!formAnonimo ?
          <div style={{ width: '100%' }}>
            <Skeleton variant="text" width={100} style={{ marginBottom: '-.8em', alignSelf: 'initial', display: "flex" }} />
            <Skeleton className="input is-large" style={{ marginBottom: '-.8em' }} />
            <Typography variant="h1" style={{ display: 'flex', justifyContent: 'center' }}>{<Skeleton width={500} />}</Typography>
          </div> :
          <div style={{ width: '100%', alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
            <Skeleton className="input is-large" width={300} />
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'initial', width: '100%' }}>
              <Skeleton variant="text" width={200} />
              <Skeleton variant="text" height={15} width={200} />
              <Skeleton className="input is-large" width={200} />
              <Skeleton variant="text" width={200} />
              <Skeleton variant="text" width={250} />
            </div>
          </div>
        }
        </>
        :
        <>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '100%' }}>
            {formAnonimo ? <h1 style={{ alignSelf: 'center', fontSize: '1.6em' }}>{t('formDetails.Formulario_Anonimo')}</h1> :
              <>
                <Label>
                  {t('teamManage.modal.customer')}
                </Label>
                <select
                  ref={customerSelectRef}
                  disabled={data.length < 1 || !parsed?.includes('clientes')}
                  defaultValue=''
                  className="select is-small is-fullwidth"
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                    setGeneral({ ...initialState, cliente: e.target.value })
                    mutate(e.target.value)
                  }}>
                  <option value='' >{t('select')}</option>
                  {data?.sort((a: ClientType, b: ClientType) => { return a.nome.toLowerCase() > b.nome.toLowerCase() ? 1 : - 1 })
                    ?.filter((e: ClientType) => e.status === 'ativo')
                    ?.map((e: ClientType) => (
                      <option
                        key={e.id}
                        value={e.id}>
                        {e.nome}
                      </option>
                    ))
                  }
                </select>
              </>}
            {!parsed?.includes('clientes') && <span style={{ textAlign: 'center', width: '100%' }}>Este usuário não tem acesso a lista de clientes</span>}
            {general && general.cliente === '' ?
              <div style={{ margin: '10px', border: '1px solid lightgray', padding: '15px', alignSelf: 'center' }}>
                <p style={{ color: 'gray', fontSize: '15px', fontWeight: 500, textAlign: 'center' }}>
                  {t('formDetails.GeneralTab.Selecione_um_cliente')}
                </p>
              </div> :
              <>
                {isLoadingForm ?
                  <div className="columns" style={{ display: 'flex', justifyContent: 'space-around', width: '100%', justifySelf: 'center', marginTop: '1em', flexDirection: 'column' }}>
                    <div style={{ display: 'flex' }}>
                      <div className="column">
                        <Skeleton variant="text" width={200} />
                        <Skeleton variant="text" height={15} width={200} />
                        <Skeleton className="input is-large" width={200} />
                        <Skeleton variant="text" width={200} />
                        <Skeleton variant="text" width={250} />
                      </div>
                      <div className="column">
                        <Skeleton variant="text" width={200} />
                        <Skeleton variant="text" height={15} width={50} />
                        <Skeleton className="input is-large" width={100} />
                        <Skeleton variant="text" width={200} />
                      </div>
                    </div>
                    <hr />
                    <div style={{ display: 'flex' }}>
                      {/* <div className="column">
                        <Skeleton variant="text" width={200} />
                        <Skeleton variant="text" height={15} width={150} />
                        <Skeleton className="input is-large" width={200} />
                      </div> */}
                      <div className="column">
                        <Skeleton variant="text" width={200} />
                        <Skeleton variant="text" width={150} />
                        <Skeleton variant="text" width={200} />
                      </div>
                    </div>
                  </div> :
                  <>
                    <div className="columns" style={{ display: 'flex', width: "100%", marginTop: '10px', padding: '.8em' }}>
                      <div className="column" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '100%' }}>
                        <h1>{t('formDetails.GeneralTab.Config_de_chamados')}</h1>
                        <Label>{t('formDetails.GeneralTab.Selecione_um_chamado')}</Label>
                        <TagContainer>
                          {general && general.tipoChamado && general.tipoChamado.map((e: number, i: number) => (
                            <TagSpan
                              key={i}
                              onClick={() => {
                                setGeneral({ ...general, tipoChamado: general.tipoChamado.filter((el: number) => el !== e) })
                              }}>
                              {findticketsName(e)}&nbsp;&nbsp;
                              <IoCloseCircleOutline />
                            </TagSpan>
                          ))}
                        </TagContainer>
                        <select
                          disabled={ticketsData && ticketsData.length < 1}
                          defaultValue=''
                          className="select is-small"
                          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                            setGeneral((prevState) => prevState.tipoChamado && ({ ...prevState, tipoChamado: [...prevState.tipoChamado, Number(e.target.value)] }))
                          }}
                        >
                          <option value=''>{t('select')}</option>
                          {
                            ticketsData && ticketsData?.sort((a: typeToSend, b: typeToSend) => {
                              return a.nome.toLowerCase() > b.nome.toLowerCase() ? 1 : - 1
                            })
                              .filter((e: typeToSend) => e.tipo === 'chamado')
                              .map((e: typeToSend, i: number) =>
                              (<option
                                key={i}
                                disabled={general && general.tipoChamado && general.tipoChamado.includes(Number(e.id))}
                                value={Number(e.id)}
                              >
                                {e.nome}
                              </option>
                              ))
                          }
                        </select>
                        <label>
                          {t('formDetails.GeneralTab.Limite_por_formulario')}:
                          <WeightInput
                            value={general.limiteChamado ? general.limiteChamado : undefined}
                            onChange={(e) => { general && setGeneral({ ...general, limiteChamado: Number(e.target.value) }) }}
                            type="number"
                          />
                        </label>
                        {general.tipoChamado?.length > 0 && !general.limiteChamado
                          && <span
                            style={{ color: 'red', fontSize: '12px' }}>
                            *Sem limite definido, a abertura de chamados é ilimitada
                          </span>}
                        <label style={{ textAlign: 'left' }} className='checkbox'>
                          <input
                            disabled={!general.tipoChamado?.length}
                            checked={!general.tipoChamado.length ? false : general.chamadoObrigatorio}
                            onChange={(e) => setGeneral({ ...general, chamadoObrigatorio: e.target.checked })}
                            type="checkbox"
                          />
                          &nbsp;{t('formDetails.GeneralTab.Obrigar_abertura_chamado')}
                        </label>
                      </div>
                      {!formAnonimo && <div className="column" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '100%' }}>
                        <h1>{t('formDetails.GeneralTab.Config_de_Localizacao')}</h1>
                        <div className='limit-div'>
                          <label style={{ textAlign: 'left', marginTop: '.5em' }} className='checkbox'>
                            <input checked={general.gravaPOS} onChange={(e) => setGeneral({ ...general, gravaPOS: e.target.checked })} type="checkbox" />
                            &nbsp;Habilitar controle de cerca
                          </label>
                          <Label><span className='limit'>{t('formDetails.GeneralTab.Limite_de_ate')}</span></Label>
                          <TimeSelectDiv>
                            <input
                              type="number"
                              inputMode='numeric'
                              pattern='/^[0-9]*$/'
                              min={0}
                              value={general.distanciaLimite === 0 ? undefined : general.distanciaLimite}
                              ref={InputPositionRef}
                              disabled={!general.gravaPOS}
                              onChange={(e) => setGeneral({ ...general, distanciaLimite: Number(e.target.value) })}
                              className="input is-small"

                            />
                            <input className="input is-small" disabled value={'KM'} />
                          </TimeSelectDiv>
                          {disabled && <span style={{ fontSize: '12px', color: 'red' }}>**Valor limite precisa ser maior do que 0</span>}
                        </div>
                      </div>}
                    </div>
                    {!formAnonimo && <HorizontalLine />}
                    {!formAnonimo && <div className="columns" style={{ display: 'flex', width: "100%" }}>
                      {/* <div className="column" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '100%' }}>
                        <h1>{t('formDetails.GeneralTab.Configuracoes_Validade')}</h1>
                        <Label>{t('formDetails.GeneralTab.Selecione_validade')}:</Label>
                        <TimeSelectDiv>
                          <select
                            className="select is-small"
                            defaultValue={general.tipoValidade ? general.tipoValidade : ''}
                            onChange={(e) => setGeneral({ ...general, tipoValidade: e.target.value })}
                          >
                            <option value={''}>{t('formDetails.GeneralTab.Sem_validade')}</option>
                            <option value={'pre'}>{t('formDetails.GeneralTab.fazer_ate')}</option>
                            <option value={'pos'}>{t('formDetails.GeneralTab.fazer_apos')}</option>
                          </select>
                          <input
                            value={general.duracaoValidade}
                            className="input is-small"
                            type="number"
                            onChange={(e) => setGeneral({ ...general, duracaoValidade: Number(e.target.value) })}
                            disabled={!general.tipoValidade}
                          />
                          <input className="input is-small" disabled value={t('formDetails.GeneralTab.dias')} />
                        </TimeSelectDiv>
                      </div> */}
                      <div
                        className="column"
                        style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '100%', textAlign: 'left' }}
                      >
                        <h1>{t('formDetails.GeneralTab.Configurações_formulario')}</h1>
                        <label className='checkbox'>
                          <input
                            checked={general.qrcode}
                            onChange={(e) => setGeneral({ ...general, qrcode: e.target.checked })}
                            type="checkbox"
                          />
                          &nbsp;{t('formDetails.GeneralTab.Habilitar_QR_Code')}
                        </label>
                        <label className='checkbox'>
                          <input checked={general.delegacao} onChange={(e) => setGeneral({ ...general, delegacao: e.target.checked })} type="checkbox" />
                          &nbsp;{t('formDetails.GeneralTab.Permitir_delegacao')}
                        </label>
                      </div>
                    </div>}
                  </>}
              </>
            }
          </div>
        </>
      }
      <ButtonsContainer>
        <button
          disabled={(disabled || general.cliente === '')}
          className='button is-success is-small' onClick={() => {
            editConfig(general.cliente)
          }}>
          {t('save')}
        </button>
        <button className='button is-light is-small' onClick={() => setEditModal(-1)}>
          {t('close')}
        </button>
      </ButtonsContainer>
    </div >
  )
}
