import { useContext } from 'react';
import Header from '../components/Header/Header'
import Sidebar from '../components/Sidebar/Sidebar'
import { HeaderText } from '../StyledComponents/pages/pages';
import { useTranslation } from "react-i18next";
import { UserContext } from '../context/UserContext';
import ChangeAlert from '../components/ChangeAlert/ChangeAlert';
import FillingTabs from '../components/Filling/FillingTabs';
import Footer from '../components/Footer/Footer';

export default function Filling() {
  const { t } = useTranslation('translation');
  const { changed } = useContext(UserContext);

  return (
    <>
      <div style={{display:'flex', height:'100%'}}>
          <Sidebar />
        <div style={{ width: '100%', backgroundColor: '#F3F3F4' }}>
          {changed && <ChangeAlert />}
          <Header />
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px', width: '100%', marginBottom: '50px' }}>
            <HeaderText>{t('Execuções de formulário')}</HeaderText>
            <FillingTabs />
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
}