import { Card } from '@material-ui/core'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { TitleText } from '../../Teams/style/RegisteredTeamsStyle'
import { GenericHeader, TagContainer, TagSpan } from '../../../StyledComponents/Modal/generic'
import { Label } from '../../Notifications/style/SingleNotificationStyle'
import { Input } from '../../../StyledComponents/Input/generic'
import { UserContext } from '../../../context/UserContext'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@tanstack/react-query'
import AttToken from '../../../helpers/attToken'
import TicketsAndNotifications, { notificationType, typeToCreate, typeToSend } from '../../../service/Tickets_Notifications'
import { toast } from 'react-toastify'
import queryClient from '../../../service/query'
import { SimpleTeam } from '../../../service/Teams'
import { simpleFormType } from '../../../service/Checklists'
import AssociateTeamPlaceNotifi from '../AssociateTeamPlaceNotifi'
import Select from '../../Skeletons/Select'
import SmallAcordeon from '../../Skeletons/SmallAcordeon'
import { FaArrowRight } from "react-icons/fa";
import { IoIosClose } from "react-icons/io";


type props = {
  isEditing: boolean,
  notification: notificationType,
  id: number,
  teams: SimpleTeam[],
  forms: simpleFormType[],
  isLoadingTeams: boolean,
  isLoadingForms: boolean,
  refetNotifications: Function
}

export default function AddNotificationModal({
  isEditing,
  notification,
  id,
  teams,
  forms,
  isLoadingForms,
  isLoadingTeams,
  refetNotifications,
}: props
) {
  const { popUp, openModal } = useContext(UserContext);

  const NotificationsService = useMemo(() => new TicketsAndNotifications(), []);

  const { t } = useTranslation('translation');

  const [editNotification, setEditNotification] = useState<Omit<typeToSend, 'prazo' | 'prazoHoras'>>({
    nome: '',
    mensagem: '',
    equipes: [],
    remetente: '',
    formularios: [],
    tipo: 'notificacao',
    id: 0,
    equipeLocal: null,
    notifiEm: '',
    encaminha: null
  });

  const [equipeSelecionada, setEquipeSelecionada] = useState('');
  const [showingAssociation, setShowingAssociation] = useState(false);
  const [notifiEm, setNotifiEm] = useState('sempre');

  const { mutate: create, isLoading: loadingCreate } = useMutation({
    mutationKey: ['changeNotification'],
    mutationFn: async () => {
      const token = await AttToken()
      if (token) {
        const tipeObject: typeToCreate = {
          mensagem: editNotification.mensagem,
          nome: editNotification.nome,
          tipo: editNotification.tipo,
          equipes: editNotification.equipes,
          formularios: null,
          remetente: editNotification.remetente || null,
          prazo: null,
          prazoHoras: null,
          notifiEm
        }
        const response = await NotificationsService.createTypes(token, tipeObject)
        toast.warn(`${t('notifications.waitNoti')}`);
        return response;
      }
    },
    onSuccess: () => {
      openModal();
      queryClient.resetQueries(['GET_NOTIFICATIONS'])
      toast.success(`${t('notifications.notifCreated')}`);

    },
    onError: () => {
      toast.error(`${t('notifications.errorNotif')}`);
    }
  })

  const { mutate: edit, isLoading: loadingEdit } = useMutation({
    mutationKey: ['changeNotification'],
    mutationFn: async () => {
      const token = await AttToken()
      if (token) {
        const tipeObject: typeToSend = {
          mensagem: editNotification.mensagem,
          nome: editNotification.nome,
          equipes: editNotification.equipes,
          tipo: editNotification.tipo,
          remetente: editNotification.remetente || null,
          formularios: editNotification.formularios,
          prazo: null,
          prazoHoras: null,
          notifiEm,
          equipeLocal: notification.equipeLocal || null,
          id: notification.id,
          encaminha: null
        }
        const response = await NotificationsService.updateTypes(token, id, tipeObject)
        toast.warn(`${t('notifications.waitEditNot')}`);
        refetNotifications()
        return response;
      }
    },
    onSuccess: () => {
      queryClient.resetQueries(['getNotifications'])
      openModal();
      toast.success(`${t('notifications.notifEdit')}`);

    },
    onError: () => {
      toast.error(`${t('notifications.errorEditNotif')}`);
    }
  })

  useEffect(() => {
    if (isEditing) {
      setEditNotification({
        ...notification
      })
      setNotifiEm(notification.notifiEm || '')
    }
  }, [isEditing, notification])

  const findTeamsName = (value: string) => {
    const teamName = teams && teams.find((el: SimpleTeam) => el.id === value);
    if (teamName) return teamName.nome
    else return 'teste';
  }


  const optionsNotifi = {
    sempre: 'Sempre',
    nunca: 'Nunca',
    naoConformidade: 'Apenas em caso de não conformidade'
  }

  return (
    <Card>
      <TitleText>
        <div className={`modal ${popUp ? "modal is-active" : "modal"}`}>
          <div className="modal-background" onKeyDown={() => { }} onClick={() => openModal()} />
          <div className="modal-card" onSubmit={(e: React.FormEvent) => e.preventDefault()}>
            <header className="modal-card-head" style={{ display: "flex", flexDirection: "column" }}>
              <GenericHeader>
                {isEditing ?
                  t('notificationsAndTickets.notifications.editNotification') :
                  t('notificationsAndTickets.notifications.createNotification')}
              </GenericHeader>
            </header>
            <div style={{ justifyContent: 'space-around' }} className="modal-card-body">
              {isLoadingTeams || isLoadingForms ? (
                <>
                  <div style={{ marginBottom: '.5em' }}>
                    <Select />
                  </div>
                  <div style={{ marginBottom: '.5em' }}>
                    <Select />
                  </div>
                  <div style={{ marginBottom: '2em' }}>
                    <Select />
                  </div>
                  <div style={{ marginBottom: '.5em' }}>
                    <SmallAcordeon />
                  </div>
                  <div style={{ marginBottom: '.5em', display: 'flex', justifyContent: 'space-around' }}>
                    <Select />
                  </div>
                </>
              ) :
                <>
                  {showingAssociation &&
                    <AssociateTeamPlaceNotifi
                      equipe={equipeSelecionada}
                      tipoLocalAssocia={notification.equipeLocal}
                      setShowingAssociation={setShowingAssociation}
                      nomeEquipe={findTeamsName(equipeSelecionada)}
                      chamado={notification}
                      forms={forms}
                      teams={teams}
                    />}
                  {!showingAssociation && <>
                    <Label>{t('name')}</Label>
                    <Input
                      className="input is-small"
                      type="text"
                      value={editNotification.nome}
                      placeholder={t('name')}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEditNotification({ ...editNotification, nome: e.target.value })}
                    />
                    <Label>{t('notificationsAndTickets.tickets.Notificar_em')}:</Label>
                    <select
                      onChange={(e) => setNotifiEm(e.target.value)}
                      value={notifiEm}
                      className='select is-fullwidth is-small'
                    >
                      {Object.keys(optionsNotifi).map(e => (
                        <option value={e}>{optionsNotifi[e as keyof typeof optionsNotifi]}</option>
                      ))}
                    </select>
                    {isEditing && <>
                      <Label>{t('team')}</Label>
                      <TagContainer>
                        {editNotification.equipes && editNotification.equipes.map((e: string, i) => (
                          <div className='columns' style={{ margin: '0px .1em 0px 0px', display: 'flex', width: '100%', alignItems: 'center' }}>
                            <div className='column' style={{ margin: 0, padding: 0 }}>
                              <TagSpan
                                style={{ display: 'flex', justifyContent: 'space-between', height: 'fit-content' }}
                                key={i}
                                onClick={() => {
                                  setEditNotification({ ...editNotification, equipes: editNotification.equipes.filter(el => el !== e) })
                                }}>
                                {findTeamsName(e)}&nbsp;&nbsp;
                                <IoIosClose />
                              </TagSpan>
                            </div>
                            <button
                              className="button is-success is-small"
                              onClick={() => {
                                setEquipeSelecionada(e)
                                if (notification.equipeLocal && !notification.equipeLocal.some(el => el.equipe === e) && isEditing) {
                                  notification.equipeLocal = [...notification.equipeLocal, { equipe: e, conjuntosAssociados: null, cnjs: [], forms: [], doersTeam: [] }]
                                } else if (isEditing && !notification.equipeLocal) notification.equipeLocal = [{ equipe: e, conjuntosAssociados: null, cnjs: [], forms: [], doersTeam: [] }]
                                setShowingAssociation(true);
                              }}
                            >
                              {`Locais`}&nbsp;<FaArrowRight />
                            </button>
                          </div>
                        ))
                        }
                      </TagContainer>
                      <select
                        disabled={teams && teams.length < 1}
                        defaultValue=''
                        className="select is-small is-fullwidth"
                        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                          setEditNotification({
                            ...editNotification,
                            equipes: editNotification.equipes ? [...editNotification.equipes, e.target.value] : [e.target.value]
                          })
                          if (isEditing && notification.equipeLocal) notification.equipeLocal = [...notification.equipeLocal, { equipe: e.target.value, conjuntosAssociados: null, cnjs: [], forms: [], doersTeam: [] }]
                          else if (isEditing) notification.equipeLocal = [{ equipe: e.target.value, conjuntosAssociados: null, cnjs: [], forms: [], doersTeam: [] }]
                        }}
                      >
                        <option value=''>{t('select')}</option>
                        {
                          teams && teams.sort((a: SimpleTeam, b: SimpleTeam) => {
                            return a.nome.toLowerCase() > b.nome.toLowerCase() ? 1 : - 1
                          }).map((e: SimpleTeam, i: number) =>
                          (
                            <option
                              key={i}
                              disabled={editNotification.equipes && editNotification.equipes.includes(e.id)}
                              value={e.id}
                            >
                              {e.nome}
                            </option>
                          ))
                        }
                      </select>
                    </>}
                    <Label>{t('message')}</Label>
                    <textarea
                      onChange={(e) => setEditNotification({ ...editNotification, mensagem: e.target.value })}
                      value={editNotification.mensagem}
                      className="textarea"
                      placeholder={t("message")}
                    />
                    <Label>{t('notificationsAndTickets.notifications.optionalSender')}</Label>
                    <Input
                      maxLength={80}
                      className="input is-small"
                      type="text"
                      value={editNotification.remetente as string}
                      placeholder={t('notificationsAndTickets.groups.sender')}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEditNotification({ ...editNotification, remetente: e.target.value })}
                    />
                  </>}
                </>}
            </div>
            <div className='modal-card-foot' style={{ display: "flex", justifyContent: "space-between" }}>
              <button
                disabled={(loadingEdit || loadingCreate) || !editNotification.mensagem?.length}
                type='submit'
                className="button is-success is-small"
                onClick={isEditing ? () => edit() : () => create()}
              >
                {loadingCreate || loadingEdit ? t('saving') : `${t('save')}`}
              </button>
              <button
                disabled={loadingCreate || loadingEdit}
                type="submit"
                onClick={() => openModal()}
                className="button is-small is-warning"
              >
                {loadingCreate || loadingEdit ? t('saving') : t('cancel')}
              </button>
            </div>
          </div>
        </div>
      </TitleText>
    </Card>
  )
}
