import { useContext } from 'react'
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../context/UserContext'
import { RegisteredContainer } from './Style';
import { reportsData } from '../../service/Reports';

export default function RegisteredComponent({ data }: { data: reportsData }) {
  const { hierarchyLabel } = useContext(UserContext);
  const { t } = useTranslation('translation');

  return (
    <div className='columns' style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', width: '100%', margin: 0, padding:0}}>
      <RegisteredContainer className='box'>
        <span>{t('Reports.registeredUsers')}</span>
        <p>{data && data.userAtivo}</p>
        <span style={{ color: '#48C78E'}}>{t('Reports.activeUsers')}</span>
        <p>{data && data.userInativo}</p>
        <span style={{ color: "#F14668"}}>{t('Reports.inactiveUsers')}</span>
      </RegisteredContainer>
      <RegisteredContainer className='box'>
        <span>{t('Reports.registeredManagements')}</span>
        <p>{data && data.gerenciaAtiva}</p>
        <span style={{ color: '#48C78E'}}>{t('Reports.activeManagements')}</span>
        <p>{data && data.gerenciaInativa}</p>
        <span style={{ color: "#F14668"}}>{t('Reports.inactiveManagements')}</span>
      </RegisteredContainer>
      <RegisteredContainer className='box'>
        <span>{t('Reports.registeredCustomers')}</span>
        <p>{data && data.clienteAtivo}</p>
        <span style={{ color: '#48C78E'}}>{t('Reports.activeCustomers')}</span>
        <p>{data && data.clienteInativo}</p>
        <span style={{ color: "#F14668"}}>{t('Reports.inactiveCustomers')}</span>
      </RegisteredContainer>
      <RegisteredContainer className='box'>
        <span>{`${hierarchyLabel.local} ${t('customerPage.registered')}`}</span>
        <p>{data && data.userAtivo}</p>
        <span style={{ color: '#48C78E'}}>{`${hierarchyLabel.local} Ativos`}</span>
      </RegisteredContainer>
      <RegisteredContainer className='box'>
        <span>{t('Reports.registeredTeams')}</span>
        <p>{data && data.equipeAtiva}</p>
        <span style={{ color: '#48C78E'}}>{t('Reports.activeTeams')}</span>
        <p>{data && data.equipeInativa}</p>
        <span style={{ color: "#F14668"}}>{t('Reports.inactiveTeams')}</span>
      </RegisteredContainer>
      <RegisteredContainer className='box'>
        <span>{t('Reports.registeredForms')}</span>
        <p>{data && data.formAtivo}</p>
        <span style={{ color: '#48C78E'}}>{t('Reports.activeForms')}</span>
      </RegisteredContainer>
    </div>
  )
}