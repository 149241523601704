import React from 'react'
import { GenericHeader } from '../../../StyledComponents/Modal/generic'
import { useTranslation } from 'react-i18next';

export default function RemoveModal({
  mutate,
  confirmationModal,
  setConfirmationModal,
  type,
}: {
  mutate: Function,
  confirmationModal: boolean,
  setConfirmationModal: Function,
  type: string
}) {
  const { t } = useTranslation('translation');
  return (
    <div className={`modal ${confirmationModal ? "modal is-active" : "modal"}`}>
      <div className="modal-background" />
      <div className="modal-card" onSubmit={(e: React.FormEvent) => e.preventDefault()}>
        <header className="modal-card-head" style={{ display: "flex", flexDirection: "column" }}>
          <GenericHeader>{type === 'notificacao' ? t('notifications.deleteNotif') : t('notifications.deleteTicket')}</GenericHeader>
        </header>
        <div style={{ display: 'flex', justifyContent: 'space-between' }} className="modal-card-body">
          <button style={{ margin: '0.5em' }} className="button is-danger is-small" onClick={() => {
            mutate()
          }}>
            {t('delete')}
          </button>
          <button style={{ margin: '0.5em' }} className="button is-small is-warning" onClick={() => setConfirmationModal(false)}>
            {t('cancel')}
          </button>
        </div>
      </div>
    </div>
  )
}
