import {  useContext, useEffect, useState } from "react";
import { TabStyle } from "../TabsStyle/TabsStyle";
import DashboardLicense from "./DashboardLicense";
import Dashboard from "./Dashboard";
import { useQuery } from "@tanstack/react-query";
import AttToken from "../../helpers/attToken";
import { UserContext } from "../../context/UserContext";
import register from '../../service/PowerBi';

export default function DashboardTabs() {
  const { openModal } = useContext(UserContext);
  const [roleName, setRoleName] = useState('')
  const userData = localStorage.getItem('userDataLocal');
  const servicePowerBi = new register();

  const { data, isLoading } = useQuery({
    queryKey: ['GET_USER_REPORTS'],
    queryFn: async () => {
      const token = await AttToken()
      if (token) {
        const args = {
          empresa: token.userEmpresa, //'-Lmyih0t3MjeBjSGrNxE',
          userAdm: token.username,
        }
        const response = await servicePowerBi.getUserReports(token.userToken, args)
        if (!response?.length || !response) openModal()
        return response
      }
    },
    refetchOnMount: 'always',
    keepPreviousData: true,
    retry: 5,
    refetchOnWindowFocus: false,
  })

  useEffect(() => {
    if (userData) {
      const { role } = JSON.parse(userData)
      if (role) setRoleName(role)
    }
  }, [userData])

  let mapNames = {}

  roleName === 'Administrador' ? mapNames = {
    manage: 'Gerenciamento de licenças',
    Dashboard: 'Dashboard'
  } : mapNames = {
    Dashboard: 'Dashboard'
  }

  const [tab, setTab] = useState({
    isActiveObject: 'Dashboard',
    object: ['manage', 'Dashboard']
  });

  const toggleClass = (index: number) => {
    setTab({ ...tab, isActiveObject: tab.object[index] })
  }

  const toggleActive = (index: number) => {
    if (tab.object[index] === tab.isActiveObject) {
      return "is-active"
    } else {
      return "inactve"
    }
  }
  return (
    <div style={{width: '95%'}}>
      <div className="tabs is-boxed">
        <ul>
          {tab.object.map((el, index) => (
            <li key={index} className={toggleActive(index)} onClick={() => { toggleClass(index) }}>
              <TabStyle theme={toggleActive(index)}>{mapNames[el as keyof typeof mapNames]}</TabStyle>
            </li>
          ))}
        </ul>
      </div>
      {tab.isActiveObject === 'manage' && (
        <DashboardLicense userReportsData={data} isLoadingUserReports={isLoading}/>
      )}
      {tab.isActiveObject === 'Dashboard' && (
        <Dashboard userReportsData={data} isLoadingUserReports={isLoading} />
      )}
    </div>
  )
}