import AddCustomerTag from "./AddCustomerTag";
import AddCustomer from "./AddCustomer";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { TabStyle } from "../TabsStyle/TabsStyle";
import { useTranslation } from "react-i18next";
import RegisteredCustomers from "./RegisteredCustomers";
import AttToken from "../../helpers/attToken";
import ServiceTypes from "../../service/ServiceTypes";
import { useQuery } from "@tanstack/react-query";
import { UserContext } from "../../context/UserContext";

export default function TicketsTabs() {
  const { t } = useTranslation('translation');
  const { setServiceTypesList } = useContext(UserContext);

  const serviceType = useMemo(() => new ServiceTypes(), []);

  const getServiceTypes = useCallback(async () => {
    try {
      const token = await AttToken();
      if (token) {
        const body = { ...token, empresa: token.userEmpresa };
        const result = await serviceType.getAllServices(body);
        if (result) {
          return result
        }
      }
    } catch (err) {
      return console.log(err);
    }
  }, [serviceType]);

  const { data: serviceNames } = useQuery({
    queryKey: ['serviceTypes'],
    queryFn: () => getServiceTypes(),
    keepPreviousData: true,
    retry: 5,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if(serviceNames) setServiceTypesList(serviceNames)
  }, [serviceNames, setServiceTypesList])

  const mapNames = {
    customers: t('customerPage.myCustomer'),
    addCustomer: t('customerPage.addCustomer'),
    addTag: t('customerPage.customerTags'),
  }

  const [tab, setTab] = useState({
    isActiveObject: 'customers',
    object: ['customers', 'addCustomer', 'addTag']
  });

  const toggleClass = (index: number) => {
    setTab({ ...tab, isActiveObject: tab.object[index] })
  }

  const toggleActive = (index: number) => {
    if (tab.object[index] === tab.isActiveObject) {
      return "is-active"
    } else {
      return "inactve"
    }
  }
  return (
    <div style={{width: '95%'}}>
      <div className="tabs is-boxed">
        <ul>
          {tab.object.map((el, index) => (
            <li key={index} className={toggleActive(index)} onClick={() => { toggleClass(index) }}>
              <TabStyle theme={toggleActive(index)}>{mapNames[el as keyof typeof mapNames]}</TabStyle>
            </li>
          ))}
        </ul>
      </div>
      {tab.isActiveObject === 'customers' && (
        <RegisteredCustomers />
      )}
      {tab.isActiveObject === 'addCustomer' && (
        <AddCustomer />
      )}
      {tab.isActiveObject === 'addTag' && (
        <AddCustomerTag />
      )}
    </div>
  )
}