import { useTranslation } from 'react-i18next';
import './styles.css';
import PlanoDeAcao from '../../../../service/PlanoDeAcao';
import { useMutation, useQuery } from '@tanstack/react-query';
import AttToken from '../../../../helpers/attToken';
import { Label } from '../../../Notifications/style/SingleNotificationStyle';
import { Skeleton } from '@mui/material';
import { useState } from 'react';
import { TagContainer, TagSpan } from '../../../../StyledComponents/Modal/generic';
import { AiOutlineClose } from 'react-icons/ai';
import Checklists, { simpleFormType } from '../../../../service/Checklists';
import { AxiosError } from 'axios';
import { ConjuntoType } from '../../../../service/Places';
import { toast } from 'react-toastify';
import { Accordion, AccordionSummary } from '@material-ui/core';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

type props = {
  openModalPA: boolean,
  setOpenModalPA: Function,
  conjunto: ConjuntoType,
  getPlaces: Function
}

const PAService = new PlanoDeAcao();
const formService = new Checklists();

type gestoresType = {
  id: string,
  nome: string
}

export default function ModalPA({
  openModalPA,
  setOpenModalPA,
  conjunto,
  getPlaces,
}: props) {
  const { t } = useTranslation('translation');
  const [selectedGestores, setSelectedGestores] = useState<{ [name: string]: string[] }>(conjunto.listaGestoresPA || {})
  const [selectedInspetores, setSelectedInspetores] = useState<{ [name: string]: string[] }>(conjunto.listaInspetoresPA || {})
  const [expanded, setExpanded] = useState<string | boolean>(false);

  const handleChange = (isExpanded: boolean, panel: string) => {
    setExpanded(isExpanded ? panel : false);
  };


  const { data: gestores, isLoading: loadingGestores } = useQuery({
    queryKey: ['GET_GESTORES'],
    queryFn: async () => {
      const token = await AttToken();
      if (token) {
        const response = await PAService.getListaGestoresPA(token);
        return response
      }
    },
    retry: 5,
    refetchOnWindowFocus: false,
    refetchOnMount: 'always',
  })

  const { data: inspetores, isLoading: loadingInspetores } = useQuery({
    queryKey: ['GET_INSPETORES'],
    queryFn: async () => {
      const token = await AttToken();
      if (token) {
        const response = await PAService.getListaInspetoresPA(token)
        return response
      }
    },
    retry: 5,
    refetchOnWindowFocus: false,
    refetchOnMount: 'always',
  })

  const {
    data: forms,
    isLoading: loadingForms,
  } = useQuery({
    queryKey: ['GET_FORMS'],
    queryFn: async () => {
      const token = await AttToken();
      if (token) {
        const body = { ...token }
        const result = await formService.getSimpleForm(body, true);
        return result
      }
    },
    keepPreviousData: true,
    retry: 5,
    refetchOnWindowFocus: false,
    refetchOnMount: 'always',
    onError: () => {
      console.log(AxiosError)
    }
  });

  const { mutate, isLoading } = useMutation({
    mutationKey: ['SAVE_GESTORES'],
    mutationFn: async () => {
      const token = await AttToken();
      if (token) {
        const response = await PAService.salvarGestoresPA(token, conjunto.id, selectedGestores, selectedInspetores)
        return response
      }
    },
    onSuccess: () => {
      getPlaces(conjunto.cliente)
      setOpenModalPA(!openModalPA)
      toast.success('Alterações salvas!')
    }
  })

  const findGestorName = (value: string) => {
    const userName = gestores.find((gestor: gestoresType) => gestor.id === value);
    if (userName) return userName.nome
    else return;
  }

  const findInspetorName = (value: string) => {
    const userName = inspetores.find((inspetor: gestoresType) => inspetor.id === value);
    if (userName) return userName.nome
    else return;
  }

  const findFormName = (value: string) => {
    const formName = forms && forms?.find((el: simpleFormType) => el.id === value);
    if (formName) return formName.nome
    else return false;
  }

  return (
    <div className={`modal ${openModalPA ? 'is-active' : ''}`}>
      <div className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">Associações de Plano de Ação</p>
        </header>
        <section className="modal-card-body">
          <Accordion style={{ padding: '0px 15px 10px 15px' }} expanded={expanded === 'panel1'}>
            <AccordionSummary
              expandIcon={<ArrowDownwardIcon
                onClick={(isExpanded) => {
                  if (expanded === 'panel1') {
                    handleChange(!isExpanded, '')
                  } else handleChange(true, 'panel1')
                }}
              />}>
              <span style={{ fontWeight: 600, color: 'gray' }}>Associações de gestores</span>
            </AccordionSummary>
            {loadingGestores ?
              <>
                <Skeleton width={110} style={{ marginBottom: '-.8em' }} />
                <Skeleton height={60} />
              </>
              :
              <>

                <Label>
                  Adicionar gestores:
                </Label>
                <TagContainer>
                  {Object?.keys(selectedGestores)?.map((e: string, i: number) => (
                    selectedGestores[e] && <TagSpan key={`element${e}|index${i}`} onClick={() => {
                      delete selectedGestores[e]
                      setSelectedGestores({ ...selectedGestores })
                    }}>
                      {
                        findGestorName(e)
                      }
                      <AiOutlineClose />
                    </TagSpan>
                  ))}
                </TagContainer>
                <select
                  defaultValue={''}
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                    setSelectedGestores({ ...selectedGestores, [e.target.value]: [] })
                  }}
                  className='select is-fullwidth is-small'>
                  <option value={''}>{t('select')}...</option>
                  {gestores?.map((gestor: gestoresType) => (
                    <option key={gestor.id} value={gestor.id}>{gestor.nome}</option>
                  )
                  )}
                </select>
              </>
            }
            {loadingForms ?
              <>
                <Skeleton width={110} style={{ marginBottom: '-.8em' }} />
                <Skeleton height={60} />
              </> :
              <>
                {Object.keys(selectedGestores).length > 0 &&
                  <>
                    <Label>
                      Formulários associados
                    </Label>
                    {selectedGestores && Object.keys(selectedGestores).map((user: string) => (
                      <>
                        <Label>
                          {user}
                        </Label>
                        <TagContainer>
                          {selectedGestores[user]?.map((formId: string, i: number) => (
                            // forms.includes(formId) &&
                            <TagSpan key={i}
                              onClick={() => {
                                setSelectedGestores((prev) => ({ ...prev, [user]: prev[user as keyof typeof prev].filter(ele => ele !== formId) }))
                              }}>
                              {
                                findFormName(formId)
                              }
                              <AiOutlineClose />
                            </TagSpan>
                          ))}
                        </TagContainer>
                        <select
                          defaultValue=''
                          className="select is-small is-fullwidth"
                          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                            setSelectedGestores((prev) => ({ ...prev, [user]: [...prev[user as keyof typeof prev], e.target.value] }))
                          }}
                        >
                          <option value='' disabled>{t('select')}</option>
                          {
                            forms
                              ?.sort((a: simpleFormType, b: simpleFormType) => { return a.nome.toLowerCase() > b.nome.toLowerCase() ? 1 : - 1 })
                              ?.map((e: simpleFormType, i: number) => (
                                <option
                                  key={i}
                                  disabled={selectedGestores[user] && selectedGestores[user].includes(e.id)}
                                  value={e.id}>
                                  {e.nome}
                                </option>
                              ))
                          }
                        </select>
                      </>
                    ))}
                  </>}
              </>}
          </Accordion>
          <Accordion style={{ padding: '0px 15px 10px 15px' }} expanded={expanded === 'panel2'}>
            <AccordionSummary
              expandIcon={<ArrowDownwardIcon
                onClick={(isExpanded) => {
                  if (expanded === 'panel2') {
                    handleChange(!isExpanded, '')
                  } else handleChange(true, 'panel2')
                }}
              />}>
              <span style={{ fontWeight: 600, color: 'gray' }}>Associações de inspetores</span>
            </AccordionSummary>
            {loadingInspetores ?
              <>
                <Skeleton width={110} style={{ marginBottom: '-.8em' }} />
                <Skeleton height={60} />
              </>
              :
              <>
                <Label>
                  Adicionar Inspetores:
                </Label>
                <TagContainer>
                  {Object?.keys(selectedInspetores)?.map((e: string, i: number) => (
                    selectedInspetores[e] && <TagSpan key={`element${e}|index${i}`} onClick={() => {
                      delete selectedInspetores[e]
                      setSelectedInspetores({ ...selectedInspetores })
                    }}>
                      {
                        findInspetorName(e)
                      }
                      <AiOutlineClose />
                    </TagSpan>
                  ))}
                </TagContainer>
                <select
                  defaultValue={''}
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                    setSelectedInspetores({ ...selectedInspetores, [e.target.value]: [] })
                  }}
                  className='select is-fullwidth is-small'>
                  <option value={''}>{t('select')}...</option>
                  {inspetores?.map((inspetor: gestoresType) => (
                    <option key={inspetor.id} value={inspetor.id}>{inspetor.nome}</option>
                  )
                  )}
                </select>
              </>
            }
            {loadingForms ?
              <>
                <Skeleton width={110} style={{ marginBottom: '-.8em' }} />
                <Skeleton height={60} />
              </> :
              <>
                {Object.keys(selectedInspetores).length > 0 &&
                  <>
                    <Label>
                      Formulários associados
                    </Label>
                    {selectedInspetores && Object.keys(selectedInspetores).map((user: string) => (
                      <>
                        <Label>
                          {user}
                        </Label>
                        <TagContainer>
                          {selectedInspetores[user]?.map((formId: string, i: number) => (
                            // forms.includes(formId) &&
                            <TagSpan key={i}
                              onClick={() => {
                                setSelectedInspetores((prev) => ({ ...prev, [user]: prev[user as keyof typeof prev].filter(ele => ele !== formId) }))
                              }}>
                              {
                                findFormName(formId)
                              }
                              <AiOutlineClose />
                            </TagSpan>
                          ))}
                        </TagContainer>
                        <select
                          defaultValue=''
                          className="select is-small is-fullwidth"
                          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                            setSelectedInspetores((prev) => ({ ...prev, [user]: [...prev[user as keyof typeof prev], e.target.value] }))
                          }}
                        >
                          <option value='' disabled>{t('select')}</option>
                          {
                            forms
                              ?.sort((a: simpleFormType, b: simpleFormType) => { return a.nome.toLowerCase() > b.nome.toLowerCase() ? 1 : - 1 })
                              ?.map((e: simpleFormType, i: number) => (
                                <option
                                  key={i}
                                  disabled={selectedInspetores[user] && selectedInspetores[user].includes(e.id)}
                                  value={e.id}>
                                  {e.nome}
                                </option>
                              ))
                          }
                        </select>
                      </>
                    ))}
                  </>}
              </>}
          </Accordion>

        </section>
        <footer className="modal-card-foot">
        <button
              disabled={isLoading}
              onClick={() => {
                mutate()
              }}
              className="button is-success is-small">
              {t('save')}
            </button>
            <button
              className='button is-small is-warning'
              onClick={() => setOpenModalPA(!openModalPA)}>
              {t('cancel')}
            </button>
        </footer>
      </div>
    </div>
  )
}
