import { useContext } from 'react'
import { useTranslation } from 'react-i18next';
import Header from '../components/Header/Header'
import ReportsComponent from '../components/Reports/ReportsComponent';
import Sidebar from '../components/Sidebar/Sidebar'
import { HeaderText } from '../StyledComponents/pages/pages';
import { UserContext } from '../context/UserContext';
import ChangeAlert from '../components/ChangeAlert/ChangeAlert';
import Footer from '../components/Footer/Footer';

export default function Reports() {
  const { t } = useTranslation('translation');
  const { changed } = useContext(UserContext);

  return (
    <div style={{ display: 'flex', width: '100%', height: '100%' }}>
      <Sidebar />
      <div style={{ width: '100%', backgroundColor: '#F3F3F4' }}>
        {changed && <ChangeAlert />}
        <Header />
        <HeaderText style={{textAlign: 'center'}}>{t('Reports.title')}</HeaderText>
        <div style={{
        //  display: 'flex',
        //   flexDirection: 'column',
          marginBottom: '50px',
          padding: `${window.innerWidth < 800 ? '0px 2% 0px 2%' : '0px 10% 0px 10%'}`,
          width: '100%',
          maxWidth: '100%',
          flexWrap: 'wrap',
         }}>
          <ReportsComponent />
        </div>
        <Footer />
      </div>
    </div>
  );
}