import React, { useEffect, useMemo, useState } from 'react'
import Checklists, { ChecklistType } from '../../../../service/Checklists'
import { Label } from '../../../../StyledComponents/Input/generic'
import { useMutation, useQuery } from '@tanstack/react-query'
import AttToken from '../../../../helpers/attToken'
import ServiceTypes, { types } from '../../../../service/ServiceTypes'
import { useTranslation } from 'react-i18next'
import { TagContainer, TagSpan } from '../../../../StyledComponents/Modal/generic'
import { AiOutlineClose } from 'react-icons/ai'
import { toast } from 'react-toastify'
import { ButtonsContainer } from './FormModalStyle'
import { Skeleton } from '@mui/material'
import { Typography } from '@material-ui/core'

type props = {
  setEditModal: Function,
  form: ChecklistType
}

type mandatorySub = 'naoConforme' | 'confome' | 'sempre' | 'nunca';

export type ticketConfigType = {
  serviceTypes: string[],
  limit: number,
  mandatory: mandatorySub | boolean,
}


function useForceUpdate() {
  let [value, setState] = useState(true);
  return () => setState(!value);
}


export default function FormChamadosConfig({ form, setEditModal }: props) {

  const { t } = useTranslation('translation');

  const serviceTypeService = useMemo(() => new ServiceTypes(), []);
  const checklistService = useMemo(() => new Checklists(), []);

  const [ticketConfigSub, setTicketConfigSub] = useState<ticketConfigType>();
  const [serviceTypesForm, setServiceTypesForm] = useState<string[]>([]);
  const [serviceTypesSub, setServiceTypesSub] = useState<string[]>([]);
  const [limitForm, setLimitForm] = useState<number>(0);
  const [limitSub, setLimitSub] = useState<number>(0);
  const [mandatoryForm, setMandatoryForm] = useState<boolean>(false);
  const [mandatorySub, setMandatorySub] = useState<mandatorySub>('naoConforme');
  const [selectedSub, setSelectedSub] = useState('');
  const [disable, setDisable] = useState(false)

  const handleForceupdateMethod = useForceUpdate();


  const { data, isLoading } = useQuery({
    queryKey: ['getServices'],
    queryFn: async () => {
      const token = await AttToken();
      if (token) {
        const body = { ...token, empresa: token.userEmpresa };
        const result = await serviceTypeService.getAllServices(body);
        return result
      }
    },
    keepPreviousData: true,
    retry: 5,
    refetchOnWindowFocus: false,
  });

  const findServiceNameForm = (value: string) => {
    const serviceName = data.find((el: types) => el.tipoId === value);
    if (serviceName) return serviceName.nome
    else return 'teste';
  }

  const findServiceNameSub = (value: string) => {
    const serviceName = data.find((el: types) => el.tipoId === value);
    if (serviceName) return serviceName.nome
    else return 'teste';
  }

  useEffect(() => {
    if (form.ticketConfig) {
      setServiceTypesForm(form.ticketConfig.serviceTypes)
      setLimitForm(form.ticketConfig.limit)
      setMandatoryForm(form.ticketConfig.mandatory as boolean)
    }
  }, [])

  useEffect(() => {
    if (ticketConfigSub && selectedSub) {
      const parsed = JSON?.parse(selectedSub) as { item: string, subItem: string }
      form
        .itens?.find(item => item.id === parsed.item)
        ?.subitens?.forEach(sub => {
          if (String(sub.id) === String(parsed.subItem)) {
            sub.ticketConfig = ticketConfigSub
            setMandatorySub(ticketConfigSub?.mandatory as mandatorySub)
            setLimitSub(ticketConfigSub.limit)
            setServiceTypesSub(ticketConfigSub.serviceTypes)
          }
        })
    }
  }, [selectedSub])

  const { mutate } = useMutation({
    mutationKey: ['UPDATE_CHAMADO_FORM'],
    mutationFn: async () => {
      setDisable(true)
      toast.warn(`${t('saving')}`)
      const token = await AttToken();
      if (token) {
        const response = await checklistService.updateChecklist(token, form)
        return response
      }
    },
    onSuccess: () => {
      setDisable(false)
      toast.success(`${t('salva')}`)
    }
  })

  const getSubItemFilled = (value: string) => {
    if (value) {
      const parsed = JSON?.parse(value) as { item: string, subItem: string }
      if (parsed) {
        form
          .itens?.find(item => item.id === parsed.item)
          ?.subitens?.forEach(sub => {
            if (String(sub.id) === String(parsed.subItem)) {
              if (sub.ticketConfig) {
                console.log("aaaawn", sub.id)
                setMandatorySub(sub.ticketConfig?.mandatory as mandatorySub)
                setLimitSub(sub.ticketConfig.limit)
                setServiceTypesSub(sub.ticketConfig.serviceTypes)
              } else {

                setMandatorySub('naoConforme')
                setLimitSub(0)
                setServiceTypesSub([])
              }
            }
          })
      }
    }
  }

  return (
    <div style={{ marginBottom: '60px', display: 'flex', flexDirection: 'column', alignItems: 'center', maxWidth: '700px' }}>
      {
        isLoading ?
          <>
            <div style={{ display: 'flex', justifyContent: "initial", width: '100%' }}>
              <Skeleton height={40} width={'50%'} />
            </div>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
              <div style={{ width: '50%' }}>
                <Skeleton height={20} width={'95%'} />
                <Skeleton height={60} width={'95%'} />
              </div>
              <div style={{ width: '50%' }}>
                <Skeleton height={60} width={'100%'} />
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: "initial", width: '100%', flexDirection:'column' }}>
              <Skeleton height={40} width={'50%'} />
              <Skeleton width={'20%'} height={20}/>
              <Skeleton height={60}/>
            </div>
          </> :
          <div className='modal-card-body'>
            <div className='hero' style={{ marginBottom: 10 }}>
              <h1 className='subtitle'>{t('formDetails.configChamados.ChamadosPor')}</h1>
            </div>
            <div className='columns'>
              <div className='column'>
                <Label>
                  {t('formDetails.configChamados.SelecioneOTipoformulario')}
                </Label>
                <TagContainer>
                  {serviceTypesForm?.length > 0 && serviceTypesForm?.map((ele: string, i: number) => (
                    <TagSpan key={`FORM_INDEX${i}`}
                      onClick={() => {
                        setServiceTypesForm(prev => prev ? prev.filter(el => el !== ele) : [])
                      }}>
                      {findServiceNameForm(ele)}
                      <AiOutlineClose />
                    </TagSpan>
                  ))}
                </TagContainer>
                <select
                  onChange={(e) => {
                    setServiceTypesForm((prev) => prev ? [...prev, e.target.value] : [e.target.value])
                  }}
                  className='select is-fullwidth'>
                  <option value='' >{t('select')}</option>
                  {data && data?.sort((a: types, b: types) => { return a.nome.toLowerCase() > b.nome.toLowerCase() ? 1 : - 1 })?.map((elem: types) => (
                    <option disabled={serviceTypesForm?.includes(elem.tipoId)} value={elem.tipoId}>{elem.nome}</option>
                  ))}
                </select>
              </div>
              <div className='column' style={{ display: 'flex', alignItems: 'center' }}>
                <div className='columns'>
                  <div className='column' style={{ display: 'flex', alignItems: 'center' }}>
                    <Label>
                      {t('formDetails.configChamados.LimiteFormulario')}
                    </Label>
                    <input
                      style={{ border: 'none', borderBottom: '1px solid black', width: '20px !important' }}
                      type="number"
                      onChange={(e) => {
                        setLimitForm(Number(e.target.value))
                      }}
                      value={limitForm === 0 ? undefined : limitForm}
                      className='input is-small'
                    />
                  </div>
                  <div className='column' style={{ display: 'flex', alignItems: 'center' }}>
                    <Label>
                      {t('formDetails.configChamados.Obrigatorio')}:&nbsp;
                    </Label>
                    <select
                      onChange={(e) => {
                        setMandatoryForm(e.target.value === 'verdadeiro' ? true : false)
                      }}
                      value={!mandatoryForm ? 'falso' : 'verdadeiro'}
                      className='select is-fullwidth'>
                      <option value={'verdadeiro'}>{t('yes')}</option>
                      <option value={'falso'}>{t('no')}</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className='hero' style={{ marginBottom: 10 }}>
              <h1 className='subtitle'>{t('formDetails.configChamados.ChamadosSub-item')}</h1>
            </div>
            <div>
            </div>
            <Label>{t('formDetails.configChamados.SelecioneSubitem')}</Label>
            <select
              style={{ marginBottom: '.5em' }}
              disabled={form.itens && form?.itens?.every((elem) => elem && elem?.subitens?.length <= 0)}
              className="select is-small is-fullwidth"
              defaultValue=""
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                getSubItemFilled(e.target.value);
                handleForceupdateMethod()
                setSelectedSub(e.target.value);
              }}
            >
              <option disabled value="">{t('select')}...</option>
              {
                form.itens.map((item) => item.subitens.sort((a, b) => { return a.nome.toLowerCase() > b.nome.toLowerCase() ? 1 : - 1 }).map((sub, i) => (
                  <option
                    key={i}
                    value={JSON.stringify({ item: item.id, subItem: sub.id })}
                  >
                    {sub.nome}
                  </option>
                )
                ))
              }
            </select>
            {selectedSub && <div className='columns'>
              <div className='column'>
                <Label>
                  {t('formDetails.configChamados.SelecioneOTiposubitem')}
                </Label>
                <TagContainer>
                  {serviceTypesSub?.length > 0 && serviceTypesSub?.map((ele: string, i: number) => (
                    <TagSpan key={Math.random()}
                      onClick={() => {
                        setServiceTypesSub(prev => prev ? prev.filter(el => el !== ele) : [])
                      }}>
                      {findServiceNameSub(ele)}
                      <AiOutlineClose />
                    </TagSpan>
                  ))}
                </TagContainer>
                <select
                  onChange={(e) => {
                    setServiceTypesSub([...serviceTypesSub, e.target.value])
                  }}
                  className='select is-fullwidth'>
                  <option value='' >{t('select')}</option>
                  {data?.sort((a: types, b: types) => { return a.nome.toLowerCase() > b.nome.toLowerCase() ? 1 : - 1 })?.map((elem: types) => (
                    <option disabled={serviceTypesSub?.includes(elem.tipoId)} value={elem.tipoId}>{elem.nome}</option>
                  ))}
                </select>
              </div>
              <div className='column' style={{ display: 'flex', alignItems: 'center' }}>
                <div className='columns'>
                  <div className='column' style={{ display: 'flex', alignItems: 'center' }}>
                    <Label>
                      {t('formDetails.configChamados.LimiteSubitem')}:
                    </Label>
                    <input
                      style={{ border: 'none', borderBottom: '1px solid black', width: '20px !important' }}
                      type="number"
                      onChange={(e) => {
                        setLimitSub(Number(e.target.value))
                      }}
                      value={limitSub === 0 ? undefined : limitSub}
                      className='input is-small'
                    />
                  </div>
                  <div className='column' style={{ display: 'flex', alignItems: 'center' }}>
                    <Label>
                      {t('formDetails.configChamados.Obrigatorio')}:&nbsp;
                    </Label>
                    <select
                      onChange={(e) => {
                        setMandatorySub(e.target.value as mandatorySub)
                      }}
                      value={!mandatorySub ? 'falso' : 'verdadeiro'}
                      className='select is-fullwidth'>
                      <option value={'naoConforme'}>Não Conforme</option>
                      <option value={'conforme'}>Conforme</option>
                      <option value={'sempre'}>Sempre</option>
                      <option value={'nunca'}>Nunca</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>}
          </div>
      }
      <ButtonsContainer>
        <button
          disabled={disable}
          className='button is-success is-small'
          onClick={() => {
            form.ticketConfig = {
              limit: limitForm,
              mandatory: mandatoryForm,
              serviceTypes: serviceTypesForm,
            }
            if (selectedSub) {
              const parsed = JSON?.parse(selectedSub) as { item: string, subItem: string }
              form?.itens?.find(item => item.id === parsed.item)
                ?.subitens?.forEach(sub => {
                  if (String(sub.id) === String(parsed.subItem)) {
                    sub.ticketConfig = {
                      limit: limitSub,
                      mandatory: mandatorySub,
                      serviceTypes: serviceTypesSub,
                    }
                  }
                })
            }
            mutate()
          }}>
          {t('save')}
        </button>
        <button
          className='button is-light is-small'
          onClick={() => {
            setEditModal(-1)
          }}>
          {t('close')}
        </button>
      </ButtonsContainer>
    </div>
  )
}