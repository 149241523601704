import { memo } from "react"

function ModalImageOnTicket({
  image,
  open,
  setOpen,
}: {
  image: string,
  open: Boolean,
  setOpen: Function,
}) {

  return (
    <div className={`modal ${open ? "modal is-active" : "modal"}`}>
      <div className="modal-background" onClick={() => {
        setOpen()
      }}></div>
      <div className="modal-content">
        <p style={{ color: 'white' }}>
          Clique fora da imagem para fechar a visualização
        </p>
        <img style={{ maxHeight: '75svh' }} src={image} alt={image} />
      </div>
    </div>
  )
}

export default memo(ModalImageOnTicket)