export enum Profiles {
  Usuario,
  Supervisor,
  Gestor,
  Gerente,
  Administrador
}

interface IUserData {
  companyName: string,
  email: string,
  notificacao: string,
  listaFuncionalidades: string[]
  profilePhoto: string,
  role: Profiles,
  userName: string,
  aprovador: boolean,
  migrado: boolean,
  gestorPA: boolean,
  inspetorPA: boolean,
  formMigrado: boolean
}

export default IUserData