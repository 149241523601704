import React, { useContext, useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import Loading from '../components/Loading/Loading';
import { UserContext } from '../context/UserContext';
import AxymaLogo from '../assets/images/Axyma_branco.svg';

function PrivateRoute({ children }:{children: React.ReactComponentElement<any>}) {
    const [authUser, setAuthUser] = useState(false);
    const { saveUserData } = useContext(UserContext);
    const [stopper, setStopper] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
      const checkAccess = (userDataParam: { listaFuncionalidades: string[], aprovador: boolean }) => {
      if (
        userDataParam.listaFuncionalidades?.some(e => e?.toLowerCase() === window.location.pathname?.replace('/', '')?.toLowerCase()) ||
        (window.location.pathname.split('/')[1] === 'plano-de-acao' && localStorage.getItem('loginData')) ||
        (window.location.pathname.split('/')[1] === 'detalheChamado' && localStorage.getItem('loginData')) ||
        (window.location.pathname.split('/')[1] === 'aprovacao' && userDataParam.aprovador) ||
        window.location.pathname.split('/')[1] === 'preenchedor'
      ) {
          setAuthUser(true);
        } else { setAuthUser(false); }
        setStopper(true);
      };
      
      setTimeout(() => {
        const userDataLocal = localStorage.getItem('userDataLocal');
        if (userDataLocal && !stopper) {
          checkAccess(JSON.parse(userDataLocal));
          saveUserData(JSON.parse(userDataLocal));
        } else if(!stopper) {
          checkAccess({listaFuncionalidades: [''], aprovador: false});
        }
      }, 2000)
    }, [stopper, saveUserData, navigate]);

    if (stopper) {
        return authUser ? children : <Navigate to="/login" />
    } else return (
      <div style={{ backgroundColor: '#3a1c64', display: 'flex', flexDirection: 'column', height:'100vh', alignItems: 'center', justifyContent:'space-evenly' }}>
        <img src={AxymaLogo} alt="logo axyma branco" width={'40%'}/>
        <Loading />
      </div>
    )
}

export { PrivateRoute };