import React, { useMemo, useRef, useState } from 'react'
import { Label } from '../StyledComponents/Input/generic';
import Teams, { SimpleTeam } from '../service/Teams';
import { useQuery } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import AttToken from '../helpers/attToken';
import Users, { userType } from '../service/Users';
import { Skeleton } from '@mui/material';
import { useTranslation } from 'react-i18next';

type props = {
  callback: Function,
  userLabel: string,
  defaultSelected: string | null
}

export default function UserSelector({ callback, userLabel, defaultSelected } : props) {
  const [selectingByTeam, setSlectingByTeam] = useState(false);
  const [selectedTeam, setSeletedTeam] = useState<SimpleTeam>();

  const { t } = useTranslation('translation');

  const TeamsService = useMemo(() => new Teams(), []);
  const UsersService = useMemo(() => new Users(), [])


  const { data: teamsData, isLoading: isLoadingTeams } = useQuery({
    queryKey: ['TeamsList'],
    queryFn: async () => {
      const token = await AttToken();
      if (token) {
        const response: SimpleTeam[] = await TeamsService.getSimpleTeams(token);
        return response;
      }
    },
    keepPreviousData: true,
    retry: 5,
    refetchOnWindowFocus: false,
    refetchOnMount: 'always',
    onError: () => {
      toast.error('Erro ao buscar equipes!')
    }
  });

  const { data: users, isLoading: isLoadingUsers } = useQuery({
    queryKey: ['UsersList'],
    queryFn: async () => {
      const token = await AttToken();
      if (token) {
        const body = { ...token }
        const result: {  users: userType[] } = await UsersService.getAllUsers(body);
        return result.users
      }
    },
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    refetchOnMount: 'always',
  });

  const userSelectByTeamRef = useRef<HTMLSelectElement>(null);
  const userSelectRef = useRef<HTMLSelectElement>(null);

  

  if (isLoadingTeams || isLoadingUsers) return ( <Skeleton height={60} width={'100%'} style={{marginTop: '-1em'}}/> )

  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', backgroundColor: 'white', padding: '6px', borderRadius: '10px' }}>
      <Label className="checkbox" style={{ marginRight: '5px', width: '20%'}}>
        <input
          type="checkbox"
          onChange={(e) => {
            callback('');
            if (userSelectByTeamRef && userSelectByTeamRef.current) {
              userSelectByTeamRef.current.value = '';
            }
            if (userSelectRef && userSelectRef.current) {
              userSelectRef.current.value = '';
            }
            setSeletedTeam(undefined);
            setSlectingByTeam(e.target.checked)
          }}
          checked={selectingByTeam}
          style={{ marginRight: '5px'}}
        />
        Filtrar por equipes
      </Label>
      <div style={{ display: 'flex', flexDirection: 'column', borderLeft: '1px solid lightgrey', paddingLeft: '5px' }}>
        <Label>
          {`${selectingByTeam ? selectedTeam ? `Equipe ${selectedTeam.nome} selecionada` : 'Selecione uma equipe' : 'Você não está filtrando por equipes'}`}
        </Label>
        <select
          onChange={(e) => {
            callback('')
            if (userSelectByTeamRef && userSelectByTeamRef.current) {
              userSelectByTeamRef.current.value = '';
            }
            setSeletedTeam(teamsData?.find(team => team.id === e.target.value))
          }}
          value={selectedTeam ? selectedTeam.id : ""}
          className='select is-fullwidth'
          disabled={!selectingByTeam}
          required
        >
          <option disabled value="">{t('select')}</option>
          {teamsData ? teamsData.map((team, index) => (
            team.membros && team.membros.length && <option key={index} value={team.id}>{team.nome}</option>
          )) : <option disabled>Nenhuma equipe encontrada</option>}
        </select>
        <Label>
          {`${selectingByTeam ? selectedTeam ? userLabel : 'Selecione uma equipe acima' : 'Você não está filtrando por equipes'}`}
        </Label>
        <select
          onChange={(e) => callback(e.target.value)}
          defaultValue={""}
          className='select is-fullwidth'
          ref={userSelectByTeamRef}
          disabled={!selectedTeam || !selectingByTeam}
          required
          >
          <option disabled value="">{t('select')}</option>
          {selectedTeam && selectedTeam.membros?.length ? selectedTeam.membros?.map((membro, index) => (
            <option key={index} value={membro.id}>{membro.nome}</option>
          )) : <option disabled>Nenhum usuario encontrado</option>}
        </select>
        <Label>
          {`${selectingByTeam ? 'Você está filtrando por equipes' : userLabel}`}
        </Label>
        <select
          onChange={(e) => callback(e.target.value)}
          defaultValue={defaultSelected || ""}
          className='select is-fullwidth'
          ref={userSelectRef}
          disabled={selectingByTeam}
          required
        >
          <option disabled value="">{t('select')}</option>
          {users ? users.map((user, index) => (
            <option key={index} value={user.id}>{user.nome}</option>
          )) : <option disabled>Nenhuma equipe encontrada</option>}
        </select>
      </div>
    </div>
  )
}
