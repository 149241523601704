import { useQuery } from '@tanstack/react-query';
import RegisteredComponent from './RegisteredComponent'
import ReportsTable from './ReportsTable'
import AttToken from '../../helpers/attToken';
import { useMemo } from 'react';
import Reports, { filterDataType, reportsData } from '../../service/Reports';
import SmallComp from '../Skeletons/SmallComp';
import { Skeleton } from '@mui/material';

export default function ReportsComponent() {

  const reportsService = useMemo(() => new Reports(), []);
  const { data, isLoading, isError } = useQuery({
    queryKey: ['Reports'],
    queryFn: async () => {
      const token = await AttToken();
      if (token) {
        const response = await reportsService.getData(token)
        return response
      }
    },
    keepPreviousData: true,
    retry: 5,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  })

  if (isLoading) {
    return (
      <>
        <div className='columns' style={{display:'flex', justifyContent: 'center', flexWrap: 'wrap', width: '100%', height: 'fit-content' }}>
          <SmallComp />
          <SmallComp />
          <SmallComp />
          <SmallComp />
          <SmallComp />
          <SmallComp />
        </div>
        <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
          <Skeleton width={'100%'} height={'400px'} style={{ marginTop: '-5em' }} />
        </div>
      </>
    )
  }

  if (isError) {
    return <span>Erro ao buscar dados</span>
  }

  return (
    <div>
      <RegisteredComponent data={data.dados as reportsData} />
      <ReportsTable data={data.filterData as filterDataType} />
    </div>
  )
}