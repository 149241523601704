import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { UserContext } from '../../../context/UserContext'
import { Label } from '../../../StyledComponents/Input/generic'
import { GenericHeader } from '../../../StyledComponents/Modal/generic'
import { Card, TitleText } from '../../Teams/style/RegisteredTeamsStyle'
import { NewPlaceWrapper } from './ModalStyle';

export default function EditModal({ 
  open, 
  setOpen, 
  componentName, 
  edit,
  nameOf,
  isDisabled,
}
  : { 
    open: boolean, 
    setOpen: Function, 
    componentName: string, 
    edit: Function,
    nameOf: string,
    isDisabled: boolean,
  }) {

  const { t } = useTranslation('translation');
  const [name, setName] = useState(componentName);
  const { hierarchyLabel } = useContext(UserContext);

  return (
    <Card>
      <TitleText>
        <div className={`modal ${open ? "modal is-active" : "modal"}`}>
          <div className="modal-background" onClick={() => setOpen(false)} />
          <div className="modal-card" onSubmit={(e: React.FormEvent) => e.preventDefault()}>
            <header className="modal-card-head" style={{ display: "flex", flexDirection: "column" }}>
              <GenericHeader>
                {`${t('edit')} ${nameOf === 'conjunto' ? hierarchyLabel.conjunto : hierarchyLabel.unidade}`}
              </GenericHeader>
            </header>
            <div className="modal-card-body">
              <NewPlaceWrapper>
                <Label>
                  {t('name') + ':'}
                </Label>
                <input value={name} onChange={(e) => setName(e.target.value)} className="input is-fullwidth" />
              </NewPlaceWrapper>
            </div>
              <div className='modal-card-foot' style={{ display: "flex", justifyContent: "space-between" }}>
                <button disabled={isDisabled} className='button is-success is-small' onClick={() => edit(name)}>{t('save')}</button>
                <button className='button is-warning is-small' onClick={() => setOpen(false)}>{t('cancel')}</button>
              </div>
          </div>
        </div>
      </TitleText>
    </Card>
  )
}
