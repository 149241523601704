import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { useNavigate, useParams } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { Box, Container, InputLogin, SmallText, UnderLogoText } from '../components/FormLogin/FormLoginStyle';
import Logo from "../assets/images/logo-login.png";
import Loading from '../components/Loading/Loading';
import Login from '../service/Login';
import AxymaLogo from '../assets/images/Axyma_branco.svg';

const loginService = new Login();

export default function Recuperacao() {
  const [loading, setLoading] = useState(false);
  const [inputType, setInputType] = useState("password");
  const toggleEye = () => inputType === "password" ? setInputType("text") : setInputType("password")
  const [password, setPassword] = useState("");

  const { t } = useTranslation('translation');

  const { uid } = useParams();

  const now = new Date();

  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    toast.warning('Resetando senha')
    setLoading(true);

    const result = await loginService.resetPassword({ uid: uid as string, password });
    if (!result) {
      toast.error('Erro ao alterar senha')
    } else {
      toast.success('Senha alterada com sucesso!');
      setPassword('');
      navigate('/login');
    }
    setLoading(false);
    return result;
  }

  return loading ? (<div style={{ backgroundColor: '#3a1c64', display: 'flex', flexDirection: 'column', height: '100vh', alignItems: 'center', justifyContent: 'space-evenly' }}>
    <img src={AxymaLogo} alt="logo axyma branco" width={'40%'} />
    <Loading />
  </div>) : (
    <Container className="container is-fluid">
      <ToastContainer />
      <Box className="box">
        <div className="img">
          <img alt="Axyma" src={Logo} />
        </div>
        <UnderLogoText>Alterar Senha</UnderLogoText>
        <form onSubmit={handleSubmit} autoComplete="off" style={{ width: '100%' }}>
          {inputType === "password" ? (
            <AiOutlineEye onClick={toggleEye} className="toggle" />
          ) : (
            <AiOutlineEyeInvisible onClick={toggleEye} className="toggle" />
          )
          }
          <InputLogin
            required
            className="input is-small"
            type={inputType}
            placeholder={t('loginScreen.password')}
            value={password}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
          />
          <button
            disabled={loading}
            style={{ marginTop: "10px" }}
            type='submit'
            className='button is-small is-fullwidth'
          >
            Alterar
          </button>
        </form>
        <SmallText>Axyma @ {now.getFullYear()}</SmallText>
      </Box>
    </Container>
  )
}
