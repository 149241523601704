import SearchIcon from '@mui/icons-material/Search';
import { useEffect, useState } from 'react';
import { SubItemFormFilling } from '../../../Types';

type props = {
  openModal: boolean,
  setOpenModal: Function,
  subItemSelect: string[],
  setSelectedValue: Function,
  selectedValue: string[],
  subItem: SubItemFormFilling
  onChange?: () => void,
}

type OptionProps = {
  setSelectedValue: Function,
  selectedValue: string[]
  elem: string,
  ind: number,
  subItem: SubItemFormFilling,
  onChange?: Function,
}


function Option({ setSelectedValue, elem, ind, selectedValue, subItem, onChange }: OptionProps) {
  const [selected, setSelected] = useState<boolean[]>([]);

  useEffect(() => {
    const value = subItem.selecionados && subItem?.selecionados.map((sel, index) => selectedValue.includes(String(index)))
    if (value) {
      setSelected(value)
      subItem.selecionados = value
    }
  }, [selectedValue, subItem])

  useEffect(() => {
    onChange && onChange()
  }, [selected, selectedValue])

  const style = {
    marginLeft: '.5em',
    border: 'none',
    backgroundColor: `${selected && selected[ind] ? '#A400CF' : 'inherit'}`,
    cursor: 'pointer',
    color: `${selected && selected[ind] ? 'white' : 'black'}`
  }

  return (
    <>
      <>
        <span
          onClick={() => {
            if (selectedValue.includes(String(ind))) {
              setSelectedValue(selectedValue.filter((el) => el !== String(ind)))
            } else {
              setSelectedValue([String(ind)])
            }
          }}
          style={style}

        >
          {elem}
        </span>
      </>
      <hr style={{ margin: '.5em', width: '100%' }} />
    </>
  )
}

export default function ModalSelect({
  openModal,
  setOpenModal,
  subItemSelect,
  setSelectedValue,
  selectedValue,
  subItem,
  onChange,
}: props) {

  const [search, setSearch] = useState<string>('');

  return (
    <div className={`${openModal ? "modal is-active" : "modal"}`}>
      <div onClick={() => {
        setOpenModal(false)
      }} style={{ backgroundColor: '#6F7376', opacity: .5 }} className="modal-background"></div>
      <div style={{ padding: '.5em', width: '80svw' }} className="modal-card box">
        <section style={{ padding: 0 }} className="modal-card-body">
          <div className="field">
            <p className="control has-icons-left has-icons-right">
              <input
                value={search.toLowerCase()}
                onChange={(e) => setSearch(e.target.value)}
                className="input"
                type="text"
                placeholder="Pesquise aqui" />
              <span className="icon is-small is-left">
                <SearchIcon />
              </span>
              <span className="icon is-small is-right">
                <i className="fas fa-check"></i>
              </span>
            </p>
            <p
              onClick={(e) => {
                e.preventDefault()
                setSelectedValue([])
                subItem.selecionados = Array(subItem.selecionados?.length).fill(false)
                setOpenModal(false)
              }}
              style={{ color: 'red', textAlign: 'center', padding: '.5em', cursor: 'pointer' }}>
              Limpar valor selecionado
            </p>
            <div style={{ display: 'flex', flexDirection: 'column', padding: '.5em' }}>
              {subItem.selecao && subItem?.selecao.filter((elem) => elem.toLowerCase().includes(search.toLowerCase())).map((elem: string, ind: number) =>
                <Option onChange={onChange} key={ind} selectedValue={selectedValue} elem={elem} ind={ind} setSelectedValue={setSelectedValue} subItem={subItem} />
              )}
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}