import { useState } from "react";
import { TabStyle } from "../TabsStyle/TabsStyle";
import AdmData from "./AdmData";
import Password from "./Password";
import Role from "./Role";
import HierarchyConfig from "./HierarchyConfig";
import { useTranslation } from "react-i18next";

export default function SettingsTabs() {
  const { t } = useTranslation('translation');

  const mapNames = {
    role: t('settingsPage.profile.title'),
    password: t('settingsPage.password.title'),
    data: t('settingsPage.data.title'),
    hierarchy: t('settingsPage.hierarchy.title'),
    //profile: t('notifications.profile.tabtitle')
  }

  const [tab, setTab] = useState({
    isActiveObject: 'role',
    object: ['role', 'password', 'data', 'hierarchy']
  });

  const toggleClass = (index: number) => {
    setTab({...tab, isActiveObject: tab.object[index]})
  }

  const toggleActive = (index: number) => {
    if(tab.object[index] === tab.isActiveObject){
      return "is-active"
    } else {
      return "inactve"
    }
  }
  return (
    <div style={{width: '95%'}}>
      <div className="tabs is-boxed">
        <ul>
          { tab.object.map((el, index) => (
            <li key={index} className={toggleActive(index)} onClick={() =>{toggleClass(index)}}>
              <TabStyle theme={toggleActive(index)}>{mapNames[el as keyof typeof mapNames]}</TabStyle>
            </li>
          )) }
        </ul>
      </div>
      {tab.isActiveObject === 'role' && (
        <Role />
      )}
      {tab.isActiveObject === 'password' && (
        <Password />
      )}
      {tab.isActiveObject === 'data' && (
        <AdmData />
      )}
      {tab.isActiveObject === 'hierarchy' && (
        <HierarchyConfig />
      )}
      {/* {tab.isActiveObject === 'profile' && (
        <Profile />
      )} */}
    </div>
  )
}

