import { useTranslation } from "react-i18next";

export default function ModalAlteranativas({ 
  alternativas, 
  openModal, 
  setOpenModal }: { 
    alternativas: string[], 
    openModal: boolean, 
    setOpenModal: Function }) {
  const { t } = useTranslation('translation');

  return (
    <div className={`modal ${openModal ? 'is-active' : ''}`}>
      <div className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p
            style={{
              textAlign: 'center',
              width: '100%',
              fontSize: '20px',
              color: 'grey',
              fontWeight: 500
            }}
          >
            Alternativas
          </p>
        </header>
        <section className="modal-card-body">
          <table className='table' style={{ width: '100%' }}>
            <thead style={{ backgroundColor: '#EEEEEE' }}>
              <tr>
                <th style={{ width: '30px' }}>No.</th>
                <th>Pergunta</th>
              </tr>
            </thead>
            <tbody>
              {alternativas?.map((alternativa: string, index) => (
                <tr key={index}>
                  <td width={'30px'} style={{ textAlign: 'center' }}>{index + 1}</td>
                  <td>
                    {alternativa}
                  </td>
                </tr>
              ))
              }
            </tbody>
          </table>
        </section>
        <footer className="modal-card-foot" style={{ display: 'flex', justifyContent: 'center' }}>
          <div className="buttons">
            <button onClick={() => setOpenModal(!openModal)} className="button is-warning">{t('close')}</button>
          </div>
        </footer>
      </div>
    </div>
  )
}
