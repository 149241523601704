import { memo, useContext, useState } from 'react';
import { AiOutlineCopy, AiOutlineTool } from 'react-icons/ai';
import { RiDeleteBin5Line } from 'react-icons/ri';
import { ButtonsContainerSubItem } from './FormStyle';
import FormSubitemModal from './Modals/FormSubitemModal';
import { useTranslation } from 'react-i18next';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';
import { ChecklistSubitem, ChecklistType, ItemChecklist } from '../../service/Checklists';
import { FaEye } from "react-icons/fa";
import { UserContext, identifierTypesList } from '../../context/UserContext';

const questionKey = 'checklists.subitemModal.questionTypes.';

export type FormSubitemProp = {
  subitem: ChecklistSubitem,
  index: number,
  indexItem: number,
  isEditing: boolean,
  setEditingForm: Function,
  form: ChecklistType,
  item: ItemChecklist,
  setSubModalOpen: Function,
}


function FormSubItem({
  subitem,
  index,
  indexItem,
  isEditing,
  setEditingForm,
  setSubModalOpen,
  form,
  item,
}
  : FormSubitemProp) {
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [editSubModalOpen, setEditSubModalOpen] = useState(false);

  const { identifiersByForm, setIdentifiersByForm } = useContext(UserContext);
  const { t } = useTranslation('translation')
  const optionsSelectMap = {
    avaliacao: t(questionKey + 'avaliation'), multiple: t(questionKey + 'multiple'),
    unique: 'Seleção Única',
    textoCurto: t(questionKey + 'shortText'), textoLongo: t(questionKey + 'longText'),
    list: 'Lista de textos',
    data: t(questionKey + 'date'), hora: t(questionKey + 'hour'),
    numero: t(questionKey + 'number'), assinatura: t(questionKey + 'signature'),
    email: t('email'), telefone: t('tel'),
    rg: 'RG', cpfcnpj: 'CPF/CNPJ',
    cep: 'CEP', placa: 'Placa de Carro'
  }

  const handleDelete = () => {
    form.itens[indexItem].subitens = form.itens[indexItem].subitens.filter((_e, i) => i !== index);
    if (identifierTypesList.includes(subitem.tipo)) {
      const subIdentifi = `${form.id}|${form.versao}|${indexItem}|${index}`
      const thisIdentifierList = identifiersByForm.find(e => e.formId === form.id)?.identifiersList;
      if (form.identificadorLista === subIdentifi) {
        form.identificadorLista = '';
      }
      const newIdentifiers = thisIdentifierList?.filter(e => e.value !== subIdentifi);
      identifiersByForm.forEach(e => {
        if (newIdentifiers !== undefined && e.formId === form.id) {
          e.identifiersList = newIdentifiers
        }
      })
      setIdentifiersByForm([...identifiersByForm])
    }
    setEditingForm({ ...form })
    setConfirmationModal(false);
  }

  const handleDuplicate = () => {
    const thisSubItem = { ...subitem };
    thisSubItem.nome = `${thisSubItem.nome} (Cópia)`
    thisSubItem.id = item.subitens?.length
    thisSubItem.itemid = item?.id
    if (thisSubItem.avaliacao) {
      const newAvaliation = JSON.parse(JSON.stringify(thisSubItem.avaliacao))
      thisSubItem.avaliacao = newAvaliation
    }

    form.itens[indexItem].subitens.push(thisSubItem);
    setEditingForm({ ...form });
  }

  return (
    <>
      <td align="center" draggable={true}>
        {confirmationModal && <ConfirmationModal
          yesFunction={handleDelete}
          isOpen={confirmationModal}
          closeFunction={setConfirmationModal}
        />}
        {editSubModalOpen && (
          <FormSubitemModal
            item={item}
            fatherIndex={indexItem}
            thisIndex={index}
            subItem={subitem}
            editSubModalOpen={editSubModalOpen}
            setEditSubModalOpen={setEditSubModalOpen}
            isEditing={isEditing}
            setEditingForm={setEditingForm}
            form={form}
            setSubModalOpen={setSubModalOpen}
          />
        )}
        {`${indexItem + 1}.${index + 1}`}</td>
      <td align="left" style={{
        width: "60%", textAlign: 'initial',
        wordWrap: 'break-word',
        wordBreak: 'break-word'
      }}>{subitem.nome}</td >
      <td align="center" style={{ width: "12%" }}>{optionsSelectMap[subitem.tipo as keyof typeof optionsSelectMap]}</td>
      <td align="center" style={{ width: "5%" }}>{typeof subitem.peso !== "number" ? '-' : subitem.peso}</td>
      <td>
        <ButtonsContainerSubItem>
          {!isEditing ?
            <button
              className="button is-light is-small"
              onClick={() => {
                const x = { ...subitem };
                subitem = x;
                setEditSubModalOpen(true);
              }}
            >
              <FaEye size={25} />
            </button> :
            <button
              className="button is-light is-small"
              onClick={() => {
                const x = { ...subitem };
                subitem = x;
                setEditSubModalOpen(true);
                setSubModalOpen(true);
              }}
              disabled={!isEditing}
            >
              <AiOutlineTool size={25} />
            </button>
          }
          <button
            className="button is-primary is-small"
            disabled={!isEditing}
            onClick={handleDuplicate}
          >
            <AiOutlineCopy size={20} />
          </button>
          <button
            className="button is-danger is-small"
            onClick={() => {
              setConfirmationModal(true);
            }}
            disabled={!isEditing || item.subitens.length === 1}
          >
            <RiDeleteBin5Line size={20} />
          </button>
        </ButtonsContainerSubItem>
      </td>
    </>
  )
}

export default memo(FormSubItem)