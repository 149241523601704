import { useState } from "react";
import { TabStyle } from "../TabsStyle/TabsStyle";
import Pending from "./Pending";
import History from "./History";
import { useTranslation } from "react-i18next";

export default function AprovationTabs() {
  const { t } = useTranslation('translation');
  const mapNames = {
    pending: t('Aprovations.aprovacoes_pendentes'),
    history: t('Aprovations.historico'),
  }

  const [tab, setTab] = useState({
    isActiveObject: 'pending',
    object: ['pending', 'history']
  });

  const toggleClass = (index: number) => {
    setTab({ ...tab, isActiveObject: tab.object[index] })
  }

  const toggleActive = (index: number) => {
    if (tab.object[index] === tab.isActiveObject) {
      return "is-active"
    } else {
      return "inactve"
    }
  }

  return (
    <div style={{ width: '95%' }}>
      <div className="tabs is-boxed">
        <ul>
          {tab.object.map((el, index) => (
            <li key={index} className={toggleActive(index)} onClick={() => { toggleClass(index) }}>
              <TabStyle theme={toggleActive(index)}>{mapNames[el as keyof typeof mapNames]}</TabStyle>
            </li>
          ))}
        </ul>
      </div>
      {tab.isActiveObject === 'pending' && (
        <Pending />
      )}
      {tab.isActiveObject === 'history' && (
        <History />
      )}
    </div>
  )
}