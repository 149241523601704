import styled from "styled-components";

export const ContainerBase = styled.div`
  background-color: white;
  margin-top: 10px;
`;

export const Column = styled.div`
  //padding-left: 25px;
`;

export const Paragraph = styled.p`
  font-size: 0.8em;
  font-weight: 700;
  color: gray;
  display: flex;
  span {
    font-weight: 500;
  }
`;

export const ParagraphMsg = styled.p`
  font-size: 1.2em;
  font-weight: 700;
  color: gray;
  padding-left: 20px;
`;

export const Label = styled.label`
  color: gray;
  font-size: 12px;
  font-weight: 600;
  text-align: left;
  margin: 0;
  display: flex;
  margin: 5px 0;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;
