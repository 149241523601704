import { useEffect, useState } from "react";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import { Slider } from "@material-ui/core";
import { ButtonsContainer } from "./FormModalStyle";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import Checklists, { ChecklistType } from "../../../../service/Checklists";
import AttToken from "../../../../helpers/attToken";
import { toast } from "react-toastify";
import queryClient from "../../../../service/query";

const ChecklistService = new Checklists();

export default function RangeScoringTab({ setEditModal, form }: { setEditModal: Function, form: ChecklistType }) {
  const { t } = useTranslation('translation');
  const [checked, setChecked] = useState(form.range ? true : false);
  const [loading, setLoading] = useState(false);
  const [itemRange, setItemRange] = useState([
    {
      index: 0,
      name: t('formDetails.range_scoring.perfect'),
      range: 100,
    }
  ]);

  useEffect(() => {
    if (form.range) {
      const objs: any = [];
      Object.keys(form.range).forEach((e: any, i) => {
        objs.push({
          index: i,
          name: form.range ? form.range[e] : '',
          range: Number(e)
        })
      })
      setItemRange(objs);
    }
  }, [])

  const marks = [
    { value: 0, label: 0 },
    { value: 5, label: '|' },
    { value: 10, label: 10 },
    { value: 15, label: '|' },
    { value: 20, label: 20 },
    { value: 25, label: '|' },
    { value: 30, label: 30 },
    { value: 35, label: '|' },
    { value: 40, label: 40 },
    { value: 45, label: '|' },
    { value: 50, label: 50 },
    { value: 55, label: '|' },
    { value: 60, label: 60 },
    { value: 65, label: '|' },
    { value: 70, label: 70 },
    { value: 75, label: '|' },
    { value: 80, label: 80 },
    { value: 85, label: '|' },
    { value: 90, label: 90 },
    { value: 95, label: '|' },
    { value: 100, label: 100 }
  ];

  const valueLabelFormat = (value: number) => {
    const title = itemRange.find((e) => e.range === value)?.name
    if (!title) return <></>
    return <span style={{ backgroundColor: '#3F51B5', color: 'white' }} className="tag">{title}</span>
  }

  return (
    <>
      <div style={{ marginBottom: '60px', display: 'flex', flexDirection: 'column' }}>
        <label className="checkbox" style={{ alignItems:'center' }}>
          <input
            checked={checked}
            onChange={() => setChecked(!checked)}
            type="checkbox"
          />&nbsp;
          {t('formDetails.range_scoring.Habilitar_range_Scoring')}
        </label>
        {checked &&
          <div>
            <div style={{ width: '90%', marginTop: '45px', marginBottom: '20px' }}>
              <Slider
                max={100}
                min={0}
                valueLabelFormat={valueLabelFormat}
                defaultValue={100}
                getAriaLabel={() => itemRange[0].name}
                valueLabelDisplay="on"
                value={itemRange.map((e) => e.range)}
                marks={marks}
              />
            </div>
            <ol>
              {itemRange.map((e, i) => (
                <li style={{ marginLeft: '20px' }} key={i}>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around', marginTop: '10px' }}>
                    <input
                      type="text"
                      className="input is-small column is-4"
                      value={itemRange[i].name}
                      onChange={(e) => {
                        const newState = itemRange.map((item, index) => {
                          if (index === i) {
                            item.name = e.target.value;
                          }
                          return item
                        })
                        setItemRange(newState)
                      }}
                    />
                    <input
                      type="number"
                      max={100}
                      maxLength={3}
                      className="input is-small column is-1"
                      value={itemRange[i].range}
                      onChange={(e) => {
                        const newState = itemRange.map((item, index) => {
                          if (index === i) {
                            item.range = Number(e.target.value);
                          }
                          return item
                        })
                        setItemRange(newState)
                      }} />
                    {i === 0 ?
                      <button
                        style={{ borderRadius: '50px', padding: '1px', margin: '0' }}
                        className="button is-small is-success"
                        onClick={() => setItemRange([...itemRange, { index: itemRange.length, name: t('formDetails.range_scoring.Novo_Range'), range: 50 }])}
                      >
                        <AddCircleIcon />
                      </button>
                      :
                      <button
                        style={{ borderRadius: '50px', padding: '1px', margin: '0' }}
                        className="button is-small is-danger"
                        onClick={() => setItemRange(itemRange.filter((el) => el.index !== e.index))}
                      >
                        <DeleteIcon />
                      </button>}
                  </div>
                </li>
              ))}
            </ol>
          </div>}
        <ButtonsContainer>
          <button
            className='button is-success is-small' disabled={loading} onClick={async () => {
              setLoading(true);
              const token = await AttToken();
              if (token) {
                await ChecklistService.changeRange(token, itemRange, checked, form.id);
                queryClient.resetQueries(['GET_CHECKLIST']);
                toast.success('Range salvo!');
                setEditModal(-1);
              }
              setLoading(false);
            }}>
            {t('save')}
          </button>
          <button className='button is-light is-small' disabled={loading} onClick={() => setEditModal(-1)}>
            {t('close')}
          </button>
        </ButtonsContainer>
      </div>
    </>
  )
}
