import { BiError } from "react-icons/bi";
import { NotFoundImageDiv } from "./Checklists/Modals/FormModal/FormModalStyle";
import ModalImagePreview from "./Reports/ModalImagePreview";
import { Skeleton } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import Reports, { ChecklistReportType } from "../service/Reports";
import AttToken from "../helpers/attToken";

const ImagesCmpAssinatura = ({ images, selected }: { images: string[], selected: ChecklistReportType }) => {

  const reportsService = useMemo(() => new Reports(), []);
  const [returnedImg, setReturnedImg] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState<string>('');
  const [open, setOpen] = useState(false);

  const getImages = useCallback(async () => {
    setLoading(true)
    const token = await AttToken()
    if (token && selected.itens) {
      const response = await reportsService.getImages(token, images)
      if (response) {
        setReturnedImg(response)
        setLoading(false)
      }
    }
  }, [images, reportsService, selected.itens])

  useEffect(() => {
    getImages()
  }, [getImages])

  return (loading) ? (<Skeleton width={'200px'} height={'180px'} style={{ marginTop: '-1.8em' }} />) : (
    <>
      <div
        style={{
          overflow: 'auto',
          display: 'flex',
          margin: 0,
          padding: 0,
          flexWrap: 'wrap',
          backgroundColor: 'white',
        }}>
        {returnedImg && returnedImg?.map((e: string, i: number) => (
          e.length > 0 ?
            <div style={{marginTop: '-3em', marginLeft: '55px'}} key={i}>
              <img style={{ width: 'fit-content', height: '200px', rotate: '-90deg'}} onClick={() => {
                setOpen(true)
                setImage(e)
              }} src={e} alt={`Subitem ${i}`} key={i} />
            </div>
            : (
              <NotFoundImageDiv className='column is-2' style={{ padding: '15px' }}>
                <BiError size={50} />
                <span>{'(imagem nao encontrada)'}</span>
              </NotFoundImageDiv>
            )
        ))}
      </div >
    </>
  )
}

export default ImagesCmpAssinatura;