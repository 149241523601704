import { memo, useState } from "react";
import { TabStyle } from "../TabsStyle/TabsStyle";
import { useTranslation } from "react-i18next";
import PrintTemplate from "./PrintTemplate";
import ChecklistComponent from "./ChecklistComponent";
import PrintTemplateTabs from "./PrintTemplateTabs";

function ChecklistsTabs() {
  const { t } = useTranslation('translation');

  const mapNames = {
    checklists: t('checklists.checklists.title'),
    printConfig: t('checklists.printConfig.title'),
  }

  const [isTab, setIsTab] = useState({
    isActiveObject: 'checklists',
    object: ['checklists', 'printConfig']
  });

  const toggleClass = (index: number) => {
    setIsTab({ ...isTab, isActiveObject: isTab.object[index] })
  }

  const toggleActive = (index: number) => {
    if (isTab.object[index] === isTab.isActiveObject) {
      return "is-active"
    } else {
      return "inactve"
    }
  }
  return (
    <div style={{width: '95%'}}>
      <div className="tabs is-boxed" >
        <ul>
          {isTab.object.map((el, index) => (
            <li key={index} className={toggleActive(index)} onClick={() => { toggleClass(index) }}>
              <TabStyle theme={toggleActive(index)}>{mapNames[el as keyof typeof mapNames]}</TabStyle>
            </li>
          ))}
        </ul>
      </div>
      {isTab.isActiveObject === 'checklists' && (
        <ChecklistComponent />
      )}
      {isTab.isActiveObject === 'printConfig' && (
        <PrintTemplateTabs />
      )}
    </div>
  )
}

export default memo(ChecklistsTabs)