import { useTranslation } from 'react-i18next';
import { GenericHeader } from '../../../StyledComponents/Modal/generic';
import { Label } from '../../Notifications/style/SingleNotificationStyle';
import ServiceTypes, { types } from '../../../service/ServiceTypes';
import { useMutation, useQuery } from '@tanstack/react-query';
import AttToken from '../../../helpers/attToken';
import { toast } from 'react-toastify';
import queryClient from '../../../service/query';
import NovaNotificacao, { newNotificationType } from '../../../service/NovaNotificacao';
import { useEffect } from 'react';

type props = {
  isEdit: boolean,
  notificationEdit: newNotificationType | undefined,
  setNotificationEdit: Function,
  openModal: boolean,
  setOpenModal: Function,
}

const serviceType = new ServiceTypes();
const notifService = new NovaNotificacao();

export default function NewNotificationsModal({
  isEdit,
  notificationEdit,
  setNotificationEdit,
  openModal,
  setOpenModal }: props) {
  const { t } = useTranslation('translation');

  const { data: servicesData } = useQuery({
    queryKey: ['SERVICES_LIST'],
    queryFn: async () => {
      const token = await AttToken();
      if (token) {
        const body = { ...token, empresa: token.userEmpresa };
        const result = await serviceType.getAllServices(body);
        return result
      }
    },
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });

  const { mutate: create } = useMutation({
    mutationKey: ['NEW_NOTIFICATION_MODEL'],
    mutationFn: async () => {
      toast.warn('Criando novo modelo de notificação')
      const token = await AttToken()
      if (token) {
        const response = await notifService.create(token, notificationEdit as newNotificationType)
        return response
      }
    },
    onSuccess: () => {
      queryClient.resetQueries(['GET_NOTIFICATION_LIST'])
      setOpenModal(!openModal)
      toast.success(`${t('notifications.ticket.ModeloCriado')}`)
    },
    onError: () => {

      toast.error(`${t('notifications.ticket.ErroAoCriarModelo')}`)
    }
  })

  const { mutate: edit } = useMutation({
    mutationKey: ['EDIT_NOTIF'],
    mutationFn: async () => {
      toast.warn(`Editando notificação`)
      const token = await AttToken()
      if (token) {
        const response = await notifService.edit(token, notificationEdit as newNotificationType)
        return response
      }
    },
    onSuccess: () => {
      setOpenModal(!openModal)
      queryClient.resetQueries(['GET_NOTIFICATION_LIST'])
      toast.success(`Notificação alterada`)
    },
    onError: () => {
      toast.error(`Erro ao alterar notificação`)
    }
  })

  return (
    <div className={`modal ${openModal ? "modal is-active" : "modal"}`}>
      <div className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head" style={{ display: "flex", flexDirection: "column" }}>
          <GenericHeader>{!isEdit ? t('notifications.ticket.criarModelo') : t('notifications.ticket.editarModelo')}</GenericHeader>
        </header>
        <section className="modal-card-body" style={{ textAlign: 'initial' }}>
          <Label>{t('name')}</Label>
          <input
            className="input is-small"
            type="text"
            value={notificationEdit ? notificationEdit?.name : undefined}
            placeholder={t('name')}
            onChange={(event) => setNotificationEdit({ ...notificationEdit, name: event.target.value })}
          />
          <Label>{t('message')}</Label>
          <textarea
            className="textarea is-small"
            value={notificationEdit?.message}
            placeholder={t('message')}
            onChange={(event) => setNotificationEdit({ ...notificationEdit, message: event.target.value })}
          />
          <Label>{t('notifications.ticket.tipoServico')}</Label>
          <div className='columns' style={{ marginBottom: 0 }}>
            <div className='column'>
              <select
                value={notificationEdit?.serviceType}
                onChange={(elem) => {
                  setNotificationEdit({ ...notificationEdit, serviceType: elem.target.value })
                }}
                className='select is-fullwidth'>
                <option value="">{`${t('select')}...`}</option>
                {servicesData
                  ?.sort((a: types, b: types) => { return a.nome.toLowerCase() > b.nome.toLowerCase() ? 1 : - 1 })
                  ?.map((e: types) => (
                    <option value={e.tipoId} key={e.tipoId}>{e.nome}</option>
                  ))}
              </select>
            </div>
          </div>
        </section>
        <footer className="modal-card-foot" style={{ display: 'flex', justifyContent: 'space-between' }}>
          <button
            disabled={
              (!notificationEdit?.name?.length) ||
              (!notificationEdit?.message?.length) ||
              (!notificationEdit?.serviceType?.length)
            }
            className="button is-success is-small"
            onClick={() => {
              isEdit ?
                edit() :
                create()
            }}>{`${t('save')}`}</button>
          <button
            className="button is-warning is-small"
            onClick={() => {
              setOpenModal(!openModal)
            }}>
            {`${t('cancel')}`}
          </button>
        </footer>
      </div>
    </div>
  )
}
