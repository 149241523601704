import React, { useCallback, useContext, useMemo, useState } from 'react'
import { ConjuntoType } from '../../../../service/Places'
import { UserContext } from '../../../../context/UserContext';
import { useTranslation } from 'react-i18next';
import Set from '../../../../service/Set';
import AttToken from '../../../../helpers/attToken';
import queryClient from '../../../../service/query';
import { toast } from 'react-toastify';
import { GenericHeader, TagContainer, TagSpan } from '../../../../StyledComponents/Modal/generic';
import { AiOutlineClose } from 'react-icons/ai';
import { Label } from '../../../Notifications/style/SingleNotificationStyle';

type props = {
  open: boolean,
  setOpen: Function,
  set: ConjuntoType,
  getPlaces: Function
}

export default function SetTypeAssoci({ open, setOpen, set, getPlaces }: props) {
  const { serviceTypesList, setChanged } = useContext(UserContext);
  const { t } = useTranslation('translation');
  const [selectedServices, setSelectedServices] = useState<string[]>(set.tiposServico || []);

  const SetService = useMemo(() => new Set(), []);

  const updateSet = useCallback(async () => {
    try {
      const token = await AttToken();
      if (token) {
        await SetService.addServiceType({ ...token }, selectedServices, set.id);
        getPlaces(set.cliente);
        setOpen(false);
        queryClient.resetQueries(['Clientes'])
        toast.success('Tipos Adicionados Com Sucesso!');
      }
    }
    catch (err) {
      return console.log(err);
    }
  }, [selectedServices, setOpen, set, SetService, getPlaces])

  const findServiceName = (value: string) => {
    const serviceName = serviceTypesList.find((el) => el.tipoId === value);
    if (serviceName) return serviceName.nome
    else return 'teste';
  }

  return (
    <div className={`modal ${open ? "modal is-active" : "modal"}`}>
      <div className="modal-background" onClick={() => setOpen(false)} />
      <div className="modal-card" onSubmit={(e: React.FormEvent) => e.preventDefault()}>
        <header className="modal-card-head" style={{ display: "flex", flexDirection: "column" }}>
          <GenericHeader>
          {t('places.Associar_Tipos_de_Serviço')}
          </GenericHeader>
        </header>
        <div className="modal-card-body" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column"
            }}
          >
            <Label>{t('Services.servicesManagement.kind')}</Label>
            <TagContainer>
              {selectedServices && selectedServices.length > 0 && selectedServices.map((e: string, i: number) => (
                <TagSpan key={i} onClick={() => {
                  setSelectedServices(selectedServices.filter(el => el !== e))
                }}>
                  {findServiceName(e)}
                  <AiOutlineClose />
                </TagSpan>
              ))}
            </TagContainer>
            <select
              disabled={serviceTypesList.length < 1}
              defaultValue=''
              className="select is-small"
              //placeholder="Selecione as Tags"
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                setSelectedServices([...selectedServices, e.target.value])
              }}
            >
              <option value='' >{t('select')}</option>
              {
                serviceTypesList && serviceTypesList.sort((a, b) => { return a.nome.toLowerCase() > b.nome.toLowerCase() ? 1 : - 1 }).map((e, i) => (
                  <option key={Math.random()} disabled={selectedServices && selectedServices.includes(e.tipoId)} value={e.tipoId}>{e.nome}</option>
                ))
              }
            </select>
          </div>
        </div>
          <div className='modal-card-foot' style={{ display: "flex", justifyContent: "space-between" }}>
            <button
              type='button'
              className="button is-success is-small"
              onClick={() => {
                localStorage.setItem('changedHirarquia', 'true');
                setChanged(true);
                updateSet();
              }}
            >
              {t('save')}
            </button>
            <button
              type="button"
              onClick={() => { setOpen(false) }}
              className="button is-small is-warning"
            >
              {t('cancel')}
            </button>
          </div>
      </div>
    </div>
  )
}
