import React, { Dispatch, SetStateAction, useState } from 'react';
import { FilterAprovationsType } from '../../service/Aprovations';
import { Accordion, AccordionSummary, Autocomplete, AutocompleteChangeDetails, AutocompleteChangeReason, TextField } from '@mui/material';
import { Label } from '../../StyledComponents/Input/generic';
import { useQuery } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import AttToken from '../../helpers/attToken';
import Clients from '../../service/Clients';
import { AxiosError } from 'axios';
import Accordeon from '../Skeletons/Accordeon';
import { IoFilter } from 'react-icons/io5';
import { useTranslation } from 'react-i18next';
import ReactDatePicker from 'react-datepicker';

type props = {
  filter: FilterAprovationsType,
  setFilter: Dispatch<SetStateAction<FilterAprovationsType>>,
  refetch: Function,
  dates: { dataInicio: number, dataFim: number },
  setDates: Function,
  commingFromHistory: boolean
}

const clientService = new Clients();

interface nomeIdSons {
  nome: string;
  id: string;
  locais: string[] | null;
  conjuntos: string[] | null;
  unidades: string[] | null;
}

type keyOptions = 'clientes' | 'locais' | 'conjuntos' | 'unidades';

export default function FiltersAprovationComponent({ filter, setFilter, refetch , dates, setDates, commingFromHistory } : props) {
  const { t } = useTranslation('translation');

  const [values, setValues] = useState<{[key: string]: { label: string, value: string }[]}>({
    clientes: [],
    locais: [],
    conjuntos: [],
    unidades: []
  });

  const {
    data,
    isLoading,
    isFetching
  } = useQuery({
    queryKey: ['GET_FULL_HIEARCHY'],
    queryFn: async () => {
      const response = await toast.promise(async () => {
        const token = await AttToken();
        if (token) {
          const data: { clientes: nomeIdSons[], locais: nomeIdSons[], conjuntos: nomeIdSons[], unidades: nomeIdSons[] } = await clientService.getAllHierarchy(token);
          return data;
        }
      }, {success: 'Filtros Encontrados', error: 'Erro ao buscar', pending: 'Buscando Filtros'})
      return response;
    },
    retry: 5,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    onError: () => {
      console.log(AxiosError)
    }
  });

  const verifySons = (values: { label: string, value: string }[], keyFather: keyOptions, idSon: string, keySons: 'locais' | 'conjuntos' | 'unidades' ) => {
    return values.some(val => {
      if (data) {
        const current = data[keyFather].find(el => el.id === val.value);
        const sons = current ? current[keySons] || [] : []
        if (sons && sons.length) {
          return sons.includes(idSon);
        }
      } else return false;
    })
  }

  const removeSons = (fatherIds: string[], keyFather: keyOptions, keySons: 'locais' | 'conjuntos' | 'unidades', keyFilter: 'cliente' | 'local' | 'conjunto' | 'unidade' ) => {
    if (data) {
      const result: string[] = [];
      fatherIds.forEach(id => {
        const current = data[keyFather].find(el => el.id === id);
        const sons = current ? current[keySons] || [] : [];
        if (sons && sons.length) {
          sons.forEach(sonId => {
            setValues((prev) => {
              if (prev[keySons] && prev[keySons].some(el => el.value === sonId)) {
                return {...prev, [keySons]:  prev[keySons].filter(el => el.value !== sonId)}
              } else return prev
            });
            setFilter((prev) => {
              const filters = prev[keyFilter]
              if (filters && filters.some(ind => ind === sonId)) {
                return {...prev, [keyFilter]: filters.filter(ind => ind !== sonId)}
              } else return prev
            })
            result.push(sonId);
          })
        }
      })
      return result;
    } else return []
  }

  if (isLoading || isFetching) {
    return (
      <Accordeon />
    )
  }

  return (
    <Accordion style={{ marginBottom: '10px' }}>
      <AccordionSummary>
        <span style={{ width: '100%', textAlign: 'center', fontSize: '20px', fontWeight: '600', color: 'gray' }}>
          Filtros
        </span>
      </AccordionSummary>
      <div style={{ padding: '10px' }}>
        {!data ? (
          <span className='tag is-warning'>Nenhum Filtro Encontrado</span>
        ) : (
              <div>
                <div className="columns" style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
              <div className="column" style={{ display: 'flex' }}>
                <div style={{ display: 'flex', width: 'fit-content', flexDirection: 'column' }}>
                  <Label>{t('notifications.unread.startAt')}</Label>
                  <ReactDatePicker
                    selected={new Date(dates.dataInicio)}
                    onKeyDown={(e) => e.preventDefault()}
                    onChange={(date: Date) =>
                      setDates({ ...dates, dataInicio: new Date(date && date.setHours(0, 0, 0, 0)).getTime() })}
                    locale="pt"
                    maxDate={new Date(dates.dataFim)}
                    dateFormat='dd/MM/yyyy'
                    className='input is-small'
                  />
                </div>
                <div style={{ display: 'flex', width: 'fit-content', flexDirection: 'column' }}>
                  <Label>{t('notifications.unread.until')}</Label>
                  <ReactDatePicker
                    selected={new Date(dates.dataFim)}
                    onKeyDown={(e) => e.preventDefault()}
                    onChange={(date: Date) => setDates({ ...dates, dataFim: new Date(date && date.setHours(23, 59, 59, 59)).getTime() })}
                    maxDate={new Date(Date.now())}
                    locale="pt"
                    dateFormat='dd/MM/yyyy'
                    className='input is-small'
                  />
                </div>
              </div>
            </div>
            <Autocomplete
              className='column'
              noOptionsText={'Nenhum Cliente encontrado'}
              size="small"
              multiple
              options={data.clientes
                .sort((a, b) => { return a.nome.toLowerCase() > b.nome.toLowerCase() ? 1 : - 1 })
                .map((option) => ({
                  label: option.nome,
                  value: option.id,
                }))}
              value={values['clientes']}
              isOptionEqualToValue={(option, value) => option.value === value.value}
              renderOption={(props, option, {selected}) => (
                <li {...props} key={option.value}>
                  <span>{option.label}</span>
                </li>)}
              onChange={(event: any, newValue: { label: string, value: string }[] | null, reason: AutocompleteChangeReason, deatails: any) => {
                if (reason === 'clear' && filter.cliente && filter.cliente.length) {
                  const removedLocais = removeSons(filter.cliente, 'clientes', 'locais', 'local');
                  if (removedLocais && removedLocais.length) {
                    const removedConjuntos = removeSons(removedLocais, 'locais', 'conjuntos', 'conjunto');
                    if (removedConjuntos && removedConjuntos.length) {
                      removeSons(removedConjuntos, 'conjuntos', 'unidades', 'unidade');
                    }
                  }
                }
                if (reason === 'removeOption') {
                  const removedCliente = deatails.option.value as string;
                  const removedLocais = removeSons([removedCliente], 'clientes', 'locais', 'local');
                  if (removedLocais && removedLocais.length) {
                    const removedConjuntos = removeSons(removedLocais, 'locais', 'conjuntos', 'conjunto');
                    if (removedConjuntos && removedConjuntos.length) {
                      removeSons(removedConjuntos, 'conjuntos', 'unidades', 'unidade');
                    }
                  }
                }
                if (reason === 'clear') {
                  setFilter((prev) => {
                    if (prev) {
                      delete prev.cliente
                      return prev ? {...prev} : {}
                    } else return {}
                  })
                  setValues((prev) => {
                    if (prev) {
                      prev.clientes = [];
                      return prev ? {...prev} : {}
                    } else return {}
                  })
                } else if (newValue && newValue.length >= 0) {
                  const values = newValue.map(val => val.value);
                  setValues((prev) => prev ? { ...prev, clientes: newValue } : { clientes: newValue })
                  setFilter((prev) => prev ? { ...prev, cliente: values } : { cliente: values });
                }
              }}
              renderInput={(params) => <TextField {...params} label={'Selecionar Clientes'} />}
            />
              <Autocomplete
              className='column'
              noOptionsText={'Sem locais correspondentes'}
              size="small"
              multiple
              options={data.locais
                .filter(lcl => values.clientes.length && verifySons(values.clientes, 'clientes', lcl.id, 'locais'))
                .sort((a, b) => { return a.nome.toLowerCase() > b.nome.toLowerCase() ? 1 : - 1 })
                .map((option) => ({
                  label: option.nome,
                  value: option.id,
                }))}
              value={values['locais']}
              isOptionEqualToValue={(option, value) => option.value === value.value}
              renderOption={(props, option, {selected}) => (
                <li {...props} key={option.value}>
                  <span>{option.label}</span>
                </li>)}
              onChange={(event: any, newValue: { label: string, value: string }[] | null, reason: AutocompleteChangeReason, deatails: any) => {
                if (reason === 'clear' && filter.local && filter.local.length) {
                  const removedConjuntos = removeSons(filter.local, 'locais', 'conjuntos', 'conjunto');
                  if (removedConjuntos && removedConjuntos.length) {
                    removeSons(removedConjuntos, 'conjuntos', 'unidades', 'unidade');
                  }
                }
                if (reason === 'removeOption') {
                  const removedLocal = deatails.option.value as string;
                  const removedConjuntos = removeSons([removedLocal], 'locais', 'conjuntos', 'conjunto');
                  if (removedConjuntos && removedConjuntos.length) {
                    removeSons(removedConjuntos, 'conjuntos', 'unidades', 'unidade');
                  }
                }
                if (reason === 'clear') {
                  setFilter((prev) => {
                    if (prev) {
                      delete prev.local
                      return prev ? {...prev} : {}
                    } else return {}
                  })
                  setValues((prev) => {
                    if (prev) {
                      prev.locais = [];
                      return prev ? {...prev} : {}
                    } else return {}
                  })
                } else if (newValue && newValue.length  >= 0) {
                  const values = newValue.map(val => val.value);
                  setValues((prev) => prev ? { ...prev, locais: newValue } : { locais: newValue })
                  setFilter((prev) => prev ? { ...prev, local: values } : { local: values });
                }
              }}
              renderInput={(params) => <TextField {...params} label={'Selecionar Locais'} />}
            />
              <Autocomplete
              className='column'
              noOptionsText={'Sem conjuntos correspondentes'}
              size="small"
              multiple
              options={data.conjuntos
                .filter(cnj => values.locais.length && verifySons(values.locais, 'locais', cnj.id, 'conjuntos'))
                .sort((a, b) => { return a.nome.toLowerCase() > b.nome.toLowerCase() ? 1 : - 1 })
                .map((option) => ({
                  label: option.nome,
                  value: option.id,
                }))}
              value={values['conjuntos']}
              isOptionEqualToValue={(option, value) => option.value === value.value}
              renderOption={(props, option, {selected}) => (
                <li {...props} key={option.value}>
                  <span>{option.label}</span>
                </li>)}
              onChange={(event: any, newValue: { label: string, value: string }[] | null, reason: AutocompleteChangeReason, deatails: any) => {
                if (reason === 'clear' && filter.conjunto && filter.conjunto.length) {
                  removeSons(filter.conjunto, 'conjuntos', 'unidades', 'unidade');
                }
                if (reason === 'removeOption') {
                  const removedConjunto = deatails.option.value as string;
                  removeSons([removedConjunto], 'conjuntos', 'unidades', 'unidade');
                }
                if (reason === 'clear') {
                  setFilter((prev) => {
                    if (prev) {
                      delete prev.conjunto
                      return prev ? {...prev} : {}
                    } else return {}
                  })
                  setValues((prev) => {
                    if (prev) {
                      prev.conjuntos = [];
                      return prev ? {...prev} : {}
                    } else return {}
                  })
                } else if (newValue && newValue.length  >= 0) {
                  const values = newValue.map(val => val.value);
                  setValues((prev) => prev ? { ...prev, conjuntos: newValue } : { conjuntos: newValue })
                  setFilter((prev) => prev ? { ...prev, conjunto: values } : { conjunto: values });
                }
              }}
              renderInput={(params) => <TextField {...params} label={'Selecionar Conjuntos'} />}
            />
              <Autocomplete
              className='column'
              noOptionsText={'Sem unidades correspondentes'}
              size="small"
              multiple
              options={data.unidades
                .filter(uni => values.conjuntos.length && verifySons(values.conjuntos, 'conjuntos', uni.id, 'unidades'))
                .sort((a, b) => { return a.nome.toLowerCase() > b.nome.toLowerCase() ? 1 : - 1 })
                .map((option) => ({
                  label: option.nome,
                  value: option.id,
                }))}
              value={values['unidades']}
              isOptionEqualToValue={(option, value) => option.value === value.value}
              renderOption={(props, option, {selected}) => (
                <li {...props} key={option.value}>
                  <span>{option.label}</span>
                </li>)}
              onChange={(event: any, newValue: { label: string, value: string }[] | null, reason: AutocompleteChangeReason, deatails: any) => {
                if (reason === 'clear' && filter.conjunto && filter.conjunto.length) {
                  setFilter((prev) => prev && prev.unidade ? {...prev, unidade: undefined} : {})
                }
                if (reason === 'clear') {
                  setFilter((prev) => {
                    if (prev) {
                      delete prev.unidade
                      return prev ? {...prev} : {}
                    } else return {}
                  })
                  setValues((prev) => {
                    if (prev) {
                      prev.unidades = [];
                      return prev ? {...prev} : {}
                    } else return {}
                  })
                } else if (newValue && newValue.length  >= 0) {
                  const values = newValue.map(val => val.value);
                  setValues((prev) => prev ? { ...prev, unidades: newValue } : { unidades: newValue })
                  setFilter((prev) => prev ? { ...prev, unidade: values } : { unidade: values });
                }
              }}
              renderInput={(params) => <TextField {...params} label={'Selecionar Unidades'} />}
            />
            {commingFromHistory && (
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around', margin: '10px' }}>
                <Label className='label'>
                  <input value={'aprovados'} name={'aprov'} type="radio" onChange={(e) => setFilter((prev) => prev ? {...prev, reprovados: undefined, aprovados: true} : {reprovados: undefined, aprovados: true})}/>
                  <span className='tag is-success'>Mostrar Apenas Aprovados</span>
                </Label>
                <Label className='label'>
                  <input value={'reprovados'} name={'aprov'} type="radio" onChange={(e) => setFilter((prev) => prev ? {...prev, reprovados: true, aprovados: undefined} : {reprovados: true, aprovados: undefined})}/>
                  <span className='tag is-danger'>Mostrar Apenas Reprovados</span>
                </Label>
                <Label className='label'>
                  <input value={'all'} name={'aprov'} type="radio" defaultChecked onChange={(e) => setFilter((prev) => prev ? {...prev, reprovados: undefined, aprovados: undefined} : {reprovados: undefined, aprovados: undefined})}/>
                  <span className='tag is-info'>Mostrar todos</span>
                </Label>
              </div>
            )}
            <button onClick={() => refetch()} className='button is-fullwidth is-success' type="button">
            {t('filter')}&nbsp;<IoFilter />
            </button>
          </div>
        )}
      </div>
    </Accordion>
  )
}
