import {
  ChecklistReportType,
} from '../../service/Reports';
import { useQuery } from '@tanstack/react-query';
import Reports from '../../service/Reports';
import { useParams } from 'react-router-dom';
import ModalDetailPrint from './ModalDetailPrint';

export default function LinkExterno() {
  const reportsService = new Reports();
  const params = useParams();



  const { data, isLoading } = useQuery({
    queryKey: ['GET_LINK_EXTERNO'],
    queryFn: async () => {
      const response = await reportsService.getExecutionById(String(params.formId))
      return response.execucao
    },
    keepPreviousData: true,
    retry: 5,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  })

  return (
    <>
      {!isLoading &&
        <ModalDetailPrint
          openModal={true}
          isLoadingLink={isLoading}
          selected={data as ChecklistReportType} />}
    </>
  )
}
