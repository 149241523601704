import axios, { AxiosError, AxiosResponse } from "axios";
import { AuthBodyType } from "./types";
import { ChecklistSubitem } from "./Checklists";
import { toast } from "react-toastify";

export interface analiseFinalizada extends analisePendenteType {
  userFinalizador: string,
  userFinalizadorNome: string,
  rejeitada?: boolean,
  planosCriados?: string[],
  dataAnalise?: number
}

export interface analisePendenteType {
  dataCriacao: number;
  empresa: string;
  id: string;
  idExecucao: string;
  naoConformidades: ChecklistSubitem[];
  nomeForm: string;
};

export type logPlanoType = {
  acao: string;
  user: string;
  data: number;
  foto?: string;
};

export type taskType = {
  criadorNome?: string;
  responsavelNome?: string;
  nome: string;
  comentario?: string;
  status: "pendente" | "emExecucao" | "concluido" | "aprovado" | "rejeitado";
  responsavel: string;
  dataLimite: number;
  detalhes?: string;
  obrigatorioEvidencia: boolean;
  log?: logPlanoType[];
  fim?: number;
  id?: string;
};

export type planoDeAcaoType = {
  criadorNome?: string;
  criador?: string;
  naoConformidades: ChecklistSubitem[];
  nome: string;
  ocorrencia?: string;
  empresa: string;
  idExecucao: string;
  id?: string;
  responsavel: string;
  dataLimite: number;
  dataCriacao?: number;
  fim?: number;
  status: "aberto" | "fechado";
  avaliacao?: "pendente" | "malSucedido" | "bemSucedido";
  responsavelNome?: string;
  tasks: taskType[];
  avaliadorNome?: string;
  detalhesAvaliacao?: string;
  dataFechamento?: number;
  dataAvaliacao?: number;
  dataEdicao?: number;
  dataReinicio?: number;
  userReinicioNome?: number;
  userEditorNome?: string;
  editado?: boolean;
};

class PlanoDeAcao {
  private api = axios.create({
    baseURL: `${process.env.REACT_APP_API_HOST}/planoDeAcao`,
  });

  getPendingList = async (body: AuthBodyType) => {
    try {
      const data: AxiosResponse = await this.api.post("/getPendingList", {
        ...body,
      });
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.response);
        const dataError = error.response?.data as unknown as any;
        const erro = dataError.message;
        toast.error(String(erro));
      }
    }
  };

  startPlano = async (body: AuthBodyType, analiseId: string, planos: planoDeAcaoType[] | null) => {
    try {
      const data: AxiosResponse = await this.api.post("/startPlano", {
        ...body,
        analiseId,
        planos,
      });
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        return error.response?.status;
      }
    }
  };

  salvarGestoresPA = async (
    body: AuthBodyType,
    conjuntoId: string,
    listaGestoresPA?: { [usuarioId: string]: string[] },
    listaInspetoresPA?: { [usuarioId: string]: string[] }
  ) => {
    try {
      const data: AxiosResponse = await this.api.post("/salvarGestoresPA", {
        ...body,
        conjuntoId,
        listaGestoresPA,
        listaInspetoresPA,
      });
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.response);
        const dataError = error.response?.data as unknown as any;
        const erro = dataError.message;
        toast.error(String(erro));
      }
    }
  };

  getListaGestoresPA = async (body: AuthBodyType) => {
    try {
      const data: AxiosResponse = await this.api.post("/getListaGestoresPA", {
        ...body,
      });
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.response);
        const dataError = error.response?.data as unknown as any;
        const erro = dataError.message;
        toast.error(String(erro));
      }
    }
  };

  getPlansByUser = async (body: AuthBodyType) => {
    try {
      const data: AxiosResponse = await this.api.post("/getPlansByUser", {
        ...body,
      });
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.response);
        const dataError = error.response?.data as unknown as any;
        const erro = dataError.message;
        toast.error(String(erro));
      }
    }
  };

  changeStatusTask = async (
    body: AuthBodyType,
    acao: {
      taskIndex: number;
      comentario?: string;
      status: string;
      planoId: string;
      foto?: string;
    }
  ) => {
    try {
      const data: AxiosResponse = await this.api.post("/changeStatusTask", {
        ...body,
        acao,
      });
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.response);
        const dataError = error.response?.data as unknown as any;
        const erro = dataError.message;
        toast.error(String(erro));
      }
    }
  };

  finalizaPlano = async (body: AuthBodyType, planoId: string) => {
    try {
      const data: AxiosResponse = await this.api.post("/finalizaPlano", {
        ...body,
        planoId,
      });
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.response);
        const dataError = error.response?.data as unknown as any;
        const erro = dataError.message;
        toast.error(String(erro));
      }
    }
  };

  getListaInspetoresPA = async (body: AuthBodyType) => {
    try {
      const data: AxiosResponse = await this.api.post("/getListaInspetoresPA", {
        ...body,
      });
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.response);
        const dataError = error.response?.data as unknown as any;
        const erro = dataError.message;
        toast.error(String(erro));
      }
    }
  };

  getFileLink = async (body: AuthBodyType, fileRef: String) => {
    try {
      const data: AxiosResponse = await this.api.post("/getFileLink", {
        ...body,
        fileRef,
      });
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.response);
        const dataError = error.response?.data as unknown as any;
        const erro = dataError.message;
        toast.error(String(erro));
      }
    }
  };

  getListForInspector = async (body: AuthBodyType) => {
    try {
      const data: AxiosResponse = await this.api.post("/getListForInspector", {
        ...body,
      });
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.response);
        const dataError = error.response?.data as unknown as any;
        const erro = dataError.message;
        toast.error(String(erro));
      }
    }
  };

  evaluatePlan = async (body: AuthBodyType, planoId: string, avaliacao: string, detalhes: string) => {
    try {
      const data: AxiosResponse = await this.api.post("/evaluatePlan", {
        ...body,
        planoId,
        avaliacao,
        detalhes
      });
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.response);
        const dataError = error.response?.data as unknown as any;
        const erro = dataError.message;
        toast.error(String(erro));
      }
    }
  };

  getFinishedList = async (body: AuthBodyType) => {
    try {
      const data: AxiosResponse = await this.api.post("/getFinishedList", {
        ...body,
      });
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.response);
        const dataError = error.response?.data as unknown as any;
        const erro = dataError.message;
        toast.error(String(erro));
      }
    }
  };

  getRefuseds = async (body: AuthBodyType) => {
    try {
      const data: AxiosResponse = await this.api.post("/getRefuseds", {
        ...body,
      });
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.response);
        const dataError = error.response?.data as unknown as any;
        const erro = dataError.message;
        toast.error(String(erro));
      }
    }
  };

  editPlan = async (body: AuthBodyType, plano: planoDeAcaoType, restarting: boolean) => {
    try {
      const data: AxiosResponse = await this.api.post("/editPlan", {
        ...body,
        plano,
        restarting
      });
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.response);
        const dataError = error.response?.data as unknown as any;
        const erro = dataError.message;
        toast.error(String(erro));
      }
    }
  };

  getPlansById = async (body: AuthBodyType, planIds: string[]) => {
    try {
      const data: AxiosResponse = await this.api.post("/getPlansById", {
        ...body,
        planIds
      });
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.response);
        const dataError = error.response?.data as unknown as any;
        const erro = dataError.message;
        toast.error(String(erro));
      }
    }
  };
}

export default PlanoDeAcao;
