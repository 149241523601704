import { Slider } from '@material-ui/core';
import React, { ChangeEvent, useMemo, useRef, useState } from 'react'
import AvatarEditor from 'react-avatar-editor';
import { useTranslation } from 'react-i18next';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { RiErrorWarningFill } from 'react-icons/ri';
import ReactInputMask from 'react-input-mask';
import { toast, ToastContainer } from 'react-toastify';
import Users from '../../service/Users';
import { Label } from '../../StyledComponents/Input/generic';
import { AproverContainer, FileInput, SenhaDiv, UserRegisterContainer, WaringSpan } from './style/UserRegisterStyle';
import AttToken from '../../helpers/attToken';
import queryClient from '../../service/query';
import ProfilePic from "../../assets/images/profile.png";
import { Skeleton } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import Companies from '../../service/Companies';

export type user = {
  id: string | undefined,
  email: string,
  contato: string,
  empresa: string,
  gerente: string,
  matricula: string,
  nome: string,
  passwdReset: boolean,
  perfil: string,
  aprovador: boolean,
  notificacao: string,
  status: string,
  senha: string,
  foto: string | File | undefined,
  sendEmail: boolean,
  gestorPA?: boolean,
  inspetorPA?: boolean,
}

const companySrvc = new Companies();

type senhaType = {
  digit: number
  uppercase: number
  lowercase: number
  special: number
  min: number
}

export default function UserRegister() {
  const initialUser: user = {
    id: '',
    nome: '',
    email: '',
    contato: '',
    status: 'ativo',
    perfil: '',
    matricula: '',
    senha: '',
    aprovador: false,
    empresa: '',
    gerente: '',
    notificacao: '1',
    passwdReset: false,
    foto: '',
    sendEmail: false,
    gestorPA: false,
    inspetorPA: false,
  }
  const avatarEditorRef = useRef<AvatarEditor | null>(null);

  const [file, setFile] = useState<File | string>();
  const [user, setUser] = useState<user>(initialUser);
  const [validPassword, setValidPassword] = useState<string>('');
  const [inputType, setInputType] = useState("password");
  const [inputTypeValid, setInputTypeValid] = useState("password");
  const [isUserSaved, setIsUserSaved] = useState(false);
  const [scale, setScale] = useState<number>(1);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation('translation');
  const userService = useMemo(() => new Users(), []);

  function checkChar(value: string): undefined | string {
    if (value === '') {
      return ''
    }
    const pattern = /^[A-Za-z0-9_-]+$/;
    if (pattern.test(value)) {
      return value;
    }
    return
  }

  const { data, isLoading } = useQuery({
    queryKey: ['GET_REGRAS'],
    queryFn: async () => {
      const token = await AttToken();
      if (token) {
        const body = { ...token, empresa: token.userEmpresa }
        const response = await companySrvc.getAllPoliticaSenha(body)
        return response
      }
    },
    refetchOnWindowFocus: false,
    refetchOnMount: 'always',
  })

  function checkMinNum(value: senhaType, senha: string) {
    if (value.digit > 0) {
      const minNumbers = new RegExp(`([0-9]{${value.digit},})`)
      return minNumbers.test(senha)
    }
  }

  function checkMinLower(value: senhaType, senha: string) {
    if (value.lowercase > 0) {
      const minLowerCase = new RegExp(`([a-z]{${value.lowercase},})`)
      return minLowerCase.test(senha)
    }
  }

  function checkMinUpper(value: senhaType, senha: string) {
    if (value.uppercase > 0) {
      const minUpperCase = new RegExp(`([A-Z]{${value.uppercase},})`)
      return minUpperCase.test(senha)
    }
  }

  function checkSpecial(value: senhaType, senha: string) {
    if (value.special > 0) {
      const minSpecial = new RegExp(`[!@#$%^&*(),.?":{}|<>]{${value.special},}`)
      return minSpecial.test(senha)
    }
  }

  function checkLenght(value: senhaType, senha: string) {
    if (value.min > 0) {
      const minLenght = new RegExp(`(.{${value.min},})`)
      return minLenght.test(senha)
    }
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      setLoading(true);
      if (validPassword !== user.senha) return toast.warning(t<string>('users.userRegister.passwordWarning'));
      const token = await AttToken();
      if (token) {
        const result = await userService.registerUser({
          ...token,
          user: {
            ...user,
            gerente: token.username,
            empresa: token.userEmpresa,
            foto: file
          }
        })
        setUser(initialUser);
        setValidPassword('');
        setIsUserSaved(true);
        queryClient.resetQueries(['Users']);
        if (result) {
          toast.success(t<string>('users.userRegister.registeredMessage'));
        }
      }
    } catch (err) {
      return console.log(err);
    }
    setLoading(false);
    setFile(ProfilePic)
  }

  const toggleEye = () => {
    inputType === "password" ? setInputType("text") : setInputType("password");
  }

  const toggleEyeValid = () => {
    inputTypeValid === "password" ? setInputTypeValid("text") : setInputTypeValid("password");
  }

  const handleChange = (event: ChangeEvent<{}>, newValue: number | number[]) => {
    if (typeof newValue === 'number') {
      setScale(newValue);
    }
  };

  function getBase64(file: File, cb: (s: string) => void) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      cb(reader.result as string)
    }
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }

  const regexUser = /^[A-Za-z0-9_-]+$/;

  const regexEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  const validatePassword = () => {
    if (user.senha !== validPassword) {
      return (
        <>
          <WaringSpan>{t('users.userRegister.passwordWarning')}</WaringSpan><RiErrorWarningFill />
        </>
      )
    }
  }

  const validateEmail = () => {
    switch (true) {
      case !regexEmail.test(user.email):
        return (
          <>
            <WaringSpan>Email inválido</WaringSpan><RiErrorWarningFill />
          </>
        )
      default:
        return (<></>)
    }
  }

  function disableButton() {
    if (
      (user.senha !== validPassword) ||
      (user.senha === '') ||
      (!regexUser.test(String(user.id))) ||
      (user.perfil === '') ||
      (data.digit > 0 && !checkMinNum(data, user.senha)) ||
      (data.lowercase > 0 && !checkMinLower(data, user.senha)) ||
      (data.uppercase > 0 && !checkMinUpper(data, user.senha)) ||
      (data.special > 0 && !checkSpecial(data, user.senha)) ||
      (data.min > 0 && !checkLenght(data, user.senha))
    ) {
      return true
    }
  }

  return loading ? (
    <div style={{ height: 600, marginTop: '-8.3em' }}>
      <Skeleton width={'100%'} height={'100%'} style={{ margin: 0, padding: 0 }} />
    </div>
  ) : (
    <>
      <UserRegisterContainer>
        {isUserSaved && (
          <ToastContainer />
        )}
        <div className='columns'>
          <div className='column' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} >
            <h1>{t('users.userRegister.importImage')}</h1>
            <div>
              <FileInput
                type="file"
                id="img"
                name="img"
                accept="image/*"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  e.target.files && getBase64(e.target.files[0], (base64) => {
                    setFile(base64)
                  })
                }}
                theme={t('users.userRegister.selectMessage')}
              />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
              <AvatarEditor
                image={!file ? ProfilePic : file}
                width={200}
                height={200}
                border={10}
                borderRadius={200}
                color={[243, 243, 244, 0.6]} // RGBA
                scale={scale}
                rotate={0}
                ref={avatarEditorRef}
              />
              <span style={{ marginTop: '15px' }}>Zoom:</span>
              <Slider
                aria-label="Always visible"
                defaultValue={100}
                step={0.1}
                min={1}
                max={5}
                value={scale}
                style={{ width: '30%', color: '#47aa8d' }}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className='column'>
            <form autoComplete='new-password' onSubmit={handleSubmit} onInvalid={() => 'Preencha esse campo'}>
              <div className='columns'>
                <div className='column' style={{ display: 'flex', flexDirection: 'column' }}>
                  <Label>{t('name')}</Label>
                  <input type="text" value={user.nome} className="input is-small is-fullwidth"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setUser({ ...user, nome: e.target.value })}
                    maxLength={200} required />
                  <Label>{t('email')}</Label>
                  <input type="text" value={user.email} className="input is-small is-fullwidth"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setUser({ ...user, email: e.target.value.toLocaleLowerCase() })}
                    maxLength={200} required />
                  <Label>{t('loginScreen.username')}</Label>
                  <input
                    type="text"
                    value={user.id}
                    className="input is-small is-fullwidth"
                    min={5}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const userName = checkChar(e.target.value)
                      if (userName === undefined) return;
                      setUser({ ...user, id: userName })
                    }}
                    maxLength={20}
                    required
                  />
                  <Label>{t('loginScreen.password')}</Label>
                  <div style={{ display: 'flex' }}>
                    <input
                      autoComplete='new-password'
                      type={inputType}
                      value={user.senha}
                      className="input is-small is-fullwidth"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setUser({ ...user, senha: e.target.value.trim() });
                        checkMinNum(data, e.target.value.trim())
                        checkMinLower(data, e.target.value.trim())
                        checkMinUpper(data, e.target.value.trim())
                        checkSpecial(data, e.target.value.trim())
                        checkLenght(data, e.target.value.trim())
                      }}
                      required />

                    <button type='button' onClick={toggleEye} className='button is-small'>
                      {inputType === "password" ? (
                        <AiOutlineEye />
                      ) : (
                        <AiOutlineEyeInvisible />
                      )
                      }
                    </button>
                  </div>
                  <AproverContainer>
                    <Label>{t('users.userRegister.sendPassword')}</Label>&nbsp;&nbsp;
                    <input type="checkbox"
                      checked={user.sendEmail}
                      onChange={() => setUser({ ...user, sendEmail: !user.sendEmail })} />
                  </AproverContainer>
                </div>
                <div className='column' style={{ display: 'flex', flexDirection: 'column' }}>
                  <Label>{t('profile')}</Label>
                  <select className="input is-small is-fullwidth"
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setUser({ ...user, perfil: e.target.value })}
                    required defaultValue={''}>
                    <option disabled value=''>{t('select')}</option>
                    <option value={'Usuario'}>{t('profiles.user')}</option>
                    <option value={'Supervisor'}>Supervisor</option>
                    <option value={'Gerente'}>{t('profiles.manager')}</option>
                    <option value={'Gestor'}>{t('profiles.coordinator')}</option>
                  </select>
                  <Label>{t('tel')}</Label>
                  <ReactInputMask mask="(99) 99999-9999" type="text" value={user.contato} className="input is-small is-fullwidth"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setUser({ ...user, contato: e.target.value })}
                    required />
                  <Label>{t('registration')}</Label>
                  <input type="text" value={user.matricula} className="input is-small is-fullwidth"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setUser({ ...user, matricula: e.target.value })}
                    maxLength={50} required />
                  <Label>{t('users.userRegister.confirmation')}</Label>
                  <div style={{ display: 'flex' }}>
                    <input
                      autoComplete='new-password'
                      type={inputTypeValid}
                      className="input is-small is-fullwidth" value={validPassword}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => setValidPassword(e.target.value.trim())}
                      required
                    />
                    <button type='button' onClick={toggleEyeValid} className='button is-small'>
                      {inputTypeValid === "password" ? (
                        <AiOutlineEye />
                      ) : (
                        <AiOutlineEyeInvisible />
                      )
                      }
                    </button>
                  </div>
                  <AproverContainer>
                    <Label>{t('approver')}</Label>&nbsp;&nbsp;
                    <input
                      type="checkbox"
                      checked={user.aprovador}
                      onChange={() => setUser({ ...user, aprovador: !user.aprovador })} />
                  </AproverContainer>
                  {process.env.REACT_APP_PROJECT_NAME !== 'Axyma Forms' && (
                    <>
                      <AproverContainer>
                        <Label>Gestor de planos de ação</Label>&nbsp;&nbsp;
                        <input
                          type="checkbox"
                          checked={user.gestorPA}
                          onChange={() => setUser({ ...user, gestorPA: !user.gestorPA })} />
                      </AproverContainer>
                      <AproverContainer>
                        <Label>Inspetor de planos de ação</Label>&nbsp;&nbsp;
                        <input
                          type="checkbox"
                          checked={user.inspetorPA}
                          onChange={() => setUser({ ...user, inspetorPA: !user.inspetorPA })} />
                      </AproverContainer>
                    </>
                  )}
                </div>
              </div>
              <button
                style={{ marginTop: '.8em' }}
                className='button is-small is-success'
                disabled={disableButton()}
                type="submit"
              >
                {t('save')}
              </button>
              <div style={{ display: 'flex', flexDirection: 'column', marginTop: '.2em' }}>
                <div>
                  {user.senha.length > 0 &&
                    validatePassword()
                  }<br />
                  {user.email.length > 0 && validateEmail()}
                </div>
              </div>
            </form>
          </div>
        </div>
      </UserRegisterContainer>
      {isLoading ? < Skeleton /> :
        <SenhaDiv>
          Regras de senha da empresa:
          {data.digit > 0 && !checkMinNum(data, user.senha) && <span>*{`${t('settingsPage.password.minNmbr')} ${data.digit}`}</span>}
          {data.lowercase > 0 && !checkMinLower(data, user.senha) && <span>*{`${t('settingsPage.password.minLower')} ${data.lowercase}`}</span>}
          {data.uppercase > 0 && !checkMinUpper(data, user.senha) && <span>*{`${t('settingsPage.password.minUpper')} ${data.uppercase}`}</span>}
          {data.special > 0 && !checkSpecial(data, user.senha) && <span>*{`${t('settingsPage.password.minSpecial')} ${data.special}`}</span>}
          {data.min > 0 && !checkLenght(data, user.senha) && <span>*{`${t('settingsPage.password.minChar')} ${data.min}`}</span>}
        </SenhaDiv>
      }
    </>
  )
}