import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { toast, ToastContainer } from 'react-toastify';
import { UserContext } from '../../context/UserContext';
import Companies from '../../service/Companies';
import { Form, GreenButton, Input, Label } from '../../StyledComponents/Input/generic';
import AttToken from '../../helpers/attToken';
import SmallTable from '../Skeletons/SmallTable';

const companiesSrvc = new Companies();

export type HierarchyLabel = {
  cliente: string,
  local: string,
  conjunto: string,
  unidade: string
}

const initialHierarchy = {
  cliente: '',
  local: '',
  conjunto: '',
  unidade: ''
}

export default function HierarchyConfig() {
  const { hierarchyLabel, setHierarchyLabel } = useContext(UserContext);
  const [hierarchy, setHierarchy] = useState(initialHierarchy);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation('translation');

  const editLabels = async () => {
    setLoading(true);
    try {
      const token = await AttToken()
      if (token) {
        const { message } = await companiesSrvc.editHierarchyLabel({ ...token, empresa: token.userEmpresa, labels: { ...hierarchy, default: false } });
        message && toast.success(message);
      }

    } catch (err) {
      return console.log(err)
    }
    setLoading(false);
  }

  const getAllFunc = useCallback(async () => {
    try {
      const token = await AttToken();
      if(token) {
        const result = await companiesSrvc.getHierarchyLabels({ ...token, empresa: token.userEmpresa });
        setHierarchy(result);
      }
    } catch (err) {
      return console.log(err)
    }
    setLoading(false);
  }, []);

  useEffect(() => {
      getAllFunc();
  }, [loading, getAllFunc])

  return loading ? (<SmallTable />) : (
    <div>
      <ToastContainer />
      <Form onSubmit={(e: React.FormEvent) => {
        e.preventDefault();
        editLabels();
        setHierarchyLabel(hierarchy);
        setHierarchy(initialHierarchy);
      }}>
        <h1>{t('settingsPage.hierarchy.chooseLabel')}</h1>
        <Label>{`${hierarchyLabel.cliente} - (Cliente)`}</Label>
        <Input className='input is-small'
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setHierarchy({ ...hierarchy, cliente: e.target.value })
          }}
          value={hierarchy.cliente}
        />
        <Label>{`${hierarchyLabel.local} - (Local)`}</Label>
        <Input className='input is-small'
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setHierarchy({ ...hierarchy, local: e.target.value })
          }}
          value={hierarchy.local}
        />
        <Label>{`${hierarchyLabel.conjunto} - (Conjunto)`}</Label>
        <Input className='input is-small'
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setHierarchy({ ...hierarchy, conjunto: e.target.value })
          }}
          value={hierarchy.conjunto}
        />
        <Label>{`${hierarchyLabel.unidade} - (Unidade)`}</Label>
        <Input className='input is-small'
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setHierarchy({ ...hierarchy, unidade: e.target.value })
          }}
          value={hierarchy.unidade}
        />
        <GreenButton type='submit' disabled={
          hierarchy.cliente.length < 3 ||
          hierarchy.local.length < 3 ||
          hierarchy.conjunto.length < 3 ||
          hierarchy.unidade.length < 3
        }
          className="button is-success is-small"
        >
          {t('settingsPage.hierarchy.changeBtn')}
        </GreenButton>
      </Form>
    </div>
  )
}
