import { MainContainerMulti } from './Multiple/MultipleStyle'
import { Container } from './Unique/UniqueStyle'
import { SubItemProps } from './Avaliacao/Avaliacao';
import SubItemPhoto from './SubItemPhoto';
import { Input } from "./Unique/UniqueStyle";
import { useEffect, useState } from 'react';

export default function PlacaCarro({ subItem, indexItem, onChange }: SubItemProps) {
  const [ocorrencia, setOcorrencia] = useState(subItem.padrao ? subItem.padrao : null);

  useEffect(() => {
    if (subItem.padrao) {
      subItem.ocorrencia = subItem.padrao
    }
  }, [])

  useEffect(() => {
    onChange && onChange()
  }, [ocorrencia])

  const placaCarro = (value: string) => {
    return value
      .toUpperCase()
      .replace(/\W/, '')
      .replace(/(\w)(\W)/, '$1')
      .replace(/^(\d)/, '')
      .replace(/^(\w)(\d)/, '$1')
      .replace(/^(\w)(\w)(\d)/, '$1$2')
      .replace(/^(\w{3})(\D)/, '$1')
      .replace(/^(\w{5})(\D)/, '$1')
      .replace(/^(\w{6})(\D)/, '$1')
      .replace(/^(\D{3})(\w)/, '$1-$2')
      .slice(0, 8)
  };

  const borderCheckerComent = () => {
    if (subItem.obrigatoriedade?.preenchimento && ocorrencia === '') return '0.3em red solid'
    if (!subItem.obrigatoriedade?.preenchimento && !ocorrencia?.length) return '0.3em #2196f3 solid'
    if (subItem.obrigatoriedade?.preenchimento && ocorrencia?.length < 8) return '0.3em #FB8E27 solid'
    if (!subItem.obrigatoriedade?.preenchimento && ocorrencia?.length < 8) return '0.3em #FB8E27 solid'
    if (ocorrencia?.length === 8) return '0.3em #4caf50 solid'
  }

  return (
    <MainContainerMulti>
      <Container>
        <Input
          placeholder="Toque para digitar"
          className='input' style={{ borderBottom: `${borderCheckerComent()}`, marginTop: '0.5em' }}
          value={ocorrencia}
          onChange={
            async (e) => {
              setOcorrencia(placaCarro(e.target.value));
              subItem.ocorrencia = placaCarro(e.target.value)
            }}
        />
        {subItem.ocorrencia && subItem.foto &&
          <SubItemPhoto subItem={subItem} indexItem={indexItem} onChange={onChange} />}
      </Container>
    </MainContainerMulti>
  )
}