import { useContext } from 'react'
import { useTranslation } from 'react-i18next';
import Header from '../components/Header/Header'
import Sidebar from '../components/Sidebar/Sidebar'
import { HeaderText } from '../StyledComponents/pages/pages';
import { UserContext } from '../context/UserContext';
import ChangeAlert from '../components/ChangeAlert/ChangeAlert';
import Footer from '../components/Footer/Footer';
import ResearchesTabs from '../components/Researches/ResearchesTabs';

export default function Researches() {
  const { t } = useTranslation('translation');
  const { changed } = useContext(UserContext);

  return (
    <div style={{ display: 'flex', width: '100svw', height: '100%' }}>
      <Sidebar />
      <div style={{ width: '100%', backgroundColor: '#F3F3F4' }}>
        {changed && <ChangeAlert />}
        <Header />
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '50px', flexWrap: 'wrap' }}>
          <HeaderText>Pesquisas (Legado)</HeaderText>
          <ResearchesTabs />
        </div>
        <Footer />
      </div>
    </div>
  );
}