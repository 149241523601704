import MUIDataTable, { MUIDataTableOptions } from "mui-datatables";
import { StyledDiv } from '../TableHelpers/TableStyle';
import { useTranslation } from 'react-i18next';
import { createTheme, ThemeProvider } from '@mui/material/styles';

export type userBackObj = {
  aliasname: string,
  contato: string,
  dataAlteracao: number,
  dataCadastro: number,
  email: string,
  empresa: string,
  foto: string,
  gerente: string,
  id: string,
  matricula: string,
  nome: string,
  notificacao: string,
  passwdReset: string,
  perfil: string,
  status: string,
  aprovador: boolean,
  tags?: []
}

export default function UserComponent({
  userData,
  onChanged
}: {
  userData: userBackObj[],
  onChanged: (e: any) => void
}) {

  const { t } = useTranslation('translation');


  const columns = [
    {
      name: "nome",
      label: t('name'),
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true
      }
    },
    {
      name: "email",
      label: t('email'),
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true
      }
    },
    {
      name: "perfil",
      label: t('profile'),
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true,
        customBodyRender: (value: string) => (
          value === 'Usuario' ? t('profiles.user') : value
        ),
      }
    },
    {
      name: "id",
      label: 'Função',
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true,
        customBodyRender: (userName: string) => (
          <select
            defaultValue=''
            className="select is-small is-fullwidth"
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              console.log(userName, e.target.value)
              onChanged({ userName, value: e.target.value })
            }}
          >
            <option value=''>Selecione</option>
            <option value='rls_cliente'>Cliente</option>
            <option value='rls_conjunto'>Conjunto</option>
            <option value='rls_empresa'>Empresa</option>
            <option value='rls_gerente'>Gerente</option>
            <option value='all'>Total</option>

          </select>
        ),
      }
    },
  ];

  const translatedTextLabels: MUIDataTableOptions = {
    textLabels: {
      body: {
        noMatch: `${t('table.noMatch')}`,
        toolTip: `${t('table.toolTip')}`,
        columnHeaderTooltip: column =>
          `${t('table.columnHeaderTooltip')} ${column.label}`
      },
      pagination: {
        next: `${t('table.next')}`,
        previous: `${t('table.previous')}`,
        rowsPerPage: `${t('table.rowsPerPage')}`,
        displayRows: `${t('table.displayRows')}`
      },
      toolbar: {
        search: `${t('table.search')}`,
        filterTable: `${t('table.filterTable')}`
      },
      filter: {
        title: `${t('table.title')}`,
        reset: `${t('table.reset')}`
      },
      viewColumns: {
        title: `${t('table.viewColumnsTitle')}`
      }
    }
  }

  const getMuiTheme = () => createTheme({
    components: {
      MuiInputBase: {
        styleOverrides: {
          input: {
            width: 'fit-content',
            margin: 0,
            padding: 0
          }
        }
      },
      MUIDataTableFilter: {
        styleOverrides: {
          root: {
            width: '35dvw',
          }
        }
      },
      MuiTableBody: {
        styleOverrides: {
          root: {
            td: {
              height: 'fit-content',
              margin: 0,
              padding: '5px',
              width: 'fit-content',
              stackedCommon: {
                '@media (max-width:500px)': {
                  display: 'flex',
                  flexDirection: 'column'
                }
              }
            }
          }
        }
      },
      MuiGrid: {
        styleOverrides: {
          item: {
            margin: 0,
            padding: 0
          },
          root: {
            margin: 0,
            padding: 0
          }
        }
      },
      MuiFormControl: {
        styleOverrides: {
          root: {
            padding: '10px'
          }
        }
      },
      MUIDataTableBodyCell: {
        styleOverrides: {
          root: {
            fontSize: '12px'
          },
        }
      }
    }
  })

  const tableOptions: MUIDataTableOptions = {
    selectableRowsHeader: false,
    selectableRowsHideCheckboxes: true,
    viewColumns: false,
    print: false,
    download: false,
    filter: false,
    responsive: 'simple',
  };

  return (
    <StyledDiv>
      <ThemeProvider theme={getMuiTheme()}>
        <MUIDataTable
          title={t('users.userSearch.userList')}
          data={userData as userBackObj[]}
          columns={columns}
          options={{ ...tableOptions, ...translatedTextLabels }}
        />
      </ThemeProvider>
    </StyledDiv>
  )
}