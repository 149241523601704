import axios, { AxiosError, AxiosResponse } from "axios";
import { AuthBodyType } from "./types";

export type modeloStyleType = {
  id?: string,
  company?: string,
  name: string;
  colorHeader: string;
  fontHeader: {
    fontFamily: string | null;
    fontSize: number | null;
    color: string;
  };
  background: string;
  fontBackground: {
    fontFamily: string | null;
    fontSize: number | null;
    color: string;
  };
  logo: string | null;
  associatedChecklists?: string[];
  enablePosMessage?: boolean;
  enablePostMessage?: boolean;
  enableLoop?: boolean;
  enableRedirect?: boolean;
  messageBefore?: string;
  imageBefore?: string;
  messageAfter?: string;
  imageAfter?: string;
  link?: string
}

class checklistStyle {
  private api = axios.create({
    baseURL: `${process.env.REACT_APP_API_HOST}/checklistStyle`,
  });

  create = async (
    body: AuthBodyType,
    modelo: modeloStyleType
  ) => {
    try {
      const data: AxiosResponse = await this.api.post("/create", {
        ...body,
        modelo,
      });
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        return error.response?.status;
      }
    }
  };

  edit = async (
    body: AuthBodyType,
    modelo: modeloStyleType
  ) => {
    try {
      const data: AxiosResponse = await this.api.post("/edit", {
        ...body,
        modelo,
      });
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        return error.response?.status;
      }
    }
  };

  getAll = async (
    body: AuthBodyType,
  ) => {
    try {
      const data: AxiosResponse = await this.api.post("/getAll", {
        ...body,
      });
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        return error.response?.status;
      }
    }
  };

  delete = async (
    body: AuthBodyType,modeloId: string
  ) => {
    try {
      const data: AxiosResponse = await this.api.post("/delete", {
        ...body, modeloId
      });
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        return error.response?.status;
      }
    }
  };
}

export default checklistStyle;
