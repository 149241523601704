import axios, { AxiosError, AxiosResponse } from "axios";
import { AuthBodyType } from "./types";

export type respostasType = {
  acerto:
    | {
        acertos: number;
        total: number;
      }[]
    | null;
  configuracao: {
    bg: {
      cor: string;
    } | null;
    fraseFinal: string;
    fraseInicial: string;
    header: {
      cor1: string;
      cor2: string;
      corFonte: string;
      fonte: string;
      tamanhoFonte: number;
    } | null;
    question: {
      cor1: string;
      cor2: string;
      corFonte: string;
      fonte: string;
      tamanhoFonte: number;
    } | null;
  } | null;
  dados: string[] | null;
  empresa: string;
  fim: number;
  historicoId: string | null;
  id: string;
  fileMap: {
    hash: string;
    id: number;
  };
  identificador: any | null;
  inicio: number;
  loop: boolean | null;
  nome: string;
  perguntas: {
    resposta?: string | number | string[],
    alternativas: string[];
    empresa: string;
    id: string;
    observacao: boolean;
    pesquisaId: string;
    questao: string;
    respostaCorreta: boolean;
    tipo: string;
    ultimo: boolean;
  }[];
  userNome: string | null;
  status: boolean | null;
  versao: number;
};

export type perguntasType = {
  alternativas: string[];
  empresa: string;
  id: string;
  observacao: boolean;
  pesquisaId: string;
  questao: string;
  respostaCorreta: boolean;
  tipo: string;
  ultimo: boolean,
  resposta?: number | string | string[] | number[],
};

export type pesquisaType = {
  configuracao: {
    bg: {
      cor: string;
    } | null;
    fraseFinal: string;
    fraseInicial: string;
    header: {
      cor1: string;
      cor2: string;
      corFonte: string;
      fonte: string;
      tamanhoFonte: number;
    } | null;
    question: {
      cor1: string;
      cor2: string;
      corFonte: string;
      fonte: string;
      tamanhoFonte: number;
    } | null;
  } | null;
  dataAlteracao: number | null;
  dataCadastro: number;
  id: string;
  loop: boolean;
  nome: string;
  shown: boolean;
  status: boolean;
  userNome: string;
  versao: number;
  perguntas: perguntasType[];
};

class Pesquisa {
  private api = axios.create({
    baseURL: `${process.env.REACT_APP_API_HOST}/pesquisa`,
  });

  getAll = async (body: AuthBodyType) => {
    try {
      const data: AxiosResponse = await this.api.post("/getAll", { ...body });
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        return error.response?.status;
      }
    }
  };

  getExecutions = async (body: AuthBodyType, id: string) => {
    try {
      const data: AxiosResponse = await this.api.post("/getExecutions", { ...body, id });
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        return error.response?.status;
      }
    }
  };
}

export default Pesquisa;
