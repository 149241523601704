import { Tooltip } from '@material-ui/core'
import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ChecklistReportType,
  equipeReport
} from '../../service/Reports';
import { GenericHeader } from '../../StyledComponents/Modal/generic';
import ReactToPrint, { useReactToPrint } from 'react-to-print';
import { AiFillSetting } from 'react-icons/ai';
import { Label } from '../../StyledComponents/Input/generic';
import { SettingsDiv } from './Style';
import { useMutation, useQuery } from '@tanstack/react-query';
import AttToken from '../../helpers/attToken';
import PrintService, { printTemplate } from '../../service/PrintTemplate';
import { PrintTemplateType } from '../Checklists/Types';
import ModalAprovation from '../Aprovations/Modal/ModalAprovation';
import Select from '../Skeletons/Select';
import Accordeon from '../Skeletons/Accordeon';
import PrintIcon from '@mui/icons-material/Print';
import TicketListReportComponent from './TicketListReportComponent';
// import generatePDF, { Resolution, Margin } from 'react-to-pdf';
import { FaRegFilePdf } from "react-icons/fa6";
import DataDetail from './DataDetail';
import { RiFileExcel2Fill } from 'react-icons/ri';
import { CiLink } from "react-icons/ci";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import copy from "copy-to-clipboard";
import Reports from '../../service/Reports';
import { Skeleton } from '@mui/material';
import { useLocation, useParams } from 'react-router-dom';
import ModalExportCSV from './ModalExportCSV';
import Html2Pdf from 'html2pdf.js';
import './styles.css';

type props = {
  selected?: ChecklistReportType,
  selectedObj?: ChecklistReportType[],
  openModal?: boolean,
  setOpenModal?: Function,
  commingFromFilled?: boolean,
  numberOfTicktes?: number,
  isLoadingLink?: boolean,
  inicio?: number,
  onClose?: () => void
}

export type addTicketType = {
  itemIndex?: number | boolean,
  subItemIndex?: number | boolean,
  active?: boolean
}

export type campoOcultosType = {
  [key: string]: string
}

const printTemplateService = new PrintService();

function ModalDetailPrint({ selected, openModal, setOpenModal, isLoadingLink, selectedObj, onClose, inicio }: props) {
  const reportsService = new Reports();

  const { t } = useTranslation('translation');
  const [showSettings, setShowSettings] = useState(false);
  const [showHeaders, setShowHeaders] = useState(true);
  const [showNotSelected, setShowNotSelected] = useState(true);
  const [showScoring, setShowScoring] = useState(false);

  const [openAprovation, setOpenAprovation] = useState(false);
  const [aprove, setAprove] = useState('')
  // eslint-disable-next-line
  const [showHideFields, setShowHideFields] = useState(false);
  const [showLink, setShowLink] = useState(false);
  const [openModalCSV, setOpenModalCSV] = useState(false);
  const [loadingPrint, setLoadingPrint] = useState(false);

  const { data, isLoading } = useQuery({
    queryKey: ['getAllPrints'],
    queryFn: async () => {
      const token = await AttToken();
      if (token) {
        const response = await printTemplateService.getAllTemplates(token)
        return response.printTemplates;
      }
    },
    keepPreviousData: true,
    retry: 5,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  })

  const [printTemplate, setPrintTemplate] = useState<PrintTemplateType>({} as PrintTemplateType);

  useEffect(() => {
    if (data) {
      const print = data?.find((elem: PrintTemplateType) => elem.dafaultTemplate)
      if (print) setPrintTemplate(print)
    }
  }, [data]);

  const componentRef = useRef<HTMLDivElement>(null);

  const subitensHeight = () => {
    let total = 0;
    if (selectedObj && selectedObj.length) {
      selectedObj.forEach(cl => cl.itens.forEach(e => e.subitens?.forEach(() => total += 150)));
    } else {
      selected?.itens.forEach(e => e.subitens?.forEach(() => total += 150));
    }
    return total;
  }

  const options = {
    filename: `${selected?.formulario || 'Axyma-form'}.pdf`,
    margin: 1,
    image: { type: 'jpeg', quality: 0.98 },
    pagebreak: { mode: ['avoid-all', 'css', 'legacy'] },
    jsPDF: {
      compress: true,
      putTotalPages: true,
    },
    html2canvas: {
      height: (componentRef?.current?.clientHeight || 1000) + subitensHeight(),
      scale: 2,
      dpi: 300,
      letterRendering: true,
      useCORS: true
    }
  };

  const handleShare = useReactToPrint({
		content: () => componentRef.current,
		// documentTitle: `${selected?.nome || 'Axyma-form'}.pdf`,
		copyStyles: true,
    pageStyle: '@page { size: auto; dpi: 1, display: block}',
		print: async (printIframe: HTMLIFrameElement) => {
			const document = printIframe.contentDocument;
			if (document) {
				const html = document.getElementsByTagName('html')[0];
				console.log(html);
        const exporter = new Html2Pdf(html, options);
        setLoadingPrint(false);
				await exporter.getPdf(options);
			}
      setLoadingPrint(false);
		}
	});

  const location = useLocation();

  // const options = {
  //   filename: 'Relatório Axyma',
  //   resolution: Resolution.LOW,
  //   keepTogether: 'li',
  //   pageBreakAfter: 'div',
  //   page: {
  //     margin: Margin.MEDIUM,
  //   },

  //   overrides: {
  //     pdf: {
  //       header: <div>Teste</div>,
  //       compress: true,
  //       precision: 10
  //     },
  //   },
  // };

  // const getTargetElement = () => componentRef.current;

  const escape = useCallback((e: any): void => {
    if (e.key === 'Escape' && openModal) {
      setOpenModal && setOpenModal(false);
    }
  }, [openModal, setOpenModal])

  useEffect(() => {
    document.addEventListener('keydown', escape, true)
  }, [escape])

  const findTeams = (team: equipeReport) => {
    let names: string[][] = [];
    if (team) {
      names = Object.keys(team)?.map((e) => ([
        team[e].nome
      ]))
    }
    return names;
  }

  const camposOcultos = (val: campoOcultosType) => {
    return Object.keys(val)?.map((key) => (
      <>
        <p>{`${key}: ${val[key]}`}</p>
      </>
    ))
  }

  const copyToClipboard = () => {
    let copyText = ''
    if (linkRef.current) copyText = linkRef.current.value
    copy(copyText);
    alert(`link copiado para área de transferência`);
  }

  const linkRef = useRef<HTMLInputElement>(null)

  const { mutate: linkLogado, isLoading: loadingLinkLogado, data: logado } = useMutation({
    mutationKey: ['GET_LINK'],
    mutationFn: async () => {
      const token = await AttToken()
      if (token) {
        const response = await reportsService.getLink(token, String(selected?.username), null, Number(selected?.inicio), String(process.env.REACT_APP_PROJECT_NAME))
        return response.message
      }
    }
  })

  const { mutate: linkDeslogado, isLoading: loadingLinkSDeslogado, data: deslogado } = useMutation({
    mutationKey: ['GET_LINK'],
    mutationFn: async () => {
      const token = await AttToken()
      if (token) {
        const response = await reportsService.getLink(token, null, String(selected?.id), Number(selected?.inicio), String(process.env.REACT_APP_PROJECT_NAME))
        return response.message
      }
    }
  })

  const params = useParams();

  useEffect(() => {
    const print = data && data.find((elem: PrintTemplateType) => elem.dafaultTemplate)
    if (print) setPrintTemplate(print)
  }, [data])

  if (!selected) return <p>Não constam respostas</p>

  return (
    <>
      <ModalAprovation
        openAprovation={openAprovation}
        setOpenAprovation={setOpenAprovation}
        aprove={aprove}
        setAprove={setAprove}
        checklist={Number(selected.inicio)}
        usuarioExecutor={selected.userName}
        setOpenModal={setOpenModal}
        selected={selected}
      />
      {openModalCSV &&
        <ModalExportCSV
          openModal={openModalCSV}
          setOpenModal={setOpenModalCSV}
          form={selected}
        />
      }
      {!openAprovation && !openModalCSV &&
        <div className={`modal ${openModal ? "modal is-active" : "modal"}`}>
          <div className="modal-background" onKeyDown={escape} onClick={() => setOpenModal && setOpenModal(false)} />
          <div className="modal-card" style={{ width: `${window.innerWidth <= 760 ? '100%' : '800px'}` }}>
            <header className="modal-card-head" style={{ display: "flex", maxWidth: '100%', justifyContent: 'space-between', flexDirection: 'column' }}>
              <div>
                <GenericHeader>
                  {t('Filling.visualizacaoForm')}
                </GenericHeader>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', width: '100%', flexWrap: 'wrap' }}>
                {window.location.pathname !== '/aprovacao' &&
                  <>
                    <ReactToPrint
                      pageStyle={'@page { size: auto; dpi: 1, display: block, height: window.innerHeight}'}
                      trigger={() =>
                        <button onClick={(e) => {
                          e.preventDefault()
                        }}
                          disabled={loadingPrint}
                          className='button is-success is-small'>
                          <PrintIcon />
                          {t('checklists.printConfig.Imprimir')}
                        </button>}
                      content={() => componentRef.current}
                    />
                    <button
                      className='button  is-warning is-light is-small'
                      disabled={loadingPrint}
                      onClick={() => {
                        setLoadingPrint(true);
                        handleShare()
                      }}
                    >
                      <FaRegFilePdf />&nbsp;
                      Gerar PDF
                    </button>
                    <button className='button is-small is-info' onClick={() => setOpenModalCSV(true)}>
                      <RiFileExcel2Fill />&nbsp;
                      Gerar CSV
                    </button>
                    {location.pathname !== `/detalhe-checklist/${params.formId}/${params.user}` && <button onClick={
                      location.pathname === '/relatorios' ? () => {
                        linkLogado()
                        setShowLink(true)
                      }
                        : () => {
                          linkDeslogado()
                          setShowLink(true)
                        }
                    } className='button is-small'>
                      <CiLink />&nbsp;
                      Gerar Link</button>}
                  </>
                }
                {location.pathname !== `/detalhe-checklist/${params.formId}/${params.user}` && <button
                  className='button is-danger is-small'
                  onClick={() => {
                    onClose && onClose()
                    setOpenModal && setOpenModal(false);
                    selected = {} as ChecklistReportType
                  }}
                >
                  {t('close')}
                </button>}
              </div>
            </header>
            <div className="modal-card-body" style={{ overflowX: 'hidden', padding: '2em' }}>
              {!printTemplate || isLoading || isLoadingLink ? (
                <>
                  <div style={{ marginBottom: '.5em' }}>
                    <Select />
                  </div>
                  <div style={{ marginBottom: '.5em' }}>
                    <Select />
                  </div>
                  <div style={{ marginBottom: '.5em' }}>
                    <Accordeon />
                  </div>
                </>
              ) : <>
                {
                  <>
                    {showLink &&
                      <>
                        <Label>Link</Label>
                        <div style={{ display: 'flex', width: '100%' }}>
                          {loadingLinkLogado || loadingLinkSDeslogado ? <Skeleton style={{ marginTop: '-.7em' }} height={50} width={'100%'} /> :
                            <input
                              ref={linkRef}
                              disabled
                              value={location.pathname === '/relatorios' ? logado : deslogado}
                              className='input is-small is-fullwidth'
                            />
                          }
                          <Tooltip title={` ${t('checklists.subitemModal.preenchimento.copiar')}`}>
                            <button
                              disabled={loadingLinkLogado || loadingLinkSDeslogado}
                              onClick={() => copyToClipboard()}
                              className='button is-small is-success'>
                              <ContentCopyIcon />
                            </button>
                          </Tooltip>
                        </div>
                      </>
                    }

                    <>
                    </>
                    {location.pathname !== `/detalhe-checklist/${params.formId}/${params.user}` &&
                      <Label
                        style={{
                          fontSize: '.6em',
                          display: 'flex',
                          alignItems: 'flex-start',
                          marginBottom: '1em',
                          flexDirection: 'column',
                          width: '100%'
                        }}>
                        {t('checklists.printConfig.Selecione_modelo')}:
                      </Label>
                    }
                    <div style={{ display: 'flex', width: '100%' }}>
                      {location.pathname !== `/detalhe-checklist/${params.formId}/${params.user}` &&
                        <select
                          disabled={!data}
                          defaultValue={printTemplate.id}
                          className="select is-small is-fullwidth"
                          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                            const getTemplate = data?.find((el: printTemplate) => el.id === e.target.value)
                            setPrintTemplate(getTemplate)
                          }}
                        >
                          <option value=''>{t('select')}</option>
                          {
                            data && data?.filter((elem: PrintTemplateType) => elem.status === true)?.sort((a: PrintTemplateType, b: PrintTemplateType) => {
                              return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : - 1
                            })
                              ?.filter((e: printTemplate) => e.status === true)
                              ?.map((e: PrintTemplateType, i: number) => (
                                <option key={i} value={e.id}>{e.name}</option>
                              ))
                          }
                        </select>
                      }
                      <Tooltip title={t('options')}>
                        <button
                          className={showSettings ? 'button is-dark is-small' : 'button is-light is-small'}
                          style={{ display: 'flex', alignSelf: 'center' }}
                          onClick={(e) => {
                            e.preventDefault();
                            setShowSettings(!showSettings)
                          }}
                        >
                          <AiFillSetting />
                        </button>
                      </Tooltip>
                    </div>
                  </>
                }
                {showSettings && (
                  <SettingsDiv>
                    <Label className='checkbox'>
                      <input type="checkbox" className='checkbox' checked={!showHeaders}
                        onClick={() => setShowHeaders(!showHeaders)}
                      />
                      <p>{t('checklists.printConfig.Ocultar_cabecalho')}</p>
                    </Label>
                    <Label className='checkbox'>
                      <input type="checkbox" className='checkbox' checked={!showNotSelected}
                        onClick={() => setShowNotSelected(!showNotSelected)}
                      />
                      <p>{t('checklists.printConfig.Ocultar_alternativas')}</p>
                    </Label>
                    <Label className='checkbox'>
                      <input type="checkbox" className='checkbox' checked={!showScoring}
                        onClick={() => setShowScoring(!showScoring)}
                      />
                      <p>{t('checklists.printConfig.Ocultar_informacoes')}</p>
                    </Label>
                    {selected.camposOcultos && <Label className='checkbox'>
                      <input
                        type="checkbox"
                        className='checkbox'
                        checked={showHideFields}
                        onClick={() => setShowHideFields(!showHideFields)} />
                      <p>Exibir campos ocultos</p>
                    </Label>}
                  </SettingsDiv>
                )}
                {selected.chamadosConfig &&
                  <TicketListReportComponent
                    addingTickets={{
                      itemIndex: false,
                      subItemIndex: false,
                      active: true
                    }}
                    chamadosObj={selected.chamados}
                    chamadosConfig={selected.chamadosConfig}
                    formId={selected.idForm ? selected.idForm : selected.id || '-'}
                    inicio={Number(selected.inicio)}
                    commingFromSub={false}
                    chamadosConfigSub={undefined}
                  />
                }
                {window.location.pathname === '/aprovacao' &&
                  selected.status === 'pendente' &&
                  <div style={{ display: 'flex', justifyContent: 'space-around', marginTop: '.5em' }}>
                    <button className='button is-small is-success'
                      onClick={() => {
                        setOpenAprovation(true);
                        setAprove('aprovado')
                      }}>
                      {t('checklists.printConfig.Aprovar')}
                    </button>
                    <button
                      className='button is-small is-danger'
                      onClick={() => {
                        setOpenAprovation(true);
                        setAprove('reprovado')
                      }}
                    >{t('checklists.printConfig.Reprovar')}
                    </button>
                  </div>}
                <div style={{ backgroundColor: '#ffffff' }} ref={componentRef}>
                  {selectedObj?.length ? selectedObj?.map((elem) =>
                    <DataDetail
                      //componentRef={componentRef}
                      printTemplate={printTemplate}
                      selected={elem}
                      showHeaders={showHeaders}
                      showScoring={showScoring}
                      findTeams={findTeams}
                      showHideFields={showHideFields}
                      camposOcultos={camposOcultos}
                      showNotSelected={showNotSelected}
                    />) :
                    <DataDetail
                      //componentRef={componentRef}
                      printTemplate={printTemplate}
                      selected={selected}
                      showHeaders={showHeaders}
                      showScoring={showScoring}
                      findTeams={findTeams}
                      showHideFields={showHideFields}
                      camposOcultos={camposOcultos}
                      showNotSelected={showNotSelected}
                    />}
                </div>
              </>}
            </div>
          </div>
        </div>
      }
    </>
  )
}

export default memo(ModalDetailPrint)