import axios, { AxiosError, AxiosResponse } from "axios";
import { AuthBodyType } from "./types";

//Arquivos para funcionalidades de tipos de serviço
export interface NameIdService {
  nome: string;
  id: string;
  dataAdicao: number;
  dataDelecao?: number;
}

export interface ConjuntosServico extends NameIdService {
  forms?: NameIdService;
  equipesExecuta?: NameIdService;
  equipesAtende?: NameIdService;
  equipesNotifica?: NameIdService;
  chamados?: NameIdService;
}

export type types = {
  nome: string;
  dataCriacao: number;
  tipoDescicao?: string,
  tipoId: string;
  locais?: {
    [id: string]: NameIdService
  };
  clientes?: {
    [id: string]: NameIdService
  };
  notificacoes?: {
    [id: string]: NameIdService
  };
  conjuntos?: {
    [id: string]: ConjuntosServico
  };
};

export default class ServiceTypes {
  private api = axios.create({
    baseURL: `${process.env.REACT_APP_API_HOST}/servicesTypes`,
  });

  getAllServices = async (body: AuthBodyType & { empresa: string }) => {
    try {
      const data: AxiosResponse = await this.api.post("/getAllByCompany", {
        ...body,
      });
      return data.data.tiposServicos;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        return error.response?.status;
      }
    }
  };

  createServiceType = async (body: AuthBodyType & { empresa: string; tipoNome?: string, descricao?: string }) => {
    try {
      const data: AxiosResponse = await this.api.post("/createServiceType", {
        ...body,
      });
      return data.data.tiposServicos;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        return error.response?.status;
      }
    }
  };

  editServiceType = async (
    body: AuthBodyType & {
      tipo: {
        nome: string;
        tipoId: string;
        dataCriacao: number;
        equipes?: string[];
        forms?: string[];
        clientes?: string[];
        locais?: string[];
        conjuntos?: string[];
        unidades?: string[];
        descricao?: string;
      };
    }
  ) => {
    try {
      const data: AxiosResponse = await this.api.post("/editServiceType ", {
        ...body,
      });
      return data.data.tiposServicos.tipo;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        return error.response?.status;
      }
    }
  };

  deleteServiceType = async (
    body: AuthBodyType & { empresa: string, tipoId: string}
  ) => {
    try {
      const data: AxiosResponse = await this.api.post("/deleteServiceType ", {
        ...body,
      });
      return data.data.tiposServicos;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        return error.response?.status;
      }
    }
  };
}
