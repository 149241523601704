import { MainContainerMulti } from "../Multiple/MultipleStyle";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Button, Container } from "./UniqueStyle";
import ModalSelect from "./ModalSelect";
import { useEffect, useState } from "react";
import { SubItemProps } from "../Avaliacao/Avaliacao";
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import SubItemComentario from "../SubItemComentario";
import SubItemPhoto from "../SubItemPhoto";

export default function Unique({ subItem, indexItem, onChange }: SubItemProps) {
  const [openModal, setOpenModal] = useState(false);
  const [selected, setSelected] = useState<string[]>([]);

  useEffect(() => {
    if (subItem.padrao) {
      setSelected([...subItem.padrao])
    }
  }, [subItem.padrao]);

  if (!subItem.selecionados && subItem.selecao) {
    subItem.selecionados = subItem.selecao?.map((_e, i) => subItem.padrao && subItem.padrao === String(i));
  }

  const borderCheckerComent = () => {
    const comentario = subItem?.ocorrencia === '' || !subItem?.ocorrencia;
    if (subItem.obrigatoriedade?.preenchimento && !(subItem.selecionados as boolean[])?.includes(true)) return '0.3em red solid';
    if (!subItem.obrigatoriedade?.preenchimento) return '0.3em #2196f3 solid';
    if (!subItem?.obrigatoriedade?.ocorrencia && comentario) return '0.3em #2196f3 solid';
    else return '0.3em #4caf50 solid'
  }

  useEffect(() => {
    onChange && onChange()
  }, [selected])

  return (
    <MainContainerMulti>
      {
        openModal &&
        <ModalSelect
          subItemSelect={subItem.selecao as string[]}
          selectedValue={selected}
          openModal={openModal}
          setOpenModal={setOpenModal}
          setSelectedValue={setSelected}
          subItem={subItem}
        />
      }
      <Container>
        {
          selected && selected?.length <= 0 ?
            <>
              <Button
                style={{ borderBottom: `${borderCheckerComent()}` }}
                onClick={(e: any) => {
                  e.preventDefault();
                  setOpenModal(true)
                }}
                className="box">
                <span>
                  Escolha suas opções
                </span>
                <ArrowForwardIosIcon style={{ padding: '.1em' }} />
              </Button>
            </>
            :
            <>
              <div
                style={{
                  borderBottom: '0.3em #4caf50 solid',
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                  justifyContent: 'center',
                  margin: 0,
                  paddingBottom: 0,
                  marginBottom: '.5em',
                  color: 'black'
                }}
                className="box">
                {
                  selected && selected?.map((e, i) => (
                    <>
                      <button style={{ border: 'none', display: 'flex', margin: 0, padding: 0 }} onClick={(e) => {
                        e.preventDefault()
                        setOpenModal(true)
                      }} className="control has-icons-left" key={`${e}${i}`}>
                        <span style={{ backgroundColor: '#E8E8E8', border: 'none', color: 'black' }} className="input is-fullwidth" />
                        <span
                          className="icon is-small is-left"
                          style={{ zIndex: 0, backgroundColor: '#E8E8E8', color: 'black', width: '100%', justifyContent: 'left' }}>
                          <CheckBoxIcon
                            style={{ backgroundColor: 'inherit', color: '#A400CF', height: 'fit-content' }} />
                          &nbsp;{subItem.selecao && subItem.selecao[Number(e)]}
                        </span>
                      </button>
                      <hr style={{ margin: '.5em' }} />
                    </>
                  ))}
              </div>
            </>
        }
        {(subItem.selecionados as boolean[]).some(e => e === true) &&
          <>
            {subItem.comentario &&
              <SubItemComentario onChange={onChange} subItem={subItem} indexItem={indexItem} />
            }
            {subItem.foto &&
              <SubItemPhoto onChange={onChange} subItem={subItem} indexItem={indexItem} />
            }
          </>
        }
      </Container>
    </MainContainerMulti>
  )
}