import { IoExitOutline } from "react-icons/io5";
import { useContext, useEffect } from "react";
import * as Style from "./HeaderStyle"
import { UserContext } from "../../context/UserContext";
import Translation from "../Translation/Translation";
import { useTranslation } from "react-i18next";
import DensityMediumIcon from '@mui/icons-material/DensityMedium';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { logout } from "../../logout";

export type logoutType = {
  userEmpresa: string,
  userToken: string,
  username: string,
}

export default function Header() {
  const { isHidden, setIsHidden } = useContext(UserContext);
  const { t } = useTranslation('translation');

  return (
    <Style.Navbar
      className={isHidden ? "hidden-navbar" : "navbar"}
      id="sidebar">
      <button
        style={{
          background: "#2EA689",
          color: "white",
          borderRadius: "2px",
          border: 'none'
        }}
        onClick={() => setIsHidden(!isHidden)}>
        {!isHidden ? <DensityMediumIcon /> : <ChevronRightIcon />}
      </button>
      <Translation header={true} />
      <Style.Button onClick={() => logout()} className="button">
        <IoExitOutline />
        &nbsp;&nbsp;{t('header.exitButton')}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </Style.Button>
    </Style.Navbar>
  )
}
