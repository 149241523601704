import { centerType } from './DataDetail';
import { AdvancedMarker, APIProvider, Map } from '@vis.gl/react-google-maps';

export default function MapModal({ center, openMap, setOpenMap }: { center: centerType, openMap: boolean, setOpenMap: Function }) {

  return (
    <APIProvider apiKey='AIzaSyC8fpczW4hil6MsSKvaUCVxeTDB0zX2HVU'>
      <div className={`modal ${openMap ? 'is-active' : ''}`}>
        <div className="modal-background"></div>
        <div className="modal-content">
          <button
            onClick={() => setOpenMap(!openMap)}
            className='button is-danger is-small'>
            Fechar X
          </button>
          <div style={{ height: '60svh' }}>
            <Map
              defaultCenter={center || 0}
              defaultZoom={18}
              mapId={'678230660388-snvhcecjbud4freerolc8nsm8rr2hhr0.apps.googleusercontent.com'}>
              <AdvancedMarker position={center || 0} />
            </Map>
          </div>
        </div>
      </div>
    </APIProvider>

  )
}
