import { MainContainerMulti } from './Multiple/MultipleStyle';
import { Container } from './Unique/UniqueStyle';
import { SubItemProps } from './Avaliacao/Avaliacao';
import SubItemInputMask from './SubItemInputMask';
import SubItemPhoto from './SubItemPhoto';

export default function Telefone({ subItem, indexItem, onChange }: SubItemProps) {
  
  return (
    <MainContainerMulti>
      <Container>
        <SubItemInputMask onChange={onChange} subItem={subItem} indexItem={indexItem} mask={'99 9999-99999'} type={'tel'}/>
        {subItem.ocorrencia && subItem.foto &&
          <SubItemPhoto subItem={subItem} indexItem={indexItem} onChange={onChange} />}
      </Container>
    </MainContainerMulti>
  )
}