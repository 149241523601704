import MUIDataTable, { MUIDataTableOptions } from "mui-datatables";
import { ActiveH1, EditButton, IncativeH1, StyledDiv } from '../TableHelpers/TableStyle';
import { RiPencilFill } from 'react-icons/ri';
import { tableOptions } from '../TableHelpers/options';
import React, { useContext, useState, useEffect } from 'react';
import { UserContext } from '../../context/UserContext';
import InputMask from "react-input-mask";
import { Label, Button, Input } from "../../StyledComponents/Input/generic";
import { ModalCard, ModalCardbody, GenericHeader } from "../../StyledComponents/Modal/generic";
import { useTranslation } from "react-i18next";

export default function RegisteredCompanies() {
  const { companyList, openModal, popUp } = useContext(UserContext);
  const [selectedCompany, setSelectedCompany] = useState(0);
  const { t } = useTranslation('translation');

  const initialChangedCompany = {
    companyName: companyList[selectedCompany].companyName,
    cnpj: companyList[selectedCompany].cnpj,
    email: "",
    templateNotif: "",
    templateCham: "",
    templatePesq: "",
    status: companyList[selectedCompany].status,
  }

  const [changeCompany, setChangeCompany] = useState(initialChangedCompany);

  useEffect(() => {
    if (companyList.length > 1 && selectedCompany === 0) setSelectedCompany(selectedCompany + 1);
  }, [companyList, selectedCompany]);

  const columns = [
    {
      name: "companyName",
      label: t('company'),
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true
      }
    },
    {
      name: "cnpj",
      label: "CNPJ",
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true
      }
    },
    {
      name: "createdAt",
      label: t('companiesManage.companiesList.registerDate'),
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true
      }
    },
    {
      name: "status",
      label: t('status'),
      options: {
        customBodyRender: (value: string) => (
          value.toLocaleLowerCase() === 'ativo' ? <ActiveH1>{t('active')}</ActiveH1> : <IncativeH1>{t('inactive')}</IncativeH1>
        ),
        filter: true,
        sort: true,
        sortThirdClickReset: true
      }
    },
    {
      name: "edit",
      label: t('edit'),
      options: {
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return (
            <EditButton
              className="button is-small is-light"
              onClick={() => {
                setSelectedCompany(tableMeta.rowIndex)
                setChangeCompany({
                  companyName: companyList[tableMeta.rowIndex].companyName,
                  cnpj: companyList[tableMeta.rowIndex].cnpj,
                  email: "",
                  templateNotif: "",
                  templateCham: "",
                  templatePesq: "",
                  status: companyList[tableMeta.rowIndex].status,
                })
                openModal()
              }}>
              <RiPencilFill />
              <span>{t('edit')}</span>
            </EditButton>
          )
        },
        filter: true,
        sort: false,
      }
    },
  ];

  const translatedTextLabels: MUIDataTableOptions = {
    textLabels: {
      body: {
        noMatch: `${t('table.noMatch')}`,
        toolTip: `${t('table.toolTip')}`,
        columnHeaderTooltip: column =>
          `${t('table.columnHeaderTooltip')} ${column.label}`
      },
      pagination: {
        next: `${t('table.next')}`,
        previous: `${t('table.previous')}`,
        rowsPerPage: `${t('table.rowsPerPage')}`,
        displayRows: `${t('table.displayRows')}`
      },
      toolbar: {
        search: `${t('table.search')}`,
        filterTable: `${t('table.filterTable')}`
      },
      filter: {
        title: `${t('table.title')}`,
        reset: `${t('table.reset')}`
      },
      viewColumns: {
        title: `${t('table.viewColumnsTitle')}`
      }
    }
  }

  return (
    <StyledDiv>
      {!popUp && (
        <MUIDataTable
          title={t('companiesManage.companiesList.title')}
          data={companyList.filter((e) => e.id !== 0)}
          columns={columns}
          options={{ ...tableOptions, ...translatedTextLabels }}
        />
      )}
      <div className={`modal ${popUp ? "modal is-active" : "modal"}`}>
        <div className="modal-background" />
        <ModalCard className="modal-card" onSubmit={(e: React.FormEvent) => e.preventDefault()}>
          <header className="modal-card-head" style={{ display: "flex", flexDirection: "column" }}>
            <GenericHeader>
              {t('companiesManage.companiesList.alterCompany')}
            </GenericHeader>
          </header>
          <ModalCardbody className="modal-card-body">
            <div
              style={{
                display: "flex",
                flexDirection: "column"
              }}
              onSubmit={(e) => e.preventDefault()}
            >
              <div>
              </div>
              <Label>{t('company')}</Label>
              <Input
                maxLength={100}
                className="input is-small"
                type="text"
                value={changeCompany.companyName}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setChangeCompany({ ...changeCompany, companyName: e.target.value })}
              />
              <Label>CNPJ</Label>
              <InputMask
                mask="99.999.999/9999-99"
                className="input is-small"
                type="text"
                placeholder={companyList[selectedCompany].cnpj}
                value={changeCompany.cnpj}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setChangeCompany({ ...changeCompany, cnpj: e.target.value })}
              />
              <Label>{t('email')}</Label>
              <Input
                maxLength={100}
                className="input is-small"
                type="text"
                placeholder=""
                value={changeCompany.email}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setChangeCompany({ ...changeCompany, email: e.target.value })}
              />
              <Label>{t('companiesManage.companiesList.notificationTemplate')}</Label>
              <Input
                maxLength={100}
                className="input is-small"
                type="text"
                placeholder=""
                value={changeCompany.templateNotif}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setChangeCompany({ ...changeCompany, templateNotif: e.target.value })}
              />
              <Label>{t('companiesManage.companiesList.ticketTemplate')}</Label>
              <Input
                maxLength={100}
                className="input is-small"
                type="text"
                placeholder=""
                value={changeCompany.templateCham}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setChangeCompany({ ...changeCompany, templateCham: e.target.value })}
              />
              <Label>{t('companiesManage.companiesList.researchTemplate')}</Label>
              <Input
                maxLength={100}
                className="input is-small"
                type="text"
                placeholder=""
                value={changeCompany.templatePesq}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setChangeCompany({ ...changeCompany, templatePesq: e.target.value })}
              />
              <Label>{t('status')}</Label>
              <div className="select is-small" style={{ marginTop: "10px" }}>
                <select style={{ width: "100vw" }} onChange={(e) => {
                  setChangeCompany({ ...changeCompany, status: e.target.value })
                }}>
                  <option>Ativo</option>
                  <option>Inativo</option>
                </select>
              </div>
              <div style={{ display: "flex", justifyContent: "space-around", marginTop: "15px" }}>
                <Button
                  className="button is-success is-small"
                  onClick={() => {
                    companyList[selectedCompany] = {
                      id: selectedCompany,
                      companyName: changeCompany.companyName,
                      cnpj: changeCompany.cnpj,
                      createdAt: companyList[selectedCompany].createdAt,
                      status: changeCompany.status
                    }
                    openModal();
                  }}
                >
                  {t('save')}
                </Button>
                <Button
                  type="submit"
                  onClick={() => { openModal() }}
                  className="button is-small is-warning"
                >
                  {t('cancel')}
                </Button>
              </div>
            </div>
          </ModalCardbody>
        </ModalCard>
      </div>
    </StyledDiv>
  )
}
