
export default function ModalDelete({
  openDelete, 
  setOpenDelete,
  mutate,
  user,
  name
}: {
    openDelete: boolean, 
    setOpenDelete :Function,
    mutate: Function,
    user: string,
    name: string
  }) {
    
  return (
    <div className={`modal ${!!openDelete ? "modal is-active" : "modal"}`}>
      <div className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p style={{textAlign: 'center'}} className="modal-card-title">Deseja remover o usário <b>{name}</b>?</p>
        </header>
        <section style={{display: 'flex', justifyContent: 'space-around'}} className="modal-card-body"> 
          <button onClick={() => mutate(user)} className="button is-danger">Remover</button>
          <button className="button" onClick={() => setOpenDelete()}>Cancelar</button>
        </section>
        <footer className="modal-card-foot">
        </footer>
      </div>
    </div>
  )
}
