import { memo, useState } from "react";
import { TabStyle } from "../TabsStyle/TabsStyle";
import { useTranslation } from "react-i18next";
import PrintTemplate from "./PrintTemplate";
import ChecklistStyle from "./ChecklistStyle";

function PrintTemplateTabs() {
  const { t } = useTranslation('translation');

  const mapNames = {
    printConfig: t('checklists.printConfig.title'),
    formWeb: 'Visual Checklist Web'
  }

  const [isTab, setIsTab] = useState({
    isActiveObject: 'printConfig',
    object: [
      'printConfig', 
      'formWeb'
    ]
  });

  const toggleClass = (index: number) => {
    setIsTab({ ...isTab, isActiveObject: isTab.object[index] })
  }

  const toggleActive = (index: number) => {
    if (isTab.object[index] === isTab.isActiveObject) {
      return "is-active"
    } else {
      return "inactve"
    }
  }
  return (
    <div style={{ width: '95%' }}>
      <div className="tabs is-boxed" >
        <ul>
          {isTab.object.map((el, index) => (
            <li key={index} className={toggleActive(index)} onClick={() => { toggleClass(index) }}>
              <TabStyle theme={toggleActive(index)}>{mapNames[el as keyof typeof mapNames]}</TabStyle>
            </li>
          ))}
        </ul>
      </div>
      {isTab.isActiveObject === 'printConfig' && (
        <PrintTemplate />
      )}
      {isTab.isActiveObject === 'formWeb' && (
        <ChecklistStyle />
      )}
    </div>
  )
}

export default memo(PrintTemplateTabs)