import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import Clients, { ClientType } from '../../service/Clients';
import Places, { PlaceType } from '../../service/Places';
import { Label } from '../../StyledComponents/Input/generic';
import PlacesAcordeon from './PlacesAcordeon';
import { MainWrrapper } from './style';
import { UserContext } from '../../context/UserContext';
import NewPlaceModal from './Modals/NewPlaceModal';
import { ToastContainer, toast } from 'react-toastify';
import AttToken from '../../helpers/attToken';
import { useQuery } from '@tanstack/react-query';
import { Pagination, Skeleton } from '@mui/material';
import { Typography } from '@material-ui/core';

const clientsService = new Clients();
const placesService = new Places();

export type reorderType = {
  [key: string]: string
}

export default function PlacesComponent() {
  const { customerId, setCustomerId, placesList, setPlacesList } = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const [clientList, setClientList] = useState<ClientType[] | null>([]);
  const [selected, setSelected] = useState(['initial']);
  const [isLoading, setIsloading] = useState(false);
  const { popUp, openModal, hierarchyLabel } = useContext(UserContext);
  const [places, setPlaces] = useState<PlaceType[]>([]);
  const [search, setSearch] = useState<string>('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);
  const [paginated, setPaginated] = useState<PlaceType[]>([]);
  const [expanded, setExpanded] = useState<string | false>(false);
  const [reorderBy, setReorderBy] = useState<string>('createdBy');
  const [local, setLocal] = useState<reorderType>({});

  const handleChange = (isExpanded: boolean, panel: string) => {
    setExpanded(isExpanded ? panel : false);
  };

  const { t } = useTranslation('translation');

  const { data } = useQuery({
    queryKey: ['GET_CLIENTES'],
    queryFn: async () => {
      const token = await AttToken();
      if (token) {
        const response = await clientsService.getAllClients(token, false);
        return response.clientList;
      }
    },
    retry: 5,
    refetchOnWindowFocus: false,
    refetchOnMount: 'always',
  })

  const getPlaces = async (value: string) => {
    setIsloading(true)
    setPlaces([])
    const token = await AttToken();
    if (token) {
      const response = await placesService.getPlacesWithChildren(token, value)
      setIsloading(false)
      if (response && response.locais.length === 0) {
        toast.warn('Este cliente não tem locais cadastrados!');
        setPlaces([]);
      } else {
        const newPlaces = [...response.locais]
        setPlaces(newPlaces)
      }
    }
    setIsloading(false);
  }

  useEffect(() => {
    if (data) {
      setClientList(data);
      setLoading(false);
    }
    if (places) {
      setPlacesList(places)
    }
  }, [data, places, setPlacesList, placesList])

  const lastPageIndex = currentPage * itemsPerPage;
  const firstPageIndex = lastPageIndex - itemsPerPage;

  const switchSort = (value: string, a: PlaceType, b: PlaceType) => {
    switch (value) {
      case 'createBy':
        return (a.dataCadastro < b.dataCadastro)
      case 'crescent':
        return (a.nome.toLowerCase() > b.nome.toLowerCase())
      case 'decrescent':
        return (a.nome.toLowerCase() < b.nome.toLowerCase())
      default: return (a.dataCadastro < b.dataCadastro)
    }
  }

  useEffect(() => {
    if (placesList) {
      const currentPageData = placesList?.sort((a: PlaceType, b: PlaceType) => {
        return (
          switchSort(reorderBy, a, b) ? 1 : -1
        )
      })
        ?.filter((e: PlaceType) => e.nome.toLowerCase().includes(search.toLowerCase()))
      search ? setPaginated(currentPageData?.slice(0, 5)) : setPaginated(currentPageData?.slice(firstPageIndex, lastPageIndex))
    }
  }, [firstPageIndex, lastPageIndex, places, search, placesList, reorderBy])

  let pages = [];

  for (let i = 1; i <= Math.ceil(placesList!!!?.length / itemsPerPage); i++) {
    pages.push(i)
  }


  return loading ? (
    <>
      <Skeleton variant='text' height={20} width={100} style={{ marginBottom: '-.8em' }} />
      <Skeleton className='input is-large is-fullwidth' style={{ marginBottom: '-.5em' }} />
    </>) : (
    <MainWrrapper>
      <ToastContainer />
      {popUp && <NewPlaceModal getPlaces={getPlaces} />}
      <Label style={{ display: 'flex', flexDirection: 'column' }}>
        {`${t('Services.selectCustomer')} ${hierarchyLabel.cliente}:`}
        <select
          disabled={clientList ? clientList?.length < 1 : false}
          className="select is-small"
          defaultValue=''
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
            setClientList(null);
            setPlaces([]);
            localStorage.setItem('customerId', e.target.value)
            setCustomerId(e.target.value)
            getPlaces(e.target.value);
            const stringLocal = localStorage.getItem('localOrder')
            if (stringLocal) {
              const storageLocal = JSON.parse(stringLocal)
              if (storageLocal) {
                console.log(storageLocal)
                if (storageLocal[e.target.value]) {
                  setReorderBy(storageLocal[e.target.value])
                } else setReorderBy('createdBy')
                setLocal(storageLocal);
              }
            }
          }}
        >
          <option value='' >{t('select')}</option>
          {
            clientList && clientList.sort((a, b) => { return a.nome.toLowerCase() > b.nome.toLowerCase() ? 1 : - 1 })
              .filter((e) => e.status === 'ativo')
              .map((e: ClientType, i) => (
                <option key={i} value={e.id}>{e.nome}</option>
              ))
          }
        </select>
      </Label>
      {customerId?.length > 0 && <span style={{ width: '100%', textAlign: 'center', color: 'gray' }}>
        Exibindo locais do cliente:&nbsp;
        <span style={{ fontWeight: 600 }}>
          {clientList?.find((elem: ClientType) => elem.id === customerId)?.nome}
        </span>
      </span>}
      {customerId?.length > 0 && <button
        style={{ marginTop: '10px', alignSelf: 'flex-start' }}
        className='button is-success'
        onClick={() => openModal()}
        disabled={customerId === ''}
      >
        {`${t('add')} ${hierarchyLabel.local} +`}
      </button>}
      {isLoading ? (
        <>
        </>
      ) :
        <>

          {paginated?.length > 0 &&
            <Label style={{ display: 'flex', flexDirection: 'column' }}>
              {`${t('search')} ${hierarchyLabel.local}`}
              <input value={search} onChange={(even) => setSearch(even.target.value.toLowerCase())} className='input is-small' />
            </Label>}
        </>
      }
      {isLoading ? (
        <>
          <Skeleton width={80} height={15} />
          <Skeleton height={50} style={{ marginTop: '-.5em', marginBottom: '-.5em' }} />
          <Skeleton width={200} height={50} style={{ marginBottom: '-2.5em', marginTop: '-.2em' }} />
        </>
      ) :
        paginated?.length > 0 && <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginBottom: '.5em', marginTop: '.5em' }}>
          <div style={{ marginBottom: '5px' }}>
            {places.length > 5 && <Pagination
              style={{ display: `${!pages?.length ? 'none' : ''}` }}
              showFirstButton
              showLastButton
              shape="rounded"
              variant="outlined"
              count={pages?.length}
              defaultPage={currentPage}
              onChange={(eve, pageNumber) => {
                setCurrentPage(pageNumber);

              }}
            />}
          </div>
        </div>}
      {isLoading ? (
        <>
          <div style={{ display: 'flex', width: '100%', marginTop: 40 }}>
            <Skeleton variant='text' width={60} height={18} />
          </div>
          <Typography style={{ marginBottom: '.2em' }} variant="h2">{<Skeleton variant='rounded' />}</Typography>
        </>
      ) : (
        <div>
          {paginated.length > 0 &&
            <Label style={{ fontSize: '15px' }}>{`${hierarchyLabel.local}:`}
              <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '15px' }}>
                <Label>Ordenar por:</Label>
                <select
                  defaultValue={reorderBy}
                  onChange={(event) => {
                    setReorderBy(event.target.value)
                    local[customerId] = event.target.value
                    setLocal(local)
                    localStorage.setItem('localOrder', JSON.stringify(local))
                  }}
                  style={{ margin: '0px 5px 0px 5px' }}
                  className='select is-small'>
                  <option value={'createBy'}>Por data de criação</option>
                  <option value={'crescent'}>Alfabética crescente (A-Z)</option>
                  <option value={'decrescent'}>Alfabética decrescente (Z-A)</option>
                </select>
              </div>
            </Label>
          }
          {paginated?.map((e: PlaceType) => (
            <PlacesAcordeon
              local={local}
              setLocal={setLocal}
              expanded={expanded}
              handleChange={handleChange}
              place={e}
              key={e.id}
              getPlaces={getPlaces}
              selected={selected}
              setSelected={setSelected}
              setLoading={setLoading}
            />
          ))}
        </div>
      )}
    </MainWrrapper>
  );
};
