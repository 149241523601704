import { useContext, useEffect, useMemo, useState } from 'react'
import { Button } from '../../StyledComponents/Input/generic';
import { useTranslation } from 'react-i18next';
import Checklists, { ChecklistType, NewFormType } from '../../service/Checklists';
import AttToken from '../../helpers/attToken';
import FormFisrtLevel from './FormFirstLevel';
import { toast } from 'react-toastify';
import ServiceTypes from '../../service/ServiceTypes';
import { useQuery, useMutation } from '@tanstack/react-query';
import queryClient from '../../service/query';
import { Skeleton } from '@mui/material';
import { Typography } from '@material-ui/core';
import Pagination from '@mui/material/Pagination';
import { UserContext } from '../../context/UserContext';

function ChecklistComponent() {
  const [editing, setEditing] = useState<{ editing: boolean, id: string }>({ editing: false, id: '' });
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);
  const [checklists, setChecklists] = useState<ChecklistType[]>([]);
  const [search, setSearch] = useState('');
  const [pages, setPages] = useState<number[]>();
  const [totalItens, setTotalItens] = useState<any[]>();
  const [preenchimentoDeslogado, setPreenchimentoDeslogado] = useState<boolean>(false);
  const [preenchimentoWeb, setPreenchimentoWeb] = useState<boolean>(false);
  const [expanded, setExpanded] = useState<string | boolean>(false);
  const [loadSaving, setLoadingSave] = useState(false);

  const { setInitialIdentifiers } = useContext(UserContext);

  const handleChange = (isExpanded: boolean, panel: string) => {
      setExpanded(isExpanded ? panel : false);
  };

  const { t } = useTranslation('translation');

  const checklistService = useMemo(() => new Checklists(), []);

  useEffect(() => {
    if (totalItens) {
      const pagesToSet = [];
      for (let i = 1; i <= Math.ceil(totalItens?.length / itemsPerPage); i++) {
        pagesToSet.push(i)
        setPages(pagesToSet);
      }
    }
  }, [totalItens, itemsPerPage])

  const {
    isLoading,
    isRefetching: isFetchingChecklists
  } = useQuery({
    queryKey: ['GET_CHECKLIST'],
    queryFn: async () => {
      const token = await AttToken();
      if (token) {
        const body = { ...token, empresa: token.userEmpresa, itensPerPage: itemsPerPage, pageNumber: currentPage, filter: search || null }
        const res = await checklistService.getAllChecklists(body);
        setChecklists(res.forms);
        setTotalItens(res.totalItens);
        setInitialIdentifiers(res.forms as ChecklistType[])
        return res
      }
    },
    refetchOnWindowFocus: false,
    refetchOnMount: 'always',
  });

  const { mutate, isLoading: loadingMutate } = useMutation({
    mutationFn: async () => {
      const token = await AttToken();
      if (token) {
        const body = { ...token, empresa: token.userEmpresa, itensPerPage: itemsPerPage, pageNumber: currentPage, filter: search || null }
        const res = await checklistService.getAllChecklists(body);
        setChecklists(res.forms);
        setTotalItens(res.totalItens);
        setInitialIdentifiers(res.forms as ChecklistType[])
      }
    }
  })

  const serviceType = useMemo(() => new ServiceTypes(), []);
  const { data: services } = useQuery({
    queryKey: ['services'],
    queryFn: async () => {
      const token = await AttToken();
      if (token) {
        const body = { ...token, empresa: token.userEmpresa };
        const result = await serviceType.getAllServices(body);
        return result
      }
    },
    refetchOnWindowFocus: false,
    refetchOnMount: 'always',
  });

  const addNew = useMutation({
    mutationKey: ['checklistNew'],
    mutationFn: async () => {
      const token = await AttToken();
      if (token) {
        const itemData: NewFormType[] = [{
          nome: 'Item',
          peso: 1,
          subitens: [
            {
              id: 0,
              nome: 'Subitem',
              tipo: 'avaliacao',
              peso: 1,
              threshold: -1,
              avaliacao: [{
                nome: "1",
                valor: 0
              },
              {
                nome: "2",
                valor: 100
              }]
            }
          ]
        }]
        await checklistService.createChecklist(token, '(Novo Formulário)', itemData, preenchimentoWeb, preenchimentoDeslogado)
      }
    },
    onSuccess: () => {
      queryClient.resetQueries(['GET_CHECKLIST'])
      toast.success('Checklist criado!')
    },
  });

  if (isLoading || isFetchingChecklists || loadingMutate || loadSaving) {
    return (
      <>
        <div style={{ display: 'flex', marginBottom: '-1.8em', alignItems: 'center' }}>
          <Skeleton width={200} height={80} className='button is-large' />&nbsp;&nbsp;
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
            <Skeleton variant="text" width={180} />
            <Skeleton variant="text" width={200} />
          </div>
        </div><br />
        <Typography variant='h3' style={{ marginBottom: '-.3em' }}>
          <Skeleton height={60} width={350} />
        </Typography>
        <Skeleton className='input is-large' />
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Skeleton height={400} style={{ marginTop: '-5.9em'}} />
        </div><br />
      </>
    )
  }

  return (
    <>
      <div
        style={
          {
            display: 'flex',
            alignItems: 'center',
            marginBottom: '10px'
          }
        }
      >
        <Button
          className="button is-small is-primary"
          style={{ fontWeight: "x-bold", fontSize: "20px" }}
          onClick={() => {
            addNew.mutate()

            setTimeout(() => {
              setPreenchimentoDeslogado(false);
              setPreenchimentoWeb(false);
            }, 500)
          }}
        >
          {t('checklists.checklists.newBtn')}
        </Button>
        <div style={{ display: 'flex', flexFlow: 'column wrap', alignItems: 'flex-start' }}>
          <label className='checkbox'>
            <input
              type='checkbox'
              className='checkbox'
              checked={preenchimentoWeb}
              onChange={(e) => {
                setPreenchimentoWeb(e.target.checked)
                if (!e.target.checked) setPreenchimentoDeslogado(false);
              }}
              style={{ marginRight: '5px' }}
            />
            {t('checklists.checklists.preenchimentoWeb')}
          </label>
          <label className='checkbox'>
            <input
              type='checkbox'
              className='checkbox'
              checked={preenchimentoDeslogado}
              onChange={(e) => setPreenchimentoDeslogado(e.target.checked)}
              disabled={!preenchimentoWeb}
              style={{ marginRight: '5px' }}
            />
            {t('checklists.checklists.preenchimentoDeslogado')}
          </label>
        </div>
      </div>
      <div>
        <>
          <div style={{ marginBottom: '5px' }}>
            {Number(totalItens?.length) > 5 && <Pagination
            style={{display: `${!pages?.length ? 'none' : ''}`}}
              showFirstButton 
              showLastButton
              shape="rounded"
              count={pages?.length}
              defaultPage={currentPage}
              onChange={(eve, pageNumber) => {
                setCurrentPage(pageNumber);
                mutate()
              }}
            />}
          </div>
          <form
            onSubmit={(event) => {
              event.preventDefault();
              setCurrentPage(1)
              mutate();
            }}
          >
            <input
              style={{ marginBottom: '.5em' }}
              className='input'
              placeholder={`${t('table.search')} ${t('notifications.unread.form')}`}
              value={search}
              onChange={(e) => setSearch(e.target.value.toLowerCase())}
            />
          </form>
          {checklists ? checklists.map((form: ChecklistType, index: number) => {
            return (
              <FormFisrtLevel
                expanded={expanded}
                handleChange={handleChange}
                form={form}
                key={index}
                formIndex={index}
                editing={editing}
                setEditing={setEditing}
                formKey={index}
                services={services}
                refetchChecklists={mutate}
                setLoadingSave={setLoadingSave}
              />)
          }) : <></>}
        </>
      </div>
    </>
  );
}

export default ChecklistComponent;