import styled from "styled-components";

export const WeightInput = styled.input.attrs({
  onkeydown: "return false"
})`
  background-color: inherit;
  cursor: default;
  border: none;
  width: 40px !important;
  /* ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  } */
  [type="number"] {
    -moz-appearance: textfield;
  }
  border-bottom: 1px solid black;
  /* height: 30px; */
  :focus {
    outline: none;
    background-color: inherit;
  }
  font-size: 18px;
  //text-align: center;
  margin: 0 10px;
`

export const GraySpan = styled.span`
  color: #6e7072;
  font-weight: 600;
  text-align: left;
`

export const StyledFormItemHeader = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 90%;
  padding: 15px;
  .opt {
    justify-self: flex-end;
  }
`

export const StyledFormItem = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  //justify-content: space-;
  align-items: center;
  //width: 90%;
  overflow-wrap: break-word;
  .redButtonX {
    margin: 0 10px;
    :hover {
      cursor: pointer;
    }
  }
`

export const StyledFormItemOptions = styled.div`
  display: flex;
  align-items: flex-end;
  //flex-wrap: wrap;
  justify-content: center;
  //position: absolute;
  margin-right: 40px;
  overflow: none;
  svg {
    max-width: 15px;
  }
  button {
    margin-top: 10px;
  }
`

export const TableContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  table {
    width: 99%;
    margin-bottom: 15px;
  }
`

export const ItemDiv = styled.div`
  width: 98%;
  border: 1px solid lightgrey;
  padding: 10px;
  margin-left: 15px;
  margin-bottom: 20px;
  border-radius: 10px;
`

export const HeaderContainer = styled.div`
  span{
    margin: 0;
    padding: 0;
    font-weight: bold;
    //border-right: 1px solid lightgrey;
    height: 100%;
    //margin-top: 1px;
    //padding-top: 15px;
  }
`

export const GeneralContainer = styled.div`
  display: flex;
  flex-direction: column;
  //justify-content: space-around;
  width: 100%;
  align-items: flex-start;
  overflow: auto
  select {
    height: 20px !important;
    margin-top: 3px;
  }
`

export const SwitchLabel = styled.label`
  margin: 0 10px;
  display: flex;
  align-items: center;
  .switch {
    margin: 0 5px;
  }
`

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  padding-bottom: 7px;
  //border-left: 1px solid lightgray;
  padding-left: 20px;
`

export const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  //margin-right: 93px;
  padding-top: 8px;
  //border-left: 1px solid lightgray;
  //padding-left: 30px;
  color: #6e7072;
  font-weight: 600;
  label {
    margin: 4px 0;
  }
`

export const AddBtn = styled.button`
  margin-bottom: 20px;
  text-align: start;
  margin-right: 86%;
  margin-left: 20px;
  box-shadow: 6px 5px 16px -6px rgba(0,0,0,0.53) !important;
`

export const ButtonsContainerSubItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: 1400px) {
    svg {
      //max-width: 40px;
      max-height: 15px;
    }
    button {
      max-width: 40px;
      padding: 5px;
    }
  }
`

export const PrintTemplateModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px;
  input[type='checkbox'] {
    margin-right: 8px;
  }
  `

export const CheckboxContainer = styled.div`
  padding: 10px;
  width: 100%;
  border-bottom: 1px solid lightgrey;
  select { 
    margin-top: 5px;
    color: #808084;
    :focus {
      border: 2px solid #58c78e;
    }
  }
  textarea {
    border: 1px solid lightgray !important;
    resize: none;
    overflow:hidden;
    :focus {
      border: none;
    }
  }
`

export const ResizeContainer = styled.div`
  display: flex;
  margin-top: 10px;
  align-items: center;
  border: 1px solid lightgray;
  max-width: 46.5%;
  padding: 0;
  background-color: #f5f5f5;
  border-radius: 5px;
  span {
    font-size: 0.5em;
    font-weight: 500;
    padding: 5px;
  }
  input { 
    max-width: 30%;
    height: 27px;
    margin: 0 5px;
    border: none;
    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
  button {
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background-color: inherit;
    height: 30px;
    width: 30px;
    color: #606064;
    :hover {
      background-color: #58c78e;
      color: white;
      cursor: pointer;
    }
  }
  .marked {
    background-color: #58c78e;
    color: white;
    :hover {
      background-color: inherit;
      color: #606064;
      cursor: pointer;
    }
  }
`

export const DropzoneContainer = styled.div`
  min-height: 280px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  justify-content: space-around;
  margin: 10px;
  border: 1px solid lightgrey;
  border-radius: 10px;
  span {
    font-size: 14px;
    padding: 10px;
  }
`

export const FormExampleContainer = styled.tbody`
width: 100%;
  margin: 20px 5px;
  h1 {
    font-size: 1.7em;
    color: black;
    margin: 10px;
    font-weight: bold;
    font-family: 'Times New Roman', Times, serif;
  }
  p, li {
    color: black;
    font-size: 0.8em;
    text-align: left;
  }
  h3 {
    color: black;
    font-weight: bold;
    text-align: left;
    margin-top: 15px;
    border-top: 1px solid lightgray;
    padding-bottom: 0;
    margin-bottom: 0;
  }
  .typeP{
    color: gray;
    font-size: 0.7em;
    margin-bottom: 10px;
    margin-top: 0;
    padding-top: 0;
  }
  .companyTitle {
    position: relative;
    top: 1.3em;
    background-color: white;
    padding: 2px;
    //left: 9em;
    font-family: 'Times New Roman', Times, serif;
    text-transform: uppercase;
    border-top: 0px solid lightgray;
    z-index: 1;
    display: inline;
    margin-right: 44%;
    margin-left: 44%;
  }
  .columns {
    position: relative;
    border: 1px solid darkgray;
    //margin-top: 20px;
    padding: 8px;
    border-radius: 30px;
    //  height: 200px;
    margin: 10px;
  }
`

export const PreviewColor = styled.div`
  align-self: center;
  height: 25px;
  width: 25px;
  //margin: 10px;
  border: 2px solid black;
  border-radius: 10px;
  :hover {
    cursor: pointer;
  }
`

export const WarningSpan = styled.span`
  font-size: 0.5em;
  margin-top: 10px;
  background-color: #ffcc00;
  color: black;
  padding: 5px;
  border-radius: 10px;
`
