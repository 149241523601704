import { useMemo, useState } from 'react'
import { Priority, Team, TreatmentTypes } from '../../service/Teams'
import { useQuery } from '@tanstack/react-query';
import AttToken from '../../helpers/attToken';
import Clients, { ClientsWithChildren } from '../../service/Clients';
import Loading from '../Loading/Loading';
import { WarningSpan } from '../Checklists/FormStyle';
import { Accordion, AccordionSummary } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Label } from '../Notifications/style/SingleNotificationStyle';
import { Skeleton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

type props = {
  tipoServico: string;
  tiposAtendimento: TreatmentTypes[] | null;
  setShowingAssociation: Function;
  nomeServico: string;
  equipe: Team
}

type AccordeonPlacesParams = {
  client: ClientsWithChildren;
  tipoAtendimento: TreatmentTypes | null;
  equipe: Team,
  tipoServico: string,
  expanded: string | boolean,
  handleChange: Function,
}

export default function TypePlaceTreatment({ tipoServico, tiposAtendimento, setShowingAssociation, nomeServico, equipe }: props) {
  const thisTipoLocal = tiposAtendimento ? tiposAtendimento.find(e => e.tipo === tipoServico) || null : null;
  const [priority, setPriority] = useState<Priority[] | null>(tiposAtendimento ? tiposAtendimento.find(e => e.tipo === tipoServico)?.prioridade || null : null)

  const [expanded, setExpanded] = useState<string | boolean>(false);

  const handleChangeOpening = (isExpanded: boolean, panel: string) => {
    setExpanded(isExpanded ? panel : false);
  };

  const CustomerService = useMemo(() => new Clients(), []);

  const { t } = useTranslation('translation');
  const { data: ClientesChildren, isLoading: isLoadingClientesChildren, isFetching } = useQuery({
    queryKey: ['ClientesChildren'],
    queryFn: async () => {
      const data = await AttToken();
      if (data) {
        const teams = await CustomerService.getAllWithSets(data, tipoServico);
        return teams
      } else throw new Error('aaaa')
    },
    retry: 10
  });

  if (isFetching || isLoadingClientesChildren) return (
    <>
      <Skeleton width={300} height={40} />
      <Skeleton width={180} height={50} style={{ marginTop: -10 }} />
      <Skeleton width={280} height={20} style={{ marginTop: -5 }} />
      <div style={{ display: 'flex', justifyContent: 'space-evenly', width: '100%' }}>
        <Skeleton variant='text' width={20} height={30} />
        <Skeleton variant='text' width={20} height={30} />
        <Skeleton variant='text' width={20} height={30} />
      </div>
      <Skeleton width={300} height={40} />
      <Skeleton width={120} height={40} style={{ marginTop: -10 }} />
      <Skeleton width={'100%'} height={80} style={{ marginTop: -10 }} />
    </>
  )

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setPriority((prev) => prev ? [...prev, e.target.value as Priority] : null)
      if (thisTipoLocal && thisTipoLocal.prioridade) {
        thisTipoLocal.prioridade = [...thisTipoLocal.prioridade, e.target.value] as Priority[];
      }
    } else {
      if (priority && priority.length > 1) {
        setPriority((prev) => prev ? prev.filter(el => el !== e.target.value) : null)
        if (thisTipoLocal && thisTipoLocal.prioridade) {
          thisTipoLocal.prioridade = thisTipoLocal.prioridade.filter(pri => pri !== e.target.value) as Priority[];
        }
      }
    }
  }

  return (
    <div>
      <h1>{`${nomeServico}`}</h1>
      <button
        className="button is-success is-small"
        onClick={() => {
          setShowingAssociation(false);
        }}
      >
        <ArrowBackIcon />&nbsp;{t('teamManage.registered.voltar')}
      </button>
      <Label style={{ display: 'flex', flexDirection: 'column' }}>
        {t('teamManage.register.PrioridadeAtendimentoChamados')}:
        <div style={{ display: 'flex', justifyContent: 'space-evenly', width: '100%' }}>
          <label className='checkbox' style={{ display: 'flex', flexDirection: 'column' }}>
            <input
              type="checkbox"
              value="baixa"
              checked={(priority && priority.includes('baixa')) || false}
              onChange={handleChange}
            />
            {t('teamManage.register.low')}
          </label>
          <label className='checkbox' style={{ display: 'flex', flexDirection: 'column' }}>
            <input
              type="checkbox"
              value="media"
              checked={(priority && priority.includes('media')) || false}
              onChange={handleChange}
            />
            {t('teamManage.register.media')}
          </label>
          <label className='checkbox' style={{ display: 'flex', flexDirection: 'column' }}>
            <input
              type="checkbox"
              value="alta"
              checked={(priority && priority.includes('alta')) || false}
              onChange={handleChange}
            />
            {t('teamManage.register.high')}
          </label>
        </div>
      </Label>
      <h1>{t('teamManage.registered.AssociacaoConjuntos')}</h1>
      <h2>{t('teamManage.registered.Clientes')}</h2>
      {isLoadingClientesChildren || isFetching || !ClientesChildren ? <Loading /> : (
        ClientesChildren.map((e: ClientsWithChildren, i: number) => (
          e &&
          <AccordeonPlaces
            expanded={expanded}
            handleChange={handleChangeOpening}
            client={e}
            key={e.id}
            tipoAtendimento={thisTipoLocal}
            equipe={equipe}
            tipoServico={tipoServico}
          />
        ))
      )}
      {(!isLoadingClientesChildren && !isFetching) && ClientesChildren.length === 0 &&
        <WarningSpan>
          * Você não possui nenhuma estrutura geográfica associada a
          esse tipo, por favor associe em "Gestão de Serviços" na aba de "Gestão de Local"
          ou em "Gestão de Cliente" na aba "Meus Clientes".
        </WarningSpan>
      }
    </div>
  )
}

const AccordeonPlaces = ({ client, tipoAtendimento, equipe, tipoServico, expanded, handleChange }: AccordeonPlacesParams) => {
  const [subExpanded, setSubExpanded] = useState(-1);

  const [cnjsArr, setCnjsArr] = useState(tipoAtendimento ? tipoAtendimento.cnjs || [] : []);

  const allSetsFromClient: string[] = [];
  client.locaisList.forEach((local) => {
    local.conjuntosList.forEach((cnj) => allSetsFromClient.push(cnj.id))
  })

  return (
    <div key={client.id}>
      <Accordion style={{ marginBottom: '10px' }} expanded={expanded === client.id}  >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon
            onClick={(isExpanded) => {
              if (expanded === client.id) {
                handleChange(!isExpanded, '')
              } else
                handleChange(isExpanded, client.id)
            }}
          />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Label className='checkbox'>
            <input
              type='checkbox'
              style={{ marginLeft: '10px', marginRight: '10px' }}
              checked={allSetsFromClient.every(set => cnjsArr.includes(set))}
              onChange={(event) => {
                const cnjs: string[] = [];
                if (event.target.checked) {
                  client.locaisList.forEach((local) => {
                    local.conjuntosList.forEach((cnj) => {
                      setCnjsArr((prev) => prev ? [...prev, cnj.id] : [cnj.id])
                      cnjs.push(cnj.id)
                    })
                  })
                  if (equipe.tiposAtendimento) {
                    equipe.tiposAtendimento.forEach(el => {
                      if (el.tipo === tipoServico) {
                        if (el.cnjs) el.cnjs = [...el.cnjs, ...cnjs];
                        else el.cnjs = cnjs;
                      }
                    })
                  }
                } else {
                  client.locaisList.forEach((local) => {
                    local.conjuntosList.forEach((cnj) => {
                      setCnjsArr((prev) => prev.filter(el => el !== cnj.id))
                      cnjs.push(cnj.id)
                    })
                  })
                  if (equipe.tiposAtendimento) {
                    equipe.tiposAtendimento.forEach(el => {
                      if (el.tipo === tipoServico) {
                        if (el.cnjs) el.cnjs = el.cnjs.filter(set => !cnjs.includes(set));
                        else el.cnjs = [];
                      }
                    })
                  }
                }
              }}
            />
            <span style={{ fontWeight: 'bold', color: 'black' }}>{client.nome}</span>
          </Label>
        </AccordionSummary>
        <h2 style={{ marginLeft: '10px', fontSize: '0.8xem' }}>Locais:</h2>
        {client.locaisList && client.locaisList.map((e, i) => {
          const allSetsFromPlace: string[] = e.conjuntosList.map((cnj) => cnj.id)
          return (
            <div key={i}>
              <Accordion style={{ backgroundColor: '#e5e5e5', borderRadius: '0', margin: '10px' }} expanded={subExpanded === i} >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  key={i}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  IconButtonProps={{
                    onClick: () => subExpanded === i ? setSubExpanded(-1) : setSubExpanded(i)
                  }}
                >
                  <Label className='checkbox'>
                    <input
                      type='checkbox'
                      style={{ marginLeft: '10px', marginRight: '10px' }}
                      checked={allSetsFromPlace.every(set => cnjsArr.includes(set))}
                      onChange={(event) => {
                        const cnjs: string[] = [];
                        if (event.target.checked) {
                          e.conjuntosList.forEach((cnj) => {
                            setCnjsArr((prev) => prev ? [...prev, cnj.id] : [cnj.id])
                            cnjs.push(cnj.id)
                          })
                          if (equipe.tiposAtendimento) {
                            equipe.tiposAtendimento.forEach(el => {
                              if (el.tipo === tipoServico) {
                                if (el.cnjs) el.cnjs = [...el.cnjs, ...cnjs];
                                else el.cnjs = cnjs;
                              }
                            })
                          }
                        } else {
                          e.conjuntosList.forEach((cnj) => {
                            setCnjsArr((prev) => prev.filter(el => el !== cnj.id))
                            cnjs.push(cnj.id)
                          })
                          if (equipe.tiposAtendimento) {
                            equipe.tiposAtendimento.forEach(el => {
                              if (el.tipo === tipoServico) {
                                if (el.cnjs) el.cnjs = el.cnjs.filter(set => !cnjs.includes(set));
                                else el.cnjs = [];
                              }
                            })
                          }
                        }
                      }}
                    />
                    <span style={{ fontWeight: 'bold', color: 'black' }}>{e.nome}</span>
                  </Label>
                </AccordionSummary>
                <h2 style={{ marginLeft: '10px', fontSize: '0.6em' }}>Conjuntos:</h2>
                {e.conjuntosList && e.conjuntosList.map((el, ind) => {
                  return (
                    <Label className='checkbox' key={ind}>
                      <input
                        type='checkbox'
                        style={{ marginLeft: '25px', marginRight: '10px' }}
                        checked={cnjsArr.includes(el.id)}
                        onChange={(event) => {
                          const cnjs: string[] = [];
                          if (event.target.checked) {
                            setCnjsArr((prev) => prev ? [...prev, el.id] : [el.id])
                            cnjs.push(el.id)
                            if (equipe.tiposAtendimento) {
                              equipe.tiposAtendimento.forEach(el => {
                                if (el.tipo === tipoServico) {
                                  if (el.cnjs) el.cnjs = [...el.cnjs, ...cnjs];
                                  else el.cnjs = cnjs;
                                }
                              })
                            }
                          } else {
                            setCnjsArr((prev) => prev.filter(ele => ele !== el.id))
                            cnjs.push(el.id)
                            if (equipe.tiposAtendimento) {
                              equipe.tiposAtendimento.forEach(el => {
                                if (el.tipo === tipoServico) {
                                  if (el.cnjs) el.cnjs = el.cnjs.filter(set => !cnjs.includes(set));
                                  else el.cnjs = [];
                                }
                              })
                            }
                          }
                        }}
                      />
                      {el.nome}
                    </Label>
                  )
                })}
              </Accordion>
            </div>
          )
        })}
      </Accordion>
    </div>
  )
}
