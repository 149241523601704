import Sidebar from "../components/Sidebar/Sidebar";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import { useContext } from "react";
import { UserContext } from "../context/UserContext";
import { HeaderText } from '../StyledComponents/pages/pages';
import { useTranslation } from "react-i18next";
import DashboardTabs from "../components/Dashboard/DashboardTabs";
import ChangeAlert from "../components/ChangeAlert/ChangeAlert";

export default function PowerBi() {
  const { userData, changed } = useContext(UserContext);
  const { t } = useTranslation('translation');

  return (
    <>
      <div style={{ display: 'flex', width: '100%', height: '100%', backgroundColor: '#2F4050' }}>
        <div style={{ height: '100svh', minHeight: '100svh', }}>
          <Sidebar />
        </div>
        <div style={{ width: '100%', backgroundColor: '#F3F3F4' }}>
          {changed && <ChangeAlert />}
          <Header />
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px', width: '100%', marginBottom: '50px' }}>
            <HeaderText>{`${t('notifications.welcome')}, ${userData.userName}`}</HeaderText>
            <DashboardTabs />
          </div>
          <Footer />
        </div>
      </div>
    </>
    // <MainPageContainer>
    //   <div className="side-bar">
    //     <Sidebar />
    //   </div>
    //   <PageContentContainer>
    //     {changed && <ChangeAlert />}
    //     <Header />
    //     <Container>
    //       <HeaderText>{`${t('notifications.welcome')}, ${userData.userName}`}</HeaderText>
    //       <DashboardTabs />
    //     </Container>
    //     <Footer />
    //   </PageContentContainer>
    // </MainPageContainer>
  );
}
