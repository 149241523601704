import axios, { AxiosError, AxiosResponse } from "axios";
import { AuthBodyType } from "./types";

export type ConjuntoToSend = {
  cliente: string,
  dataAlteracao: number,
  listaEquipe: string[] | [],
  local: string,
  nome: string,
  unidades: string[] | []
}

class Set {
  private api = axios.create({
    baseURL: `${process.env.REACT_APP_API_HOST}/conjunto`
  });

  newConjunto = async (body: AuthBodyType, conjunto: ConjuntoToSend) => {
    try {
      const data: AxiosResponse = await this.api.post('/newConjunto', {
        ...body,
        conjunto
      })
      return data.data
    } catch (err) {
      const error = err as Error | AxiosError;
      if(axios.isAxiosError(error)){
        console.error(error.message);
        console.log('erro --->', error)
        return error.response?.status;
      }
    }
  }

  changeUnityOrder = async (body: AuthBodyType, unidades: string[], conjunto: string) => {
    try {
      const data: AxiosResponse = await this.api.post('/changeUnityOrder', {
        ...body,
        unidades,
        conjunto
      })
      console.log(data);
      return data.data
    } catch (err) {
      const error = err as Error | AxiosError;
      if(axios.isAxiosError(error)){
        console.error(error.message);
        console.log('erro --->', error)
        return error.response?.status;
      }
    }
  }

  changeConjunto = async (body: AuthBodyType, conjunto: string, nome: string) => {
    try {
      const data: AxiosResponse = await this.api.post('/changeConjunto', {
        ...body,
        conjunto,
        nome
      })
      console.log(data);
      return data.data
    } catch (err) {
      const error = err as Error | AxiosError;
      if(axios.isAxiosError(error)){
        console.error(error.message);
        console.log('erro --->', error)
        return error.response?.status;
      }
    }
  }

  deleteConjunto = async (body: AuthBodyType, conjunto: string, local: string) => {
    try {
      const data: AxiosResponse = await this.api.post('/deleteConjunto', {
        ...body,
        conjunto,
        local
      })
      return data.data
    } catch (err) {
      const error = err as Error | AxiosError;
      if(axios.isAxiosError(error)){
        console.error(error.message);
        console.log('erro --->', error)
        return error.response?.status;
      }
    }
  }

  duplicateConjunto = async (body: AuthBodyType, conjunto: string) => {
    try {
      const data: AxiosResponse = await this.api.post('/duplicateConjunto', {
        ...body,
        conjunto,
      })
      return data.data
    } catch (err) {
      const error = err as Error | AxiosError;
      if(axios.isAxiosError(error)){
        console.error(error.message);
        console.log('erro --->', error)
        return error.response?.status;
      }
    }
  }

  addServiceType = async (body: AuthBodyType, tiposServico: string[], conjunto: string) => {
    try {
      const data: AxiosResponse = await this.api.post("/addTipoServico", {
        ...body,
        tiposServico,
        conjunto
      });
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        throw new Error(error.message);
      }
    }
  };

  associateAll = async (body: AuthBodyType) => {
    try {
      const data: AxiosResponse = await this.api.post("/associateAll", {
        ...body,
      });
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        throw new Error(error.message);
      }
    }
  };
}

export default Set;
