import React, { useContext } from 'react';
import { WebFormContext } from '../../../context/WebFormContext';
import { ChamadoType } from '../Types';

type props = {
  open: boolean,
  setOpen: Function,
  itemIndex?: number,
  subItemIndex?: number
}

export default function TicketEditModal({ open, setOpen, subItemIndex, itemIndex } : props) {
  const { webForm, setWebForm } = useContext(WebFormContext);

  const CallBackMap = (e: ChamadoType, i: number) => (
    <div key={i}>
      <button
        onClick={() => {
          setOpen(false);
          if (subItemIndex !== undefined && itemIndex !== undefined) {
            const item = webForm.itens[itemIndex];
            const subItem = item.subitens[subItemIndex];
            if (subItem.chamados) {
              subItem.chamados.push({
                selecionado: e
              })
              webForm.itens[itemIndex].subitens[subItemIndex] = subItem;
            } else {
              webForm.itens[itemIndex].subitens[subItemIndex].chamados = [{
                selecionado: e
              }]
            }
          } else {
            if (webForm.chamados) {
              webForm.chamados.push({
                selecionado: e
              })
            } else {
              webForm.chamados = [{
                selecionado: e
              }]
            }
          }
          setWebForm({...webForm})
        }}
        style={{
          background: 'none',
          fontSize: '1.3em',
          border: 'none',
          backgroundColor: '#F3F3F3',
          width: '100%',
          height: '40px',
          borderBottom: '1px solid darkGray'
        }}
      >
        {e.nome}
      </button>
    </div>
  )

  return (
    <>
    <div className={`modal ${open ? 'is-active' : ''}`}>
      <div className="modal-background" onClick={() => setOpen(false)}></div>
      <div style={{ backgroundColor: 'white', width: '75%', padding: '10px' }} className="modal-content">
        {(subItemIndex !== undefined && itemIndex !== undefined) ? (
            <>
              <h1 style={{ fontSize: '1.4em', margin: '5px' }}>Selecione o Chamado:</h1>
              {webForm.itens[itemIndex].subitens[subItemIndex].chamadosConfig?.tipoChamado.map((el, ind) => CallBackMap(el, ind))}
            </>
          ) : webForm.chamadosConfig ?
          <>
            <h1 style={{ fontSize: '1.4em', margin: '5px' }}>Selecione o Chamado:</h1>
            {webForm.chamadosConfig.tipoChamado.map(CallBackMap)}
          </> : (
            <div>
              Nenhum chamado encontrado!
            </div>
          )}
        </div>
    </div>
    </>
  )
}
