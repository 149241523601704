export interface listItems {
  name: string,
  id: string,
  key: string
}

export const allFuncionalities: Array<listItems> = [
  {
    name: 'sidebar.notifications',
    id:'1',
    key: 'notificacaoWeb'
  },
  {
    name:'settings',
    id:'2',
    key: 'configuracoes'
  },
  {
    name:'sidebar.users',
    id:'3',
    key: 'usuarios'
  },
  {
    name:'sidebar.management',
    id:'4',
    key: 'locais'
  },
  {
    name:'sidebar.forms',
    id:'5',
    key: 'formularios'
  },
  {
    name:'sidebar.customer',
    id:'6',
    key: 'clientes'
  },
  {
    name:'sidebar.team',
    id:'7',
    key: 'equipes'
  },
  {
    name:'sidebar.report',
    id:'8',
    key: 'relatorios'
  },
  {
    name:'sidebar.management',
    id:'9',
    key: 'gerencias'
  },
  {
    name:'sidebar.callsAndNotifications',
    id:'10',
    key: 'notificacoes'
  },
  {
    name:'sidebar.powerBi',
    id:'11',
    key: 'powerbi'
  },
  {
    name:'sidebar.preenchimento',
    id:'12',
    key: 'preenchimento'
  },
  {
    name: 'Pesquisas (Legado)',
    id:'13',
    key: 'pesquisas'
  },
  {
    name:'Plano de Ação',
    id:'21',
    key: 'plano-de-acao'
  },
  // {
  //   name:'sidebar.company',
  //   id:'0',
  //   key: 'empresas'
  // },
]