import MUIDataTable, { MUIDataTableMeta, MUIDataTableOptions } from "mui-datatables";
import { StyledDiv } from '../TableHelpers/TableStyle';
import { RiPencilFill } from 'react-icons/ri';
import { tableOptions } from '../TableHelpers/options';
import { useContext, useMemo, useState } from 'react';
import { UserContext } from '../../context/UserContext';
import { RiDeleteBin5Line } from 'react-icons/ri';
import { Button } from "../../StyledComponents/Input/generic";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import TicketsAndNotifications, { notificationType } from "../../service/Tickets_Notifications";
import { useMutation, useQuery } from "@tanstack/react-query";
import AttToken from "../../helpers/attToken";
import AddNotificationModal from "./Modal/AddNotificationModal";
import { AxiosError } from "axios";
import RemoveModal from "./Modal/RemoveModal";
import Teams from "../../service/Teams";
import { TicketsType } from "../../service/Tickets";
import Checklists from "../../service/Checklists";
import Table from "../Skeletons/Table";
import { ThemeProvider } from '@mui/material/styles';
import { getMuiTheme } from "../TableHelpers/options";
import queryClient from "../../service/query";

export default function Notifications() {
  const { openModal, popUp } = useContext(UserContext);

  const NotificationsService = useMemo(() => new TicketsAndNotifications(), []);
  const checklistService = useMemo(() => new Checklists(), []);
  const TeamsService = useMemo(() => new Teams(), []);

  const [isEditing, setIsEditing] = useState(false);
  const [notification, setNotification] = useState<notificationType>();
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [id, setId] = useState<number>(0);
  const [open, setOpen] = useState<{ [i: string]: boolean }>({});

  const handleTooltipClose = (i: number) => {
    setOpen({ ...open, [i]: false });
  };

  const handleTooltipOpen = (i: number) => {
    setOpen({ ...open, [i]: true });
  };

  const { t } = useTranslation('translation');

  const { data, isLoading, refetch: refetNotifications } = useQuery({
    queryKey: ['GET_NOTIFICATIONS'],
    queryFn: async () => {
      const token = await AttToken()
      if (token) {
        const response = await NotificationsService.getTypes(token, 'notificacao')
        return response;
      }
    },
    onError: () => {
      toast.error(`${t('notifications.errorNotifiCall')}`)
    },
    keepPreviousData: true,
    retry: 5,
    refetchOnWindowFocus: false,
    refetchOnMount: 'always',
  });

  const { data: teamsData, isLoading: isLoadingTeams } = useQuery({
    queryKey: ['TeamsList'],
    queryFn: async () => {
      const token = await AttToken();
      if (token) {
        const response = await TeamsService.getSimpleTeams(token);
        return response;
      }
    },
    keepPreviousData: true,
    retry: 5,
    refetchOnWindowFocus: false,
    refetchOnMount: 'always',
    onError: () => {
      toast.error('Erro ao buscar equipes!')
    }
  });

  const { data: formsData, isLoading: isLoadingForms } = useQuery({
    queryKey: ['simpleFormList'],
    queryFn: async () => {
      const token = await AttToken();
      if (token) {
        const response = await checklistService.getSimpleForm(token);
        return response;
      }
    },
    keepPreviousData: true,
    retry: 5,
    refetchOnWindowFocus: false,
    refetchOnMount: 'always',
    onError: () => {
      toast.error('Erro ao buscar formulários!')
    }
  });

  const { mutate } = useMutation({
    mutationKey: ['DELETE_NOTIFICATION'],
    mutationFn: async () => {
      const token = await AttToken()
      if (token) {
        await NotificationsService.deleteTypes(token, id)
        toast.warn(`${t('notifications.deleteNotifWait')}`)
        setConfirmationModal(false)
      }
    },
    onSuccess: () => {
      queryClient.resetQueries(['GET_NOTIFICATIONS'])
      toast.success(`${t('notifications.notifRemoved')}`)
    },
    onError: () => {
      toast.error(`${t('notifications.errorRemoveNotif')}`)
      console.log(AxiosError)
    }
  });

  const getTeamsNamesById = (teamNames: string[]) => {
    const elements = teamNames.map(name => (
      <p style={{ whiteSpace: 'break-spaces', wordBreak: 'break-word', wordWrap: 'break-word' }}>{name}</p>
    ))
    return elements;
  }

  const columns = [
    {
      name: "nome",
      label: t('name'),
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true
      }
    },
    {
      name: "mensagem",
      label: t('message'),
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true
      }
    },
    {
      name: "remetente",
      label: t('notificationsAndTickets.groups.sender'),
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true
      }
    },
    {
      name: "equipesList",
      label: t("team"),
      options: {
        filter: false,
        sort: false,
        sortThirdClickReset: false,
        customBodyRender: (value: string[], tableData: MUIDataTableMeta<TicketsType>) => {
          if(value?.length)
          if (Array.isArray(value)) {
            return open[tableData.rowIndex.toString()] ? (
              <div key={tableData.rowIndex} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'left' }}>
                {getTeamsNamesById(value)}
                <button
                  style={{ padding: 0, width: 'fit-content' }}
                  className="button is-small is-ghost"
                  onClick={() => {
                    handleTooltipClose(tableData.rowIndex)
                  }}>
                  {t('notifications.all.hide')}
                </button>
              </div>
            ) : (
              <button className='button is-small' style={{textAlign: 'left', width: 'fit-content', margin: 0}} onClick={() => {
                handleTooltipOpen(tableData.rowIndex);
              }}>
                {t('notifications.all.clickShow')}
              </button>
            );
          }
        },
      },
    },
    {
      name: "id",
      label: t('edit'),
      options: {
        customBodyRender: (value: number) => {
          return (
            <button onClick={() => {
              openModal();
              setIsEditing(true);
              setId(value)
              data && setNotification(data.filter((e: notificationType) => e.id === value)[0])
            }}
              className="button is-small"
            >
              <RiPencilFill />
              <span>{t('edit')}</span>
            </button>
          )
        },
        filter: true,
        sort: false,
      }
    },
    {
      name: "id",
      label: t('delete'),
      options: {
        customBodyRender: (value: number) => {
          return (
            <button onClick={() => {
              setId(value)
              setConfirmationModal(true)
            }} className="button is-small is-danger"
            >
              <RiDeleteBin5Line />
              <span>{t('delete')}</span>
            </button>
          )
        },
        filter: true,
        sort: false,
      }
    },
  ];

  const translatedTextLabels: MUIDataTableOptions = {
    textLabels: {
      body: {
        noMatch: `${t('table.noMatch')}`,
        toolTip: `${t('table.toolTip')}`,
        columnHeaderTooltip: column =>
          `${t('table.columnHeaderTooltip')} ${column.label}`
      },
      pagination: {
        next: `${t('table.next')}`,
        previous: `${t('table.previous')}`,
        rowsPerPage: `${t('table.rowsPerPage')}`,
        displayRows: `${t('table.displayRows')}`
      },
      toolbar: {
        search: `${t('table.search')}`,
        filterTable: `${t('table.filterTable')}`
      },
      filter: {
        title: `${t('table.title')}`,
        reset: `${t('table.reset')}`
      },
      viewColumns: {
        title: `${t('table.viewColumnsTitle')}`
      },
    },
  };

  return (
    <>
      <Button
        className="button is-small is-primary"
        style={{ fontWeight: "x-bold", fontSize: "20px" }}
        onClick={() => {
          openModal();
          setIsEditing(false);
        }}
        type="button"
        name='add'
      >
        {`${t('notificationsAndTickets.addNotification')}`}
      </Button>
      <StyledDiv>
        {confirmationModal &&
          <RemoveModal
            mutate={mutate}
            setConfirmationModal={setConfirmationModal}
            confirmationModal={confirmationModal}
            type={'notificacao'}
          />}
        {popUp &&
          <AddNotificationModal
            isLoadingTeams={isLoadingTeams}
            isLoadingForms={isLoadingForms}
            isEditing={isEditing}
            notification={notification as notificationType}
            id={id}
            teams={teamsData}
            forms={formsData}
            refetNotifications={refetNotifications}
          />
        }
        {isLoading ? <Table /> : (
          <ThemeProvider theme={getMuiTheme()}>
            <MUIDataTable
              title={t('notificationsAndTickets.notifications.title')}
              data={data}
              columns={columns}
              options={{ ...tableOptions, ...translatedTextLabels }}
            />
          </ThemeProvider>
        )}
      </StyledDiv>
    </>
  )
}