import React, { useState } from 'react'
import PlanoDeAcao, { planoDeAcaoType } from '../../../service/PlanoDeAcao';
import AttToken from '../../../helpers/attToken';
import { useQuery } from '@tanstack/react-query';
import ModalEditPlan from './ModalEditPlan';
import { Skeleton } from '@mui/material';
import AccordionDetailPlan from './AccordionDetailPlan';

type props = {
  planIds: string[],
  setPlanIds: Function,
}

const planoService = new PlanoDeAcao();

export default function ViewPlansById({
  planIds,
  setPlanIds,
}: props) {
  const [plano, setPlano] = useState<planoDeAcaoType | null>(null);

  


  const { data: planosDeAcao, isLoading: isLoadingPlanosDeAcao, isFetching, isRefetching } = useQuery({
    queryKey: ['GET_MALSUCEDIDOS'],
    queryFn: async () => {
      const token = await AttToken()
      if (token) {
        const result: planoDeAcaoType[] = await planoService.getPlansById(token, planIds);
        return result
      }
    },
    refetchOnMount: 'always',
    refetchOnWindowFocus: false
  });

  return (
    <>
      {plano && (
        <ModalEditPlan
          plano={plano}
          setPlano={setPlano}
          restarting={false}
        />
      )}
      {!plano && (
        <div className={`modal ${planIds ? 'is-active' : ''}`}>
          <div className="modal-background"></div>
          <div className="modal-card" style={{ width: '80%' }}>
            <header className="modal-card-head">
              <p className="modal-card-title">{`${planIds.length} Planos Abertos`}</p>
              <button
                onClick={() => {
                  setPlanIds(null)
                }}
                className="delete"
                aria-label="fechar"
                type='button'
              />
            </header>
            <section className="modal-card-body">
              {isLoadingPlanosDeAcao || isFetching || isRefetching ? (
                planIds.map(() => (
                  <Skeleton height={250} style={{ marginTop: '-3.5em' }} />
                ))
              ) : (
                !planosDeAcao || !planosDeAcao.length ? (
                  <span className='spanInspetor'>Planos não encontrados</span>
                ) : (
                  planosDeAcao?.map((plano) => (
                    <div key={plano.id}>
                      <AccordionDetailPlan
                        plano={plano}
                      />
                    </div>
                  ))
                ))}
            </section>
          </div>
        </div>
      )}
    </>
  )
}
