import { Input } from "./Unique/UniqueStyle";
import { SubItemProps } from "./Avaliacao/Avaliacao";
import { useEffect, useState } from "react";

const SubItemComentario = ({ subItem, onChange }: SubItemProps) => {
  const [comentario, setComentario] = useState<string | undefined>(subItem.obs || undefined)

  const borderCheckerComent = () => {
    if (subItem.comentario) {
      const comentario = (!subItem?.obs || !subItem?.obs.length);
      if (subItem.obrigatoriedade?.ocorrencia && comentario) return '0.3em red solid';
      if (!subItem.obrigatoriedade?.ocorrencia && comentario) return '0.3em #2196f3 solid';
      // if (!subItem?.obrigatoriedade?.ocorrencia && comentario) return '0.3em #2196f3 solid';
      return '0.3em #4caf50 solid'
    }
  }


  useEffect(() => {
    subItem.obs = comentario
    onChange && onChange()
  }, [comentario])

  return (
    <Input
      style={{ borderBottom: `${borderCheckerComent()}`, marginTop: '0.5em' }}
      value={comentario}
      onChange={(e) => { setComentario(e.target.value) }}
      className="input"
      placeholder="Toque para digitar" />
  )
}

export default SubItemComentario;