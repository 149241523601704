import { useContext, useEffect, useState } from "react";
import { Button, Input } from "../../StyledComponents/Input/generic";
import { useNavigate } from "react-router";
import Logo from "../../assets/images/logo-login.png";
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";
import { AuthContext } from "../../context/AuthContext";
import Auth from "../../service/Auth";
import Loading from "../Loading/Loading";
import { toast } from 'react-toastify';
import { UserContext } from "../../context/UserContext";
import { Container, Box, SmallText, UnderLogoText, BtnResetPass } from "./FormLoginStyle"
import { ModalCard, ModalCardbody, GenericHeader, GenericText } from "../../StyledComponents/Modal/generic";
import Translation from "../Translation/Translation";
import { useTranslation } from "react-i18next";
import Login from "../../service/Login";
import Companies from "../../service/Companies";

const loginService = new Login();
const companiesService = new Companies();

console.log(process.env.NODE_ENV)
console.log(process.env.REACT_APP_PROJECT_NAME)

const url = process.env.NODE_ENV === 'production' ?
  'http://admin.axyma.com.br/resetPassword' :
  'http://a0a4316a9a7304018b11eb236a19d5a9-606525459.us-west-2.elb.amazonaws.com/resetPassword';


function FormLogin() {
  const { saveLoginToken, setTicking, ticking } = useContext(AuthContext)
  const { saveUserData, openModal, popUp, setHierarchyLabel, setFirebaseToken } = useContext(UserContext)
  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");
  const [click, setClick] = useState('');
  const [isLoading, setIsLoading] = useState(false)
  const [inputType, setInputType] = useState("password");
  const [resetValue, setResetValue] = useState('')
  const [modalOn, setModalOn] = useState(false);
  const [loadingReset, setLoadingReset] = useState<boolean>(false);
  const navigate = useNavigate();
  const { t } = useTranslation('translation');

  const onStorageUpdate = (e: any) => {
    const { key, newValue } = e;
    if (key === "user") {
      setUser(newValue);
    } if (key === 'password') {
      setPassword(newValue);
    } if (key === 'click') {
      setClick(newValue)
    }
  };


  const handleSubmit = async () => {
    setIsLoading(true);
    const loginData = await saveLoginToken({ email: user, senha: password });
    if (loginData) {
      setFirebaseToken(loginData.token)
      const auth = new Auth(loginData.uid, loginData.userName, loginData.empresa);
      const token = await auth.getToken();
      const hierarchyLabels = await companiesService.getHierarchyLabels({
        userToken: token,
        username: loginData.userName,
        userEmpresa: loginData.empresa,
        empresa: loginData.empresa
      });
      setHierarchyLabel(hierarchyLabels);
      const userData = await auth.confirmLoginAndGetData();
      if (userData) {
        saveUserData(userData);
        localStorage.setItem('userDataLocal', JSON.stringify(userData));
        window.localStorage.setItem('click', 'click');
        localStorage.removeItem("user")
        localStorage.removeItem("password")
        localStorage.removeItem("click")
        setTicking(true);
        navigate("/home");
      }
    } 
    else {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setUser(localStorage.getItem("user") || "");
    setPassword(localStorage.getItem("password") || "");
    setClick(localStorage.getItem("click") || "");
    window.addEventListener("storage", onStorageUpdate);
    window.addEventListener('storage', function (event) {
      if (event.key === 'click' && event.newValue !== event.oldValue) {
          window.location.reload();
      }
  });
    return () => {
      window.removeEventListener("storage", onStorageUpdate);
    };
  }, []);

  const now = new Date()

  useEffect(() => {
    ticking && setTicking(false);
  }, [ticking, setTicking]);

  useEffect(() => {
      const userLocalData = localStorage.getItem('userDataLocal')
      if (userLocalData) {
        setModalOn(true)
      }
  }, [setModalOn])

  const toggleEye = () => inputType === "password" ? setInputType("text") : setInputType("password")

  return (
    <Container className="container is-fluid">
      <Box className="box">
        <Translation header={false} />
        <div className="img">
          <img alt="Axyma" src={Logo} />
        </div>
        <UnderLogoText>{t('loginScreen.welcomelogin')}</UnderLogoText>
        <SmallText>{t('loginScreen.login')}</SmallText>
        {isLoading ? (
          <Loading />
        ) : (
          <form
            id='form'
            style={{
              minWidth: '100%',
              display: 'flex',
              flexDirection: 'column',
              maxWidth: '100%'
            }}
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
            autoComplete="off">
            <input
              required
              className="input is-small is-fullwidth"
              type="text"
              placeholder={t('loginScreen.username')}
              value={user}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setUser(e.target.value);
                localStorage.setItem("user", e.target.value);
              }}
            />
            <div style={{ display: 'flex', maxWidth: '100%', marginTop: '10px' }}>
              <input
                required
                className="input is-small is-fullwidth"
                type={inputType}
                placeholder={t('loginScreen.password')}
                value={password}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setPassword(e.target.value)
                  localStorage.setItem("password", e.target.value);
                }}
              />
              <div>
                <button
                  style={{ minWidth: 'fit-content', display: 'flex', maxWidth: 'fit-content', justifyContent: 'flex-start', margin: 0, padding: '10px', border: 'none' }}
                  type='button'
                  onClick={toggleEye}
                  className="button is-small"
                >
                  {inputType === "password" ? (
                    <AiOutlineEye />
                  ) : (
                    <AiOutlineEyeInvisible />
                  )
                  }
                </button>
              </div>
            </div>
            <button
              id='form'
              style={{ marginTop: "10px" }}
              type='submit'
              className='button is-small is-fullwidth'
            >
              {t('loginScreen.loginbtn')}
            </button>
          </form>
        )}
        <BtnResetPass
          className="button"
          onClick={() => openModal()}
        >
          {t('loginScreen.forgot')}
        </BtnResetPass>
        <div className={`modal ${popUp ? "modal is-active" : "modal"} `}>
          <div className="modal-background" />
          <ModalCard className="modal-card">
            <header className="modal-card-head" style={{ display: "flex", flexDirection: "column" }}>
              <GenericHeader>
                {t('loginScreen.forgotModal')}
              </GenericHeader>
              <GenericText>
                {t('loginScreen.textModal')}
              </GenericText>
            </header>
            <ModalCardbody className="modal-card-body">
              <form onSubmit={async (e) => {
                e.preventDefault();
                setLoadingReset(true);
                toast.warning('Enviando e-mail com reset de senha.')
                await loginService.sendEmailReset({ login: resetValue, lastPasswordRequired: false, url: url as string });
                toast.success('Email enviado com sucesso!');
                setLoadingReset(false)
                openModal();
              }}>
                <Input
                  required
                  className="input is-small"
                  type="text"
                  placeholder={t('loginScreen.inputModal')}
                  value={resetValue}
                  onChange={(e) => setResetValue(e.target.value)}
                />
                <div style={{ display: "flex", justifyContent: "space-around", marginTop: "15px", flexWrap: "wrap" }}>
                  <Button
                  disabled={loadingReset}
                    className="button is-success is-small is-fullwidth"
                  >
                    {t('loginScreen.sendModal')}
                  </Button>
                  <Button
                    onClick={
                      async () => {
                        openModal();
                      }}
                    className="button is-small is-fullwidth"
                    type="button"
                  >
                    {t('return')}
                  </Button>
                </div>
              </form>
            </ModalCardbody>
          </ModalCard>
        </div>
        <SmallText>Axyma @ {now.getFullYear()}</SmallText>
      </Box>
    </Container >
  )
}
export default FormLogin;