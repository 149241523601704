import { useContext, useState } from "react";
import { TabStyle } from "../TabsStyle/TabsStyle";
import ListaAnalises from "./ListaAnalises";
import PlanosDeAcaoComp from "./PlanosDeAcaoComp";
import Historico from "./Historico";
import { UserContext } from "../../context/UserContext";
import PlanoNaoContratado from "./PlanoNaoContratado";

export default function PlanoDeAcaoTabs() {
  const { acessoPlanoDeAcao } = useContext(UserContext);

  const mapNames = {
    analises: 'Analisar Execuções de Formulários',
    planos: 'Planos de Ação',
    historico: 'Inspeção de Qualidade'
  }

  const [tab, setTab] = useState({
    isActiveObject: 'analises',
    object: ['analises', 'planos', 'historico']
  });

  const toggleClass = (index: number) => {
    setTab({ ...tab, isActiveObject: tab.object[index] })
  }

  const toggleActive = (index: number) => {
    if (tab.object[index] === tab.isActiveObject) {
      return "is-active"
    } else {
      return "inactve"
    }
  }

  if (!acessoPlanoDeAcao) {
    return <PlanoNaoContratado />
  }

  return (
    <div style={{ width: '100%' }}>
      <div className="tabs is-boxed">
        <ul>
          {tab.object.map((el, index) => (
            <li key={index} className={toggleActive(index)} onClick={() => { toggleClass(index) }}>
              <TabStyle theme={toggleActive(index)}>{mapNames[el as keyof typeof mapNames]}</TabStyle>
            </li>
          ))}
        </ul>
      </div>
      {tab.isActiveObject === 'analises' && (
        <ListaAnalises />
      )}
      {tab.isActiveObject === 'planos' && (
        <PlanosDeAcaoComp />
      )}
      {tab.isActiveObject === 'historico' && (
        <Historico />
      )}
    </div>
  )
}