import axios, { AxiosError } from "axios";
import ILoginInfo from "../interface/LoginInterfaces";
import { toast } from "react-toastify";

type messageType = {
  message: string;
};
class Login {
  private api = axios.create({
    baseURL: process.env.REACT_APP_API_HOST,
  });

  getLoginInfo = async (endpoint: string, body: { email: string; senha: string }): Promise<ILoginInfo | undefined> => {
    try {
      const data = await this.api.post("/login", { ...body });
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.response);
        const dataError = error.response?.data as unknown as any
        const erro = dataError.message;
        toast.error(String(erro));
      }
    }
  };

  sendEmailReset = async (body: { login: string; lastPasswordRequired: boolean; url: string }): Promise<ILoginInfo | undefined> => {
    try {
      const data = await this.api.post("/login/sendEmailReset", { ...body });
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
      }
    }
  };

  resetPassword = async (body: { uid: string; password: string; lastPassword?: string }): Promise<ILoginInfo | undefined> => {
    try {
      const data = await this.api.post("/login/resetPassword", { ...body });
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
      }
    }
  };

  logout = async (uid: string) => {
    try {
      await this.api.post("login/logout", { uid });
      return null;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
      }
    }
  };
}

export default Login;
