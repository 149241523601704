import { GenericHeader, TagContainer, TagSpan } from "../../StyledComponents/Modal/generic"
import { BoldText, GenericText } from "./style/RegisteredTeamsStyle"
import { useTranslation } from "react-i18next";
import { Label, Input } from "../../StyledComponents/Input/generic";
import { AiOutlineClose } from "react-icons/ai";
import Teams, { NotificaSobre, Team, TreatmentTypes } from "../../service/Teams";
import { useContext, useMemo, useState } from "react";
import { UserContext } from "../../context/UserContext";
import AttToken from "../../helpers/attToken";
import { toast } from 'react-toastify';
import queryClient from "../../service/query";
import TypePlacesTeamModal from "./TypePlacesTeamModal";
import { useMutation } from "@tanstack/react-query";
import TypePlaceTreatment from "./TypePlaceTreatment";
import TypePlaceNotification from "./TypePlaceNotification";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

export default function ChangeTeamModal({
  team,
  teamId,
  setIsTab,
  isLoadingById,
  getInfo,
}:
  {
    team: Team,
    teamId: string,
    setIsTab: Function,
    isLoadingById: boolean,
    getInfo:Function,
  }) {
  const { openModal, popUp, serviceTypesList, userList, escape, setChanged } = useContext(UserContext);
  const [teamName, setTeamName] = useState(team?.nome);
  const [selectedServices, setSelectedServices] = useState<string[]>(team?.tiposServico || []);
  const [selectedUsers, setSelectedUsers] = useState<string[]>(Object.keys(team?.users || []));
  const [status, setStatus] = useState(team?.status);
  const [tiposAtendimento, setTiposAtendimento] = useState(team?.tiposAtendimento || []);
  const [selectedTipoAtendimento, setSelectedTipoAtendimento] = useState('');
  const [notificadoSobre, setNotifcadoSobre] = useState(team?.notificaSobre || []);
  const [selectedTipoNotifica, setSelectedTipoNotifica] = useState('');
  const [showingAssociationNotifica, setShowingAssociationNotifica] = useState(false);
  const [showingAssociationAtendimento, setShowingAssociationAtendimento] = useState(false);
  const [showingAssociation, setShowingAssociation] = useState(false);
  const [tipoSelecionado, setTipoSelecionado] = useState('');
  const [disabled, setDisabled] = useState(false);

  const TeamsService = useMemo(() => new Teams(), []);

  const { t } = useTranslation('translation');

  const { mutate } = useMutation({
    mutationKey: ['changeTeam'],
    mutationFn: async () => {
      setDisabled(true)
      toast.warn('Alterando equipe, aguarde até o modal se fechar')
      const token = await AttToken()
      if (token) {
        const equipe = {
          nome: teamName,
          tiposServico: selectedServices,
          status: status,
          users: selectedUsers,
          tiposLocaisAssocia: team.tiposLocaisAssocia,
          tiposAtendimento: team.tiposAtendimento,
          notificaSobre: team.notificaSobre
        }
        await TeamsService.updateTeam(token, teamId, equipe);
      }
    },
    onSuccess: () => {
      openModal();
      queryClient.refetchQueries(['TeamList'])
      getInfo(teamId)
      toast.success('Equipe alterada!')
      setDisabled(false)
    },
    onError: () => {
      toast.error('Erro ao alterar equipe')
    }
  })

  const findServiceName = (value: string) => {
    const serviceName = serviceTypesList.find((el) => el.tipoId === value);
    if (serviceName) return serviceName.nome
    else return 'Serviço não encontrado';
  }

  const findUserName = (value: string) => {
    const userName = userList && userList.find((el) => {
      return el.id === value
    });
    if (userName) return userName.nome
    else return 'Nome não encontrado';
  }

  return (
    <div className={`modal ${popUp ? "modal is-active" : "modal"}`}>
      <div className="modal-background" onKeyDown={() => escape()} onClick={() => openModal()} />
      <div className="modal-card" onSubmit={(e: React.FormEvent) => e.preventDefault()}>
        <header className="modal-card-head" style={{ display: "flex", flexDirection: "column" }}>
          <GenericHeader>
            {t('teamManage.registered.edit')}
          </GenericHeader>
          <GenericText>
            {t('teamManage.registered.beConf')}
          </GenericText>
        </header>
        <div className="modal-card-body">
          <div
            style={{
              display: "flex",
              flexDirection: "column"
            }}
            onSubmit={(e) => e.preventDefault()}
          >
            {showingAssociation &&
              <TypePlacesTeamModal
                tipoServico={tipoSelecionado}
                tipoLocalAssocia={team.tiposLocaisAssocia || null}
                setShowingAssociation={setShowingAssociation}
                nomeServico={findServiceName(tipoSelecionado)}
                equipe={team}
              />}
            {showingAssociationAtendimento &&
              <TypePlaceTreatment
                tipoServico={selectedTipoAtendimento}
                tiposAtendimento={team.tiposAtendimento || null}
                setShowingAssociation={setShowingAssociationAtendimento}
                nomeServico={findServiceName(selectedTipoAtendimento)}
                equipe={team}
              />}
            {showingAssociationNotifica &&
              <TypePlaceNotification
                tipoServico={selectedTipoNotifica}
                notificaSobre={team.notificaSobre || null}
                setShowingAssociation={setShowingAssociationNotifica}
                nomeServico={findServiceName(selectedTipoNotifica)}
                equipe={team}
              />}
            {!showingAssociation && !showingAssociationAtendimento && !showingAssociationNotifica && <>
              <BoldText>{t('team')}</BoldText>
              <Input
                className="input is-small"
                value={teamName}
                maxLength={80}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setTeamName(e.target.value)}
              />
              <Label>{t('Services.servicesManagement.kind')}</Label>
              <TagContainer>
                {team?.tiposLocaisAssocia && team?.tiposLocaisAssocia.length > 0 && team?.tiposLocaisAssocia?.map((e, i: number) => {
                  return selectedServices.includes(e.tipo) ? (
                    <div key={i} className='columns' style={{ margin: '.2em ', display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                      <div className='column' style={{ margin: 0, padding: 0 }}>
                        <TagSpan
                          style={{ display: 'flex', justifyContent: 'space-between' }}
                          key={`INDEX${i}`} onClick={() => {
                            setSelectedServices(selectedServices.filter(el => el !== e.tipo))
                            if (team.tiposLocaisAssocia) team.tiposLocaisAssocia = team.tiposLocaisAssocia.filter(el => el.tipo !== e.tipo)
                          }}>
                          {findServiceName(e.tipo)}
                          <AiOutlineClose />
                        </TagSpan>
                      </div>
                      <div className='column' style={{ display: 'flex', margin: 0, padding: 0 }}>
                        <button
                          className="button is-success is-small"
                          onClick={() => {
                            setTipoSelecionado(e.tipo)
                            setShowingAssociation(true);
                          }}
                        >
                          {t('Services.servicesManagement.locaisTrabalho')}&nbsp;<ArrowForwardIcon />
                        </button>
                      </div>
                    </div>
                  ) : <></>
                })}
              </TagContainer>
              <select
                disabled={serviceTypesList.length < 1}
                defaultValue=''
                className="select is-small"
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                  if (team.tiposLocaisAssocia) team.tiposLocaisAssocia.push({ tipo: e.target.value, conjuntosAssociados: null, cnjs: [] });
                  else team.tiposLocaisAssocia = [{ tipo: e.target.value, conjuntosAssociados: null, cnjs: [] }];
                  setSelectedServices([...selectedServices, e.target.value])
                }}
              >
                <option value='' >{t('select')}</option>
                {
                  serviceTypesList && serviceTypesList.sort((a, b) => { return a.nome.toLowerCase() > b.nome.toLowerCase() ? 1 : - 1 }).map((e, i) => (
                    <option
                      key={i + 1}
                      disabled={
                        selectedServices &&
                        selectedServices.includes(e.tipoId) &&
                        !!team.tiposLocaisAssocia &&
                        team.tiposLocaisAssocia.some(el => el.tipo === e.tipoId)
                      }
                      value={e.tipoId}
                    >
                      {e.nome}
                    </option>
                  ))
                }
              </select>
              {/* {t('Services.servicesManagement.kind')} */}
              <Label style={{ marginTop: '10px' }}>{t('Services.servicesManagement.AtenderDe')}</Label>
              <TagContainer>
                {team?.tiposAtendimento && team?.tiposAtendimento?.length > 0 && team?.tiposAtendimento?.map((e, i: number) => (
                  <div key={i} className='columns' style={{ margin: '.2em ', display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                    <div className='column' style={{ margin: 0, padding: 0 }}>
                      <TagSpan
                        style={{ display: 'flex', justifyContent: 'space-between' }}
                        key={`INDEX${i}`} onClick={() => {
                          setTiposAtendimento(prev => prev ? prev.filter(el => el.tipo !== e.tipo) : []);
                          if (team.tiposAtendimento) team.tiposAtendimento = team.tiposAtendimento.filter(el => el.tipo !== e.tipo)
                        }}>
                        {findServiceName(e.tipo)}
                        <AiOutlineClose />
                      </TagSpan>
                    </div>
                    <div className='column' style={{ display: 'flex', margin: 0, padding: 0 }}>
                      <button
                        className="button is-success is-small"
                        onClick={() => {
                          setSelectedTipoAtendimento(e.tipo)
                          setShowingAssociationAtendimento(true);
                        }}
                      >
                        {t('Services.servicesManagement.locaisAtendimento')}&nbsp;<ArrowForwardIcon />
                      </button>
                    </div>
                  </div>
                ))}
              </TagContainer>
              <select
                disabled={serviceTypesList.length < 1}
                defaultValue=''
                className="select is-small"
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                  const objToPush: TreatmentTypes = { tipo: e.target.value, prioridade: ['baixa'], cnjs: [] }
                  if (team.tiposAtendimento) team.tiposAtendimento.push(objToPush);
                  else team.tiposAtendimento = [objToPush];
                  setTiposAtendimento(prev => prev ? [...prev, objToPush] : [objToPush])
                }}
              >
                <option value='' >{t('select')}</option>
                {
                  serviceTypesList && serviceTypesList.sort((a, b) => { return a.nome.toLowerCase() > b.nome.toLowerCase() ? 1 : - 1 }).map((e, i) => (
                    <option
                      key={Math.random()}
                      disabled={
                        tiposAtendimento &&
                        tiposAtendimento.some(el => el.tipo === e.tipoId) &&
                        team.tiposAtendimento &&
                        team.tiposAtendimento.some(el => el.tipo === e.tipoId)
                      }
                      value={e.tipoId}
                    >
                      {e.nome}
                    </option>
                  ))
                }
              </select>
              <Label style={{ marginTop: '10px' }}>{t('Services.servicesManagement.serNotificado')}</Label>
              <TagContainer>
                {team?.notificaSobre && team?.notificaSobre?.length > 0 && team?.notificaSobre?.map((e, i: number) => (
                  <div key={i} className='columns' style={{ margin: '.2em ', display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                    <div className='column' style={{ margin: 0, padding: 0 }}>
                      <TagSpan
                        style={{ display: 'flex', justifyContent: 'space-between' }}
                        key={`INDEX${i}`} onClick={() => {
                          setNotifcadoSobre(prev => prev ? prev.filter(el => el.tipo !== e.tipo) : []);
                          if (team.notificaSobre) team.notificaSobre = team.notificaSobre.filter(el => el.tipo !== e.tipo)
                        }}>
                        {findServiceName(e.tipo)}
                        <AiOutlineClose />
                      </TagSpan>
                    </div>
                    <div className='column' style={{ display: 'flex', margin: 0, padding: 0 }}>
                      <button
                        className="button is-success is-small"
                        onClick={() => {
                          setSelectedTipoNotifica(e.tipo)
                          setShowingAssociationNotifica(true);
                        }}
                      >
                        {t('teamManage.register.locaisNotificados')}&nbsp;<ArrowForwardIcon />
                      </button>
                    </div>
                  </div>
                ))}
              </TagContainer>
              <select
                disabled={serviceTypesList.length < 1}
                defaultValue=''
                className="select is-small"
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                  const objToPush: NotificaSobre = { tipo: e.target.value, cnjs: [] }
                  if (team.notificaSobre) team.notificaSobre.push(objToPush);
                  else team.notificaSobre = [objToPush];
                  setNotifcadoSobre(prev => prev ? [...prev, objToPush] : [objToPush])
                }}
              >
                <option value='' >{t('select')}</option>
                {
                  serviceTypesList && serviceTypesList.sort((a, b) => { return a.nome.toLowerCase() > b.nome.toLowerCase() ? 1 : - 1 }).map((e, i) => (
                    <option
                      key={Math.random()}
                      disabled={
                        notificadoSobre &&
                        notificadoSobre.some(el => el.tipo === e.tipoId) &&
                        team.notificaSobre &&
                        team.notificaSobre.some(el => el.tipo === e.tipoId)
                      }
                      value={e.tipoId}
                    >
                      {e.nome}
                    </option>
                  ))
                }
              </select>
              <Label style={{ marginTop: '10px' }}>{t('Services.servicesManagement.members')}</Label>
              <TagContainer>
                {selectedUsers.length > 0 && selectedUsers.map((e: string, i: number) => (
                  <TagSpan key={i} onClick={() => {
                    setSelectedUsers(selectedUsers.filter(el => el !== e))
                  }}>
                    {findUserName(e)}
                    <AiOutlineClose />
                  </TagSpan>
                ))}
              </TagContainer>
              <select
                disabled={userList && userList?.length < 1}
                defaultValue=''
                className="select is-small"
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                  setSelectedUsers([...selectedUsers, e.target.value])
                }}
              >
                <option value=''>{t('select')}</option>
                {
                  userList && userList
                    .sort((a, b) => { return a.nome.toLowerCase() > b.nome.toLowerCase() ? 1 : - 1 })
                    .filter((e) => e.status === 'ativo')
                    .map((e, i) => (
                      <option
                        key={i}
                        disabled={selectedUsers && selectedUsers.includes(e.id)}
                        value={e.id}>
                        {e.nome}
                      </option>
                    ))
                }
              </select>
              <Label>{t('status')}</Label>
              <select
                className='select is-small'
                onChange={(e) => setStatus(e.target.value === 'ativo' ? true : false)}
                defaultValue={status ? 'ativo' : 'inativo'}
              >
                <option value={'ativo'}>{t('active')}</option>
                <option value={'inativo'}>{t('inactive')}</option>
              </select>
            </>}
          </div>
        </div>
        <div className='modal-card-foot' style={{ display: "flex", justifyContent: "space-between" }}>
          <button
            disabled={disabled}
            className="button is-success is-small"
            onClick={() => {
              localStorage.setItem('changedHirarquia', 'true');
              setChanged(true);
              mutate();
            }}
          >
            {t('save')}
          </button>
          <button
            type="submit"
            onClick={() => openModal()}
            className="button is-small is-warning"
          >
            {t('cancel')}
          </button>
        </div>
      </div>
    </div>
  )
}