import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { AiOutlineClose } from 'react-icons/ai';
import { Skeleton } from '@mui/material';
import { UserContext } from '../../../../context/UserContext';
import checklistStyle from '../../../../service/checklistStyle';
import Checklists from '../../../../service/Checklists';
import AttToken from '../../../../helpers/attToken';
import queryClient from '../../../../service/query';
import { Label } from '../../../Notifications/style/SingleNotificationStyle';
import { TagContainer, TagSpan } from '../../../../StyledComponents/Modal/generic';
import Header from './Header';
import Body from './Body';
import { modeloStyleType } from "../../../../service/checklistStyle";

export type checklistType = {
  id: string,
  nome: string
}

type props = {
  editing: boolean,
  setEditing: Function,
  id: string,
  setId: Function,
  setObjectToEdit: Function,
  objectToEdit: modeloStyleType,
  objectToSend: modeloStyleType,
  setObjectToSend: Function,
  image: string,
  setImage: Function,
  initialObjectToSend: modeloStyleType,
}

export default function StyleFillModal(
  {
    editing,
    setEditing,
    setId,
    objectToEdit,
    objectToSend,
    setObjectToSend,
    image,
    setImage,
    initialObjectToSend
  }: props) {
  const { openModal } = useContext(UserContext);
  const [selected, setSelected] = useState('header')
  const { t } = useTranslation('translation');

  const modelService = new checklistStyle()
  const checkListService = new Checklists()

  useEffect(() => {
    if (editing && objectToEdit) {
      setObjectToSend(objectToEdit)
    }
  }, [editing, objectToEdit, setObjectToSend])

  const { data, isLoading: loadingSimples } = useQuery({
    queryKey: ['GET_SIMPLES'],
    queryFn: async () => {
      const token = await AttToken()
      if (token) {
        const response = await checkListService.getSimples(token)
        return response.cls
      }
    },
    keepPreviousData: true,
    retry: 5,
    refetchOnWindowFocus: false,
    refetchOnMount: 'always',
  })

  const { mutate: create, isLoading } = useMutation({
    mutationKey: ['CREATE_MODEL'],
    mutationFn: async () => {
      toast.warn('Criando novo modelo')
      const token = await AttToken()
      if (token && objectToSend) {
        const response = await modelService.create(token, objectToSend)
        return response
      }
    },
    onSuccess: () => {
      queryClient.resetQueries(['GET_MODELS'])
      toast.success('Modelo criado')
      setObjectToSend(initialObjectToSend)
      setSelected('header')
      setImage('')
      openModal();
    }
  })

  const { mutate: edit, isLoading: isLoadingEdit } = useMutation({
    mutationKey: ['EDIT_MODEL'],
    mutationFn: async () => {
      toast.warn('Editando modelo')
      const token = await AttToken()
      if (token && objectToSend) {
        const response = await modelService.edit(token, objectToSend)
        return response
      }
    },
    onSuccess: () => {
      queryClient.resetQueries(['GET_MODELS'])
      toast.success('Modelo editado')
      setObjectToSend(initialObjectToSend)
      setSelected('header')
      openModal();
      setId('')
      setEditing(false)
      setImage('')
    }
  })

  const fonts = [
    'Arial',
    'Arial Black',
    'Comic Sans MS',
    'Courier New',
    'Georgia',
    'Impact',
    'Lucida Console',
    'Lucida Sans Unicode',
    'open sans',
    'Palatino Linotype',
    'Tahoma',
    'Times New Roman',
    'Trebuchet MS',
    'Verdana'
  ]

  const findChecklistName = (value: string) => {
    const checklistName = data?.find((el: checklistType) => el.id === value);
    if (checklistName) return checklistName?.nome
    else return 'teste';
  }

  return (
    <>
      <div style={{padding:30}}>
        <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '60px' }}>
          <Label>Nome:</Label>
          <input
            value={objectToSend.name}
            onChange={(elem) => setObjectToSend({ ...objectToSend, name: elem.target.value })}
            className='input is-fullwidth is-small' />
          <Label>{t('teamManage.modal.form')}</Label>
          <TagContainer>
            {objectToSend && objectToSend.associatedChecklists?.map((e: string, i) => (
              <TagSpan key={i} onClick={() => {
                setObjectToSend({ ...objectToSend, associatedChecklists: objectToSend?.associatedChecklists?.filter(el => el !== e) })
              }}>
                {findChecklistName(e)}
                <AiOutlineClose />
              </TagSpan>
            ))}
          </TagContainer>
          {loadingSimples ? <Skeleton height={60} style={{ marginTop: '-1em' }} /> : <select
            disabled={!data}
            value=''
            className="select is-small"
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              if (objectToSend.associatedChecklists) {
                setObjectToSend({ ...objectToSend, associatedChecklists: [...objectToSend.associatedChecklists, e.target.value] })
              } else setObjectToSend({ ...objectToSend, associatedChecklists: [e.target.value] })
            }}
          >
            <option value='' >{t('select')}</option>
            {
              data
                ?.sort((a: checklistType, b: checklistType) => { return a.nome.toLowerCase() > b.nome.toLowerCase() ? 1 : - 1 })
                ?.map((e: checklistType, i: number) => (
                  <option
                    key={i}
                    disabled={objectToSend && objectToSend.associatedChecklists?.includes(e.id)}
                    value={e.id}>
                    {e.nome}
                  </option>
                ))
            }
          </select>}
          <div className="select is-small is-fullwidth" style={{ marginTop: '10px' }}>
            <select
              value={selected}
              onChange={(e) => setSelected(e.target.value)}
            >
              <option value={'header'}>
                Cabeçalho
              </option>
              <option value={'background'}>
                Fundo
              </option>
            </select>
          </div>
          {selected === 'header' ?
            <Header objectToSend={objectToSend} setObjectToSend={setObjectToSend} fonts={fonts} />
            :
            <Body objectToSend={objectToSend} setObjectToSend={setObjectToSend} fonts={fonts} />
          }
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifySelf: 'center', alignSelf: 'center', backgroundColor: objectToSend.colorHeader!!! }}>
              <header style={{ height: 'fit-content', width: '100%', paddingLeft: '10px' }}>
                <p style={{ color: objectToSend.fontHeader?.color!!!, fontFamily: objectToSend.fontHeader?.fontFamily!!!, fontSize: objectToSend.fontHeader?.fontSize!!! }}>
                  {objectToSend.name}
                </p>
              </header>
            </div>
            <div style={{ width: '100%', paddingLeft: '20px', background: objectToSend.background!!! }}>
              <p style={{ color: objectToSend?.fontBackground?.color!!!, fontFamily: objectToSend?.fontBackground?.fontFamily!!!, fontSize: objectToSend.fontBackground?.fontSize!!! }}>1 - Nome da Pergunta<br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Resposta da pergunta</p>
            </div>
          </div>
        </div>
      </div>
        <footer className="modal-card-foot" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', margin:0 }}>
          <button
            disabled={(isLoading || isLoadingEdit) || !objectToSend.associatedChecklists?.length}
            className="button is-success is-small"
            onClick={!editing ? () => create() : () => edit()}>
            Salvar
          </button>
          <button className="button is-small"
            disabled={(isLoading || isLoadingEdit) || !objectToSend.associatedChecklists?.length}
            onClick={() => {
              setObjectToSend(initialObjectToSend)
              setSelected('header')
              setImage('')
              setEditing(false)
              setId('')
              openModal();
            }}>Cancelar
          </button>
        </footer>
    </>
  )
}
