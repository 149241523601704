import { Accordion, AccordionSummary } from '@material-ui/core'
import { useContext, useRef, useState } from 'react'
import { Label } from './style/SingleNotificationStyle'
import { UserContext } from '../../context/UserContext'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useTranslation } from 'react-i18next';
import './style.css';
import ReactDatePicker from 'react-datepicker';
import { actions, filtrosType, statusType } from './MyTickets';
import { useQuery } from '@tanstack/react-query';
import Tickets from '../../service/Tickets';
import AttToken from '../../helpers/attToken';
import { MdCancel } from "react-icons/md";
import { VscCalendar } from "react-icons/vsc";
import { Skeleton } from '@mui/material';
import { IoFilter } from "react-icons/io5";
import { RxReset } from "react-icons/rx";

type props = {
  changeEstado: (action: actions, value: string[] | number[] | Date | boolean | number | string | null) => void
  emitidos: boolean
  setEmitidos: Function,
  isFetching: boolean,
  isRefetching: boolean,
  loadingData: boolean,
  filter: filtrosType
}

type dataReturn = {
  id: string,
  nome: string
}

const chamdosService = new Tickets();

export type orderType = 'inicio'
  | 'usuarioNome'
  | 'nome'
  | 'responsavel'
  | 'prioridade'
  | 'novo'
  | 'dataPrevisao'
  | 'status'

export default function TicketsFilters({
  changeEstado,
  isFetching,
  emitidos,
  setEmitidos,
  isRefetching,
  loadingData,
  filter
}: props) {

  const [userEmissor, setUserEmissor] = useState<string[] | null>(filter.userEmissor || null);
  const [modelos, setModelos] = useState<number[] | null>(filter.modelos || null);
  const [modelosNovos, setModelosNovos] = useState<string[] | null>(filter.modelosNovos || null);
  const [inicioRangeDe, setInicioRangeDe] = useState<number>(filter.inicioRange?.de || new Date(Date.now()).setDate(-0.1));
  const [previsaoRangeDe, setPrevisaoRangeDe] = useState<number>(filter.previsaoRange?.de || new Date(Date.now()).setDate(-0.1));
  const [inicioRangeAte, setInicioRangeAte] = useState<number>(filter.inicioRange?.ate || Date.now());
  const [previsaoRangeAte, setPrevisaoRangeAte] = useState<number>(filter.previsaoRange?.ate || Date.now());
  const [cliente, setCliente] = useState<string[] | null>(filter.cliente || null);
  const [local, setLocal] = useState<string[] | null>(filter.local || null);
  const [conjunto, setConjunto] = useState<string[] | null>(filter.conjunto || null);
  const [unidade, setUnidade] = useState<string[] | null>(filter.unidade || null);
  const [ordem, setOrdem] = useState<orderType>(filter.ordenarPor || 'inicio');
  const [status, setStatus] = useState<statusType[] | null>(filter.status || null)

  const { t } = useTranslation('translation');
  const { userData, hierarchyLabel } = useContext(UserContext)

  const statusArray = [
    { concluido: 'Concluído' },
    { cancelado: 'Cancelado' },
    { emandamento: 'Em Andamento' },
    { pendente: 'Pendente' },
    { recusado: 'Recusado' }
  ]

  const orderArray = [
    { inicio: 'Por data de cadastro' },
    { usuarioNome: 'Por usuário' },
    { nome: 'Por chamado' },
    { responsavel: 'Por responsável' },
    { prioridade: 'Por prioridade' },
    { novo: 'Por modelo de chamado' },
    { dataPrevisao: 'Por data limite ' },
    { status: 'Por estado' }
  ]

  const { data, isLoading } = useQuery({
    queryKey: ['GET_FILTERS'],
    queryFn: async () => {
      const token = await AttToken()
      if (token) {
        const response = await chamdosService.getFiltersForWeb(token)
        return response
      }
    },
    refetchOnMount: 'always',
    refetchOnWindowFocus: false,
    retry: 1,
  })

  const findUserName = (value: string) => {
    const valor = data?.users?.find((el: dataReturn) => el.id === value);
    if (valor) return valor.nome
    else return 'User não encontrado';
  }

  const findChamadoName = (value: number) => {
    const valor = data?.chamadosLegado?.find((el: dataReturn) => Number(el.id) === value);
    if (valor) return valor.nome
    else return 'Chamado não encontrado';
  }

  const findChamadoLegadoName = (value: string) => {
    const valor = data?.chamadosNovos?.find((el: dataReturn) => el.id === value);
    if (valor) return valor.nome
    else return 'Chamado não encontrado';
  }

  const findClienteName = (value: string) => {
    const valor = data?.clientes?.find((el: dataReturn) => el.id === value);
    if (valor) return valor.nome
    else return 'Cliente não encontrado';
  }

  const findLocalName = (value: string) => {
    const valor = data?.locais?.find((el: dataReturn) => el.id === value);
    if (valor) return valor.nome
    else return 'Local não encontrado';
  }

  const findUnidadeName = (value: string) => {
    const valor = data?.unidades?.find((el: dataReturn) => el.id === value);
    if (valor) return valor.nome
    else return 'Unidade não encontrada';
  }

  const findConjuntoName = (value: string) => {
    const valor = data?.conjuntos?.find((el: dataReturn) => el.id === value);
    if (valor) return valor.nome
    else return 'Conjunto não encontrado';
  }

  const findStatusName = (value: string) => {
    const valor = statusArray.find((el) => Object.keys(el)[0] === value);
    if (valor) return Object.values(valor)[0]
    else return 'Status não encontrado';
  }

  const ordemCheck = (value: string, sortValue: boolean) => {
    switch (value) {
      case 'inicio': case 'dataPrevisao':
        return (sortValue ? 'Ordem de data crescente' : 'Ordem de data decrescente')
      case 'usuarioNome': case 'nome': case 'responsavel': case 'prioridade': case 'novo': case 'status':
        return (sortValue ? 'Ordem alfabética crescente (A-Z)' : 'Ordem alfabética  decrescente (Z-A)')
    }
  }

  const selectRef = useRef<HTMLSelectElement>(null)

  return (
    <Accordion className='acordion'>
      <AccordionSummary className='acordionSummary' expandIcon={<ExpandMoreIcon />} >
        <span style={{ width: '100%', textAlign: 'center' }}>Filtros</span>
      </AccordionSummary>
      <div style={{ marginTop: '10px', padding: '0px 5% 0px 5%', width: '100%' }}>
        <div>
          <div className='columns'>
            <span className="checkbox column">
              <Label className="checkbox">
                <input
                  style={{ marginBottom: '10px' }}
                  checked={emitidos}
                  onChange={(e) => {
                    setEmitidos(e.target.checked)
                  }}
                  disabled={isRefetching || isFetching || isLoading}
                  type="checkbox"
                />&nbsp;
                Mostrar chamados emitidos por&nbsp; <b>{userData.userName}</b>
              </Label>
            </span>
            <button
              disabled={isFetching || isLoading || isRefetching || loadingData}
              onClick={() => {
                changeEstado('@resetFilters', '')
              }} style={{ marginRight: '5px' }} className='button is-danger'>Limpar filtros&nbsp; <RxReset /></button>
            <button
              disabled={isFetching || isLoading || isRefetching || loadingData}
              className="button is-success "
              type="submit"
            >
              {t('filter')}&nbsp;<IoFilter />
            </button>
          </div>
          <span className='filtersTitle'>Ordenação</span>
          <hr />
          <div className='columns'>
            <div className='column'>
              <Label>Ordenar:</Label>
              <select
                onChange={(elem) => {
                  changeEstado('@orderBy', elem.target.value as orderType)
                  setOrdem(elem.target.value as orderType)
                }}
                defaultValue={ordem}
                className='select is-fullwidth'
              >
                {orderArray.map((ele) =>
                  <option value={Object.keys(ele)}>
                    {Object.values(ele)}
                  </option>)}
              </select>
            </div>
            <div className='column'>
              <Label>Ordem:</Label>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <label>
                  <input
                    onChange={() => changeEstado('@sortBy', true)}
                    defaultChecked={filter.ordem || false}
                    type="radio"
                    name="status" />&nbsp;
                  {ordemCheck(ordem, true)}
                </label>
                <label>
                  <input
                    onChange={() => changeEstado('@sortBy', false)}
                    defaultChecked={filter.ordem === false}
                    type="radio"
                    name="status" />&nbsp;
                  {ordemCheck(ordem, false)}
                </label>
              </div>

            </div>
          </div>
          <span className='filtersTitle'>Por Usuários/Chamados</span>
          <hr />
          <div className='columns'>
            <div className='column'>
              <Label>Usuário:</Label>
              {isLoading ? (<Skeleton className='skeletonSelect' />)
                : <select
                  className='select is-fullwidth'
                  value={''}
                  onChange={(event) => {
                    changeEstado('@addUserEmissor', event.target.value)
                    setUserEmissor((prev) => prev ? [...prev, event.target.value] : [event.target.value])
                  }}
                >
                  <option disabled value={''}>{t('select')}...</option>
                  {data?.users
                    ?.sort((a: dataReturn, b: dataReturn) => { return a.nome.toLowerCase() > b.nome.toLowerCase() ? 1 : - 1 })
                    ?.map((value: dataReturn, index: number) =>
                      <option
                        disabled={userEmissor?.includes(value.id)}
                        key={`${index} | user ${value.id}`}
                        value={value.id}>
                        {value.nome}
                      </option>
                    )}
                </select>
              }
              <div className='tagDiv'>
                {userEmissor
                  ?.map((value) => <span
                    onClick={() => {
                      changeEstado('@removeUserEmissor', value)
                      setUserEmissor(userEmissor?.filter((elem) => elem !== value))
                    }}
                    style={{ margin: '2px' }}
                    className='tag'>
                    {findUserName(value)}&nbsp;<MdCancel />
                  </span>)}
              </div>
              {userData.migrado === true && <>
                <Label>Chamados 2.0:</Label>
                {isLoading ? (<Skeleton className='skeletonSelect' />)
                  : <select
                    className='select is-fullwidth'
                    value={''}
                    onChange={(event) => {
                      changeEstado('@addModelosNovos', event.target.value)
                      setModelosNovos((prev) => prev ? [...prev, event.target.value] : [event.target.value])
                    }}
                  >
                    <option disabled value={''}>{t('select')}...</option>
                    {data?.chamadosNovos
                      ?.sort((a: dataReturn, b: dataReturn) => { return a.nome.toLowerCase() > b.nome.toLowerCase() ? 1 : - 1 })
                      ?.map((value: dataReturn, index: number) =>
                        <option
                          disabled={modelosNovos?.includes(value.id)}
                          key={`${index} | user ${value.id}`}
                          value={value.id}>
                          {value.nome}
                        </option>
                      )}
                  </select>
                }
                <div className='tagDiv'>
                  {modelosNovos
                    ?.map((value) => <span
                      onClick={() => {
                        changeEstado('@removeUserEmissor', value)
                        setModelosNovos(modelosNovos?.filter((elem) => elem !== value))
                      }}
                      style={{ margin: '2px' }}
                      className='tag'>
                      {findChamadoLegadoName(value)}&nbsp;<MdCancel />
                    </span>)}
                </div>
              </>
              }
            </div>
            <div className='column'>
              <Label>Chamados legado:</Label>
              {isLoading ? (<Skeleton className='skeletonSelect' />)
                : <select
                  className='select is-fullwidth'
                  value={''}
                  onChange={(event) => {
                    changeEstado('@addModelos', Number(event.target.value))
                    setModelos((prev) => prev ? [...prev, Number(event.target.value)] : [Number(event.target.value)])
                  }}
                >
                  <option disabled value={''}>{t('select')}...</option>
                  {data?.chamadosLegado
                    ?.sort((a: dataReturn, b: dataReturn) => { return a.nome.toLowerCase() > b.nome.toLowerCase() ? 1 : - 1 })
                    ?.map((value: dataReturn, index: number) =>
                      <option
                        disabled={modelos?.includes(Number(value.id))}
                        key={`${index} | modelo ${Number(value.id)}`}
                        value={Number(value.id)}>
                        {value.nome}
                      </option>
                    )}
                </select>}
              <div className='tagDiv'>
                {modelos
                  ?.map((value) => <span
                    onClick={() => {
                      changeEstado('@removeModelos', Number(value))
                      setModelos(modelos?.filter((elem) => Number(elem) !== Number(value)))
                    }}
                    style={{ margin: '2px' }}
                    className='tag'>
                    {findChamadoName(Number(value))}&nbsp;<MdCancel />
                  </span>)}
              </div>
            </div>
          </div>
          <span className='filtersTitle'>Por datas</span>
          <hr />
          <div className='columns'>
            <div className='column'>
              <Label>Data de cadastro de:</Label>
              <ReactDatePicker
                selected={new Date(inicioRangeDe ? (Number(inicioRangeDe)) : Date.now())}
                onChange={(date: Date) => {
                  changeEstado('@changeInicioRangeDe', new Date(date.setHours(0, 0, 0, 0)).getTime())
                  setInicioRangeDe(new Date(date && date.setHours(0, 0, 0, 0)).getTime())
                }}
                locale="pt"
                maxDate={new Date(inicioRangeAte ? (Number(inicioRangeAte)) : Date.now())}
                dateFormat='dd/MM/yyyy'
                onKeyDown={(e) => e.preventDefault()}
                className="input is-fullwidth" />
              <button disabled className='button is-light'><VscCalendar /></button>
              <Label>Data de cadastro até:</Label>
              <ReactDatePicker
                selected={new Date(inicioRangeAte ? (Number(inicioRangeAte)) : Date.now())}
                onChange={(date: Date) => {
                  changeEstado('@changeInicioRangeAte', new Date(date.setHours(23, 59, 59, 59)).getTime())
                  setInicioRangeAte(new Date(date.setHours(23, 59, 59, 59)).getTime())
                }}
                locale="pt"
                minDate={new Date(inicioRangeDe ? (Number(inicioRangeDe)) : Date.now())}
                dateFormat='dd/MM/yyyy'
                onKeyDown={(e) => e.preventDefault()}
                className="input is-fullwidth" />
              <button disabled className='button is-light'><VscCalendar /></button>
            </div>
            <div className='column'>
              <Label>Data limite de:</Label>
              <ReactDatePicker
                selected={new Date(previsaoRangeDe ? (Number(previsaoRangeDe)) : Date.now())}
                onChange={(date: Date) => {
                  changeEstado('@changePrevisaoRangeDe', new Date(date.setHours(0, 0, 0, 0)).getTime())
                  setPrevisaoRangeDe(new Date(date.setHours(0, 0, 0, 0)).getTime())
                }}
                locale="pt"
                maxDate={new Date(previsaoRangeAte ? (Number(previsaoRangeAte)) : Date.now())}
                dateFormat='dd/MM/yyyy'
                onKeyDown={(e) => e.preventDefault()}
                className="input" />
              <button disabled className='button is-light'><VscCalendar /></button>
              <Label>Data limite até:</Label>
              <ReactDatePicker
                selected={new Date(previsaoRangeAte ? (Number(previsaoRangeAte)) : Date.now())}
                onChange={(date: Date) => {
                  changeEstado('@changePrevisaoRangeAte', new Date(date.setHours(23, 59, 59, 59)).getTime())
                  setPrevisaoRangeAte(new Date(date.setHours(23, 59, 59, 59)).getTime())
                }}
                locale="pt"
                minDate={new Date(previsaoRangeDe ? (Number(previsaoRangeDe)) : Date.now())}
                dateFormat='dd/MM/yyyy'
                onKeyDown={(e) => e.preventDefault()}
                className="input" />
              <button disabled className='button is-light'><VscCalendar /></button>
            </div>
          </div>
          <span className='filtersTitle'>Por Hierarquia</span>
          <hr />
          <div className='columns'>
            <div className='column'>
              <Label>{hierarchyLabel.cliente}:</Label>
              {isLoading ? (<Skeleton className='skeletonSelect' />)
                : <select
                  className='select is-fullwidth'
                  value={''}
                  onChange={(event) => {
                    changeEstado('@addCliente', event.target.value)
                    setCliente([...cliente as string[] || [], event.target.value])
                  }}
                >
                  <option disabled value={''}>{t('select')}...</option>
                  {data?.clientes
                    ?.sort((a: dataReturn, b: dataReturn) => { return a.nome.toLowerCase() > b.nome.toLowerCase() ? 1 : - 1 })
                    ?.map((value: dataReturn, index: number) =>
                      <option
                        disabled={cliente?.includes(value.id)}
                        key={`${index} | ${value.id}`}
                        value={value.id}>
                        {value.nome}
                      </option>
                    )}
                </select>}
              <div className='tagDiv'>
                {cliente
                  ?.map((value) => <span
                    onClick={() => {
                      changeEstado('@removeCliente', value)
                      setCliente(cliente?.filter((elem) => elem !== value))
                    }}
                    style={{ margin: '2px' }}
                    className='tag'>
                    {findClienteName(value)}&nbsp;<MdCancel />
                  </span>)}
              </div>
            </div>
            <div className='column'>
              <Label>{hierarchyLabel.conjunto}:</Label>
              {isLoading ? (<Skeleton className='skeletonSelect' />)
                : <select
                  className='select is-fullwidth'
                  value={''}
                  onChange={(event) => {
                    changeEstado('@addConjunto', event.target.value)
                    setConjunto([...conjunto as string[] || [], event.target.value])
                  }}
                >
                  <option disabled value={''}>{t('select')}...</option>
                  {data?.conjuntos
                    ?.sort((a: dataReturn, b: dataReturn) => { return a.nome.toLowerCase() > b.nome.toLowerCase() ? 1 : - 1 })
                    ?.map((value: dataReturn, index: number) =>
                      <option
                        disabled={conjunto?.includes(value.id)}
                        key={`${index} | ${value.id}`}
                        value={value.id}>
                        {value.nome}
                      </option>
                    )}
                </select>}
              <div className='tagDiv'>
                {conjunto
                  ?.map((value) => <span
                    onClick={() => {
                      changeEstado('@removeConjunto', value)
                      setConjunto(conjunto?.filter((elem) => elem !== value))
                    }}
                    style={{ margin: '2px' }}
                    className='tag'>
                    {findConjuntoName(value)}&nbsp;<MdCancel />
                  </span>)}
              </div>
            </div>
          </div>
          <div className='columns'>
            <div className='column'>
              <Label>{hierarchyLabel.local}:</Label>
              {isLoading ? (<Skeleton className='skeletonSelect' />)
                : <select
                  className='select is-fullwidth'
                  value={''}
                  onChange={(event) => {
                    changeEstado('@addLocal', event.target.value)
                    setLocal([...local as string[] || [], event.target.value])
                  }}
                >
                  <option disabled value={''}>{t('select')}...</option>
                  {data?.locais
                    ?.sort((a: dataReturn, b: dataReturn) => { return a.nome.toLowerCase() > b.nome.toLowerCase() ? 1 : - 1 })
                    ?.map((value: dataReturn, index: number) =>
                      <option
                        disabled={local?.includes(value.id)}
                        key={`${index} | ${value.id}`}
                        value={value.id}>
                        {value.nome}
                      </option>
                    )}
                </select>}
              <div className='tagDiv'>
                {local
                  ?.map((value) => <span
                    onClick={() => {
                      changeEstado('@removeLocal', value)
                      setLocal(local?.filter((elem) => elem !== value))
                    }}
                    style={{ margin: '2px' }}
                    className='tag'>
                    {findLocalName(value)}&nbsp;<MdCancel />
                  </span>)}
              </div>
            </div>
            <div className='column'>
              <Label>{hierarchyLabel.unidade}:</Label>
              {isLoading ? (<Skeleton className='skeletonSelect' />)
                : <select
                  className='select is-fullwidth'
                  value={''}
                  onChange={(event) => {
                    changeEstado('@addUnidade', event.target.value)
                    setUnidade([...unidade as string[] || [], event.target.value])
                  }}
                >
                  <option disabled value={''}>{t('select')}...</option>
                  {data.unidades
                    ?.sort((a: dataReturn, b: dataReturn) => { return a.nome.toLowerCase() > b.nome.toLowerCase() ? 1 : - 1 })
                    ?.map((value: dataReturn, index: number) =>
                      <option
                        disabled={unidade?.includes((value.id))}
                        key={`${index} | ${value.id}`}
                        value={value.id}>
                        {value.nome}
                      </option>
                    )}
                </select>}
              <div className='tagDiv'>
                {unidade
                  ?.map((value) => <span
                    onClick={() => {
                      changeEstado('@removeUnidade', value)
                      setUnidade(unidade?.filter((elem) => elem !== value))
                    }}
                    style={{ margin: '2px' }}
                    className='tag'>
                    {findUnidadeName(value)}&nbsp;<MdCancel />
                  </span>)}
              </div>
            </div>
          </div>
          <span className='filtersTitle'>Por status</span>
          <hr />
          <div className='columns' style={{ marginBottom: '1em' }}>
            <div className='column'>
              <Label>Expiração</Label>
              <select
                defaultValue={'null'}
                onChange={(elem) => {
                  changeEstado('@changeExpirado', elem.target.value === 'null' ? null : elem.target.value === 'true' ? true : false)
                  // setExpirado(elem.target.value === 'true' ? true : false)
                }}
                className='select is-fullwidth'>
                <option value={'null'}>Mostrar Todos</option>
                <option value={`${true}`}>Mostrar apenas chamados expirados</option>
                <option value={`${false}`}>Mostrar apenas chamados dentro do prazo</option>
              </select>
            </div>
            <div className='column'>
              <Label>{t('status')}</Label>
              <select
                ref={selectRef}
                defaultValue={'null'}
                onChange={(elem) => {
                  if (filter.status?.length && filter.status?.length === 4) {
                    elem.target.value = 'null'
                  }
                  const val = JSON.parse(JSON.stringify(elem.target.value))
                  changeEstado('@changeStatus', val === 'null' ? null : filter.status?.length ? [...filter.status, val] : [val])
                  setStatus(prev => val === 'null' ? null : prev ? [...prev, val as statusType] : [val as statusType]);
                  if (selectRef.current && val !== 'null') {
                    selectRef.current.value = ''
                  }
                }}
                className='select is-fullwidth'>
                <option disabled value={''}>{t('select')}...</option>
                <option value={'null'}>Todos</option>
                {statusArray?.map((elem, index) =>
                  <option
                    disabled={status?.includes(Object.keys(elem)[0] as statusType)}
                    key={index}
                    value={Object.keys(elem)[0]}>
                    {Object.values(elem)[0]}
                  </option>)}
              </select>
              <div className='tagDiv'>
                {status
                  ?.map((value) => <span
                    onClick={() => {
                      changeEstado('@removeUnidade', value)
                      setStatus(status?.filter((elem) => elem !== value))
                    }}
                    style={{ margin: '2px' }}
                    className='tag'>
                    {findStatusName(value)}&nbsp;<MdCancel />
                  </span>)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Accordion>
  )
}
