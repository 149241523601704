import { useTranslation } from 'react-i18next';
import "../styles.css";

type props = {
  openFinaliza: boolean,
  setOpenFinaliza: Function,
  mutate: Function,
  planoId: string,
}

export default function ModalFinalizaPA({
  openFinaliza,
  setOpenFinaliza,
  mutate,
  planoId, }: props) {
  const { t } = useTranslation('translation');
  return (
    <div className={`modal ${openFinaliza ? 'is-active' : ''}`}>
      <div className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">Finalizar Plano de Ação</p>
          <button
            onClick={() => setOpenFinaliza(!openFinaliza)} className="delete" aria-label={t('close')}
          />
        </header>
        <section className="modal-card-body styleFinaliza">
          <span>Deseja finalizar este Plano de Ação?</span>
          <button
            onClick={() => mutate(planoId)}
            className='button is-success'
          >Finalizar Plano
          </button>
        </section>
      </div>
    </div>
  )
}
