import { Form, Label, Button, Input } from "../../StyledComponents/Input/generic";
import { useState, useContext, useMemo } from "react";
import { UserContext } from "../../context/UserContext";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from "react-i18next";
import { TagContainer, TagSpan } from "../../StyledComponents/Modal/generic";
import { AiOutlineClose } from "react-icons/ai";
import AttToken from "../../helpers/attToken";
import { userBackObj } from "../Users/UserSearch";
import Teams from "../../service/Teams";
import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import queryClient from "../../service/query";

export type team = {
  dataAlteracao: number,
  dataCadastro: number,
  empresa: string,
  gerente: string,
  listaCliente: string[],
  nome: string,
  status: boolean,
  users: string[],
  teamID: string
}

export default function TeamRegister() {
  const { userList, setTeamList, teamList } = useContext(UserContext);
  const [teamName, setTeamName] = useState("");
  const { t } = useTranslation('translation');
  const [selectedServices, setSelectedServices] = useState<string[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [disable, setDisable] = useState(true)

  const TeamsService = useMemo(() => new Teams(), []);

  const { mutate: registerTeam } = useMutation({
    mutationKey: ['createTeam'],
    mutationFn: async () => {
      const token = await AttToken()
      if (token) {
        const response = await TeamsService.createTeam(token, teamName, selectedUsers, selectedServices)
        setTeamList([...teamList, response.team])
        queryClient.resetQueries(['TeamList'])
      }
    },
    onSuccess: () => {
      setTeamName('');
      setSelectedUsers([])
      setSelectedServices([])
      toast.success(t<string>('teamManage.registered.toastSuccess'));

    },
    onError: () => console.log(AxiosError)
  })

  const findUserName = (value: string) => {
    const userName = userList && userList.find((el) => {
      return el.id === value
    });
    if (userName) return userName.nome
    else return 'teste';
  }

  return (
    <Form onSubmit={(e) => {
      e.preventDefault();
      registerTeam()
      setDisable(true)
    }}>

      <ToastContainer />
      <Label>
        {t('teamManage.register.teamName')}
      </Label>
      <Input
        maxLength={80}
        className="input is-small"
        type="text"
        placeholder={t('teamManage.register.teamNamePlaceholder')}
        value={teamName}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setTeamName(e.target.value)
          setDisable(false);
        }}
      />
      <Label>{t('Services.servicesManagement.members')}</Label>
      <TagContainer>
        {selectedUsers.length > 0 && selectedUsers.map((e: string, i: number) => (
          <TagSpan key={i} onClick={() => {
            setSelectedUsers(selectedUsers.filter(el => el !== e))
          }}>
            {findUserName(e)}
            <AiOutlineClose />
          </TagSpan>
        ))}
      </TagContainer>
      <select
        value=''
        className="select is-small is-fullwidth"
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
          setSelectedUsers([...selectedUsers, e.target.value])
          setDisable(false)
        }}
      >
        <option value='' disabled>{t('select')}</option>
        {
          userList && userList
            .sort((a, b) => { return a.nome.toLowerCase() > b.nome.toLowerCase() ? 1 : - 1 })
            .filter((e) => e.status === 'ativo')
            .map((e: userBackObj, i: number) => (
              <option
                key={i}
                disabled={selectedUsers && selectedUsers.includes(e.id)}
                value={e.id}>
                {e.nome}
              </option>
            ))
        }
      </select>
      <Button
        className="button is-success is-small"
        style={{ width: "100px" }}
        type="submit"
        disabled={teamName === '' || selectedUsers.length < 1 || disable}
      >
        {t('save')}
      </Button>
    </Form>
  )
}
