import { useMutation, useQuery } from "@tanstack/react-query";
import { useEffect, useMemo, useRef, useState } from 'react'
import AttToken from '../../../../helpers/attToken';
import Clients, { ClientType } from '../../../../service/Clients';
import { Label } from '../../../Notifications/style/SingleNotificationStyle';
import { useTranslation } from 'react-i18next';
import { ButtonsContainer, StyledH1Subitem, SubItemConfigDiv } from './FormModalStyle';
import { WeightInput } from '../../FormStyle';
import Checklists, { ChecklistSubitem, ChecklistType, SubitemChecklist } from '../../../../service/Checklists';
import TicketsAndNotifications, { typeToSend } from '../../../../service/Tickets_Notifications';
import { TagContainer, TagSpan } from '../../../../StyledComponents/Modal/generic';
import { IoCloseCircleOutline } from 'react-icons/io5';
import { toast } from "react-toastify";
import SmallBox from "../../../Skeletons/SmallBox";
import { Skeleton } from "@mui/material";
import { Typography } from "@material-ui/core";

type props = {
  setEditModal: Function,
  form: ChecklistType
}

export default function SubItemTab({ setEditModal, form }: props) {
  const formAnonimo = form.preenchimentoDeslogado && form.preenchimentoWeb;


  const customerService = useMemo(() => new Clients(), []);
  const NotificationsService = useMemo(() => new TicketsAndNotifications(), []);
  const checklistService = useMemo(() => new Checklists(), []);

  const { t } = useTranslation('translation');
  const [selectedCustomer, setSelectedCustomer] = useState<string>(formAnonimo ? 'anonimo' : '');
  const [chamadoObrigatorio, setChamadoObrigatorio] = useState('naoconforme');
  const [tipoChamado, setTipoChamado] = useState<number[]>();
  const [limiteChamado, setLimiteChamado] = useState<number>();
  const [canShow, setCanShow] = useState(false);
  const [selectedSub, setSelectedSub] = useState('');
  const [disabled, setDisabled] = useState(false)

  const clearStates = () => {
    setChamadoObrigatorio('nãoConforme');
    setTipoChamado(undefined);
    setLimiteChamado(undefined);
    setSelectedSub('');
  }

  const mapOptionsKeys = {
    naoconforme: 'Em Não Conformidade',
    conforme: 'Em Conformidade',
    sempre: 'Sempre',
    nunca: 'Nunca'
  }

  const allSubitems: ChecklistSubitem[] = [];

  if (form.itens) {
    form.itens.forEach(item => {
      item.subitens && item.subitens.forEach(sub => allSubitems.push(sub))
    })
  }

  const {
    isFetching,
    data,
    isLoading,
  } = useQuery({
    queryKey: ['customers'],
    queryFn: async () => {
      const token = await AttToken();
      if (token) {
        const body = { ...token, empresa: token.userEmpresa };
        const result = await customerService.getAllClients(body, true);
        return result.clientList
      }
    },
    keepPreviousData: true,
    retry: 5,
    refetchOnWindowFocus: false,
    refetchOnMount: 'always',
  });

  const { data: ticketsData, isLoading: loadingTickets } = useQuery({
    queryKey: ['getTickets'],
    queryFn: async () => {
      const token = await AttToken()
      if (token) {
        const response = await NotificationsService.getTypes(token, 'chamado')
        return response;
      }
    },
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    refetchOnMount: 'always',
  });

  type ValueMutate = {
    subId: string | null,
    subIndex: number,
    itemid: string
  }

  const { data: formData, isLoading: isLoadingForms, mutate: getOptionsBySubitem } = useMutation({
    mutationKey: ['getOptionsBySubitem'],
    mutationFn: async (value: ValueMutate) => {
      const token = await AttToken()
      if (token) {
        setCanShow(false);
        const response = await checklistService.getOptionsBySubitem(token, form.id, selectedCustomer, value.subId, value.subIndex, value.itemid)
        setCanShow(true);
        form.itens[Number(value.itemid.split('|')[2])].subitens[value.subIndex].new_id = response.newId;
        return response
      }
    }
  })

  useEffect(() => {
    if (formData && formData.result) {
      setChamadoObrigatorio(formData.result.chamadoObrigatorio);
      setLimiteChamado(Number(formData.result.limiteChamado));
      setTipoChamado(formData.result.tipoChamado);
    }else {
      setChamadoObrigatorio('naoconforme');
      setLimiteChamado(0);
      setTipoChamado([]);
    }
  }, [formData])

  const findticketsName = (value: number) => {
    const ticketName = ticketsData && ticketsData.find((el: typeToSend) => Number(el.id) === value);
    if (ticketName) return ticketName.nome
    else return 'teste';
  }

  const selectRef = useRef<HTMLSelectElement>(null);
  const customerSelectRef = useRef<HTMLSelectElement>(null);

  return (
    <div style={{ marginBottom: '60px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      {isLoading || isFetching ? <>
        <>{!formAnonimo ?
          <div style={{ width: '100%' }}>
            <Skeleton variant="text" width={100} style={{ marginBottom: '-.8em', alignSelf: 'initial', display: "flex" }} />
            <Skeleton className="input is-large" style={{ marginBottom: '-.8em' }} />
            <Typography variant="h1" style={{ display: 'flex', justifyContent: 'center' }}>{<Skeleton width={500} />}</Typography>
          </div> :
          <div style={{ width: '100%', alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
            <Skeleton className="input is-large" width={300} />
            <Skeleton variant="text" width={250} height={30} />
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'initial', width: '100%' }}>
              <Skeleton variant="text" width={100} style={{ marginBottom: '-.8em', alignSelf: 'initial', display: "flex" }} />
              <Skeleton className="input is-large" style={{ marginBottom: '-.8em' }} />
            </div>
          </div>
        }
        </>
      </> :
        <>
          {formAnonimo ? <h1 style={{ alignSelf: 'center', fontSize: '1.6em' }}>{t('formDetails.Formulario_Anonimo')}</h1> : <>
            <Label style={{ display: 'flex', justifySelf: 'flex-start', alignSelf: 'flex-start  ' }}>
              {t('teamManage.modal.customer')}
            </Label>
            <select
              disabled={data.length < 1}
              defaultValue=''
              className="select is-small is-fullwidth"
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                if (selectRef.current) selectRef.current.value = '';
                setCanShow(false);
                setSelectedCustomer(e.target.value);
                clearStates();
              }}
              ref={customerSelectRef}
            >
              <option value='' >{t('select')}</option>
              {
                data
                  .sort((a: ClientType, b: ClientType) => { return a.nome.toLowerCase() > b.nome.toLowerCase() ? 1 : - 1 })
                  .filter((e: ClientType) => e.status === 'ativo')
                  .map((e: ClientType) => (
                    <option
                      key={e.id}
                      value={e.id}>
                      {e.nome}
                    </option>
                  ))
              }
            </select>
          </>}

          {
            selectedCustomer === '' ?
              (<div style={{ margin: '10px', border: '1px solid lightgray', padding: '15px', alignSelf: 'center' }}>
                <p style={{ color: 'gray', fontSize: '15px', fontWeight: 500, textAlign: 'center' }}>
                  {t('formDetails.GeneralTab.Selecione_um_cliente')}
                </p>
              </div>)
              :
              <>
                <StyledH1Subitem>{t('formDetails.sub_item_settings.Config_Subitens')}</StyledH1Subitem>
                <SubItemConfigDiv>
                  <Label>{t('formDetails.sub_item_settings.Selecione_Subitem')}:</Label>
                  <select
                    style={{ maxWidth: '100%' }}
                    disabled={allSubitems.length <= 0}
                    ref={selectRef}
                    className="select is-small is-fullwidth"
                    defaultValue=""
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                      const sub: SubitemChecklist = JSON.parse(e.target.value);
                      setSelectedSub(e.target.value);
                      getOptionsBySubitem({ subId: sub.new_id || null, subIndex: Number(sub.id), itemid: sub.itemid });
                    }}
                  >
                    <option
                      disabled
                      value="">
                      {t('select')}
                      ...
                    </option>
                    {
                      allSubitems
                        .sort((a, b) => { return a.nome.toLowerCase() > b.nome.toLowerCase() ? 1 : - 1 })
                        .map((e) => (
                          <option
                            className="option"
                            key={`${e.nome}|${e.id}`}
                            value={JSON.stringify(e)}
                          >
                            {e.nome}
                          </option>
                        ))}
                  </select>
                  {canShow && !isLoadingForms ?
                    <>
                      <Label>{t('formDetails.GeneralTab.Selecione_um_chamado')}:</Label>
                      <TagContainer>
                        {tipoChamado && tipoChamado.map((e: number, i: number) => (
                          <TagSpan
                            key={`tipoChamado${e}|${i}`}
                            onClick={() => {
                              setTipoChamado((prevState) => prevState ? prevState.filter(el => el !== e) : [])
                            }}>
                            {findticketsName(e)}&nbsp;&nbsp;
                            <IoCloseCircleOutline />
                          </TagSpan>
                        ))}
                      </TagContainer>
                      {loadingTickets ?
                        <>
                          <SmallBox height=".5em" widht="90%" />
                        </>
                        :
                        <select className="select is-small is-fullwidth" defaultValue="" onChange={(e) => {
                          setTipoChamado((prevState) => prevState ? [...prevState, Number(e.target.value)] : [Number(e.target.value)])
                          setTimeout(() => e.target.value = '', 1)
                        }}>
                          <option disabled value="">{t('select')}</option>
                          {
                            ticketsData && ticketsData.sort((a: typeToSend, b: typeToSend) => {
                              return a.nome.toLowerCase() > b.nome.toLowerCase() ? 1 : - 1
                            }).map((e: typeToSend, i: number) =>
                            (<option
                              key={`${e.id}|${i}`}
                              disabled={tipoChamado && tipoChamado.includes(Number(e.id))}
                              value={Number(e.id)}
                            >
                              {e.nome}
                            </option>
                            ))
                          }
                        </select>}
                      <Label>
                        <span className='limit-span'>{t('formDetails.sub_item_settings.Limite_por_subitem')}:</span>
                        <WeightInput
                          type="number"
                          value={limiteChamado ? limiteChamado : undefined}
                          onChange={(e) => setLimiteChamado(Number(e.target.value))}
                        />
                      </Label>
                        {!limiteChamado
                          && <span
                            style={{ color: 'red' }}>
                            *Sem limite definido, a abertura de chamados é ilimitada
                          </span>}
                      {JSON.parse(selectedSub).tipo === 'avaliacao' && <Label>
                        {t('formDetails.sub_item_settings.Abertura_de_chamado')}:
                        <select className="select is-small is-fullwidth" value={chamadoObrigatorio} onChange={(ev) => {
                          setChamadoObrigatorio(ev.target.value)
                        }}>
                          {Object.keys(mapOptionsKeys).map(e => (
                            <option key={e} value={e}>{mapOptionsKeys[e as keyof typeof mapOptionsKeys]}</option>
                          ))}
                        </select>
                      </Label>}
                    </>
                    : isLoadingForms ?
                      <>
                        <Skeleton variant="text" width={200} />
                        <Skeleton className="input is-large" />
                        <Skeleton variant="text" width={100} /><br />
                        {JSON.parse(selectedSub).tipo === 'avaliacao' && <div className="columns" style={{ width: '100%', display: 'flex' }}>
                          <div style={{ width: 'fit-content' }} className="column is-1">
                            <Skeleton variant="text" width={100} />
                            <Skeleton variant="text" width={100} />
                          </div>
                          <Skeleton width={'100%'} className="input is-fullwidth is-large column" />

                        </div>}
                      </>
                      : <></>}
                </SubItemConfigDiv>
              </>
          }
        </>}

      <ButtonsContainer>
        <button
          disabled={disabled || selectedSub === ''}
          className='button is-success is-small' onClick={
            async () => {
              setDisabled(true)
              toast.warn('Salvando Configurações de Subitem')
              const token = await AttToken();
              if (token) {
                const sub: SubitemChecklist = JSON.parse(selectedSub);
                const result = await checklistService.editOptionsForSubitem(token, form.id, selectedCustomer, sub.new_id || null, Number(sub.id), sub.itemid, {
                  chamadoObrigatorio,
                  limiteChamado: limiteChamado || 0,
                  nome: sub.nome,
                  tipo: sub.tipo,
                  tipoChamado: tipoChamado || []
                })
                if (result) {
                  form.itens[Number(sub.itemid.split('|')[2])].subitens[Number(sub.id)].new_id = result.newId;
                }
                toast.success('Configurações salvas');
                setDisabled(false)
                clearStates();
                if (selectRef.current) selectRef.current.value = '';
                setCanShow(false);
              }
            }}>
          {t('save')}
        </button>
        <button
          className='button is-light is-small'
          onClick={() => setEditModal(-1)}>
          {t('close')}
        </button>
      </ButtonsContainer>
    </div >
  )
}
