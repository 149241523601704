import { PowerBIEmbed } from 'powerbi-client-react';
import { useMutation } from '@tanstack/react-query';
import AttToken from '../../helpers/attToken';
import { useContext, useEffect, useMemo, useState } from 'react';
import register, { userReportsType } from '../../service/PowerBi';
import { useTranslation } from 'react-i18next';
import { Label } from '../../StyledComponents/Input/generic';
import { UserContext } from '../../context/UserContext';
import ModalInvalid from './ModalInvalid';
import { models } from 'powerbi-client';
import { toast } from 'react-toastify';
import Table from '../Skeletons/Table';
import { Skeleton } from '@mui/material';

export default function Dashboard({ userReportsData, isLoadingUserReports }: { userReportsData: any, isLoadingUserReports: boolean }) {
  const { openModal } = useContext(UserContext);
  const servicePowerBi = useMemo(() => new register(), []);
  const [userReports, setUserReports] = useState<userReportsType[]>([]);
  const [selectedReport, setSelectedReport] = useState<userReportsType>({
    datasetId: '',
    datasetWorkspaceId: '',
    id: '',
    name: '',
    users: [],
  });
  const [embedToken, setEmbedToken] = useState({
    accessToken: '',
    embedUrl: ''
  })

  // useEffect(() => {
  //   if (userReports) setSelectedReport({
  //     ...userReports[0]
  //   })
  // }, [userReports])

  const { t } = useTranslation('translation');

  useEffect(() => {
    if (userReportsData) setUserReports(userReportsData)
  }, [userReportsData])

  const { data, isLoading, mutate, isError } = useMutation({
    mutationKey: ['GET_EMBED'],
    mutationFn: async () => {
      try {
        const token = await AttToken()
        if (token) {
          const args = {
            empresa: token.userEmpresa,
            userAdm: token.username,
            workspaceId: selectedReport.datasetWorkspaceId,
            reportId: selectedReport.id,
            identityUser: token.username,
          }
          const response = await servicePowerBi.getEmbed(token.userToken, args)
          return response
        }
      } catch (err) {
        console.log('ops', err)
      }

    },
    useErrorBoundary: false,
    onError: () => [
      toast.error('Erro ao buscar dados!')
    ]
  })

  useEffect(() => {
    try {
      data && setEmbedToken({
        accessToken: data.accessToken,
        embedUrl: data.embedUrl[0].embedUrl,
      })
    } catch (err) {
      toast.error('Erro ao buscar dados')
    }

  }, [data])

  return (
    <>
      {isLoadingUserReports ? (
        <>
          <Skeleton variant='text' width={80} height={15} style={{ marginBottom: '-.5em' }} />
          <Skeleton width={"100%"} height={50} />
        </>
      ) :
        <>
          <ModalInvalid openModal={openModal} />
          <Label>
            {t('Dashboard.Relatorios_configurados')}:
          </Label>
          <select
            disabled={userReportsData.length === 0 || userReportsData === undefined}
            value={selectedReport?.name}
            className="select is-small is-fullwidth"
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              const userReportValue = JSON.parse(e.target.value);
              userReportValue && setSelectedReport(userReportValue)
              mutate()
            }}
          >
            <option value='' >{t('select')}</option>
            {
              userReports && userReports?.sort((a, b) => { return a.name?.toLowerCase() > b.name?.toLowerCase() ? 1 : - 1 })?.map((e: userReportsType) => (
                <option key={Math.random()} value={JSON.stringify(e)}>{e.name}</option>
              ))
            }
          </select>
        </>

      }
      {
        selectedReport?.name?.length > 1 &&
        <div style={{
          height: '100svh',
          width: '80svw',
          marginTop: '10px',
          alignItems: 'center',
          display: 'flex'
        }}>
          {isLoading ?
            <div style={{ display: 'flex', justifyContent: 'center', width: '100%', justifySelf: 'center' }}>
              <Table />
            </div>
            : isError ? <></> :
              selectedReport?.name?.length > 1 &&
              <PowerBIEmbed
                cssClassName="powerBi"
                key={userReportsData?.reportId}
                embedConfig={{
                  type: "report", // Supported types: report, dashboard, tile, visual and qna
                  id: selectedReport.id,
                  embedUrl: embedToken.embedUrl,
                  accessToken: embedToken.accessToken, // Keep as empty string, null or undefined
                  tokenType: models.TokenType.Embed, // PowerBiClient.Models.TokenType.Embed
                  settings: {
                    panes: {
                      filters: {
                        expanded: true,
                        visible: true
                      }
                    },
                  }
                }}
              />
          }
        </div>

      }
    </>
  )
}