import { useContext, useEffect, useState } from "react";
import { StyledDiv } from "../TableHelpers/TableStyle";
import MUIDataTable, { MUIDataTableOptions } from "mui-datatables";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import AttToken from "../../helpers/attToken";
import Tickets, { TicketsType } from "../../service/Tickets";
import { ThemeProvider } from '@mui/material/styles';
import { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";
import SingleNotification from "./SingleNotification";
import { UserContext } from "../../context/UserContext";
import Table from "../Skeletons/Table";
import { Alert, Skeleton, Snackbar } from "@mui/material";
import { SpanChamado } from "./style/AllNotificationsStyle";
import RedirectListModal from "./Modals/RedirectListModal";
import { getMuiTheme } from "../TableHelpers/options";
import TicketsFilters, { orderType } from "./TicketsFilters";
import { toast } from "react-toastify";

const ticketsService = new Tickets();

export type statusType = 'concluido' | 'emandamento' | 'pendente' | 'cancelado'

export type filtrosType = {
  userEmissor: string[] | null,//(array de id de usuario),
  expirado: boolean | null,
  modelos: number[] | null //(array de id de modelos de chamado antigo),
  modelosNovos: string[] | null, //(array de id de modelos de chamado2.0),
  inicioRange: {
    de: number | null,
    ate: number | null,
  } | null,
  previsaoRange: {
    de: number | null,
    ate: number | null
  } | null,
  status: statusType[] | null,
  cliente: string[] | null, //(nome do cliente),
  local: string[] | null, //(nome do local),
  conjunto: string[] | null, //(nome do conjunto),
  unidade: string[] | null, //(nome da unidade),//(array de status possiveis de chamado)
  ordenarPor: orderType | null,
  ordem: boolean | null,
}


export type actions =
  '@addUserEmissor'
  | '@removeUserEmissor'
  | '@addModelos'
  | '@removeModelos'
  | '@addModelosNovos'
  | '@removeModelosNovos'
  | '@addCliente'
  | '@removeCliente'
  | '@addLocal'
  | '@removeLocal'
  | '@addConjunto'
  | '@removeConjunto'
  | '@addUnidade'
  | '@removeUnidade'
  | '@changeExpirado'
  | '@changeStatus'
  | '@changeInicioRangeDe'
  | '@changeInicioRangeAte'
  | '@changePrevisaoRangeDe'
  | '@changePrevisaoRangeAte'
  | '@resetFilters'
  | '@orderBy'
  | '@sortBy'

export default function MyTickets() {
  const { show } = useContext(UserContext)
  const { t } = useTranslation('translation');
  const [open, setOpen] = useState(false);
  const [encaminhados, setEncaminhados] = useState<TicketsType[]>();
  const [openEncaminhados, setOpenEncaminhados] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [itensPerPage, setItensPerPage] = useState(10);
  const [totalItens, setTotalItens] = useState(10);
  const [emitidos, setEmitidos] = useState(false);

  const f = localStorage.getItem('filtros')
  let filter: filtrosType | null = f ? JSON.parse(f) as filtrosType :
    {
      userEmissor: null,//(array de id de usuario),
      expirado: null,
      modelos: null, //(array de id de modelos de chamado antigo),
      modelosNovos: null, //(array de id de modelos de chamado2.0),
      inicioRange: {
        de: new Date(Date.now()).setDate(-0.1),
        ate: Date.now()
      },
      previsaoRange: {
        de: new Date(Date.now()).setDate(-0.1),
        ate: Date.now()
      },
      status: null,
      cliente: null, //(nome do cliente),
      local: null, //(nome do local),
      conjunto: null, //(nome do conjunto),
      unidade: null,
      ordenarPor: 'inicio',
      ordem: false,
    }

  function changeEstado(action: actions, value: string[] | number[] | Date | boolean | number | string | null) {
    if (filter) {
      switch (action) {
        case '@addCliente':
          filter.cliente = filter?.cliente ? [...filter.cliente, value as string] : [value as string];
          break;
        case '@removeCliente':
          filter.cliente = filter?.cliente ? filter.cliente.filter((item) => item !== value) : null;
          break;
        case '@addLocal':
          filter.local = filter?.local ? [...filter.local, value as string] : [value as string];
          break;
        case '@removeLocal':
          filter.local = filter?.local ? filter.local.filter((item) => item !== value) : null;
          break;
        case '@addConjunto':
          filter.conjunto = filter?.conjunto ? [...filter.conjunto, value as string] : [value as string];
          break;
        case '@removeConjunto':
          filter.conjunto = filter?.conjunto ? filter.conjunto.filter((item) => item !== value) : null;
          break;
        case '@addUnidade':
          filter.unidade = filter?.unidade ? [...filter.unidade, value as string] : [value as string];
          break;
        case '@removeUnidade':
          filter.unidade = filter?.unidade ? filter.unidade.filter((item) => item !== value) : null;
          break;
        case '@addUserEmissor':
          filter.userEmissor = filter?.userEmissor ? [...filter.userEmissor, value as string] : [value as string];
          break;
        case '@removeUserEmissor':
          filter.userEmissor = filter?.userEmissor ? filter.userEmissor.filter((item) => item !== value) : null;
          break;
        case '@addModelos':
          filter.modelos = filter?.modelos ? [...filter.modelos, value as number] : [value as number];
          break;
        case '@removeModelos':
          filter.modelos = filter?.modelos ? filter.modelos.filter((item) => item !== value) : null;
          break;
        case '@addModelosNovos':
          filter.modelosNovos = filter?.modelosNovos ? [...filter.modelosNovos, value as string] : [value as string];
          break;
        case '@removeModelosNovos':
          filter.modelosNovos = filter?.modelosNovos ? filter.modelosNovos.filter((item) => item !== value) : null;
          break;
        case '@changeExpirado':
          filter.expirado = value as boolean
          break;
        case '@changeStatus':
          filter.status = value as statusType[]
          break;
        case '@changeInicioRangeDe':
          filter.inicioRange = {
            ...(filter?.inicioRange || { ate: null }),
            de: value as number,
          }
          break;
        case '@changeInicioRangeAte':
          filter.inicioRange = {
            ...(filter?.inicioRange || { de: null }),
            ate: value as number,
          }
          break;
        case '@changePrevisaoRangeDe':
          filter.previsaoRange = {
            ...(filter?.previsaoRange || { ate: null }),
            de: value as number,
          }
          break;
        case '@changePrevisaoRangeAte':
          filter.previsaoRange = {
            ...(filter?.previsaoRange || { de: null }),
            ate: value as number,
          }
          break;
        case '@orderBy':
          filter.ordenarPor = value as orderType
          break;
        case '@sortBy':
          filter.ordem = value as boolean
          break;
        case '@resetFilters':
          filter = {
            userEmissor: null,//(array de id de usuario),
            expirado: null,
            modelos: null, //(array de id de modelos de chamado antigo),
            modelosNovos: null, //(array de id de modelos de chamado2.0),
            inicioRange: {
              de: new Date(Date.now()).setDate(-0.1),
              ate: Date.now(),
            },
            previsaoRange: {
              de: new Date(Date.now()).setDate(-0.1),
              ate: Date.now()
            },
            status: null,
            cliente: null, //(nome do cliente),
            local: null, //(nome do local),
            conjunto: null, //(nome do conjunto),
            unidade: null,
            ordenarPor: null,
            ordem: null,
          }
          refetch()
          break;
      }
      localStorage.setItem('filtros', JSON.stringify(filter))
    }
  }

  const navigate = useNavigate();

  const { data, isLoading, isFetching, refetch, isRefetching } = useQuery({
    queryKey: ['GET_TICKETS'],
    queryFn: async (pageNmbr) => {
      const token = await AttToken()
      if (token) {
        const mocked = [];
        const filtros = filter ? filter : null
        const response = await ticketsService.getAll(token, itensPerPage, pageNumber + 1, filtros, emitidos)
        if (response.encaminhados.length > 0) {
          setOpen(true);
          setEncaminhados(response.encaminhados);
        }
        if (pageNumber) {
          for (let i = 0; i < pageNumber * itensPerPage; i += 1) {
            const mockedObj = {
              inicio: 0,
              usuarioNome: 'ABC',
              nome: 'mockedTIcket',
              dataPrevisao: 0,
              status: 'concluido',
              id: String(i)
            }
            mocked.push(mockedObj)
          }
        }
        if (mocked.length) {
          const newArray = [...mocked, ...response.objChamados]
          setTotalItens(response.totalItens)
          return newArray;
        }
        setTotalItens(response.totalItens)
        return response.objChamados
      }
    },
    retry: 5,
    refetchOnWindowFocus: false,
    refetchOnMount: 'always',
    onError: () => {
      console.log(AxiosError)
    }
  })

  const tableOptions: MUIDataTableOptions = {
    selectableRowsHeader: false,
    selectableRowsHideCheckboxes: true,
    viewColumns: false,
    print: false,
    download: false,
    filter: false,
    filterType: 'textField',
    responsive: 'vertical',
    rowsPerPageOptions: [10, 15, totalItens],
  };

  useEffect(() => {
    refetch()
  }, [pageNumber, itensPerPage, refetch])

  const columns = [
    {
      name: "inicio",
      label: t('notifications.ticket.createdAt'),
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true,
        customBodyRender: (value: number) => (
          new Date(value).toLocaleString()
        ),
      }
    },
    {
      name: 'usuarioNome',
      label: t('notifications.unread.user'),
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true,
        customBodyRender: (value: string) => (
          value?.startsWith('anonimo') ? 'Anônimo' :
            value
        ),
      }
    },
    {
      name: 'nome',
      label: t('notifications.ticket.tickets'),
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true,
      }
    },
    {
      name: 'responsavel',
      label: 'Responsável',
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true,
      }
    },
    {
      name: 'prioridade',
      label: 'Prioridade',
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true,
        customBodyRender: (value: string) => {
          switch (value) {
            case 'alta':
              return (
                <span className="tag is-danger">Alta</span>
              )
            case 'media':
              return (
                <span className="tag is-warning">Média</span>
              )
            case 'baixa':
              return (
                <span className="tag is-primary">Baixa</span>
              )
            default:
              return (
                <span className="tag is-info">NA</span>
              )
          }
        }
      }
    },
    {
      name: 'novo',
      label: 'Modelo de chamado',
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true,
        customBodyRender: (value: boolean) => {
          return (
            <>
              {value ? 'Chamado 2.0' : 'Chamado Legado'}
            </>)
        }
      }
    },
    {
      name: "dataPrevisao",
      label: t('notifications.ticket.limitDate'),
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true,
        customBodyRender: (value: number) => (
          value ?
            new Date(value).toLocaleString() : ''
        ),
      }
    },
    {
      name: "status",
      label: t('notifications.ticket.status'),
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true,
        customBodyRender: (value: string) => {
          switch (value) {
            case 'emandamento':
              return (
                <span className="tag is-info">{t('notifications.statusProgress')}</span>
              )
            case 'pendente':
              return (
                <span className="tag is-warning">{t('notifications.statusBad')}</span>
              )
            case 'concluido':
              return (
                <span className="tag is-success">{t('notifications.statusOk')}</span>
              )
            case 'recusado':
              return (
                <span className="tag is-danger">{t('notifications.ticket.refused')}</span>
              )
            case 'cancelado':
              return (
                <span className="tag is-danger">{t('notifications.ticket.canceled')}</span>
              )
            default:
              return (
                <></>
              )
          }
        }
      }
    },
    {
      name: "id",
      label: t(`ticketDetail.open`),
      options: {
        display: false,
        filter: false,
        sort: false,
        sortThirdClickReset: false,

      }
    }
  ];

  const translatedTextLabels: MUIDataTableOptions = {
    page: pageNumber,
    rowsPerPage: itensPerPage,
    count: totalItens,
    onRowClick: (rowData) => {
      navigate(`/detalheChamado/${rowData[8]}`)
    },
    onChangePage: (currentPage: number) => {
      setPageNumber(currentPage);
    },
    onChangeRowsPerPage: (numberOfRows: number) => {
      setPageNumber(0)
      setItensPerPage(numberOfRows);
    },
    search: false,
    sort: false,
    textLabels: {
      body: {
        noMatch: `${t('table.noMatch')}`,
        toolTip: `${t('table.toolTip')}`,
        columnHeaderTooltip: column =>
          `${t('table.columnHeaderTooltip')} ${column.label}`
      },
      pagination: {
        next: `${t('table.next')}`,
        previous: `${t('table.previous')}`,
        rowsPerPage: `${t('table.rowsPerPage')}`,
        displayRows: `${t('table.displayRows')}`
      },
      toolbar: {
        search: `${t('table.search')}`,
        filterTable: `${t('table.filterTable')}`
      },
      filter: {
        title: `${t('table.title')}`,
        reset: `${t('table.reset')}`
      },
      viewColumns: {
        title: `${t('table.viewColumnsTitle')}`
      },
    },
  };

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  }

  return (
    <>
      {openEncaminhados && (
        <RedirectListModal
          open={openEncaminhados}
          setOpen={setOpenEncaminhados}
          chamados={encaminhados}
        />
      )}
      <Snackbar
        open={open}
        autoHideDuration={60000e10}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={handleClose} severity="warning" sx={{ width: '100%' }}>
          <SpanChamado onClick={() => {
            setOpenEncaminhados(true);
          }}>
            {`${encaminhados?.length} ${encaminhados && encaminhados.length > 1 ? 'Chamados foram' : 'Chamado foi'} encaminhado para você`}
          </SpanChamado>
        </Alert>
      </Snackbar>
      <StyledDiv>
        {isLoading ? (
          <Skeleton height={100} width={'100%'} style={{ marginTop: '-.9em' }} />
        ) : (
          <form style={{ marginBottom: '1em' }}
            onSubmit={(e) => {
              e.preventDefault();
              setPageNumber(0);
              toast.promise(
                refetch(),
                {
                  pending: 'Filtrando Chamados...',
                  success: 'Chamados filtrados com sucesso!',
                  error: 'Erro ao filtrar Chamados!'
                }
              )
            }}
          >
            <TicketsFilters
              loadingData={isLoading}
              changeEstado={changeEstado}
              isRefetching={isRefetching}
              emitidos={emitidos}
              setEmitidos={setEmitidos}
              isFetching={isFetching}
              filter={filter}
            />
          </form>
        )}

        {isLoading || isFetching || isRefetching ? (
          <>
            <Table />
          </>
        ) :
          <>
            {show &&
              <SingleNotification />
            }
            {data && <ThemeProvider theme={getMuiTheme()}>
              <MUIDataTable
                title={t('notifications.ticket.tabtitle')}
                data={data as TicketsType[]}
                columns={columns}
                options={{ ...tableOptions, ...translatedTextLabels }}
              />
            </ThemeProvider>}
          </>
        }
      </StyledDiv >
    </>
  )
}