import axios, { AxiosError, AxiosResponse } from "axios";
import { AuthBodyType } from "./types";
import { newTicketObjType } from "../components/TicketsComponents/Modal/NewTicketModal";

class NovoChamado {
  private api = axios.create({
    baseURL: `${process.env.REACT_APP_API_HOST}/novoChamado`,
  });

  getList = async (token: AuthBodyType) => {
    try {
      const data: AxiosResponse = await this.api.post("/getList", {
        ...token,
      });
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        return error.response?.status;
      }
    }
  };

  createModel = async (token: AuthBodyType, newTicket: newTicketObjType) => {
    try {
      const data: AxiosResponse = await this.api.post("/createModel", {
        ...token,
        newTicket,
      });
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        return error.response?.status;
      }
    }
  };

  updateModel = async (token: AuthBodyType, ticket: newTicketObjType) => {
    try {
      const data: AxiosResponse = await this.api.post("/updateModel", {
        ...token,
        ticket,
      });
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        return error.response?.status;
      }
    }
  };

  deleteModel = async (token: AuthBodyType, ticketId: string) => {
    try {
      const data: AxiosResponse = await this.api.post("/deleteModel", {
        ...token,
        ticketId,
      });
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        return error.response?.status;
      }
    }
  };
}

export default NovoChamado;
