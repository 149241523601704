import { Accordion, AccordionSummary } from "@material-ui/core";
import { AiFillCloseCircle } from "react-icons/ai";
import { MdOutlineExpandMore } from "react-icons/md";
import { Label } from "../Notifications/style/SingleNotificationStyle";
import { StyledTextArea } from "../Filling/Modals/Styles";
import ReactDatePicker from "react-datepicker";
import { useMemo, useState } from "react";
import { ChamadoChecklist, chamadoConfigType, chamadoConfigTypeSubitem } from "../Filling/Types";
import { addTicketType } from "./ModalDetailPrint";
import { useTranslation } from "react-i18next";
import Anonimo from "../../service/Anonimo";
import AttToken from "../../helpers/attToken";
import { toast } from "react-toastify";

type props = {
  chamado: ChamadoChecklist
  index: number,
  setChamados: Function,
  chamados: ChamadoChecklist[],
}

function ChamadoCard({ chamado, index, setChamados, chamados }: props) {
  const [instrucao, setInstrucao] = useState(chamado.textChamado || '');
  const [expand, setExpand] = useState(false);
  const [data, setData] = useState<Date | number | undefined>(chamado.dateChamado as number || undefined);

  return (
    <div key={index} style={{ margin: '20px', backgroundColor: '#F2F2F2', width: `${chamado.adicionadoApos ? '68%' : '70%'}`, alignSelf: 'center' }}>
      <Accordion expanded={expand}>
        <AccordionSummary
          expandIcon={<MdOutlineExpandMore color="#18A689" />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          IconButtonProps={{
            onClick: () => {
              setExpand(!expand)
            }
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
            <h1 style={{ fontFamily: 'arial', fontSize: '12px', fontWeight: 500, color: 'gray' }}>{chamado.selecionado ? chamado.selecionado.nome : 'Nome não encontrado'}</h1>
          </div>
        </AccordionSummary>
        <div style={{ padding: '10px' }}>
          <Label style={{ display: 'flex', flexDirection: 'column' }}>
            Instruções do chamado:
            <StyledTextArea value={instrucao} className='textarea' onChange={(e) => {
              setInstrucao(e.target.value)
              chamados[index].textChamado = e.target.value;
              setChamados([...chamados]);
            }} disabled={!chamado.adicionadoApos} />
          </Label>
          <Label style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            Prazo do chamado:
            {/* após form */}
            <ReactDatePicker
              disabled={!chamado.adicionadoApos}
              showTimeSelect={true}
              onKeyDown={(e) => e.preventDefault()}
              timeIntervals={1}
              portalId="root-portal"
              selected={data === undefined ? undefined : new Date(data)}
              locale="pt"
              dateFormat='dd/MM/yyyy'
              wrapperClassName="inputDateStyle"
              className='inputDate input'
              required
              onChange={
                (date: Date) => {
                  setData(date);
                  chamados[index].dateChamado = new Date(date).getTime();
                  setChamados([...chamados]);
                }
              }
            />
          </Label>
          {data === undefined && <span
            style={{
              width: '100%',
              justifyContent: 'center',
              display: 'flex',
              color: 'red',
              fontWeight: 700,
              fontSize: '12px'
            }}>
            **É obrigatório informar uma data para abertura do chamado
          </span>}
        </div>
      </Accordion>
    </div>
  )
}

type ModalEditTicketsProps = {
  chamados: ChamadoChecklist[],
  open: boolean,
  setOpen: Function,
  currentElement: addTicketType,
  chamadosConfig: chamadoConfigTypeSubitem | chamadoConfigType,
  setChamados: Function,
  ClearAndExit: Function,
  formId: string,
  inicio: number,
  chamadosObj: ChamadoChecklist[] | undefined,
}

export default function ModalEditTicketsAfter({
  chamados,
  open,
  setOpen,
  currentElement,
  chamadosConfig,
  setChamados,
  ClearAndExit,
  formId,
  inicio,
  chamadosObj }: ModalEditTicketsProps) {
  const { t } = useTranslation('translation');

  const filterFunction = (index: number) => {
    setChamados((prev: ChamadoChecklist[]) => {
      return prev.filter((_e, i) => i !== index);
    });
  }

  return (
    <div className={`modal ${open ? 'is-active' : ''}`}>
      <div className="modal-background" onClick={() => setOpen()}></div>
      <div style={{ backgroundColor: 'white', width: '75%', display: 'flex', flexDirection: 'column' }} className="modal-content">
        <Label style={{ width: '75%', alignSelf: 'center', marginTop: '20px', marginBottom: '20px' }}>Adicione um chamado:
          <select className="select is-fullwidth" defaultValue={""} onChange={(event) => {
            const chamado = JSON.parse(event.target.value);
            chamados.push({ selecionado: chamado, adicionadoApos: true });
            setChamados([...chamados]);
            event.target.value = ""
          }}>
            <option value="" disabled>{t('select')}</option>
            {
              chamadosConfig.tipoChamado?.map(e => <option value={JSON.stringify(e)}>{e.nome}</option>)
            }
          </select>
        </Label>
        {chamados?.map((e, i) => (
          <div
            key={`INDEX${i}`}
            style={{
              display: 'flex',
              alignItems: 'center',
              alignSelf: 'center',
              width: '100%',
              justifyContent: 'center'
            }}
          >
            <ChamadoCard
              chamado={e}
              index={i}
              setChamados={setChamados}
              chamados={chamados}
            />
            {e.adicionadoApos &&
              <AiFillCloseCircle
                color='red'
                style={{ fontSize: '20px' }}
                onClick={() => {
                  filterFunction(i);
                }} />
            }
          </div>
        ))}
        <div style={{ display: 'flex', justifyContent: 'space-evenly', padding: '10px', backgroundColor: '#F3F3F3' }}>
          <ConfirmationModal
            formId={formId}
            inicio={inicio}
            itemId={currentElement?.itemIndex!!!}
            subId={currentElement?.subItemIndex!!!}
            chamados={chamados}
            close={setOpen}
            setChamados={setChamados}
          // data={e}
          />
          <button className="button is-light" onClick={() => {
            setOpen();
          }}>{t('close')}</button>
          <button className="button is-danger" onClick={() => { setChamados(chamados.filter((ele) => ele.dateChamado || ele.textChamado)); setOpen() }}>{t('cancel')}</button>
        </div>
      </div>
    </div>
  )
}

type confirmationProps = {
  formId: string,
  inicio: number,
  itemId: number | boolean,
  subId: number | boolean,
  chamados: ChamadoChecklist[] | undefined,
  close: Function,
  setChamados: Function,
  // data: number | Date | undefined
}

function ConfirmationModal({ formId, inicio, itemId, subId, chamados, close, setChamados }: confirmationProps) {
  const { t } = useTranslation('translation');
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const anonimoService = useMemo(() => new Anonimo(), []);

  const ArrumaChecklistsApos = () => {
    const newChamados = chamados?.map(e => {
      if (e.adicionadoApos) delete e.adicionadoApos;
      return e;
    })
    newChamados && setChamados([...newChamados])
  }

  return (
    <>
      <button disabled={!chamados?.every(e => e.dateChamado)} className="button is-success" onClick={() => {
        setOpen(true);
      }}>{t('save')}</button>
      <div className={`modal ${open ? 'is-active' : ''}`}>
        <div className="modal-background"></div>
        <div style={{ backgroundColor: 'white', width: '50%', display: 'flex', flexDirection: 'column', borderRadius: '10px' }} className="modal-content">
          <h1 style={{ fontFamily: 'sans-serif', fontWeight: 700 }}>Tem certeza que quer fazer isso? Essa ação é irreversivel!</h1>
          <div style={{ display: 'flex', justifyContent: 'space-evenly', padding: '10px', backgroundColor: '#F3F3F3' }}>
            <button
              disabled={loading}
              className="button is-success"
              onClick={async () => {
                setLoading(true);
                toast.warn('Salvando Chamados');
                const token = await AttToken();
                if (token) {
                  await anonimoService.editaChamadoExecucao(token, formId, inicio, chamados!!!, itemId, subId);
                  toast.success('Chamados Disparados!');
                  ArrumaChecklistsApos();
                  setOpen(false);
                  close();
                } else toast.error('Token não encontrado');
                setLoading(false);
              }}>{t('yes')}</button>
            <button className="button is-danger" onClick={() => setOpen(false)} disabled={loading}>{t('no')}</button>
          </div>
        </div>
      </div>
    </>
  )
}
