import { useContext, useState } from "react";
import { TabStyle } from "../../../TabsStyle/TabsStyle";
import StyleFillModal from "./StyleFillModal";
import { UserContext } from "../../../../context/UserContext";
import StyleConfig from "./StyleConfig";
import { modeloStyleType } from "../../../../service/checklistStyle";

type props = {
  editing: boolean,
  setEditing: Function,
  id: string,
  setId: Function,
  setObjectToEdit: Function,
  objectToEdit: modeloStyleType,
}

export default function EditFormDeslogadoTabs({
  editing,
  setEditing,
  setId,
  id,
  objectToEdit,
  setObjectToEdit
}: props) {
  const { popUp } = useContext(UserContext);
  const [image, setImage] = useState<string>('');

  const initialObjectToSend: modeloStyleType = {
    name: 'Nome do formulário',
    colorHeader: "#FFF",
    fontHeader: {
      fontFamily: 'Arial',
      fontSize: 30,
      color: '#000',
    },
    background: "#FFF",
    fontBackground: {
      fontFamily: 'Arial',
      fontSize: 20,
      color: '#000',
    },
    logo: image,
    associatedChecklists: [],
    enablePosMessage: false,
    enablePostMessage: false,
    enableLoop: false,
    enableRedirect: false,
    messageBefore: '',
    imageBefore: image,
    messageAfter: '',
    imageAfter: image,
    link: ''
  }

  const [objectToSend, setObjectToSend] = useState<modeloStyleType>(initialObjectToSend)
  const mapNames = {
    style: 'Editar visual',
    config: 'Editar configurações'
  }

  const [isTab, setIsTab] = useState({
    isActiveObject: 'style',
    object: [
      'style',
      'config'
    ]
  });

  const toggleClass = (index: number) => {
    setIsTab({ ...isTab, isActiveObject: isTab.object[index] })
  }

  const toggleActive = (index: number) => {
    if (isTab.object[index] === isTab.isActiveObject) {
      return "is-active"
    } else {
      return "inactve"
    }
  }
  return (
    <div className={popUp ? "modal is-active" : "modal"}>
      <div className="modal-background"></div>
      <div className="modal-card" >
        <header className="modal-card-head">
          <p className="modal-card-title" style={{ textAlign: 'center' }}>Criar layout para formulário web</p>
        </header>
        <div className="modal-card-body" style={{ padding: 0, paddingTop: 10 }}>
          <div style={{ width: '100%' }}>
            <div className="tabs is-boxed" style={{ padding: 0, paddingLeft: 10 }}>
              <ul>
                {isTab.object.map((el, index) => (
                  <li key={index} className={toggleActive(index)} onClick={() => { toggleClass(index) }}>
                    <TabStyle theme={toggleActive(index)}>{mapNames[el as keyof typeof mapNames]}</TabStyle>
                  </li>
                ))}
              </ul>
            </div>
            {isTab.isActiveObject === 'style' && (
              <StyleFillModal
                editing={editing}
                setEditing={setEditing}
                id={id} setId={setId}
                setObjectToEdit={setObjectToEdit}
                objectToEdit={objectToEdit}
                objectToSend={objectToSend}
                setObjectToSend={setObjectToSend}
                image={image}
                setImage={setImage}
                initialObjectToSend={initialObjectToSend}
              />
            )}
            {isTab.isActiveObject === 'config' && (
              <StyleConfig
                editing={editing}
                setEditing={setEditing}
                id={id} setId={setId}
                setObjectToEdit={setObjectToEdit}
                objectToEdit={objectToEdit}
                objectToSend={objectToSend}
                setObjectToSend={setObjectToSend}
                image={image}
                setImage={setImage}
                initialObjectToSend={initialObjectToSend}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}


