import React, { useContext } from 'react'
import Sidebar from '../components/Sidebar/Sidebar'
import { UserContext } from '../context/UserContext';
import { useTranslation } from 'react-i18next';
import ChangeAlert from '../components/ChangeAlert/ChangeAlert';
import Header from '../components/Header/Header';
import { HeaderText } from '../StyledComponents/pages/pages';
import PlanoDeAcaoTabs from '../components/PlanoDeAcao/PlanoDeAcaoTabs';
import Footer from '../components/Footer/Footer';

export default function PlanoDeAcao() {
  const { changed } = useContext(UserContext);
  const { t } = useTranslation('translation');
  return (
    <>
      <div style={{ display: 'flex', width: '100%', height: '100%', backgroundColor: '#2F4050' }}>
        <div style={{ height: '100svh', minHeight: '100svh', }}>
          <Sidebar />
        </div>
        <div style={{ width: '100%', backgroundColor: '#F3F3F4' }}>
          {changed && <ChangeAlert />}
          <Header />
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px 100px 0px 100px', width: '100%', marginBottom: '50px' }}>
            <HeaderText>Planos de Ação</HeaderText>
            <PlanoDeAcaoTabs />
          </div>
          <Footer />
        </div>
      </div>
    </>
  )
}
