import axios, { AxiosError, AxiosResponse } from "axios";
import { ChecklistHelpType, ItemChecklist } from "./Checklists";
import { AuthBodyType } from "./types";
import { ChamadoChecklist, chamadoConfigType } from "../components/Filling/Types";

export type equipeReport = {
  [id: string]: {
    id: string;
    nome: string;
  };
};

export type avaliacaoType = {
  nome: string;
  valor: number;
  NA?: boolean;
};

export type ItemChecklistReport = {
  id: string;
  nome: string;
  peso?: number;
  subitens: ChecklistSubitemReport[];
};

type NotificacaoModelo = {
  id: number | null;
  notificar: string | null;
};
export type ChecklistSubitemReport = {
  id: number;
  nome: string;
  itemid: string;
  obs?: string;
  itemid_old?: string | null;
  new_id?: string | null;
  avaliacao?: avaliacaoType[] | null;
  checked?: boolean | null;
  comentario?: boolean | null;
  foto?: boolean | null;
  galeriaFoto?: boolean | null;
  grupoNotificacao?: number | null;
  hideImg?: boolean | null;
  hideComent?: boolean | null;
  help?: ChecklistHelpType | null;
  minNumberVal?: number | null;
  maxNumberVal?: number | null;
  noDecimalNumber?: boolean | null;
  qtdeExataDecimalInput?: number | null;
  chamados?: ChamadoRelatorio[];
  obrigatoriedade?: {
    preenchimento?: boolean;
    conforme?: {
      img?: boolean;
      ocorrencia?: boolean;
    };
    naoConforme?: {
      img?: boolean;
      ocorrencia?: boolean;
    };
  } | null;
  padrao?: any | null;
  padraoMultiple?: any[] | null;
  padraoLista?: string[] | null;
  peso?: number | null;
  selecao?: any[] | null;
  threshold?: number | null;
  tipo: string;
  tipoChamado?: number | null;
  tipoNotificacao?: NotificacaoModelo | null;
  selecionados?: { texto: string }[];
  img?: string[];
  ocorrencia?: any;
  nota?: number;
  assinatura?: string;
};

export type ChecklistReportType = {
  img: string[]
  nome?: string,
  userName: string,
  score?: number;
  range?: string;
  abreChamado: {
    apos: boolean;
    durante: boolean;
  };
  user: string;
  camposOcultos: {
    [key: string]: string;
  };
  inicio: number | string;
  fim: number | string;
  formulario: string;
  cliente: string;
  local: string;
  conjunto: string;
  unidade: string;
  identificador: string;
  subItemIdentificador: string;
  status: boolean | string;
  foiDelegado: string;
  nomeAprovador: string;
  naoConforme: number;
  conforme: number;
  distancia: number;
  latitude: number;
  longitude: number;
  duracao: number;
  gerencia: string;
  nomeEmpresa: string;
  idCliente: string;
  equipes: equipeReport;
  agendamento: string;
  aprovacao: string;
  aprovacaoStatus: string;
  tempoPausa: number;
  versaoForm: number;
  idForm: string;
  itens: ItemChecklist[];
  username: string;
  chamadosConfig?: chamadoConfigType;
  chamados?: ChamadoChecklist[];
  id?: string;
  mandatory: boolean;
  scorePorcentagem: string;
};

export type ChamadoRelatorio = {
  dateChamado: string;
  selKey: number;
  selecionado: {
    email: string[];
    equipes: string[];
    id: number;
    mensagem: string;
    nome: string;
    tipo: string;
  };
  shown: boolean;
  textChamado: string;
};

export type reportsData = {
  userAtivo: number;
  userInativo: number;
  gerenciaAtiva: number;
  gerenciaInativa: number;
  clienteAtivo: number;
  clienteInativo: number;
  localAtivo: number;
  localInativo: number;
  equipeAtiva: number;
  equipeInativa: number;
  formAtivo: number;
  formInativo: number;
};

export type filterObj = {
  nome: string;
  id: string;
  gerencias?: string[];
};

export type filterDataType = {
  gerencias: filterObj[];
  users: filterObj[];
  clientes: filterObj[];
  tags: filterObj[];
  forms: filterObj[];
  locais: filterObj[];
  equipes: filterObj[];
};

export type filters = {
  dataInicio: number;
  dataFim: number | Date;
  users?: string[];
  clientes?: string[];
  tags?: string[];
  forms?: string[];
  locais?: string[];
  equipes?: string[];
  texto?: string | null;
};

class Reports {
  private api = axios.create({
    baseURL: `${process.env.REACT_APP_API_HOST}/relatorios`,
  });

  getData = async (body: AuthBodyType) => {
    try {
      const data: AxiosResponse = await this.api.post("/getStatistic", {
        ...body,
      });
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        return error.response?.status;
      }
    }
  };

  getFilters = async (body: AuthBodyType, filters: filters, pageNumber: number, itensPerPage: number) => {
    try {
      const data: AxiosResponse = await this.api.post("/getExecutions", {
        ...body,
        filters,
        pageNumber,
        itensPerPage
      });
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        return error.response?.status;
      }
    }
  };

  getImages = async (body: AuthBodyType, imagesList: string[]) => {
    try {
      const data: AxiosResponse = await this.api.post("/getImages", {
        ...body,
        imagesList,
      });
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        return error.response?.status;
      }
    }
  };

  getLink = async (body: AuthBodyType, user: string | null, modeloChecklist: string | null, inicio: number, projectName: string) => {
    try {
      const data: AxiosResponse = await this.api.post("/getLink", {
        ...body,
        user,
        modeloChecklist,
        inicio,
        projectName,
      });
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        return error.response?.status;
      }
    }
  };

  getExecutionById = async (id: string) => {
    try {
      const data: AxiosResponse = await this.api.post("/getExecutionById", {
        id,
      });
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        return error.response?.status;
      }
    }
  };

  getScoringValues = async (body: AuthBodyType, form: { inicio: number, user: string }) => {
    try {
      const data: AxiosResponse = await this.api.post("/getScoringValues", {
        ...body,
        form
      });
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        return error.response?.status;
      }
    }
  };
}

export default Reports;
