import { useEffect, useState } from 'react';
import { MainContainerMulti } from './Multiple/MultipleStyle';
import { Container, Input } from './Unique/UniqueStyle';
import { SubItemProps } from './Avaliacao/Avaliacao';
import SubItemComentario from './SubItemComentario';
import SubItemPhoto from './SubItemPhoto';

export default function Numero({ subItem, indexItem, onChange }: SubItemProps) {
  const [numero, setNumero] = useState<number>();

  const showMinNumberVal = (value: number) => {
    if ((value && typeof value === 'number') || value === 0) return <p>Valor mínimo: {value}</p>
  }

  const showMaxNumberVal = (value: number) => {
    if ((value && typeof value === 'number') || value === 0) return <p>Valor máximo: {value}</p>
  }

  const borderChecker = () => {
    if (Number(numero) < Number(subItem.minNumberVal)) return '0.3em #FB8E27 solid'
    if (Number(numero) > Number(subItem.maxNumberVal)) return '0.3em #FB8E27 solid'
    if (subItem?.obrigatoriedade?.preenchimento && numero === undefined) return '0.3em red solid'
    if (!subItem?.obrigatoriedade?.preenchimento && numero === undefined) return '0.3em #2196f3 solid'
    else return '0.3em #4caf50 solid'
  }

  useEffect(() => {
    if (subItem.padrao) {
      setNumero(parseInt(subItem.padrao))
      subItem.ocorrencia = parseInt(subItem.padrao)
    }
  }, [subItem])

  useEffect(() => {
    onChange && onChange()
  }, [numero])

  return (
    <MainContainerMulti>
      <Container>
        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '.5em' }}>
          <Input
            style={{
              borderBottom:
                `${borderChecker()}`
            }}
            value={numero}
            type='number'
            onChange={(e) => {
              setNumero(e.target.value as unknown as number)
              subItem.ocorrencia = e.target.value as unknown as number
            }}
            className="inputDate input"
          />
        </div>
        <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
          <div style={{ fontSize: '.8em', color: 'gray', display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
            {showMinNumberVal(subItem.minNumberVal as number)}
            {showMaxNumberVal(subItem.maxNumberVal as number)}
          </div>
        </div>
        {subItem.ocorrencia && subItem.comentario &&
          <SubItemComentario onChange={onChange} subItem={subItem} indexItem={indexItem} />}
        {subItem.ocorrencia && subItem.foto &&
          <SubItemPhoto onChange={onChange} subItem={subItem} indexItem={indexItem} />}
      </Container>
    </MainContainerMulti>
  )
}