import { useEffect, useMemo, useState } from 'react'
import { BsArrowClockwise } from 'react-icons/bs'
import { Input, Label } from '../../StyledComponents/Input/generic';
import { FilterForm, InputToggleContainer } from './Style';
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import pt from "date-fns/locale/pt";
import ReportsTableComponent from './ReportsTableComponent';
import { useTranslation } from 'react-i18next';
import Reports, { filterDataType } from '../../service/Reports';
import { Accordion, AccordionSummary } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useMutation } from '@tanstack/react-query';
import AttToken from '../../helpers/attToken';
import { toast } from 'react-toastify';
import { Skeleton } from '@mui/material';
import './styles.css';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

registerLocale('pt', pt);

export type selectedFilter = {
  dataInicio: Date | number,
  dataFim: Date | number,
  texto?: string | null,
  limite: number,
}

export default function ReportsTable({ data }: { data: filterDataType }) {
  const { t } = useTranslation('translation');
  const reportsService = useMemo(() => new Reports(), []);
  const [limit, setLimit] = useState(1000);
  const [disabled, setDisabled] = useState(true);
  const [canShow, setCanShow] = useState(false);
  const [resetRows, setResetRows] = useState(false)

  const [gerenciaNome, setGerenciaNome] = useState<{ label: string, value: string } | null>({
    value: '',
    label: '',
  });
  const [usuarioNome, setUsuarioNome] = useState<{ label: string, value: string }[] | null>([]);
  const [clienteNome, setClienteNome] = useState<{ label: string, value: string }[] | null>([]);
  const [tagNome, setTagNome] = useState<{ label: string, value: string }[] | null>([]);
  const [formNome, setFormNome] = useState<{ label: string, value: string }[] | null>([]);
  const [localNome, setLocalNome] = useState<{ label: string, value: string }[] | null>([]);
  const [equipeNome, setEquipeNome] = useState<{ label: string, value: string }[] | null>([]);

  const [filters, setFilters] = useState<selectedFilter>({
    dataInicio: new Date().setHours(0, 0, 0, 0),
    dataFim: new Date().setHours(23, 59, 59, 59),
    texto: null,
    limite: 0,
  });
  const [pageNumber, setPageNumber] = useState(0);
  const [itensPerPage, setItensPerPage] = useState(10);
  const [totalItens, setTotalItens] = useState(10);
  const [firstRender, setFirstRender] = useState(true);

  const { mutate, data: filteredData, isLoading } = useMutation({
    mutationKey: ['reportsData'],
    mutationFn: async () => {
      const token = await AttToken();
      if (firstRender) {
        setFirstRender(false);
      }
      if (token) {
        const f = {
          users: usuarioNome?.map((elem) => elem.value),  //filters.users,
          clientes: clienteNome?.map((elem) => elem.value),
          tags: tagNome?.map((elem) => elem.value),
          forms: formNome?.map((elem) => elem.value),
          locais: localNome?.map((elem) => elem.value),
          equipes: equipeNome?.map((elem) => elem.value),
          dataInicio: Number(filters.dataInicio),
          dataFim: Number(filters.dataFim),
          gerencia: gerenciaNome?.value || null,
          limite: limit,
          texto: filters.texto || null
        }
        setResetRows(true)
        const response = await reportsService.getFilters(token, f, pageNumber + 1, itensPerPage)
        if (response.checklists.length === 0) {
          toast.warn('Não encontramos relatórios para o período!')
        }
        const mocked = [];
        if (pageNumber) {
          for (let i = 0; i < pageNumber * itensPerPage; i += 1) {
            const mockedObj = {
              scorePorcentagem: 'Mocked',
              score: 0,
              range: 'Mocked',
              user: 'Mocked',
              username: 'Mocked',
              inicio: 1704078000000,
              fim: 1716301116388,
              formulario: 'Mocked',
              cliente: 'Mocked',
              local: 'Mocked',
              conjunto: 'Mocked',
              unidade: 'Mocked',
              identificador: 'Mocked',
              subItemIdentificador: 'Mocked',
              status: 'Mocked',
              foiDelegado: 'Mocked',
              nomeAprovador: 'Mocked',
              naoConforme: 'Mocked',
              conforme: 'Mocked',
              distancia: 'Mocked',
              latitude: 'Mocked',
              longitude: 'Mocked',
              duracao: 'Mocked',
              gerencia: 'Mocked',
              nomeEmpresa: 'Mocked',
              idCliente: 'Mocked',
              equipes: 'Mocked',
              agendamento: 'Mocked',
              aprovacao: 'Mocked',
              tempoPausa: 'Mocked',
              versaoForm: 'Mocked',
              idForm: 'Mocked',
              itens: 'Mocked'
            }
            mocked.push(mockedObj)
          }
        }
        if (mocked.length) {
          const newArray = [...mocked, ...response.checklists]
          setTotalItens(response.total)
          setResetRows(false);
          return newArray;
        }
        setTotalItens(response.total)
        setResetRows(false)
        return response.checklists
      }
    },
    onError: (erro) => {
      console.log(erro)
    },
  })

  useEffect(() => {
    if (!firstRender) {
      mutate()
    }
  }, [pageNumber, itensPerPage, mutate, firstRender])

  if (isLoading) return (
    <>
      <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', width: '100%', alignItems: 'center' }}>
        <Skeleton width={'100%'} height={'400px'} style={{ marginTop: '-5.5em' }} />
        <Skeleton width={'100%'} height={'700px'} style={{ marginTop: '-12em' }} />
      </div>
    </>
  )

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
        <FilterForm className='box' onSubmit={(e) => e.preventDefault()} style={{
          height: 'fit-content',
          padding: '10px',
          display: '-webkit-flex',
          flexWrap: 'wrap',
          marginBottom: '50px',
          width: '100%'
        }}>
          <span>{t('Reports.searchFilterSettings')}</span>
          <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }} className='columns'>
            <div className='column' style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', flexDirection: 'column' }}>
                <div style={{ width: '200px' }}>
                  <Label style={{ display: 'flex', flexDirection: 'column', width: '200px' }}>
                    {t('Reports.resultLimit')}
                  </Label>
                  <Input
                    style={{ width: '200px' }}
                    type='number'
                    value={limit}
                    onChange={(e) => setLimit(Number(e.target.value))}
                    className="input is-small is-fullwidth"
                  />
                </div>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  <div>
                    <Label>
                      {t('Reports.from')}
                    </Label>
                    <DatePicker
                      selected={new Date(filters.dataInicio)}
                      onChange={(date: Date) =>
                        setFilters({ ...filters, dataInicio: new Date(date && date.setHours(0, 0, 0, 0)) })}
                      locale="pt"
                      maxDate={new Date(new Date().setHours(23, 59, 59))}
                      dateFormat='dd/MM/yyyy'
                      onKeyDown={(e) => e.preventDefault()}
                      className='input is-small is-fullwidth'
                    />
                  </div>
                  <div>
                    <Label>
                      {t('Reports.until')}
                    </Label>
                    <DatePicker
                      selected={new Date(filters.dataFim)}
                      onChange={(date: Date) =>
                        setFilters({ ...filters, dataFim: new Date(date && date.setHours(23, 59, 59, 59)) })}
                      locale="pt"
                      maxDate={new Date(new Date().setHours(23, 59, 59))}
                      dateFormat='dd/MM/yyyy'
                      onKeyDown={(e) => e.preventDefault()}
                      className='input is-small is-fullwidth'
                    />
                  </div>
                </div>
              </div>

            </div>
            <div className='column' style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
              <button disabled={limit < 1} className='button is-info' onClick={() => {
                if (firstRender || (pageNumber === 0 && itensPerPage === 10)) {
                  mutate()
                  setCanShow(true);
                } else {
                  setPageNumber(0);
                  setItensPerPage(10);
                  setCanShow(true);
                }
              }}>
                <BsArrowClockwise />
                &nbsp;&nbsp;&nbsp;&nbsp;{t('filter')}
              </button>
            </div>
          </div>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <span style={{ width: '100%', textAlign: 'center', fontSize: '20px', fontWeight: '600', color: 'gray' }}>
                Filtros
              </span>
            </ AccordionSummary>
            <Autocomplete
              className='column'
              noOptionsText={'Sem gerências correspondentes'}
              size="small"
              options={data.gerencias
                ?.filter((elem) => gerenciaNome?.value !== elem.id)
                ?.sort((a, b) => { return a.nome.toLowerCase() > b.nome.toLowerCase() ? 1 : - 1 })
                ?.map((option) => ({
                  label: option.nome,
                  value: option.id,
                }))}
              value={gerenciaNome}
              renderOption={(props, option, {selected}) => (
                <li {...props} key={option.value}>
                <span>{option.label}</span>
              </li>)}
              onChange={(event: any, newValue: { label: string, value: string } | null) => {
                setGerenciaNome(newValue)
              }}
              renderInput={(params) => <TextField {...params} label={t('Reports.managementSelect')} />}
            />
            <div className='columns' style={{ padding: '0px 10px 0px 10px' }}>
              <Autocomplete
                className='column'
                noOptionsText={'Sem usuários correspondentes'}
                size="small"
                multiple
                style={{ fontSize: '10px !important' }}
                value={usuarioNome as { value: string, label: string }[]}
                id="disable-close-on-select"
                filterSelectedOptions
                options={data?.users
                  ?.filter((el) => {
                    if (gerenciaNome?.value === '' || !gerenciaNome) return el
                    if (el.gerencias) return el.gerencias.includes(gerenciaNome?.value as string);
                    else return false;
                  })
                  ?.filter((elem) => !usuarioNome?.some(user => user.value === elem.id))
                  ?.sort((a, b) => { return a.nome.toLowerCase() > b.nome.toLowerCase() ? 1 : - 1 })
                  ?.map((option) => ({
                    label: option.nome,
                    value: option.id,
                  }))}
                onChange={(event: any, newValue: { label: string, value: string }[] | null) => {
                  setUsuarioNome(newValue)
                }}
                renderOption={(props, option, {selected}) => (
                  <li {...props} key={option.value}>
                  <span>{option.label}</span>
                </li>)}
                renderInput={(params) => <TextField {...params} label={`${t('Reports.multipleUserSelect')}:`} />}
              />
              <Autocomplete
                renderOption={(props, option, {selected}) => (
                  <li {...props} key={option.value}>
                  <span>{option.label}</span>
                </li>)}
                className='column'
                noOptionsText={'Sem clientes correspondentes'}
                size="small"
                multiple
                value={clienteNome as { value: string, label: string }[]}
                id="disable-close-on-select"
                filterSelectedOptions
                options={data?.clientes
                  ?.filter((el) => {
                    if (gerenciaNome?.value === '' || !gerenciaNome) return el
                    if (el.gerencias) return el.gerencias.includes(gerenciaNome?.value as string);
                    else return false;
                  })
                  ?.filter((elem) => !clienteNome?.some(cliente => cliente.value === elem.id))
                  ?.sort((a, b) => { return a.nome.toLowerCase() > b.nome.toLowerCase() ? 1 : - 1 })
                  ?.map((option) => ({
                    label: option.nome,
                    value: option.id,
                  }))}
                onChange={(event: any, newValue: { label: string, value: string }[] | null) => {
                  setClienteNome(newValue)
                }}
                renderInput={(params) => <TextField {...params} label={t('Reports.multipleCustomerSelect')} />}
              />
            </div>
            <div className='columns' style={{ padding: '0px 10px 0px 10px' }}>
              <Autocomplete
                renderOption={(props, option, {selected}) => (
                  <li {...props} key={option.value}>
                  <span>{option.label}</span>
                </li>)}
                className='column'
                noOptionsText={'Sem tags correspondentes'}
                size="small"
                multiple
                value={tagNome as { value: string, label: string }[]}
                id="disable-close-on-select"
                filterSelectedOptions
                options={data?.tags
                  ?.filter((el) => {
                    if (gerenciaNome?.value === '' || !gerenciaNome) return el
                    if (el.gerencias) return el.gerencias.includes(gerenciaNome?.value as string);
                    else return false;
                  })
                  ?.filter((elem) => !tagNome?.some(tag => tag.value === elem.id))
                  ?.sort((a, b) => { return a.nome.toLowerCase() > b.nome.toLowerCase() ? 1 : - 1 })
                  ?.map((option) => ({
                    label: option.nome,
                    value: option.id,
                  }))}
                onChange={(event: any, newValue: { label: string, value: string }[] | null) => {
                  setTagNome(newValue)
                }}
                renderInput={(params) => <TextField {...params} label={t('Reports.multipleTagSelect')} />}
              />
              <Autocomplete
                renderOption={(props, option, {selected}) => (
                  <li {...props} key={option.value}>
                  <span>{option.label}</span>
                </li>)}
                className='column'
                noOptionsText={'Sem formulários correspondentes'}
                size="small"
                multiple
                value={formNome as { value: string, label: string }[]}
                id="disable-close-on-select"
                filterSelectedOptions
                options={data?.forms
                  ?.filter((el) => {
                    if (gerenciaNome?.value === '' || !gerenciaNome) return el
                    if (el.gerencias) return el.gerencias.includes(gerenciaNome?.value as string);
                    else return false;
                  })
                  ?.filter((elem) => !formNome?.some(tag => tag.value === elem.id))
                  ?.sort((a, b) => { return a.nome.toLowerCase() > b.nome.toLowerCase() ? 1 : - 1 })
                  ?.map((option) => ({
                    label: option.nome,
                    value: option.id,
                  }))}
                onChange={(event: any, newValue: { label: string, value: string }[] | null) => {
                  setFormNome(newValue)
                }}
                renderInput={(params) => <TextField {...params} label={'Selecione um ou mais formulários específicos:'} />}
              />
            </div>
            <div className='columns' style={{ padding: '0px 10px 0px 10px' }}>
              <Autocomplete
                renderOption={(props, option, {selected}) => (
                  <li {...props} key={option.value}>
                  <span>{option.label}</span>
                </li>)}
                className='column'
                noOptionsText={'Sem locais correspondentes'}
                size="small"
                multiple
                value={localNome as { value: string, label: string }[]}
                id="disable-close-on-select"
                filterSelectedOptions
                options={data?.locais
                  ?.filter((el) => {
                    if (gerenciaNome?.value === '' || !gerenciaNome) return el
                    if (el.gerencias) return el.gerencias.includes(gerenciaNome?.value as string);
                    else return false;
                  })
                  ?.filter((elem) => !localNome?.some(tag => tag.value === elem.id))
                  ?.sort((a, b) => { return a.nome.toLowerCase() > b.nome.toLowerCase() ? 1 : - 1 })
                  ?.map((option) => ({
                    label: option.nome,
                    value: option.id,
                  }))}
                onChange={(event: any, newValue: { label: string, value: string }[] | null) => {
                  setLocalNome(newValue)
                }}
                renderInput={(params) => <TextField {...params} label={t('Reports.multiplePlaceSelect')} />}
              />
              <Autocomplete
                renderOption={(props, option, {selected}) => (
                  <li {...props} key={option.value}>
                  <span>{option.label}</span>
                </li>)}
                className='column'
                noOptionsText={'Sem equipes correspondentes'}
                size="small"
                multiple
                value={equipeNome as { value: string, label: string }[]}
                id="disable-close-on-select"
                filterSelectedOptions
                options={data?.equipes
                  ?.filter((el) => {
                    if (gerenciaNome?.value === '' || !gerenciaNome) return el
                    if (el.gerencias) return el.gerencias.includes(gerenciaNome?.value as string);
                    else return false;
                  })
                  ?.filter((elem) => !equipeNome?.some(tag => tag.value === elem.id))
                  ?.sort((a, b) => { return a.nome.toLowerCase() > b.nome.toLowerCase() ? 1 : - 1 })
                  ?.map((option) => ({
                    label: option.nome,
                    value: option.id,
                  }))}
                onChange={(event: any, newValue: { label: string, value: string }[] | null) => {
                  setEquipeNome(newValue)
                }}
                renderInput={(params) => <TextField {...params} label={t('Reports.multipleTeamSelect')} />}
              />
            </div>
            <div className='column'>
              <Label>
                {t('Reports.specific')}
              </Label>
              <InputToggleContainer>
                <input
                  type="checkbox"
                  style={{ margin: '0 10px 10px 10px' }}
                  checked={!disabled}
                  onChange={() => setDisabled(!disabled)}
                />
                <Input
                  className='input is-fullwidth'
                  value={filters.texto?.toString()}
                  onChange={(e) => setFilters({ ...filters, texto: e.target.value })}
                  disabled={disabled}
                />
              </InputToggleContainer>
            </div>
          </ Accordion>
        </FilterForm >
      </div >
      {
        !isLoading && canShow && (
          <>
            <ReportsTableComponent
              resetRows={resetRows}
              currentItems={filteredData}
              setItensPerPage={setItensPerPage}
              setPageNumber={setPageNumber}
              pageNumber={pageNumber}
              itensPerPage={itensPerPage}
              limit={totalItens}
            />
          </>
        )
      }
    </>
  )
}