import { Card } from '@material-ui/core'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { TitleText } from '../../Teams/style/RegisteredTeamsStyle'
import { GenericHeader, TagContainer, TagSpan } from '../../../StyledComponents/Modal/generic'
import { Label } from '../../Notifications/style/SingleNotificationStyle'
import { Input } from '../../../StyledComponents/Input/generic'
import { UserContext } from '../../../context/UserContext'
import { useTranslation } from 'react-i18next'
import queryClient from '../../../service/query'
import { toast } from 'react-toastify'
import TicketsAndNotifications, { ticketType, typeToSend } from '../../../service/Tickets_Notifications'
import { useMutation } from '@tanstack/react-query'
import AttToken from '../../../helpers/attToken'
import { Team } from '../../../service/Teams'
import { userBackObj } from '../../Users/UserSearch'
import Select from '../../Skeletons/Select'
import SmallAcordeon from '../../Skeletons/SmallAcordeon'
import SmallInput from '../../Skeletons/SmallInput'
import { IoIosClose } from 'react-icons/io'

type props = {
  isEditing: boolean,
  ticket: ticketType,
  id: number,
  teams: Team[],
  users: userBackObj[],
  isLoadingTeams: boolean,
  isLoadingUsers: boolean,
  refetchTickets: Function
}

export default function AddTicketModal({
  isEditing,
  ticket,
  id,
  teams,
  isLoadingTeams,
  isLoadingUsers,
}: props) {
  const { popUp, openModal } = useContext(UserContext);
  const NotificationsService = useMemo(() => new TicketsAndNotifications(), []);

  const { t } = useTranslation('translation');

  const actualDate = useMemo(() => new Date().getTime(), []);

  const [editTicket, setEditTicket] = useState<Omit<typeToSend, 'remetente' | 'formularios' | 'notifiEm' | 'equipeLocal'>>({
    nome: '',
    mensagem: '',
    prazo: null,
    equipes: [],
    prazoHoras: null,
    tipo: 'chamado',
    id: 0,
    encaminha: false
  })

  const { mutate: create, isLoading } = useMutation({
    mutationKey: ['changeNotification'],
    mutationFn: async () => {
      const token = await AttToken()
      if (token) {
        const tipeObject: typeToSend = {
          mensagem: editTicket.mensagem,
          nome: editTicket.nome,
          tipo: editTicket.tipo,
          equipes: editTicket.equipes,
          remetente: null,
          formularios: null,
          prazo: editTicket.prazo || null,
          prazoHoras: editTicket.prazoHoras || null,
          id: null,
          equipeLocal: null,
          notifiEm: null,
          encaminha: editTicket.encaminha
        }
        await NotificationsService.createTypes(token, tipeObject)
        toast.warn(`${t('notifications.ticket.waitTicket')}`);
      }
    },
    onSuccess: () => {
      queryClient.resetQueries(['GET_TICKETS'])
      openModal();
      toast.success(`${t('notifications.ticket.ticketCreated')}`);

    },
    onError: () => {
      toast.error(`${t('notifications.ticket.errorCreatetTicket')}`);
    }
  })

  const { mutate: edit, isLoading: isLoadingEdit } = useMutation({
    mutationKey: ['changeNotification'],
    mutationFn: async () => {
      const token = await AttToken()
      if (token) {
        const tipeObject: typeToSend = {
          mensagem: editTicket.mensagem,
          nome: editTicket.nome,
          tipo: editTicket.tipo,
          equipes: editTicket.equipes,
          remetente: null,
          formularios: null,
          prazo: editTicket.prazo || null,
          prazoHoras: editTicket.prazoHoras || null,
          id: editTicket.id,
          equipeLocal: null,
          notifiEm: null,
          encaminha: editTicket.encaminha
        }
        await NotificationsService.updateTypes(token, id, tipeObject)
        toast.warn(`${t('notifications.ticket.waitEditingTicket')}`);
      }
    },
    onSuccess: () => {
      queryClient.resetQueries(['GET_TICKETS'])
      openModal();
      toast.success(`${t('notifications.ticket.ticketEdited')}`);

    },
    onError: () => {
      toast.error(`${t('notifications.ticket.errorEditTicket')}`);
    }
  });

  useEffect(() => {
    if (isEditing && ticket) {
      setEditTicket({
        nome: ticket.nome,
        mensagem: ticket.mensagem,
        prazo: ticket.prazo as number | null,
        equipes: ticket.equipes as string[],
        prazoHoras: ticket.prazoHoras as number | null,
        tipo: 'chamado',
        id: Number(ticket.id),
        encaminha: ticket.encaminha || false
      })
    }
  }, [isEditing, ticket, actualDate]);

  const findTeamsName = (value: string) => {
    const teamName = teams && teams.find((el: Team) => el.id === value);
    if (teamName) return teamName.nome
    else return 'teste';
  }

  return (
    <Card>
      <TitleText>
        <div className={`modal ${popUp ? "modal is-active" : "modal"}`}>
          <div className="modal-background" onKeyDown={() => { }} onClick={() => { }} />
          <div className="modal-card" onSubmit={(e: React.FormEvent) => e.preventDefault()}>
            <header className="modal-card-head" style={{ display: "flex", flexDirection: "column" }}>
              <GenericHeader>
                {isEditing ?
                  t('notificationsAndTickets.tickets.ticketEditing') :
                  t('notificationsAndTickets.tickets.createTicket')}
              </GenericHeader>
            </header>
            <div style={{ justifyContent: 'space-around' }} className="modal-card-body">
              {isLoadingTeams || isLoadingUsers ? (
                <>
                  <div style={{ marginBottom: '.5em' }}>
                    <Select />
                  </div>
                  <div style={{ marginBottom: '.5em' }}>
                    <Select />
                  </div>
                  <div style={{ marginBottom: '2em' }}>
                    <Select />
                  </div>
                  <div style={{ marginBottom: '.5em' }}>
                    <SmallAcordeon />
                  </div>
                  <div style={{ marginBottom: '.5em', display: 'flex', justifyContent: 'space-around' }}>
                    <SmallInput />&nbsp;&nbsp;&nbsp;&nbsp;<SmallInput />
                  </div>
                </>
              ) :
                <>
                  <Label>{t('name')}</Label>
                  <Input
                    className="input is-small"
                    type="text"
                    value={editTicket.nome}
                    placeholder={t('name')}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEditTicket({ ...editTicket, nome: e.target.value })}
                  />
                  <Label>{t('team')}</Label>
                  <TagContainer>
                    {editTicket.equipes && editTicket.equipes.map((e: string, i) => (
                      <TagSpan key={i}
                        onClick={() => {
                          setEditTicket({ ...editTicket, equipes: editTicket.equipes.filter(el => el !== e) })
                        }}>
                        {findTeamsName(e)}&nbsp;&nbsp;
                        <IoIosClose />
                      </TagSpan>
                    ))}
                  </TagContainer>
                  <select
                    disabled={!teams}
                    defaultValue=''
                    className="select is-small is-fullwidth"

                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                      setEditTicket({ ...editTicket, equipes: editTicket.equipes ? [...editTicket.equipes, e.target.value] : [e.target.value] })
                    }}
                  >
                    <option value=''>{t('select')}</option>
                    {
                      teams && teams.sort((a: Team, b: Team) => {
                        return a.nome.toLowerCase() > b.nome.toLowerCase() ? 1 : - 1
                      }).map((e: Team, i: number) =>
                      (
                        <option
                          key={i}
                          disabled={editTicket.equipes && editTicket.equipes.includes(e.id)}
                          value={e.id}
                        >
                          {e.nome}
                        </option>
                      ))
                    }
                  </select>
                  {/* <Label>{t('customerPage.responsible')}</Label>
                  <select
                    disabled={teams && teams.length < 1}
                    defaultValue=''
                    className="select is-small is-fullwidth"
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                      setUser(e.target.value)
                    }}
                  >
                    <option value=''>{t('select')}</option>
                    {
                      users && users.sort((a: userBackObj, b: userBackObj) => {
                        return a.aliasname.toLowerCase() > b.aliasname.toLowerCase() ? 1 : - 1
                      }).filter(e => e.status === 'ativo').map((e: userBackObj, i: number) =>
                      (
                        <option
                          key={i}
                          disabled={editTicket.equipes && editTicket.equipes.includes(e.aliasname)}
                          value={e.aliasname}
                        >
                          {e.nome}
                        </option>
                      ))
                    }
                  </select> */}
                  <div style={{ marginBottom: '60px', display: 'flex', flexDirection: 'column' }}>
                    {/* <label className="checkbox" style={{ alignSelf: 'flex-start', fontSize: '15px' }}>
                      <input
                        type="checkbox"
                      />&nbsp;
                      {t('notificationsAndTickets.tickets.Obrigar_aprovacao')}
                    </label> */}
                    <label className="checkbox" style={{ alignSelf: 'flex-start', fontSize: '15px' }}>
                      <input
                        onChange={(e) => setEditTicket(prev => ({ ...prev, encaminha: e.target.checked }))}
                        type="checkbox"
                        checked={editTicket.encaminha || false}
                      />&nbsp;
                      Chamado Redirecionavel
                    </label>
                  </div>
                  <Label>{t('message')}</Label>
                  <textarea
                    onChange={(e) => setEditTicket({ ...editTicket, mensagem: e.target.value })}
                    value={editTicket.mensagem}
                    className="textarea"
                    placeholder={t("message")}
                  />
                  <Label>{t('notificationsAndTickets.tickets.term')}</Label>
                  <div className='columns'>
                    <div className='column'>
                      <Label>Dias:</Label>
                      <input
                        value={Number(editTicket.prazo) || undefined}
                        onChange={(e) => setEditTicket({ ...editTicket, prazo: Number(e.target.value) })}
                        className='input is-small is-fullwidth'
                        type='number'
                      />
                    </div>
                    <div className='column'>
                      <Label>{t('hour')}:</Label>
                      <input
                        value={Number(editTicket.prazoHoras) || undefined}
                        onChange={(e) => setEditTicket({ ...editTicket, prazoHoras: Number(e.target.value) })}
                        className='input is-small is-fullwidth'
                        type='number'
                      />
                    </div>
                  </div>
                </>}
            </div>
            <div className='modal-card-foot' style={{ display: "flex", justifyContent: "space-between" }}>
              <button
                disabled={!editTicket.mensagem?.length || !editTicket.equipes || !editTicket.nome?.length || (isLoadingEdit || isLoading)}
                type='submit'
                className="button is-success is-small"
                onClick={isEditing ? () => edit() : () => create()}
              >
                {isLoading || isLoadingEdit ? t('saving') : `${t('save')}`}
              </button>
              <button
                disabled={isLoading || isLoadingEdit}
                type="submit"
                onClick={() => openModal()}
                className="button is-small is-warning"
              >
                {isLoading || isLoadingEdit ? t('saving') : t('cancel')}
              </button>
            </div>
          </div>
        </div>
      </TitleText>
    </Card>
  )
}
