import { useContext } from "react";
import { UserContext } from "../context/UserContext";
import { signal } from '@preact/signals-react';

const PicturePreview = ({ el }: { el: string }) => {
  const { ticket } = useContext(UserContext);

  const imgPreview = signal('');
  let openImgPreview = signal(false);

  return (
    <div style={{ height: '100px', display: 'flex' }}>
      <img
        onClick={() => {
          if (ticket?.historicoChamado) {
            imgPreview.value = ticket.historicoChamado[el].foto
            openImgPreview.value = true
          }
        }}
        height='100%'
        className="column"
        src={ticket && ticket.historicoChamado && ticket.historicoChamado[el].foto}
        alt=""
      />
    </div>
  )
}

export default PicturePreview;